/**
 *
 * ReportMonitoringModalTable
 *
 */

import React from 'react';
import { Box, Table as ChakraTable, SystemStyleObject, Tbody, Td, Th, Thead, Tr, Flex } from '@chakra-ui/react';
import { IColumn, IRow, IStyle } from 'types/table';

interface ReportMonitoringModalTableProps {
	columns: IColumn[];
	data: IRow[];
	headerCustomStyle?: SystemStyleObject;
	cellCustomStyle?: SystemStyleObject;
}

const ReportMonitoringModalTable = ({
	columns,
	data,
	cellCustomStyle,
	headerCustomStyle,
}: ReportMonitoringModalTableProps) => {
	const getStyle = ({ column, isHeader = false, row }: IStyle) => {
		let style = { ...styles?.cell, ...column?.columnCustomStyle };

		if (column?.sticky) style = { ...style, ...styles[column?.sticky] };

		if (isHeader) style = { ...style, ...styles?.header };

		if (row) style = { ...style, ...row };

		return style;
	};

	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			maxW: { base: '100%', lg: '59.25rem' },
			overflowX: 'auto',
			overflowY: 'auto',
			minHeight: '8rem',
			fontWeight: 'normal',
			borderRadius: '0.25rem',

			gap: { base: '1rem' },

			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
			},
		},
		content: {
			minW: 'auto',
			'th:first-of-type': {
				paddingLeft: ' 2rem',
				paddingRight: '0',
				borderRight: '1px solid #E1E2E5',
			},

			'td:first-of-type': {
				paddingLeft: '2rem',
				width: '3rem',
				borderRight: '1px solid #E1E2E5',
			},
			'td:last-of-type[aria-label="N/A"]': {
				color: '#000',
			},
			'td:last-of-type': {
				color: '#2a89d8',
			},
			td: {
				width: '3rem',
				height: '3rem',
			},

			th: {
				width: '3rem',
				textAlign: 'center',
				fontWeight: 'bold',
				height: '4rem',
				borderBottom: 'none',
			},
			'td:nth-of-type(2)': {
				textAlign: 'left',
				width: '36rem',
			},

			height: 'auto',
			maxHeight: '70vh',
		},
		header: {
			bgColor: '#ddeffd',
			alignItems: 'center',
			color: 'black',
			gap: '0.75rem',
			fontWeight: 'bold',
			...headerCustomStyle,
		},
		cell: {
			background: '#ddeffd',
			...cellCustomStyle,
		},

		left: {
			position: 'sticky',
			left: 0,
		},
		right: {
			position: 'sticky',
			right: 0,
		},
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<ChakraTable variant={'reduceReport'}>
					<Thead>
						<Tr>
							{columns?.map(column => (
								<Th
									key={column?.key}
									sx={getStyle({
										column,
										isHeader: true,
									})}
								>
									<Flex sx={styles.header}>{column?.label}</Flex>
								</Th>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{data?.map((data, index) => (
							<Tr key={index}>
								{columns?.map(column => (
									<Td
										sx={getStyle({
											column,
											index,
											row: data?.row as SystemStyleObject,
										})}
										aria-label={data[column?.key] as string}
										key={`${index}-${column?.key}`}
									>
										{data[column?.key] as string}
									</Td>
								))}
							</Tr>
						))}
					</Tbody>
				</ChakraTable>
			</Box>
		</Box>
	);
};

export default ReportMonitoringModalTable;
