import { IAttributionAgreedForm } from '.';
import GoalWeightInput from './GoalWeightInput';
import { Box, Grid, SystemStyleObject, Text } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';

interface GoalWeightGroupProps {
	nestedIndex: number;
	control: Control<IAttributionAgreedForm>;
	fieldErrors: any;
	isReadOnly?: boolean;
	onHandleReplicateBimester: () => void;
}

const GoalWeightGroup = ({
	nestedIndex,
	control,
	fieldErrors,
	isReadOnly,
	onHandleReplicateBimester,
}: GoalWeightGroupProps) => {
	const styles: Record<string, SystemStyleObject> = {
		title: {
			color: 'black.800',
			fontSize: '1.25rem',
			fontWeight: 'bold',
			paddingBottom: '1.5rem',
			paddingTop: '1.5rem',
		},
		gridFields: {
			gap: '3.75rem',
			rowGap: '2rem',
			gridTemplateColumns: {
				md: 'repeat(1, 1fr)',
				xl: 'repeat(2, 1fr)',
			},
			flexWrap: 'wrap',
		},
	};

	const { fields } = useFieldArray({
		name: `pactuados[${nestedIndex}].periodos` as `pactuados.0.periodos`,
		control,
	});

	return (
		<>
			{fields.map((item, index) => (
				<Box key={item.id}>
					<Text sx={styles.title}>{item.ano}</Text>
					<Grid sx={styles.gridFields}>
						<GoalWeightInput
							control={control}
							nestedIndex={nestedIndex}
							nestedGroupIndex={index}
							fieldErrors={fieldErrors}
							isReadOnly={isReadOnly}
							onReplicateBimester={onHandleReplicateBimester}
						/>
					</Grid>
				</Box>
			))}
		</>
	);
};

export default GoalWeightGroup;
