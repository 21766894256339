/**
 *
 * InputTagAutocomplete
 *
 */
import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	Input,
	InputProps,
	List,
	ListItem,
	SystemStyleObject,
	Tag,
	TagCloseButton,
	TagLabel,
} from '@chakra-ui/react';
import { IOption } from 'types/select';
import mergeRefs from 'utils/mergeRefs';

interface InputTagAutocompleteProps extends InputProps {
	options: IOption[];
	result: IOption[];
	setResult: (options: IOption[]) => void;
	allowCreation?: boolean;
	bgHoverColor?: string;
	disableRenderTag?: boolean;
	notFoundText?: string;
	placeholder?: string;
	renderTag?: (option: IOption, tagsThatCanBeRemoved: string[]) => React.ReactNode;
	tagsThatCanBeRemoved: string[];
}

const defaultRenderTag = (option: IOption, tagsThatCanBeRemoved: string[]) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			border: '0.063rem solid',
			borderRadius: '0.813rem',
			borderColor: 'gray.95',
			background: 'white',
			height: '2rem',
			alignItems: 'center',
			boxShadow: '0.063rem 0.125rem 0.188rem #0000000D',
			px: '2',
			mx: 1,
		},
		text: {
			color: 'black.800',
			fontFamily: 'Lato',
			fontWeight: '600',
			fontSize: '1rem',
		},
		button: {
			color: 'black.800',
			height: '1rem',
		},
	};

	const canBeRemovided = tagsThatCanBeRemoved.includes(option.label);
	return (
		<Tag sx={styles.container} cursor={canBeRemovided ? 'pointer' : 'default'}>
			<TagLabel sx={styles.text}>{option.label}</TagLabel>
			{canBeRemovided && <TagCloseButton sx={styles.button} />}
		</Tag>
	);
};

const InputTagAutocomplete = forwardRef<HTMLInputElement, InputTagAutocompleteProps>(
	(
		{
			options,
			result,
			setResult,
			bgHoverColor,
			allowCreation,
			notFoundText,
			renderTag = defaultRenderTag,
			disableRenderTag = false,
			tagsThatCanBeRemoved,
			...rest
		}: InputTagAutocompleteProps,
		ref,
	) => {
		const styles: Record<string, SystemStyleObject> = {
			base: {
				gap: '1rem',
				flexDirection: { base: 'column', md: 'row' },
				flexWrap: 'wrap',
			},
			boxList: {
				display: 'block',
				height: '1.875rem',
			},
			list: {
				marginTop: '-0.813rem',
				border: '0.063rem solid',
				borderRadius: '0.813rem',
				borderColor: 'gray.95',
				background: 'white',
				boxShadow: '0.375rem 0.313rem 0.5rem rgba(0,50,30,0.02)',
				position: 'relative',
				zIndex: '99',
				paddingTop: '0.938rem',
			},
			input: {
				border: '0.063rem solid',
				borderRadius: '0.813rem',
				borderColor: 'gray.95',
				background: 'white',
				height: '2rem',
				alignItems: 'center',
				boxShadow: '0.063rem 0.125rem 0.188rem #0000000D',
				zIndex: '100',
			},
			tagsGrid: {
				gap: '1rem',
				flexWrap: 'wrap',
				w: 'fit-content',
			},
			button: {
				display: 'flex',
				fontSize: '1rem',
				color: 'black.800',
				height: 'auto',
				paddingLeft: '0.313rem',
				alignSelf: { base: 'flex-start', md: 'center' },
			},
			listItem: {
				my: 1,
				p: 2,
				cursor: 'pointer',
				_hover: {
					bg: bgHoverColor || 'none',
				},
			},
		};

		const [optionsCopy, setOptionsCopy] = useState<IOption[]>(
			options.filter(opt => {
				return !(result.filter(tag => tag.value === opt.value).length > 0);
			}),
		);

		useEffect(() => {
			setOptionsCopy(
				options.filter(opt => {
					return !(result.filter(tag => tag.value === opt.value).length > 0);
				}),
			);
		}, [result, options]);

		const [partialResult, setPartialResult] = useState<IOption[]>();
		const [displayOptions, setDisplayOptions] = useState<boolean>(false);
		const [inputValue, setInputValue] = useState<string>();
		const [inputTag, setInputTag] = useState<boolean>(false);
		const inputRef = useRef<HTMLInputElement>(null);

		const filterOptions = (value: string) => {
			if (value) {
				setDisplayOptions(true);
				const result = optionsCopy.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
				setPartialResult(result);
				setInputValue(value);
			} else {
				setDisplayOptions(false);
			}
		};

		const selectOption = (option: IOption) => {
			const optionsIsInResults = result.includes(option);
			if (optionsIsInResults && tagsThatCanBeRemoved.includes(option.label as string)) {
				setResult([...result.filter(existingOption => existingOption.value !== option.value)]);
				setOptionsCopy([...optionsCopy, option]);
			} else if (!optionsIsInResults) {
				setResult([...result, option]);
				setOptionsCopy(optionsCopy.filter(item => item.value !== option.value));
			}
		};

		const findOption = (value: string) => {
			return options.filter(selectedOption => selectedOption.label === value);
		};

		const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				const optionKey = findOption(event.currentTarget.value);

				if (optionKey && optionKey.length === 1) {
					selectOptionFromList(optionKey[0]);
				}
			}
		};

		const createOption = () => {
			if (inputValue && allowCreation) {
				const newOption: IOption = {
					label: inputValue,
					value: inputValue,
				};
				setOptionsCopy([newOption, ...optionsCopy]);
				selectOption(newOption);
				setDisplayOptions(false);
				if (inputRef && inputRef.current !== null) {
					inputRef.current.value = '';
				}
			}
		};

		const selectOptionFromList = (option: IOption) => {
			selectOption(option);
			setDisplayOptions(false);
			if (inputRef && inputRef.current !== null) {
				inputRef.current.value = '';
			}
			setInputTag(false);
		};

		const handleClick = () => {
			setInputTag(!inputTag);
		};

		const createInputTag = () => {
			return (
				<>
					{inputTag && (
						<Box sx={styles.boxList}>
							<Input
								onChange={e => filterOptions(e.currentTarget.value)}
								onKeyDown={handleKeyDown}
								ref={mergeRefs([inputRef, ref])}
								{...rest}
								sx={styles.input}
								data-testid="autocomplete-input"
							/>

							{displayOptions && (
								<List data-testid="options-list" sx={styles.list}>
									{partialResult?.map(option => (
										<ListItem key={option.label} sx={styles.listItem} onClick={() => selectOptionFromList(option)}>
											<Flex align="center">{option.label}</Flex>
										</ListItem>
									))}
									{!partialResult?.length && allowCreation && (
										<ListItem sx={styles.listItem} data-testid="create-option" onClick={() => createOption()} />
									)}
									{!partialResult?.length && !allowCreation && (
										<ListItem my={1} p={2} data-testid="not-found">
											<Flex align="center">{notFoundText}</Flex>
										</ListItem>
									)}
								</List>
							)}
						</Box>
					)}
					<Button
						leftIcon={<AddIcon boxSize={2} color="#000" />}
						onClick={handleClick}
						variant={'unstyled'}
						sx={styles.button}
						data-testid="add-button"
					>
						Adicionar
					</Button>
				</>
			);
		};

		return (
			<Flex data-testid="simple-autocomplete" sx={styles.base}>
				{result.length > 0 && !disableRenderTag && (
					<Flex sx={styles.tagsGrid}>
						{result.map(option => (
							<Flex onClick={() => selectOption(option)} key={option.label}>
								{renderTag(option, tagsThatCanBeRemoved)}
							</Flex>
						))}
						{createInputTag()}
					</Flex>
				)}

				{!result.length && createInputTag()}
			</Flex>
		);
	},
);

export default InputTagAutocomplete;
