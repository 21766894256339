/**
 *
 * ResulterIndicatorCard
 *
 */

import { Box, SystemStyleObject, Tooltip } from '@chakra-ui/react';

interface ResulterTooltipProps {
	label: string;
	icon: JSX.Element;
}

const ResulterTooltip = ({ label, icon }: ResulterTooltipProps) => {
	const styles: Record<string, SystemStyleObject> = {
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
	};
	return (
		<Tooltip label={label} placement="top-start" sx={styles.tooltip}>
			<Box>{icon}</Box>
		</Tooltip>
	);
};

export default ResulterTooltip;
