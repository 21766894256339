import { api } from '.';
import cleanDeep from 'clean-deep';
import { getAxios } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import { IReportMonitoringInfo, IReportMonitoringResponse } from 'types/reportMonitoring';

export interface ReportMonitoringData {
	desempenhoInstituicional: number;
	relatorios: IReportMonitoringInfo[];
}

export interface ReportMonitoringRequest {
	etapa?: string;
	periodo: string;
	bimestre?: string[];
	unidade?: number[];
	pactuado?: number[];
	indicador?: number[];
}

export interface IRequestDownloadAgreedPerformance {
	requestParam: string;
	requestBody: IReportMonitoringResponse;
}

export const getReportMonitoringList = async (values?: ReportMonitoringRequest): Promise<IReportMonitoringResponse> => {
	const paramsParse = cleanDeep({
		etapa: reduceToArray(values?.etapa),
		periodo: values?.periodo,
		bimestre: reduceToArray(values?.bimestre),
		unidade: reduceToArray(values?.unidade, true),
		pactuado: reduceToArray(values?.pactuado, true),
		indicador: reduceToArray(values?.indicador, true),
	});

	const { data } = await api.get(ENDPOINTS.getReportMonitoring, {
		params: paramsParse,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return data;
};

const reduceToArray = (value: unknown, shouldConvertToNumber = false) => {
	if (Array.isArray(value)) {
		if (shouldConvertToNumber) {
			return value.map(item => Number(item));
		}

		return value;
	}

	return [shouldConvertToNumber && value ? Number(value) : value];
};

export const downloadCompleteReportMonitoringXlxs = async (data: IReportMonitoringResponse): Promise<Blob> => {
	const api = getAxios();
	const response = await api.post<File>(ENDPOINTS.downloadCompleteReportXlsx, data, {
		...data,
		responseType: 'blob',
	});
	return response.data;
};

export const downloadCompleteReportMonitoringPdf = async (data: IReportMonitoringResponse): Promise<Blob> => {
	const api = getAxios();
	const response = await api.post<File>(ENDPOINTS.downloadCompleteReportPdf, data, {
		...data,
		responseType: 'blob',
	});
	return response.data;
};

export const downloadReducedReportMonitoringXlxs = async (data: IReportMonitoringResponse): Promise<Blob> => {
	const api = getAxios();
	const response = await api.post<File>(ENDPOINTS.downloadReducedReportXlsx, data, {
		...data,
		responseType: 'blob',
	});
	return response.data;
};

export const downloadReducedReportMonitoringPdf = async (data: IReportMonitoringResponse): Promise<Blob> => {
	const api = getAxios();
	const response = await api.post<File>(ENDPOINTS.downloadReducedReportPdf, data, {
		...data,
		responseType: 'blob',
	});
	return response.data;
};

export const downloadDetailedReport = async (data: IRequestDownloadAgreedPerformance): Promise<Blob> => {
	const api = getAxios();
	const response = await api.post<File>(ENDPOINTS.downloadDetailedReport(data?.requestParam), data?.requestBody, {
		...data,
		responseType: 'blob',
	});
	return response.data;
};
