/**
 *
 * FileModal
 *
 */
import InputFileModal from './InputFileModal';
import CustomModal from '../CustomModal';
import { Fragment, useState } from 'react';
import {
	Button,
	SystemStyleObject,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
} from '@chakra-ui/react';
import { ClipIcon } from 'assets/icons';
import { Control, Controller } from 'react-hook-form';
import { RESULTER_SCREEN_SHOW_ARCHIVES_ETAPA_DOIS, STEPS_ENUM } from 'utils/constants';

export interface IFile {
	id?: number | string;
	name?: string;
	size?: number;
	type?: string;
	linkBy?: string;
	file?: any;
}

export interface IModalDocs {
	planoAcao?: IFile;
	causaRaiz?: IFile;
	analiseCritica?: IFile;
}

export type FileTypes = 'analiseCritica' | 'planoAcao' | 'causaRaiz';

interface FileModalProps {
	defaultValues?: IModalDocs;
	label?: string;
	subLabel?: string;
	required?: boolean;
	disabled?: boolean;
	name?: string;
	onChangeDoc?: (type: FileTypes, doc: IFile) => void;
	onDownload?: (file: IFile) => void;
	onDelete?: (file: IFile) => void;
	acceptValues?: string;
	error?: any;
	control: Control<IModalDocs, any>;
	phase?: string;
	step?: string;
}

const FileModal = ({
	defaultValues,
	label,
	subLabel,
	required,
	disabled,
	onChangeDoc,
	onDownload,
	onDelete,
	acceptValues,
	error,
	control,
	phase,
	step,
}: FileModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		principal: {
			width: '34.625rem',
			maxWidth: '34.625rem',
		},
		container: {
			flex: '1',
			flexDir: 'column',
		},

		button: {
			w: '15.688rem',
			maxW: '15.688rem',
			borderRadius: '0.25rem',
			fontSize: '1rem',
		},
	};

	const [selectedToDelete, setSelectedToDelete] = useState<IFile>({} as IFile);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();

	const filesArray = [
		{
			title: 'Anexar plano de ação',
			name: 'planoAcao',
			acceptValues: acceptValues,
			file: defaultValues?.planoAcao,
			key: 'acao' + defaultValues?.planoAcao?.id,
			id: `${'upload-acao' + defaultValues?.planoAcao?.id}`,
			label: label,
			onChangeDoc: (file: IFile) => onChangeDoc?.('planoAcao', file),
			onDeleteDoc: onDelete,
			onDownloadDoc: onDownload,
			subLabel: subLabel,
			disabled: disabled,
			required: required,
			error: !!error?.planoAcao,
		},
		{
			title: 'Anexar causa raiz',
			name: 'causaRaiz',
			acceptValues: acceptValues,
			file: defaultValues?.causaRaiz,
			key: 'causa' + defaultValues?.causaRaiz?.id,
			id: 'upload-causa' + defaultValues?.causaRaiz?.id,
			label: label,
			onChangeDoc: (file: IFile) => onChangeDoc?.('causaRaiz', file),
			onDeleteDoc: onDelete,
			onDownloadDoc: onDownload,
			subLabel: subLabel,
			disabled: disabled,
			required: required,
			error: !!error?.causaRaiz,
		},
		{
			title: 'Anexar análise crítica',
			name: 'analiseCritica',
			acceptValues: acceptValues,
			file: defaultValues?.analiseCritica,
			key: 'analise' + defaultValues?.analiseCritica?.id,
			id: 'upload-analise' + defaultValues?.analiseCritica?.id,
			label: label,
			onChangeDoc: (file: IFile) => onChangeDoc?.('analiseCritica', file),
			onDeleteDoc: onDelete,
			onDownloadDoc: onDownload,
			subLabel: subLabel,
			disabled: disabled,
			required: required,
			error: !!error?.analiseCritica,
		},
	];

	const filesArrayLimited = [
		{
			title: 'Anexar análise crítica',
			name: 'analiseCritica',
			acceptValues: acceptValues,
			file: defaultValues?.analiseCritica,
			key: 'analise' + defaultValues?.analiseCritica?.id,
			id: 'upload-analise' + defaultValues?.analiseCritica?.id,
			label: label,
			onChangeDoc: (file: IFile) => onChangeDoc?.('analiseCritica', file),
			onDeleteDoc: onDelete,
			onDownloadDoc: onDownload,
			subLabel: subLabel,
			disabled: disabled,
			required: required,
			error: !!error?.analiseCritica,
		},
	];

	const handleDelete = () => {
		onDelete?.(selectedToDelete);
		onDeleteModalClose();
	};

	const onDeleteDoc = (file: IFile) => {
		onDeleteModalOpen();
		setSelectedToDelete(file);
	};

	return (
		<>
			<Button
				sx={styles.button}
				variant="primary"
				data-testid="btn-open--modal"
				rightIcon={<ClipIcon />}
				onClick={onOpen}
			>
				Visualizar/Anexar Arquivo
			</Button>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent sx={styles.principal}>
					<ModalHeader fontSize="1rem" marginLeft="10px" color="#000" fontWeight="normal">
						Anexar um novo arquivo
					</ModalHeader>
					<ModalCloseButton />
					<FormControl>
						<ModalBody display="flex" alignItems="center">
							{step === STEPS_ENUM.ETAPA_DOIS && !RESULTER_SCREEN_SHOW_ARCHIVES_ETAPA_DOIS.includes(phase!)
								? filesArrayLimited.map((item, index) => (
										<Fragment key={index}>
											<Controller
												name={item.name as FileTypes}
												control={control}
												render={() => (
													<InputFileModal
														title={item.title}
														name={item.name}
														acceptValues={item.acceptValues}
														file={item.file}
														key={item.key}
														label={item.label}
														onChangeDoc={item.onChangeDoc}
														onDeleteDoc={onDeleteDoc}
														onDownloadDoc={item.onDownloadDoc}
														subLabel={item.subLabel}
														id={item.id}
														disabled={item.disabled}
														required={item.required}
														error={item.error}
													/>
												)}
											/>
										</Fragment>
								  ))
								: filesArray.map((item, index) => (
										<Fragment key={index}>
											<Controller
												name={item.name as FileTypes}
												control={control}
												render={() => (
													<InputFileModal
														title={item.title}
														name={item.name}
														acceptValues={item.acceptValues}
														file={item.file}
														key={item.key}
														label={item.label}
														onChangeDoc={item.onChangeDoc}
														onDeleteDoc={onDeleteDoc}
														onDownloadDoc={item.onDownloadDoc}
														subLabel={item.subLabel}
														id={item.id}
														disabled={item.disabled}
														required={item.required}
														error={item.error}
													/>
												)}
											/>
										</Fragment>
								  ))}
						</ModalBody>
					</FormControl>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você irá remover esse anexo."
				body="Tem certeza que deseja realizar essa ação?"
				isOpen={isDeleteModalOpen}
				onClose={onDeleteModalClose}
				actions={[
					{
						label: 'Quero excluir',
						type: 'cancel',
						onClick: () => handleDelete(),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: onDeleteModalClose,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default FileModal;
