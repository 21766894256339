/**
 *
 * PerformanceRangeList
 *
 */

import { useMemo, useState } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, SystemStyleObject, Spinner, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import PageWrapper from 'app/components/PageWrapper';
import PerformanceRangeCard from 'app/components/PerformanceRangeCard';
import PerformanceRangeFilter, {
	IPerformanceRangeFilterForm,
	PerformanceListParamsKeys,
} from 'app/components/PerformanceRangeFilter';
import { RegisterIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	deletePerformanceRangeCard,
	getPerformanceRange,
	updatePerformanceRangeStatus,
} from 'services/http/performanceRange';
import { IResponseErrors } from 'types/httpError';
import { IPerformanceRange } from 'types/performanceRange';
import { API_DEFAULT_ERROR, USER_ROLES } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parseUrlParams } from 'utils/parseUlrParams';

const PerformanceRangeList = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		button: {
			alignSelf: 'flex-end',
			px: '1rem',
		},
		content: {
			flexDir: 'column',
			gap: '1rem',
			mt: '1.5rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		addPerformanceRange: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '2rem',
			flexDirection: { base: 'column', lg: 'row' },
		},

		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const navigate = useNavigate();
	const [currentStatusUpdation, setCurrentStatusUpdation] = useState('');
	const { addToast } = useCustomToast();
	const [searchParams, setParams] = useSearchParams();
	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);

	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const defaultValues = useMemo(() => {
		const paramsArray: PerformanceListParamsKeys[] = ['mostrarFaixasInativas', 'nomeConjunto'];
		const params = parseUrlParams<PerformanceListParamsKeys>(paramsArray, searchParams);

		const mostrarFaixasInativas = params.mostrarFaixasInativas === 'true';

		const defaultValues: IPerformanceRangeFilterForm = {
			mostrarFaixasInativas,
			nomeConjunto: params.nomeConjunto,
		};
		return defaultValues;
	}, [searchParams]);

	const {
		data: performanceRange,
		isLoading: isLoadingPerformanceRange,
		refetch: refetchPerfomanceRange,
	} = useQuery<IPerformanceRange[], AxiosError<ResponseErrors>>(
		['performanceRangeCars', defaultValues],
		() => getPerformanceRange(defaultValues),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { mutate: deletePerformanceRangeCardMutation } = useMutation<void, AxiosError<IResponseErrors>, string>(
		['deletePerformanceRange'],
		id => deletePerformanceRangeCard(id),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'Uma faixa de desempenho foi excluída.',
				});
				refetchPerfomanceRange();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { mutate: updateStatusMutation, isLoading: isLoadingUpdateStatus } = useMutation<
		void,
		AxiosError<IResponseErrors>,
		string
	>(['updatePerformanceRange'], id => updatePerformanceRangeStatus(id), {
		onSuccess: () => {
			addToast({
				type: 'success',
				title: 'Sucesso',
				description: 'Um status foi alterado.',
			});
			setCurrentStatusUpdation('');
			refetchPerfomanceRange();
		},
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
			});
			setCurrentStatusUpdation('');
		},
	});

	const onFilterPerformanceRangeCards = (values: IPerformanceRangeFilterForm) => {
		const filters = cleanDeep({
			mostrarFaixasInativas: String(values.mostrarFaixasInativas),
			nomeConjunto: values.nomeConjunto,
		});

		setParams(filters);
	};

	const handleActive = (id: string) => {
		setCurrentStatusUpdation(id);
		updateStatusMutation(id);
	};
	const handleDelete = (id: string) => deletePerformanceRangeCardMutation(id);
	const handleEdit = (id: string) => navigate(ROUTES.editPerformanceRange(id));

	return (
		<>
			<Flex sx={styles.container}>
				<PageWrapper title="Faixa de Desempenho" goBackRoute={ROUTES.home} />
				<Flex sx={styles?.addPerformanceRange}>
					{canViewAuditHistory && (
						<>
							<Button
								variant="unstyled"
								leftIcon={<RepeatClockIcon />}
								sx={styles.buttonShowModalAudit}
								onClick={onOpenAuditModal}
							>
								Histórico de Alterações
							</Button>
						</>
					)}
					{hasPermission && (
						<Button
							leftIcon={<RegisterIcon color="white" />}
							sx={styles.button}
							onClick={() => navigate(ROUTES.createPerformanceRange)}
							data-testid="button--createPerformanceRangeCard"
						>
							Cadastrar Faixa de Desempenho
						</Button>
					)}
				</Flex>

				<PerformanceRangeFilter
					onSubmitForm={onFilterPerformanceRangeCards}
					isListing={isLoadingPerformanceRange}
					defaultValues={defaultValues}
				/>

				{isLoadingPerformanceRange ? (
					<Box sx={styles?.loadingSpinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Box>
				) : (
					<Flex sx={styles.content}>
						{performanceRange?.map(item => (
							<PerformanceRangeCard
								key={item.id}
								performanceRange={item}
								onActive={handleActive}
								onEdit={handleEdit}
								onDelete={handleDelete}
								isLoadingUpdateStatus={isLoadingUpdateStatus && currentStatusUpdation === item.id}
							/>
						))}
					</Flex>
				)}
			</Flex>
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</>
	);
};

export default PerformanceRangeList;
