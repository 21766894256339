import { IAttributionAgreedForm } from '../AttributionOfAgreedForm';
import InputTagAutocomplete from '../InputTagAutocomplete';
import { useState, useEffect } from 'react';
import { Box, SystemStyleObject, Text } from '@chakra-ui/react';
import { UseFormSetValue, UseFormGetValues } from 'react-hook-form';
import { IOption } from 'types/select';

interface TagSelectorProps {
	options: IOption<any>[];
	defaultTags?: IOption<any>[];
	fieldName: string;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<IAttributionAgreedForm>;
	tagsThatCanBeRemoved: string[];
}

const TagSelector = ({
	options,
	defaultTags,
	fieldName,
	setValue,
	getValues,
	tagsThatCanBeRemoved,
}: TagSelectorProps) => {
	const styles: Record<string, SystemStyleObject> = {
		base: {
			display: 'flex',
			flexDirection: {
				base: 'column',
				md: 'row',
			},
			mb: '1rem',
		},
		box: {
			p: '1rem 0',
			mb: '1rem',
		},
		sectionTitle: {
			fontSize: '1rem',
			fontWeight: 600,
			color: 'black.800',
			marginBottom: '1rem',
			paddingLeft: '0.313rem',
		},
	};

	const [result, setResult] = useState<IOption<any>[]>(defaultTags || []);
	const tags = getValues().tags;

	useEffect(() => {
		const labels = tags?.map(tag => tag.label);
		const newResult = result.filter(result => labels?.includes(result.label));
		setResult(newResult);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tags]);

	return (
		<>
			<Box sx={styles.box}>
				<Text sx={styles.sectionTitle}>Pactuados *</Text>
				<Box sx={styles.base}>
					<InputTagAutocomplete
						options={options}
						result={result}
						setResult={(optionsSelected: IOption[]) => {
							setResult(optionsSelected);
							setValue(fieldName, optionsSelected);
						}}
						allowCreation={false}
						notFoundText="Não encontrado"
						tagsThatCanBeRemoved={tagsThatCanBeRemoved}
					/>
				</Box>
			</Box>
		</>
	);
};

export default TagSelector;
