import { useMemo } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Button, Flex, SystemStyleObject, Box, Spinner, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AccordionDataControl from 'app/components/AccordionDataControl';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import DataControlExportModal from 'app/components/DataControlExportModal';
import DataControlFilter, { IDataControlFilterForm, paramsKeys } from 'app/components/DataControlFilter';
import PageWrapper from 'app/components/PageWrapper';
import { RegisterIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteDateControl, getDateControl } from 'services/http/dateControl';
import { DataControlData } from 'types/dateControl';
import {
	API_DEFAULT_ERROR,
	BIMESTERS_OPTIONS,
	DATA_CONTROL_STEPS_OPTIONS,
	DATA_PHASES,
	DATA_PHASES_ETAPA_UM,
	USER_ROLES,
} from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parseUrlParams } from 'utils/parseUlrParams';

const DataControlList = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		gridFields: {
			gap: '2rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			mb: '1rem',
			alignSelf: { base: 'flex-start', lg: 'flex-end' },
			width: { base: '100%', lg: 'auto' },
		},
		DatebuttonsRow: {
			alignSelf: 'flex-end',
			gap: '2rem',
			mb: '1rem',
		},
		button: {
			px: '1rem',
		},
		content: {
			flexDir: 'column',
			gap: '1rem',
			mt: '1.5rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		viewAudit: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '2rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const navigate = useNavigate();
	const [searchParams, setParams] = useSearchParams();
	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const secondStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);
	const isPermissionServidorRepresentante = useCanViewAction([USER_ROLES.SERVIDOR, USER_ROLES.REPRESENTANTE]);
	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const { params, defaultValues } = useMemo(() => {
		const params = parseUrlParams<paramsKeys>(['nomeFase', 'ano', 'bimestre', 'etapa', 'idUnidade'], searchParams);

		const DATA_PHASES_OPTIONS = params.etapa
			? DATA_PHASES[params.etapa as keyof typeof DATA_PHASES]
			: DATA_PHASES_ETAPA_UM;

		const nomeFase = DATA_PHASES_OPTIONS.find(item => item.value === params.nomeFase);
		const bimestre = BIMESTERS_OPTIONS.find(item => item.value === params.bimestre);
		const etapa = DATA_CONTROL_STEPS_OPTIONS.find(item => item.value === params.etapa);

		const defaultValues: IDataControlFilterForm = {
			nomeFase: nomeFase,
			ano: params.ano,
			bimestre: bimestre,
			etapa: etapa,
			idUnidade: params?.idUnidade,
		};
		return { params, defaultValues };
	}, [searchParams]);

	const {
		data: dateControlData,
		isLoading: isControlDateLoading,
		refetch: refetchDateControl,
	} = useQuery<DataControlData[], AxiosError<ResponseErrors>>(
		['dateControlData', params],
		() => getDateControl(params),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: deleteControlDateMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		controlDateId => deleteDateControl(controlDateId),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'Um controle de datas foi excluído.',
				});
				refetchDateControl();
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const onFilterAccordionDateControlData = (values: IDataControlFilterForm) => {
		const filteredParams = cleanDeep({
			nomeFase: String(values.nomeFase?.value || ''),
			ano: String(values.ano || ''),
			bimestre: String(values.bimestre?.value || ''),
			etapa: String(values.etapa?.value || ''),
			idUnidade: String(values?.unidade?.value || ''),
		});

		setParams(filteredParams);
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Controle de Datas" />
			<Flex sx={styles.gridFields}>
				{canViewAuditHistory && (
					<Button
						variant="unstyled"
						leftIcon={<RepeatClockIcon />}
						sx={styles.buttonShowModalAudit}
						onClick={onOpenAuditModal}
					>
						Histórico de Alterações
					</Button>
				)}

				{(secondStepPermission || isPermissionServidorRepresentante) && (
					<Button
						leftIcon={<RegisterIcon color="white" />}
						sx={styles.button}
						onClick={onOpen}
						data-testid="button--exportDates"
					>
						Exportar Datas
					</Button>
				)}

				{firstStepPermission && (
					<Button
						leftIcon={<RegisterIcon color="#3EA2A2" />}
						sx={styles.button}
						onClick={() => navigate(ROUTES.createDateControl('etapa-1'))}
						data-testid="button--exportDatesStepOne"
						variant="redirect"
					>
						Cadastrar Datas Etapa I
					</Button>
				)}

				{secondStepPermission && (
					<Button
						leftIcon={<RegisterIcon color="#3EA2A2" />}
						sx={styles.button}
						onClick={() => navigate(ROUTES.createDateControl('etapa-2'))}
						data-testid="button--exportDatesStepTwo"
						variant="redirect"
					>
						Cadastrar Datas Etapa II
					</Button>
				)}
			</Flex>

			<DataControlFilter defaultValues={defaultValues} onSubmitForm={onFilterAccordionDateControlData} />

			{isControlDateLoading ? (
				<Box sx={styles?.loadingSpinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				<Flex sx={styles.content}>
					{dateControlData?.map(item => (
						<AccordionDataControl
							key={item.id}
							data={item}
							deleteDataControl={value => deleteControlDateMutation(value)}
							editDataControl={value => navigate(ROUTES.editDateControl(value))}
						/>
					))}
				</Flex>
			)}

			<DataControlExportModal onClose={onClose} isOpen={isOpen} />
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</Flex>
	);
};

export default DataControlList;
