import { api } from '.';
import { IStrategicPlanningFilterRequest } from 'app/pages/StrategicPlanningList';
import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { StrategicPlanning, StrategicPlanningEdit } from 'types/strategicPlanning';

export const getPeriod = async (): Promise<string[]> => {
	const api = getAxios();
	const response = await api.get<string[]>(ENDPOINTS.periods);
	return response.data;
};

export const getStrategicPlanningList = async (params?: IStrategicPlanningFilterRequest) => {
	const response = await api.get<StrategicPlanning[]>(ENDPOINTS.strategicPlanningList, {
		params: { ...params },
	});
	return response.data;
};

export const createStrategicPlanning = async (data: StrategicPlanning): Promise<StrategicPlanning> => {
	const api = getAxios();
	const response = await api.post<StrategicPlanning>(ENDPOINTS.strategicPlanningCreate, data);
	return response.data;
};

export const getStrategicPlanningById = async (strategicPlanningId: string): Promise<StrategicPlanning> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.strategicPlanningGetById(strategicPlanningId));
	return response.data;
};

export const updateStrategicPlanning = async (data: StrategicPlanningEdit): Promise<StrategicPlanning> => {
	const api = getAxios();
	const response = await api.put<StrategicPlanning>(ENDPOINTS.strategicPlanningUpdate(data.id!), data);
	return response.data;
};

export const deleteStrategicPlanning = async (strategicPlanningId: string): Promise<void> => {
	const api = getAxios();
	await api.delete(ENDPOINTS.strategicPlanningDelete(strategicPlanningId));
};
