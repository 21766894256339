/**
 *
 * PerformanceRangeCard
 *
 */

import CustomModal from '../CustomModal';
import {
	Text,
	Box,
	Flex,
	SystemStyleObject,
	HStack,
	FormControl,
	FormLabel,
	useDisclosure,
	IconButton,
	Spinner,
} from '@chakra-ui/react';
import { DeleteIcon, TableEditIcon } from 'assets/icons';
import { useCanViewAction } from 'hooks/useCanViewAction';
import ReactSwitch from 'react-switch';
import { IPerformanceRange } from 'types/performanceRange';
import { USER_ROLES } from 'utils/constants';

interface PerformanceRangeCardProps {
	performanceRange: IPerformanceRange;
	onActive: (id: string) => void;
	onEdit: (id: string) => void;
	onDelete: (id: string) => void;
	isLoadingUpdateStatus?: boolean;
}

const PerformanceRangeCard = ({
	performanceRange,
	onActive,
	onEdit,
	onDelete,
	isLoadingUpdateStatus,
}: PerformanceRangeCardProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			justifyContent: { base: 'center', md: 'flex-start' },
			paddingBottom: '2rem',
		},
		content: {
			width: '100%',
		},
		contentFields: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
			gap: '1rem',
			rowGapa: '1rem',
		},
		contentFieldsCard: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: { base: 'center', sm: 'flex-end', lg: 'flex-start' },
			flexDirection: {
				base: 'column-reverse',
				md: 'column-reverse',
				lg: 'row',
			},
		},
		titleHeader: {
			fontSize: '1rem',
			fontWeight: 'bold',
			pr: '1rem',
		},
		cardTitle: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		switchAndLabel: {
			pb: '1rem',
		},
		activeTracksFormControl: {
			display: 'flex',
			alignItems: 'center',
		},
		activeFormLabel: {
			fontSize: '1rem',
			fontWeight: 'medium',
			pr: '1.5rem',
			mb: '0',
		},
		activeTracksSpinner: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
		},
		cardBorder: {
			maxWidth: '83.125rem',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			padding: { base: '2rem 0', sm: '2rem 2rem 0 2rem', lg: '1.5rem 3.563rem 0rem 5.5rem' },
			opacity: '1',
		},
		cardBorderInactive: {
			maxWidth: '83.125rem',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			padding: { base: '2rem 0', sm: '2rem 2rem 0 2rem', lg: '1.5rem 3.563rem 0rem 5.5rem' },
			opacity: '0.5',
		},
		cardDataField: {
			maxWidth: '41rem',
			flexDirection: { base: 'column', sm: 'row' },
			justifyContent: 'flex-start',
		},
		cardDataHeaders: {
			fontSize: '1rem',
			fontWeight: 'bold',
			paddingRight: '2.5rem',
			paddingBottom: '1rem',
		},
		rightCardDataHeaders: {
			fontSize: '1rem',
			fontWeight: 'bold',
			paddingBottom: '1rem',
		},
		cardDataPercentValues: {
			fontSize: '1rem',
			paddingRight: '2.5rem',
			paddingBottom: '2.5rem',
		},
		cardDataResultValues: {
			fontSize: '1rem',
			color: '#0A77D2',
			paddingBottom: '2.5rem',
		},
		cardIconsBox: {
			gap: '1.688rem',
			paddingBottom: '1rem',
		},
		cardIcons: {
			w: '1.25rem',
			h: '1.25rem',
		},
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const canEditPerformanceRange = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
	]);
	const canDeletePerformanceRange = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
	]);
	const canChangeStatusPerformanceRange = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
	]);

	const handleDelete = (performanceRangeCardId: string) => {
		onClose();
		onDelete(performanceRangeCardId);
	};

	const handleGoBack = () => {
		onClose();
	};

	const format = (value: string | number) => {
		return `${new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 1 }).format(Number(value))}%`;
	};

	const SwitchSpinner = () => {
		return isLoadingUpdateStatus ? (
			<Box sx={styles.activeTracksSpinner}>
				<Spinner size="xs" color="#1492E6" />
			</Box>
		) : undefined;
	};

	return (
		<>
			<Flex sx={styles.container}>
				<Box sx={styles.content}>
					<Box sx={styles.contentFields}>
						<HStack>
							<Text sx={styles.titleHeader}>Nome do conjunto:</Text>
							<Text sx={styles.cardTitle} data-testid="perfomance--range--card-title">
								{performanceRange.nome}
							</Text>
						</HStack>
						<Box>
							{canChangeStatusPerformanceRange && (
								<HStack sx={styles.switchAndLabel}>
									<FormControl sx={styles.activeTracksFormControl}>
										<FormLabel htmlFor="active-tracks" sx={styles.activeFormLabel}>
											{performanceRange.habilitado ? 'Inativar faixas' : 'Ativar faixas'}
										</FormLabel>

										<ReactSwitch
											data-testid="perfomance--range--card-switch"
											onColor="#1492E6"
											offColor="#CCCCCC"
											height={20}
											width={44}
											id="active-tracks"
											checked={!!performanceRange.habilitado}
											onChange={() => onActive(performanceRange.id!)}
											uncheckedIcon={false}
											checkedHandleIcon={SwitchSpinner()}
											uncheckedHandleIcon={SwitchSpinner()}
										/>
									</FormControl>
								</HStack>
							)}
						</Box>
					</Box>
					<Box sx={performanceRange.habilitado ? styles.cardBorder : styles.cardBorderInactive}>
						<HStack sx={styles.contentFieldsCard}>
							<Flex sx={styles.cardDataField}>
								<Box>
									{performanceRange.faixasDesempenho?.map((item, index) => (
										<Box key={index}>
											<Text sx={styles.cardDataHeaders}>Porcentagem incial</Text>
											<Text
												sx={styles.cardDataPercentValues}
												data-testid={`perfomance--range--card-initialPercentage-${index}`}
											>
												{format(item.porcentagemInicial)}
											</Text>
										</Box>
									))}
								</Box>
								<Box>
									{performanceRange.faixasDesempenho?.map((item, index) => (
										<Box key={index}>
											<Text sx={styles.cardDataHeaders}>Porcentagem final</Text>
											<Text
												sx={styles.cardDataPercentValues}
												data-testid={`perfomance--range--card-finalPercentage-${index}`}
											>
												{format(item.porcentagemFinal)}
											</Text>
										</Box>
									))}
								</Box>
								<Box>
									{performanceRange.faixasDesempenho?.map((item, index) => (
										<Box key={index}>
											<Text sx={styles.rightCardDataHeaders}>Resultado do desempenho</Text>
											<Text sx={styles.cardDataResultValues} data-testid={`perfomance--range--card-result-${index}`}>
												{item.resultadoProporcional ? 'Proporcional' : format(item.resultadoDesempenho || 0)}
											</Text>
										</Box>
									))}
								</Box>
							</Flex>
							<Flex>
								<Flex sx={styles.cardIconsBox}>
									{canEditPerformanceRange && (
										<IconButton
											sx={styles.cardIcons}
											aria-label={'Editar faixa de desempenho'}
											variant="unstyled"
											icon={<TableEditIcon />}
											data-testid={`perfomance--range--card-edit-${performanceRange.id}`}
											onClick={() => onEdit(performanceRange.id!)}
										/>
									)}
									{canDeletePerformanceRange && (
										<IconButton
											sx={styles.cardIcons}
											aria-label={'Excluir faixa de desempenho'}
											variant="unstyled"
											icon={<DeleteIcon />}
											data-testid={`performance--range--card-delete-${performanceRange.id}`}
											onClick={onOpen}
										/>
									)}
								</Flex>
							</Flex>
						</HStack>
					</Box>
				</Box>
			</Flex>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você irá excluir esta Faixa de Desempenho."
				body="Tem certeza que deseja realizar esta ação?"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero excluir',
						type: 'cancel',
						onClick: () => handleDelete(performanceRange.id!),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleGoBack,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default PerformanceRangeCard;
