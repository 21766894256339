/**
 *
 * CaapMembersCreate
 *
 */

import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import CaapMembersForm, { ICaapMembersForm } from 'app/components/CaapMembersForm';
import PageWrapper from 'app/components/PageWrapper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { createCaapMembers } from 'services/http/caapMember';
import { CaapMembers } from 'types/caapMembers';
import { API_DEFAULT_ERROR, STATUS_CAAP } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';

const CaapMembersCreate = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.438rem',
			mb: '3.625rem',
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { mutate: createCaapMembersMutate, isLoading: isCreateCaapMembersLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		CaapMembers[]
	>(data => createCaapMembers(data), {
		onSuccess: () => {
			navigate(ROUTES.caapMembers);
		},
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const handleSubmit = (values: ICaapMembersForm) => {
		const payload: CaapMembers[] = values.membrosDaCaap.map(item => ({
			representacao: item.representacao,
			titular: {
				idUnidade: String(item.unidadeSetorTitular?.data?.idUnidade),
				nomeUnidade: item.unidadeSetorTitular?.data?.sigla as string,
				idSetor: item.unidadeSetorTitular?.value as string,
				nomeSetor: item.unidadeSetorTitular?.data?.nomeSetor as string,
				nome: item.nomeTitular?.label as string,
				masp: item.maspTitular?.label as string,
				vinculo: item.vinculoTitular,
				cargo: item.cargoTitular,
				funcao: {
					id: item.funcaoTitular?.value as string,
					funcaoDescricao: item.funcaoTitular?.label as string,
				},
				statusCaap: STATUS_CAAP.TITULAR,
			},
			suplente: {
				idUnidade: String(item.unidadeSetorSuplente?.data?.idUnidade),
				nomeUnidade: item.unidadeSetorSuplente?.data?.sigla as string,
				idSetor: item.unidadeSetorSuplente?.value as string,
				nomeSetor: item.unidadeSetorSuplente?.data?.nomeSetor as string,
				nome: item.nomeSuplente?.label as string,
				masp: item.maspSuplente?.label as string,
				vinculo: item.vinculoSuplente,
				cargo: item.cargoSuplente,
				funcao: {
					id: item.funcaoSuplente?.value as string,
					funcaoDescricao: item.funcaoSuplente?.label as string,
				},
				statusCaap: STATUS_CAAP.SUPLENTE,
			},
		}));

		createCaapMembersMutate(payload);
	};
	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Atualização da Composição" goBackRoute={ROUTES.caapMembers} />

			<Box sx={styles.content}>
				<CaapMembersForm onSubmit={handleSubmit} isSubmitLoading={isCreateCaapMembersLoading} />
			</Box>
		</Flex>
	);
};

export default CaapMembersCreate;
