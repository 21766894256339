/**
 *
 * CustomModal
 *
 */

import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { ErrorIconModal, SuccessIconModal, WarningIconModal, InfoIconModal, HelpIcon } from 'assets/icons';

interface IModalAction {
	label: string;
	type: 'primary' | 'secondary' | 'cancel';
	onClick: () => void;
	datatestid: 'button--redirect' | 'button--confirm' | 'button--cancel';
}

export interface IModalIcons {
	type: 'info' | 'success' | 'warning' | 'error' | 'help';
}

interface CustomModalProps {
	icons: IModalIcons[];
	title: string;
	body?: string;
	onClose: () => void;
	isOpen: boolean;
	actions: IModalAction[];
	bodyCustomStyle?: SystemStyleObject;
	bodyTextCustomStyle?: SystemStyleObject;
}

const CustomModal = ({
	icons,
	title,
	body,
	actions,
	isOpen,
	onClose,
	bodyCustomStyle,
	bodyTextCustomStyle,
}: CustomModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxWidth: '41.563rem',
			alignItems: 'center',
			padding: '2rem 3rem',
			margin: '1rem',
		},
		header: {
			display: 'flex',
			flexDir: 'column',
			alignItems: 'center',
			width: '100%',
		},
		icon: {
			width: '100%',
			alignContent: 'center',
		},
		title: { fontSize: '1.5rem', fontWeight: 700, textAlign: 'center' },
		bodyContainer: { p: '0', mb: '1rem', w: '100%', ...bodyCustomStyle },
		body: { fontWeight: '600', textAlign: 'center', mb: '1rem', ...bodyTextCustomStyle },
		footerContainer: { justifyContent: 'center', w: '100%', p: '0' },
		footerContent: {
			w: '100%',
			justifyContent: 'center',
			gap: { base: '1.5rem', sm: '1.875rem' },
			flexDirection: { base: 'column', sm: 'row' },
			alignItems: { base: 'center', sm: 'end' },
		},
		button: { fontSize: '1rem', h: '2.75rem', w: '100%' },
	};

	const ICONS = {
		error: <ErrorIconModal />,
		success: <SuccessIconModal />,
		warning: <WarningIconModal />,
		info: <InfoIconModal />,
		help: <HelpIcon color="#3498DB" width="70" height="70" />,
	};

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent sx={styles.container} data-testid="modal">
				{icons?.map(({ type }, index) => (
					<Box sx={styles.icons} key={index}>
						{ICONS[type]}
					</Box>
				))}
				<ModalHeader sx={styles.header}>
					<Text sx={styles.title}>{title}</Text>
				</ModalHeader>
				<ModalCloseButton size="lg" />
				{!!body && (
					<ModalBody sx={styles.bodyContainer} data-testid="modal-body">
						<Text sx={styles.body} dangerouslySetInnerHTML={{ __html: body }}></Text>
					</ModalBody>
				)}

				<ModalFooter sx={styles.footerContainer}>
					<Flex sx={styles.footerContent}>
						{actions?.map(({ onClick, label, type, datatestid }, index) => (
							<Button variant={type} key={index} onClick={onClick} sx={styles.button} data-testid={datatestid}>
								{label}
							</Button>
						))}
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CustomModal;
