/**
 *
 * DefaultLayout
 *
 */

import CustomModal from '../CustomModal';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { Box, Divider, Flex, Spinner, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { redirect } from 'hooks/useAxios';
import { useIsMobile } from 'hooks/useIsMobile';
import { useSession } from 'hooks/useSession';
import { getUserData, login } from 'services/http/login';
import { HeaderProps } from 'types/header';
import { Login } from 'types/user';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';

interface DefaultLayoutProps extends HeaderProps {
	children: JSX.Element;
}

const DefaultLayout = ({ children, hasHeader = true }: DefaultLayoutProps) => {
	const isMobile = useIsMobile(992);
	const styles: Record<string, SystemStyleObject> = {
		container: {
			position: 'relative',
		},
		sidebar: {
			minW: isMobile ? '0' : '20rem',
			minH: '100vh',
			flexDir: 'column',
		},
		content: {
			width: '100%',
			maxW: '88.125rem',
			padding: {
				base: '0rem 2rem',
				lg: '0rem 2.5rem',
			},
			mb: '6rem',
		},
	};

	const { startSession } = useSession();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { mutate, isError: isErrorUser } = useMutation(
		(login: Login) => getUserData(login.cpf, stringifyCurrentUserUnits(login)),
		{
			onSuccess: (data, login) => {
				const parsedUser = { ...login, ...data, idLogin: login.id!, idUser: data.id! };
				startSession({ user: parsedUser });
			},
			onError: () => {
				onOpen();
			},
		},
	);

	const { isLoading: isLoadingLogin, isError: isErrorLogin } = useQuery(['login'], () => login(), {
		onSuccess: data => mutate(data),
		onError: () => {
			redirect(process.env.REACT_APP_API_BASE_URL as string);
		},
		refetchOnWindowFocus: true,
	});

	const handleRedirect = () => {
		onClose();
		redirect(`${process.env.REACT_APP_API_BASE_URL}/logout`);
	};

	return (
		<>
			{isLoadingLogin || isErrorLogin || isErrorUser ? (
				<Flex h="100vh" justifyContent="center" alignItems="center">
					<Spinner size="lg" color="#3EA2A2" />
				</Flex>
			) : (
				<Flex sx={styles.container}>
					{isMobile && <Divider position="absolute" top="3.9375rem" w="100%" />}

					<Box sx={styles.sidebar}>
						<Sidebar />
					</Box>
					<Box sx={styles.content}>
						{hasHeader && <Header />}
						<Box sx={{ mt: isMobile ? '1rem' : 0 }}>{children}</Box>
					</Box>
				</Flex>
			)}

			<CustomModal
				icons={[{ type: 'info' }]}
				title="Usuário não encontrado no sapt"
				body="Foi encontrada alguma divergência ou impedimento relacionado ao seu usuário e você será desconectado. <br> Favor entrar em contato com Administrador do sistema."
				isOpen={isOpen}
				onClose={handleRedirect}
				actions={[
					{
						label: 'Entendido',
						type: 'primary',
						onClick: handleRedirect,
						datatestid: 'button--confirm',
					},
				]}
			/>
		</>
	);
};

export default DefaultLayout;
