import { RESPONSIBLE_REALESE_ENUM, RESULT_EVALUATION_PHASES, STEPS_ENUM, USER_ROLES } from './constants';
import { IResultEvaluationForm } from 'app/components/ResultEvaluationForm';
import { UserData, UserPermission } from 'types/user';

const RESULT_EVALUATION_NEXT_PHASE = {
	LANCAMENTO_RESULTADO: RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO,
	VALIDACAO_RESULTADO: RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA,
	SOLICITACAO_PRIMEIRA_INSTANCIA: RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA,
	DECISAO_PRIMEIRA_INSTANCIA: RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA,
	SOLICITACAO_SEGUNDA_INSTANCIA: RESULT_EVALUATION_PHASES.CONTRARRAZOES,
	CONTRARRAZOES: RESULT_EVALUATION_PHASES.DECISAO_CAAP,
};

export const handlePermission = (user: UserData, data: IResultEvaluationForm, card?: boolean) => {
	const { faseEnum: phase, tipoResponsavelLancamento: responsavelLancamento } = data;

	const userPermission = user?.permissoes ?? [];

	const permissions: UserPermission[] = [
		...userPermission,
		{
			nome: USER_ROLES.REPRESENTANTE,
			ativo: user?.isRepresentante,
		},
	]?.filter(permission => permission?.ativo);

	const onlyServidorPermission = permissions?.find(permission => permission.nome === USER_ROLES.SERVIDOR);

	if (onlyServidorPermission && permissions?.length === 1) return false;

	let currentPhase = card ? RESULT_EVALUATION_NEXT_PHASE[phase as keyof typeof RESULT_EVALUATION_NEXT_PHASE] : phase;

	const isTechnicalAreaResponsible = responsavelLancamento === RESPONSIBLE_REALESE_ENUM.AREA_TECNICA;
	const isAgreedResponsible = responsavelLancamento === RESPONSIBLE_REALESE_ENUM.PACTUADO;

	if (card && isTechnicalAreaResponsible && phase === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
		currentPhase = RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA;
	}

	const isAdvisor = permissions?.some(permission =>
		[USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO].includes(permission.nome),
	);

	return handleStepPermission(user, data, currentPhase!, isTechnicalAreaResponsible, isAgreedResponsible, isAdvisor);
};

const handleStepPermission = (
	user: UserData,
	data: IResultEvaluationForm,
	phase: string,
	isTechnicalAreaResponsible: boolean,
	isAgreedResponsible: boolean,
	isAdvisor: boolean,
) => {
	const { nomeAreaTecnica: technicalArea, pactuado: agreed, etapa: step } = data;

	const userUnit = user?.unidade;
	const technicalAreaUnit = technicalArea?.split('-')?.[0].trim();
	const agreedUnit = agreed?.split('-')?.[0].trim();

	const isTechnicalAreaUnit = userUnit === technicalAreaUnit;
	const isAgreedUnit = userUnit === agreedUnit;

	const isTechnicalAreaRep = user?.unidadesRepresentantes?.some(unidade => unidade?.nomePactuado === technicalArea);
	const isAgreedRep = user?.unidadesRepresentantes?.some(unidade => unidade?.nomePactuado === agreed);

	if (isAdvisor) {
		return permissionPerPhase(
			phase,
			step!,
			isTechnicalAreaUnit,
			isAgreedUnit,
			isTechnicalAreaResponsible,
			isAgreedResponsible,
		);
	}

	return permissionPerPhase(
		phase,
		step!,
		isTechnicalAreaRep,
		isAgreedRep,
		isTechnicalAreaResponsible,
		isAgreedResponsible,
	);
};

const permissionPerPhase = (
	phase: string,
	step: string,
	isTechnicalArea: boolean,
	isAgreed: boolean,
	isTechnicalAreaResponsible = true,
	isAgreedResponsible = true,
) => {
	const isLaunchPhase = phase === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO;
	const isValidationPhase = phase === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO;
	const isAppealFirstInstacePhase = phase === RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA;
	const isDecisionFirstInstancePhase = phase === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA;
	const isAppealSecondInstacePhase = phase === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA;
	const isCounterreasonsPhase = phase === RESULT_EVALUATION_PHASES.CONTRARRAZOES;
	const isDecisionPhase = phase === RESULT_EVALUATION_PHASES.DECISAO_CAAP;

	const launchPhaseTechnicalArea = isLaunchPhase && isTechnicalAreaResponsible && isTechnicalArea;
	const launchPhaseAgreed = isLaunchPhase && isAgreedResponsible && isAgreed;

	const canHandleLaunchPhase = launchPhaseTechnicalArea || launchPhaseAgreed;

	const canHandleValidationPhase = isValidationPhase && isTechnicalArea;

	const canHandleAppealPhase = (isAppealFirstInstacePhase || isAppealSecondInstacePhase) && isAgreed;

	const canHandleSecondStepDecision = step === STEPS_ENUM.ETAPA_DOIS && isDecisionPhase;

	const canHandleDecisionPhase =
		(isDecisionFirstInstancePhase || isCounterreasonsPhase || canHandleSecondStepDecision) && isTechnicalArea;

	return canHandleLaunchPhase || canHandleValidationPhase || canHandleAppealPhase || canHandleDecisionPhase;
};
