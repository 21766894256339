/**
 *
 * PenaltyCreate
 *
 */

import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import PenaltyForm, { PenaltyFormData } from 'app/components/PenaltyForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { IRequestPenaltyData, createPenalty } from 'services/http/penalty';
import { getSector } from 'services/http/sectors';
import { ISector } from 'types/sectors';
import { API_DEFAULT_ERROR, SECTOR_TYPE } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';

const PenaltyCreate = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},

		content: {
			mt: '3.75rem',
			mb: '5.5rem',
		},
	};

	const {
		session: { user },
	} = useSession();

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { mutate: createPenaltyMutate, isLoading: isCreatePenaltyLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestPenaltyData
	>(data => createPenalty(data), {
		onSuccess: () => {
			navigate(ROUTES.penalty);
			addToast({ type: 'success', description: 'Penalidade cadastrada com sucesso.', title: 'Sucesso!' });
		},

		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const { data: pactuados } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['pactuado'],
		() => getSector(SECTOR_TYPE.BOARD_TYPE, stringifyCurrentUserUnits(user)),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const handleSubmit = (data: PenaltyFormData) => {
		const selectedTechnicalAreaUnitId = pactuados?.find(
			item => item.idSetor === Number(data.pactuado?.value),
		)?.idUnidade;

		const parsedData: IRequestPenaltyData = {
			idControleDatas: Number(data.fase.value),
			bimestreEnum: String(data.bimestre.value),
			pontosPerdidos: Number(data.pontosPerdidos.value),
			clausulaContratual: data.clausula,
			justificativa: data.justificativa,
			idExternoPactuado: Number(data.pactuado.value),
			nomePactuado: String(data.pactuado.label),
			idUnidade: Number(selectedTechnicalAreaUnitId),
		};
		createPenaltyMutate(parsedData);
	};

	return (
		<>
			<Flex sx={styles.container}>
				<PageWrapper title="Atribuir Penalidade" />

				<Box sx={styles.content}>
					<PenaltyForm onSubmit={handleSubmit} isLoading={isCreatePenaltyLoading} />
				</Box>
			</Flex>
		</>
	);
};

export default PenaltyCreate;
