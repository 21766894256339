import { getAxiosForSigesp } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import { ISector } from 'types/sectors';

export const getSector = async (
	tiposNiveisHierarquicoSetores: string | string[],
	idsUnidades?: string[],
): Promise<ISector[]> => {
	const api = getAxiosForSigesp();
	const response = await api.get<ISector[]>(ENDPOINTS.sectors, {
		params: { tiposNiveisHierarquicoSetores, idsUnidades },
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

export const getBoardSector = async (boardId: string): Promise<ISector[]> => {
	const api = getAxiosForSigesp();
	const response = await api.get(ENDPOINTS.boardSectors(boardId));
	return response.data;
};
