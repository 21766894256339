import { useMemo } from 'react';
import { extendTheme } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Text: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Lato',
		fontWeight: 500,
		fontSize: { base: '1.125rem' },
		color: 'black',
	},
	variants: {
		default: {},
		required: {
			color: 'black',
		},
		error: {
			color: 'red.500',
			fontStyle: 'italic',
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const Input: ComponentStyleConfig = {
	baseStyle: {
		field: {
			fontFamily: 'Lato',
			fontWeight: 500,
			fontSize: { base: '1rem' },
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			height: '2.75rem',
			padding: '0.83rem 1rem',
			color: 'black.800',
			_placeholder: {
				color: 'gray.500',
			},
			_disabled: {
				_placeholder: {
					color: 'gray.800',
				},
			},
		},
		_placeholder: {
			fontSize: { base: '1rem' },
		},
	},
	variants: {
		default: {
			field: {
				borderColor: 'gray.95',
				_invalid: {
					borderColor: 'red.400',
					borderLeft: '0.375rem solid',
					borderLeftColor: 'red.400',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.450',
				},
				_hover: {
					borderColor: 'gray.400',
				},
			},
		},
		rounded: {
			field: {
				borderRadius: '1.375rem',
				borderColor: 'gray.95',
				_disabled: {
					background: 'white',
					color: 'black.800',
				},
			},
		},
		small: {
			field: {
				minHeight: '1.563rem',
				padding: '1rem 1.125rem',
				borderColor: 'gray.95',
				_invalid: {
					borderColor: 'red.400',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.450',
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const Textarea: ComponentStyleConfig = {
	baseStyle: {
		padding: '0.625rem 0.75rem 1.813rem 0.625rem',
		borderRadius: '0.25rem',
		backgroundColor: 'white',
		fontSize: { base: '1.125rem' },
		border: '1px solid',
		borderColor: 'gray.95',
		color: 'black.800',
		_invalid: {
			borderColor: 'red.400',
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const Select: ComponentStyleConfig = {
	baseStyle: {
		field: {
			fontFamily: 'Lato',
			fontWeight: 500,
			fontSize: { base: '1rem' },
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			height: '2.75rem',
			padding: '0.4rem 1rem',
			color: 'black.800',
			_placeholder: {
				color: 'gray.500',
			},
			_disabled: {
				_placeholder: {
					color: 'gray.800',
				},
			},
		},
		_placeholder: {
			fontSize: { base: '1rem' },
		},
	},
	variants: {
		default: {
			field: {
				borderColor: 'gray.95',
				_invalid: {
					borderColor: 'red.400',
					borderLeft: '0.375rem solid',
					borderLeftColor: 'red.400',
				},
				_disabled: {
					background: 'gray.90',
					color: 'gray.450',
				},
				_hover: {
					borderColor: 'gray.400',
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const FormLabel: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Lato',
		fontWeight: 500,
		fontSize: { base: '1rem' },
		color: 'black.800',
	},
	variants: {
		default: {
			mb: '1rem',
			mr: '0rem',
			display: 'flex',
			alignItems: 'center',
		},
		large: {
			fontSize: { base: '1.125rem' },
		},
		bold: {
			fontWeight: 'bold',
		},
		medium: {
			fontWeight: 'medium',
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const FormErrorMessage: ComponentStyleConfig = {
	baseStyle: {
		text: {
			fontFamily: 'Lato',
			fontWeight: 500,
			fontSize: { base: '0.9rem' },
			color: 'red.400',
			ml: '0.5rem',
		},
	},
	variants: {
		default: {},
	},
	defaultProps: {
		variant: 'default',
	},
};

const Heading: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Lato',
		fontWeight: 600,
		fontSize: { base: '1.5rem' },
		color: 'black.800',
	},
	variants: {
		default: {},
		small: {
			fontSize: { base: '1.375rem' },
			color: 'black.800',
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const Button: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: 'Lato',
		fontWeight: 500,
		fontSize: { base: '1.25rem', md: '1.125rem' },
		h: '2.75rem',
		borderRadius: '0.25rem',
		transition: '0.2s',
	},
	variants: {
		primary: {
			background: 'green.550',
			color: 'white',
			_hover: {
				background: 'transparent linear-gradient(180deg, #3EA2A2 0%, #3B9191 100%)',
				color: 'white',
			},
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				pointerEvents: 'none',
			},
		},
		secondary: {
			background: 'white',
			border: '0.125rem solid',
			borderColor: 'green.550',
			color: 'green.550',
			_hover: {
				background: 'green.550',
				color: 'white',
				boxShadow: '0.125rem 0.375rem 0.563rem #FFFFFF29',
				svg: {
					path: {
						fill: '#FFF',
					},
				},
			},
			_disabled: {
				background: 'white',
				cursor: 'not-allowed',
				pointerEvents: 'none',
			},
		},
		success: {
			background: 'green.800',
			color: 'white',
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				pointerEvents: 'none',
			},
		},
		cancel: {
			background: 'red.550',
			color: 'white',
			_hover: {
				background: 'red.350',
			},
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				pointerEvents: 'none',
			},
		},
		outlined: {
			background: 'gray.400',
			color: 'white',
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				pointerEvents: 'none',
			},
		},
		redirect: {
			background: 'white',
			border: '0.125rem solid',
			borderColor: 'green.550',
			color: 'green.550',
			_hover: {
				background: 'gray.80',
			},
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				pointerEvents: 'none',
			},
		},
		redirectBlack: {
			background: 'white',
			border: '0.125rem solid',
			borderColor: 'green.550',
			color: 'black',
			_hover: {
				background: 'gray.80',
			},
			_disabled: {
				filter: 'contrast(0.7)',
				cursor: 'not-allowed',
				pointerEvents: 'none',
			},
		},
	},
	defaultProps: {
		variant: 'primary',
		size: '',
	},
};

const Link: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		default: {},
		menuLink: {
			borderLeft: '0.25rem solid transparent',
			fontSize: '1rem',
			color: 'gray.600',
			fontWeight: '500',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			gap: '1rem',
			w: '100%',
			_hover: {
				textDecoration: 'none',
				backgroundColor: 'rgba(	0, 98, 255, 0.05)',
				color: 'blue.500',
				borderLeft: '0.25rem solid #0062FF',
				fontWeight: 'bold',
				svg: {
					path: {
						fill: 'blue.700',
					},
					rect: {
						fill: 'blue.700',
					},
				},
			},
			_activeLink: {
				textDecoration: 'none',
				backgroundColor: 'rgba(	0, 98, 255, 0.05)',
				color: 'blue.500',
				borderLeft: '0.25rem solid #0062FF',
				fontWeight: 'bold',
				svg: {
					path: {
						fill: 'blue.700',
					},
					rect: {
						fill: 'blue.700',
					},
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

const Table: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		default: {
			thead: {
				tr: {
					h: '3rem',
					backgroundColor: 'gray.80',
				},
				th: {
					fontFamily: 'Lato',
					fontWeight: 600,
					fontSize: { base: '1rem' },
					textTransform: 'inherit',
					color: 'black.800',
					borderBottom: '1px solid',
					borderColor: 'gray.95',
					px: '1rem',

					_first: {
						paddingLeft: '2rem',
					},

					_last: {
						paddingRight: {
							base: '2rem',
							xl: '5rem',
						},
					},
				},
			},
			tbody: {
				td: {
					fontFamily: 'Lato',
					fontWeight: 600,
					fontSize: { base: '1rem' },
					color: 'black.800',
					borderBottom: '1px solid',
					borderColor: 'gray.95',
					p: '1rem',

					_first: {
						paddingLeft: '2rem',
					},

					_last: {
						paddingRight: {
							base: '2rem',
							xl: '5rem',
						},
					},
				},
			},
		},
		secondary: {
			thead: {
				tr: {
					maxH: '3.125rem',
					backgroundColor: 'blue.700',
				},
				th: {
					fontFamily: 'Lato',
					fontWeight: 'bold',
					fontSize: { base: '1rem' },
					color: 'white',
					px: '1.25rem',

					_first: {
						borderTopLeftRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						pl: '1.5rem',
					},

					_last: {
						borderTopRightRadius: '0.5rem',
						borderBottomRightRadius: '0.5rem',
					},
				},
			},
			tbody: {
				tr: {
					_first: {
						borderBottom: '1px solid',
						borderColor: 'gray.95',
						fontWeight: 'bold',
					},
				},
				td: {
					fontFamily: 'Roboto',
					fontSize: { base: '1rem' },
					color: 'black.800',
					py: '0.563rem',
					px: '1.25rem',
					background: 'white',
					textAlign: 'center',

					_first: {
						background: 'white',
						textAlign: 'start',
						pl: '1.5rem',
						minW: '31rem',
					},
					':nth-of-type(2)': {
						minW: '9.25rem',
					},
				},
			},
		},
		notification: {
			thead: {
				tr: {},
				th: {
					fontFamily: 'Lato',
					fontWeight: 'bold',
					fontSize: { base: '1rem' },
					color: 'black.800',
					textTransform: 'inherit',
					py: '0.5rem',
				},
			},
			tbody: {
				tr: {
					borderBottom: '1px solid',
					borderColor: '#E2E2E2',
					_last: {
						borderBottom: '1px solid',
						borderColor: 'transparent',
					},
				},
				td: {
					fontFamily: 'Lato',
					fontWeight: 'medium',
					fontSize: { base: '1rem' },
					color: '#000000',
					pr: '4.125rem',
					py: '1rem',
					background: 'white',
					verticalAlign: 'middle',
					lineHeight: '1.5rem',
					_first: {
						pr: '0.5rem',
						borderBottom: '1px solid',
						borderColor: 'transparent',
						verticalAlign: 'middle',
					},
					_last: {
						pr: '0',
						verticalAlign: 'middle',
					},
					':nth-of-type(4)': {
						maxW: '33.125rem',
						fontWeight: 'extrabold',
					},
				},
			},
		},
		exportDate: {
			thead: {
				tr: {
					h: '3rem',
					backgroundColor: 'gray.80',
				},
				th: {
					fontFamily: 'Lato',
					fontWeight: 600,
					fontSize: { base: '1rem' },
					textTransform: 'inherit',
					color: 'black.800',
					borderBottom: '1px solid',
					borderColor: 'gray.95',
					px: '1rem',
					width: '10rem',

					_first: {
						paddingLeft: '2rem',
					},

					_last: {
						paddingRight: {
							base: '1rem',
						},
					},
				},
			},
			tbody: {
				td: {
					fontFamily: 'Lato',
					fontWeight: 600,
					fontSize: { base: '1rem' },
					color: 'black.800',
					borderBottom: '1px solid',
					borderColor: 'gray.95',
					p: '1rem',
					width: '10rem',
					textAlign: 'center',

					_first: {
						paddingLeft: '2rem',
						textAlign: 'left',
					},
					_second: {
						textAlign: 'left',
					},

					_last: {
						paddingRight: {
							base: '1rem',
						},
					},
				},
			},
		},
		reduceReport: {
			thead: {
				tr: {
					h: '3rem',
					backgroundColor: 'gray.80',
				},
				th: {
					fontFamily: 'Lato',
					fontWeight: 600,
					fontSize: { base: '1rem' },
					textTransform: 'inherit',
					color: 'black.800',
					borderBottom: 'none',
					borderColor: 'gray.95',
					px: '1rem',

					_first: {
						paddingLeft: '2rem',
					},

					_last: {
						paddingRight: {
							base: '2rem',
							xl: '5rem',
						},
					},
				},
			},
			tbody: {
				td: {
					fontFamily: 'Lato',
					fontWeight: 600,
					fontSize: { base: '1rem' },
					color: 'black.800',
					borderBottom: '1px solid',
					borderColor: 'gray.95',
					p: '1rem',
					_first: {
						paddingLeft: '2rem',
					},
					_last: {
						paddingRight: {
							base: '2rem',
							xl: '5rem',
						},
					},
				},
			},
		},
		reportMonitoringModal: {
			thead: {
				tr: {
					h: '2.75rem',

					_first: {
						backgroundColor: '#004A73',
						borderTopRadius: '0.5rem',

						th: {
							color: '#FFF',
							borderTopRadius: '0.25rem',
						},
					},
				},
				th: {
					fontFamily: 'Lato',
					fontWeight: 700,
					fontSize: { base: '1rem' },
					textTransform: 'inherit',
					color: 'black.800',
					px: '1.25rem',

					_first: {
						pl: '1rem',
					},

					_last: {
						pr: '1rem',
					},
				},
			},
			tbody: {
				td: {
					fontFamily: 'Lato',
					fontWeight: 500,
					fontSize: { base: '1rem' },
					color: '#000000de',
					borderBottom: '1px solid',
					borderColor: 'gray.95',
					pt: '0.75rem',
					pb: '1rem',
					px: '1.25rem',

					_first: {
						maxW: '19rem',
						pl: '1rem',
					},

					_last: {
						pr: '1rem',
					},
				},
			},
			tfoot: {
				tr: {
					h: '2.375rem',
					backgroundColor: '#D9EBE7',
				},
				th: {
					fontFamily: 'Lato',
					fontWeight: 700,
					fontSize: { base: '1rem' },
					color: '#000000de',
					pl: '1rem',

					_first: {
						borderLeftRadius: '0.5rem',
					},

					_last: {
						pr: '1rem',
						textAlign: 'end',
						borderRightRadius: '0.5rem',
					},
				},
			},
		},
		controlPanel: {
			thead: {
				tr: {
					h: '3rem',
					backgroundColor: 'gray.80',
					borderRadius: '4px',
					position: 'sticky',
					top: 0,
				},
				th: {
					fontFamily: 'Lato',
					fontWeight: 700,
					fontSize: '1rem',
					textTransform: 'inherit',
					color: 'black.800',
					px: '0.5rem',

					_first: {
						pl: 0,
						borderBottomLeftRadius: '4px',
					},
					_last: {
						borderBottomRightRadius: '4px',
					},
				},
			},

			tbody: {
				tr: {
					borderBottom: '1px solid',
					borderColor: 'gray.95',

					_last: {
						border: 'none',
					},
				},
				td: {
					fontFamily: 'Lato',
					fontWeight: 500,
					fontSize: '1rem',
					color: 'black.800',

					p: '0.5rem',
					maxW: '18.75rem',

					_first: {
						pl: 0,
					},
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
		size: '',
	},
};

const Radio: ComponentStyleConfig = {
	variants: {
		medium: {
			control: {
				h: '1.5rem',
				w: '1.5rem',
			},
			label: {
				fontFamily: 'Lato',
				fontWeight: 'medium',
				fontSize: '1rem',
				color: 'black.800',
			},
		},
	},
};

const Checkbox: ComponentStyleConfig = {
	variants: {
		medium: {
			control: {
				h: '1.25rem',
				w: '1.25rem',
				ml: '0.115rem',
				mr: '0.125rem',
			},
			label: {
				fontFamily: 'Lato',
				fontWeight: 'medium',
				fontSize: '1rem',
				color: 'black.800',
			},
		},
	},
};

export const useCustomTheme = (breakpoints: any) => {
	const customTheme = useMemo(
		() => ({
			styles: {
				global: {
					body: {
						bg: 'white',
					},
				},
			},
			colors: {
				white: '#FFFFFF',
				transparent: 'rgba(217, 217, 217, 0)',
				green: {
					90: '#5EA82F36',
					95: '#329D9C36',
					100: '#E0ECDE',
					200: '#CDE0C9',
					300: '#68B2A0',
					400: '#56C596',
					450: '#3B9191',
					500: '#7BE495',
					550: '#3EA2A2',
					600: '#329D9C',
					650: '#328281',
					700: '#2C6975',
					800: '#5EA82F',
				},
				black: {
					700: '#282828',
					800: '#000000',
				},
				red: {
					350: '#B85F41',
					400: '#F75010',
					450: '#CC3300',
					500: '#C41616',
					550: '#C56443',
				},
				blue: {
					90: '#329D9C21',
					100: '#94CDF3',
					200: '#47CACC',
					300: '#3498DB',
					400: '#1492E6',
					500: '#0A77D2',
					600: '#0062FF',
					650: '#4A6A9F',
					700: '#205072',
					800: '#2C6DE6',
				},
				yellow: {
					300: '#FFBE88',
					400: '#FFCF5C',
					450: '#F1C40F',
					500: '#FFCC00',
				},
				gray: {
					75: '#FCFCFC',
					80: '#F9FAFC',
					85: '#F3F3F3',
					90: '#F2F2F2',
					95: '#E1E2E5',
					100: '#E2E2E2',
					150: '#E6E6E6',
					200: '#D9D9D9',
					250: '#CCCCCC',
					300: '#C0C1C4',
					350: '#BABABA',
					400: '#ABABAB',
					450: '#A7A7A7',
					500: '#B1B4BE',
					600: '#999999',
					650: '#979797',
					700: '#878787',
					750: '#707070',
					800: '#616161',
					900: '#3B3333',
				},
				pink: {
					300: '#E7B7C8',
					400: '#CDB3D4',
				},
			},
			components: {
				Text,
				Input,
				Textarea,
				FormError: FormErrorMessage,
				FormLabel,
				Heading,
				Button,
				Select,
				Link,
				Table,
				Radio,
				Checkbox,
			},
			size: {},
			fontSize: {},
		}),
		[],
	);

	return extendTheme({ breakpoints, ...customTheme });
};
