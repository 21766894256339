import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { IPerformanceRange } from 'types/performanceRange';

export const createPerformanceRange = async (data: IPerformanceRange): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.performanceRangeCreate, data);
	return response.data;
};
export interface IRequestGetPerformanceRangeCards {
	nomeConjunto?: string;
	mostrarFaixasInativas: boolean;
}

export interface IResponseGetPerformanceRangeCards {
	totalRecords: number;
	results: IPerformanceRange[];
}

export const getPerformanceRangeById = async (performanceRangeId: string): Promise<IPerformanceRange> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.performanceRangeGetById(performanceRangeId));
	return response.data;
};

export const getPerformanceRange = async (params?: IRequestGetPerformanceRangeCards) => {
	const api = getAxios();
	const response = await api.get<IPerformanceRange[]>(ENDPOINTS.performanceRangeList, {
		params: {
			...params,
		},
	});
	return response.data;
};

export const updatePerformanceRange = async (data: IPerformanceRange): Promise<IPerformanceRange> => {
	const api = getAxios();
	const response = await api.put<IPerformanceRange>(ENDPOINTS.performanceRangeUpdate(data.id!), data);
	return response.data;
};

export const updatePerformanceRangeStatus = async (performanceRangeId: string): Promise<void> => {
	const api = getAxios();
	await api.patch(ENDPOINTS.performanceRangeUpdateStatus(performanceRangeId));
};

export const deletePerformanceRangeCard = async (performanceRangeId: string): Promise<any> => {
	const api = getAxios();
	await api.delete(ENDPOINTS.performanceRangeDelete(performanceRangeId));
};

export interface IResponsePerformanceRangeName {
	id: string;
	nome: string;
}
