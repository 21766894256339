/**
 *
 * ResultEvaluationForm
 *
 */

import { IResultEvaluationForm } from '.';
import { Alert, AlertIcon, CloseButton, Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import { DeadlineIcon } from 'assets/icons';
import {
	RESULT_EVALUATION_ALERT,
	RESULT_EVALUATION_APPEAL_ALERT,
	RESULT_EVALUATION_SHOW_APPEAL_PHASES,
	RESULT_EVALUATION_SHOW_DEFERRAL_PHASES,
} from 'utils/constants';

interface ResultEvaluationCardAlertProps {
	data?: IResultEvaluationForm;
	onClose: () => void;
}

const ResultEvaluationCardAlert = ({ data, onClose }: ResultEvaluationCardAlertProps) => {
	const styles: Record<string, SystemStyleObject> = {
		alert: {
			py: '0.813rem',
			pl: '2rem',
			backgroundColor: data?.faseFinalizada ? '#F5F8FF' : '#FFF5EB',
			border: '1px solid',
			borderColor: data?.faseFinalizada ? '#C9DAFF' : '#FFD0A0',
			borderTopRadius: '4px',
			justifyContent: 'space-between',
			alignItems: 'center',
			h: '2.75rem',
		},
	};

	const showDeferral = RESULT_EVALUATION_SHOW_DEFERRAL_PHASES.includes(data?.faseEnum!);
	const showAppeal = RESULT_EVALUATION_SHOW_APPEAL_PHASES.includes(data?.faseEnum!);

	return (
		<Alert status={data?.faseFinalizada ? 'info' : 'warning'} sx={styles.alert}>
			<Flex alignItems="center" gap="1rem">
				{data?.faseFinalizada ? <AlertIcon /> : <DeadlineIcon />}
				{showDeferral && (
					<Text fontSize="1rem">
						<strong>Aviso: </strong>O recurso para este indicador foi{' '}
						<strong>{RESULT_EVALUATION_ALERT[data?.statusDeferimento as keyof typeof RESULT_EVALUATION_ALERT]}.</strong>
					</Text>
				)}
				{showAppeal && (
					<Text fontSize="1rem">
						<strong>Aviso: </strong>O pedido de recurso foi para{' '}
						<strong>
							'{RESULT_EVALUATION_APPEAL_ALERT[data?.solicitacaoEnum as keyof typeof RESULT_EVALUATION_APPEAL_ALERT]}'
						</strong>
						{data?.revisarPenalizacao && (
							<>
								, também fora pedido para <strong>'Revisar as penalizações facultativas'.</strong>
							</>
						)}
					</Text>
				)}
			</Flex>
			<CloseButton onClick={onClose} />
		</Alert>
	);
};

export default ResultEvaluationCardAlert;
