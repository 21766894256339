import { ISelectedDate } from '.';
import { SystemStyleObject, Button, Grid } from '@chakra-ui/react';

interface YearsViewProps {
	yearsRange: Date[];
	selectedDate: ISelectedDate;
	onChange: (value: ISelectedDate) => void;
	yearsToDisable?: number[];
}

const YearsView = ({ yearsRange, selectedDate, onChange, yearsToDisable }: YearsViewProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			gridTemplateColumns: 'repeat(4, 1fr)',
			gap: '0.5rem',
		},
		buttonBase: {
			h: {
				base: '2rem',
				lg: '1.688rem',
			},
			borderRadius: {
				base: '1rem',
				lg: '0.813rem',
			},
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			border: '0.063rem solid transparent',
			lineHeight: '0',
			fontWeight: 'medium',
		},
		buttonCurrent: {
			h: {
				base: '2rem',
				lg: '1.688rem',
			},
			borderRadius: {
				base: '1rem',
				lg: '0.813rem',
			},
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			fontWeight: 'medium',
			lineHeight: '0',
			color: 'blue.700',
			backgroundColor: 'green.100',
		},
	};

	const currentYear = new Date().getFullYear();

	return (
		<Grid sx={styles.container}>
			{yearsRange?.map(item =>
				item.getFullYear() === currentYear ? (
					<Button
						sx={
							currentYear === selectedDate.year && selectedDate.isCurrentSelected
								? styles.buttonBase
								: styles.buttonCurrent
						}
						onClick={() => onChange({ ...selectedDate, year: item.getFullYear() })}
						key={item.getFullYear()}
						data-testid={`button-year-${item.getFullYear()}`}
						isDisabled={yearsToDisable?.includes(item.getFullYear())}
					>
						{item.getFullYear()}
					</Button>
				) : item.getFullYear() === selectedDate.year && selectedDate.isCurrentSelected ? (
					<Button
						sx={styles.buttonBase}
						onClick={() => onChange({ ...selectedDate, year: item.getFullYear() })}
						key={item.getFullYear()}
						data-testid={`button-year-${item.getFullYear()}`}
						isDisabled={yearsToDisable?.includes(item.getFullYear())}
					>
						{item.getFullYear()}
					</Button>
				) : (
					<Button
						variant="secondary"
						sx={styles.buttonBase}
						color="blue.700"
						onClick={() => onChange({ ...selectedDate, year: item.getFullYear() })}
						key={item.getFullYear()}
						data-testid={`button-year-${item.getFullYear()}`}
						isDisabled={yearsToDisable?.includes(item.getFullYear())}
					>
						{item.getFullYear()}
					</Button>
				),
			)}
		</Grid>
	);
};

export default YearsView;
