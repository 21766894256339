/**
 *
 * ResultEvaluationForm
 *
 */

import ResultEvaluationCard from './ResultEvaluationCard';
import ResultEvaluationCardAlert from './ResultEvaluationCardAlert';
import ResultEvaluationVoteCard from './ResultEvaluationVoteCard';
import CustomModal from '../CustomModal';
import InputFile, { IFile } from '../InputFile';
import InputNumeric from '../InputNumeric';
import InputText from '../InputText';
import InputTextarea from '../InputTextarea';
import Prompt from '../Prompt';
import RadioButtonValidateResults from '../RadioButtonValidateResults';
import { IVotingRecord } from '../ResultEvaluationVotingForm';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, Flex, Grid, SystemStyleObject, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { getYear } from 'date-fns';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useDebounce } from 'hooks/useDebounce';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	appealFirstInstancePhaseExport,
	appealSecondInstancePhaseExport,
	caapDecisionPhaseExport,
	calculateResultEvaluation,
	counterReasonsPhaseExport,
	decisionFirstInstancePhaseExport,
	getResultEvaluationSummary,
	launchPhaseExport,
	validationPhaseExport,
} from 'services/http/resultEvaluation';
import {
	ICalculateResultResponse,
	ICalculateResultRequest,
	IResultEvaluationSummary,
	UnitMeasurement,
} from 'types/resultEvaluation';
import {
	BIMESTERS_ENUM_TO_MONTHS,
	RESULT_EVALUATION_PHASES,
	RESULT_EVALUATION_SHOW_ALERT_PHASES,
	RESULT_EVALUATION_APPEAL,
	RESULT_EVALUATION,
	API_DEFAULT_ERROR,
	RESULT_EVALUATION_SHOW_APPEAL_PHASES,
	RESULT_EVALUATION_SHOW_DEFERRAL_PHASES,
	ALLOWED_EXTENSIONS,
	CONVERSION_FACTOR,
	RESULT_EVALUATION_PHASES_ENUM,
	TOTAL_PERCENTAGE,
	BIMESTER_ENUM_TO_VALUE,
} from 'utils/constants';
import { USER_ROLES } from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { getBimester } from 'utils/formatBimesters';
import { handlePermission } from 'utils/handlePermission';
import { isFileSizeValid } from 'utils/isFileSizeValid';
import { allowedPorcentage, maskThousands, unMaskThousands } from 'utils/Numbers';
import { ResponseErrors } from 'utils/parseErrors';
import * as yup from 'yup';

interface IResultEvaluationVotingForm {
	registrosVotacao: IVotingRecord[];
	totalInabilitado?: number;
	totalAusente?: number;
	totalFavoravel?: number;
	totalContrario?: number;
	totalAbstencao?: number;
	totalVotos?: number;
}

export interface IResultEvaluationForm {
	id?: number;
	idIndicadoresPactoGestao?: number;
	pactuado?: string;
	nomeIndicador?: string;
	ano?: string;
	bimestre?: string;
	faseEnum?: string;
	meta?: string;
	resultadoTotal?: string;
	desempenho?: string;
	pesoCalculado?: string | number;
	pontosObtidos?: string;
	polaridade?: string;
	statusDeferimento?: string;
	solicitacaoEnum?: string;
	revisarPenalizacao?: boolean;
	naoSeAplica?: boolean;
	descricao?: string;
	documentos?: IFile[];
	responsavelLancamento?: string;
	ultimaAtualizacao?: string;
	faseFinalizada?: boolean;
	votacoes?: IResultEvaluationVotingForm;
	etapa?: string;
	tipoResponsavelLancamento?: string;
	nomeAreaTecnica?: string;
	votacaoFinalizada?: boolean;
}

interface ResultEvaluationFormProps {
	defaultValues?: IResultEvaluationForm;
	index?: number;
	onCreate: (data: IResultEvaluationForm) => Promise<boolean>;
	onUpdate: (data: IResultEvaluationForm) => Promise<boolean>;
	onDelete: (phase: string, id: string) => void;
	onNewPhase: (phase: string) => void;
	hasVotingForm?: boolean;
	unitMeasurement: UnitMeasurement;
}

const ResultEvaluationForm = ({
	defaultValues,
	index,
	onCreate,
	onUpdate,
	onDelete,
	onNewPhase,
	hasVotingForm = false,
}: ResultEvaluationFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			flexDir: 'column',
		},
		content: {
			p: '2rem 1.5rem',
			flexDir: 'column',
		},
		headerContainer: {
			flexDir: 'column',
			my: '0.5rem',
			mb: '2.125rem',
		},
		headerContent: {
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		headerTextContent: {
			w: '100%',
			gap: '1.5rem',
			alignItems: 'center',
			flexWrap: 'wrap',
		},
		headerText: {
			fontSize: '1.25rem',
			fontWeight: 'semibold',
		},
		textSmall: {
			fontSize: '0.875rem',
			fontWeight: 'medium',
		},
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
		faseText: {
			backgroundColor: '#3498DB33',
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#000000',
			borderRadius: '16px',
			h: '2.063rem',
			px: '0.5rem',
			pt: '0.25rem',
		},
		upperButton: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
					xl: 'auto',
				},
			},
		},
		calcContainer: {
			mb: '2rem',
			gap: {
				base: '1.5rem',
				lg: '3.375rem',
			},
			w: {
				base: '100%',
				lg: 'fit-content',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		calcInput: {
			w: {
				base: '100%',
				lg: '10rem',
			},
			fontSize: '1rem',
			_disabled: {
				color: '#000000',
				background: '#F2F2F2',
				borderColor: '#E1E2E5',
			},
		},
		anexoContainer: {
			flexDir: 'column',
			mt: '1.5rem',
			gap: '1rem',
		},
		text: {
			fontSize: '1rem',
			fontWeight: 'medium',
		},
		responsavelInput: {
			w: {
				base: '100%',
				lg: '22.625rem',
			},
			fontSize: '1rem',
			_disabled: {
				color: '#000000',
				background: '#F2F2F2',
				borderColor: '#E1E2E5',
			},
		},
		textarea: {
			h: '8.688rem',
			resize: 'none',
		},
		filesContainer: {
			gap: '14px',
			rowGap: '1rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
				xl: 'repeat(3, 1fr)',
			},
			mb: '2rem',
		},
		buttonContainer: {
			mt: '2rem',
			gap: {
				base: '1.5rem',
				lg: '2.75rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		button: {
			px: '5.375rem',
		},
		checkboxContainer: {
			alignItems: 'flex-end',
			ml: {
				base: 0,
				lg: '-2rem',
			},
		},
		checkbox: {
			color: 'black.800',
			fontWeight: 'medium',
			fontFamily: 'Lato',
			fontSize: '1rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
	const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
	const { isOpen: isOpenCancelModal, onOpen: onOpenCancelModal, onClose: onCloseCancelModal } = useDisclosure();

	const [isEditing, setIsEditing] = useState(false);
	const [isListing, setIsListing] = useState(!!defaultValues?.id);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const {
		session: { user },
	} = useSession();

	const hasPresidentRole = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const canHandle = handlePermission(user, defaultValues!);

	const hasPermission = hasPresidentRole ? hasPresidentRole : canHandle;

	const fileSchema = yup
		.mixed()
		.notRequired()
		.test('size', 'Tamanho do arquivo não pode ser maior que 10mb.', (value: IFile) => {
			const fileSize = value.id ? Number(value?.size?.replace(',', '.')) : Number(value?.size);

			const isSizeValid = isFileSizeValid(fileSize);

			if (!isSizeValid) {
				onOpenModal();
				return false;
			}

			return true;
		})
		.test('type', 'Selecione um arquivo do tipo .pdf de até 10mb.', (value: IFile) => {
			if (value.id) return true;

			const isValid = ALLOWED_EXTENSIONS.includes(value?.type!);

			if (!isValid) {
				onOpenModal();
				return !isValid;
			}

			return isValid;
		});

	const launchPhaseSchema = yup.object().shape({
		naoSeAplica: yup.boolean(),
		resultadoTotal: yup.string().when('naoSeAplica', {
			is: true,
			then: yup.string().nullable().notRequired(),
			otherwise: yup.string().required('Este campo é obrigatório.'),
		}),
		documentos: yup.array().of(fileSchema),
	});

	const validationPhaseSchema = yup.object().shape({
		naoSeAplica: yup.boolean(),
		statusDeferimento: yup.string().required('Este campo é obrigatório.'),
		resultadoTotal: yup.string().when('statusDeferimento', {
			is: RESULT_EVALUATION.DEFERIDO,
			then: yup.string().nullable().notRequired(),
			otherwise: yup.string().when('naoSeAplica', {
				is: true,
				then: yup.string().nullable().notRequired(),
				otherwise: yup.string().required('Este campo é obrigatório.'),
			}),
		}),
		descricao: yup.string().when('statusDeferimento', {
			is: RESULT_EVALUATION.DEFERIDO,
			then: yup.string().nullable().notRequired(),
			otherwise: yup.string().required('Este campo é obrigatório.').max(1000, 'Máximo de 1000 caracteres.').nullable(),
		}),
		documentos: yup.array().of(fileSchema),
	});

	const appealPhasesSchema = yup.object().shape({
		solicitacaoEnum: yup.string().required('Este campo é obrigatório.'),
		descricao: yup.string().required('Este campo é obrigatório.').max(1000, 'Máximo de 1000 caracteres.'),
		documentos: yup.array().of(fileSchema),
	});

	const decisionPhasesSchema = yup.object().shape({
		naoSeAplica: yup.boolean(),
		statusDeferimento: yup.string().required('Este campo é obrigatório.'),
		solicitacaoEnum: yup.string().notRequired().nullable(),
		resultadoTotal: yup.string().when('statusDeferimento', {
			is: RESULT_EVALUATION.DEFERIDO,
			then: yup.string().when('solicitacaoEnum', {
				is: RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO,
				then: yup.string().when('naoSeAplica', {
					is: true,
					then: yup.string().nullable().notRequired(),
					otherwise: yup.string().required('Este campo é obrigatório.'),
				}),
				otherwise: yup.string().nullable().notRequired(),
			}),
			otherwise: yup.string().nullable().notRequired(),
		}),
		descricao: yup.string().when('statusDeferimento', {
			is: RESULT_EVALUATION.DEFERIDO,
			then: yup.string().nullable().notRequired(),
			otherwise: yup.string().required('Este campo é obrigatório.').max(1000, 'Máximo de 1000 caracteres.').nullable(),
		}),
		documentos: yup.array().of(fileSchema),
	});

	const counterReasonsPhaseSchema = yup.object().shape({
		descricao: yup.string().required('Este campo é obrigatório.').max(1000, 'Máximo de 1000 caracteres.'),
		documentos: yup.array().of(fileSchema),
	});

	const SCHEMA: Record<string, yup.ObjectSchema<any>> = {
		LANCAMENTO_RESULTADO: launchPhaseSchema,
		VALIDACAO_RESULTADO: validationPhaseSchema,
		SOLICITACAO_PRIMEIRA_INSTANCIA: appealPhasesSchema,
		DECISAO_PRIMEIRA_INSTANCIA: decisionPhasesSchema,
		SOLICITACAO_SEGUNDA_INSTANCIA: appealPhasesSchema,
		CONTRARRAZOES: counterReasonsPhaseSchema,
		DECISAO_CAAP: decisionPhasesSchema,
	};

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		register,
		reset,
		getValues,
		formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitted },
	} = useForm<IResultEvaluationForm>({
		resolver: yupResolver(SCHEMA[defaultValues?.faseEnum!]),
		defaultValues,
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'documentos',
	});

	const idIndicatorPar = getValues('idIndicadoresPactoGestao');

	const { data: summary } = useQuery<IResultEvaluationSummary, AxiosError<ResponseErrors>>(
		['sumario', idIndicatorPar],
		() => getResultEvaluationSummary(String(idIndicatorPar)),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
			enabled: !!idIndicatorPar,
		},
	);

	const { mutate: calculateResult } = useMutation<
		ICalculateResultResponse,
		AxiosError<ResponseErrors>,
		ICalculateResultRequest
	>(data => calculateResultEvaluation(data), {
		onSuccess: data => {
			setValue('desempenho', maskThousands(data.desempenho));
			setValue('pontosObtidos', maskThousands(data.pontosObtidos));
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const { mutate: launchPhaseExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => launchPhaseExport(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'lançamento resultado.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: validationPhaseExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => validationPhaseExport(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'validação resultado.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: appealFirstInstancePhaseExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => appealFirstInstancePhaseExport(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'recorrer primeira instancia.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: decisionFirstInstancePhaseExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => decisionFirstInstancePhaseExport(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'decisão primeira instancia.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: appealSecondInstancePhaseExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => appealSecondInstancePhaseExport(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'recorrer segunda instancia.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: counterReasonsPhaseExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => counterReasonsPhaseExport(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'contrarrazoes.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const { mutate: caapDecisionPhaseExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => caapDecisionPhaseExport(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'decisao caap.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const formValues = watch();

	const currentBimester = getBimester(new Date());
	const currentYear = getYear(new Date());
	const apiFinalFase = watch('faseFinalizada');

	const {
		showResults,
		showAlert,
		showDeferralFields,
		showAppealFields,
		showOptional,
		enableSecondInstanceDecisionButton,
		isLaunchPhase,
	} = useMemo(() => {
		const isLaunchPhase = defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO;

		const isValidationPhase = defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO;

		const isFirstInstanceDecisionPhase =
			defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA;

		const isCaapDecisionPhase = defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP;

		const isDeferred = formValues.statusDeferimento === RESULT_EVALUATION.DEFERIDO;

		const isCalcRevision = defaultValues?.solicitacaoEnum === RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO;

		const showFieldsInValidation = isValidationPhase && !isDeferred;

		const showFieldsInFirstInstanceDecision = isFirstInstanceDecisionPhase && isCalcRevision && isDeferred;

		const showFieldsCaapDecision = isCaapDecisionPhase && isCalcRevision && isDeferred;

		const showAlert = RESULT_EVALUATION_SHOW_ALERT_PHASES.includes(defaultValues?.faseEnum!) && isListing;

		const showDeferralFields = RESULT_EVALUATION_SHOW_DEFERRAL_PHASES.includes(defaultValues?.faseEnum!);

		const showAppealFields = RESULT_EVALUATION_SHOW_APPEAL_PHASES.includes(defaultValues?.faseEnum!);

		const showOptional = (showDeferralFields && isDeferred) || isLaunchPhase;

		const showResults =
			isLaunchPhase || showFieldsInValidation || showFieldsInFirstInstanceDecision || showFieldsCaapDecision;

		const enableSecondInstanceDecisionButton = isFirstInstanceDecisionPhase && !isCalcRevision && isDeferred;

		return {
			showResults,
			showAlert,
			showDeferralFields,
			showAppealFields,
			showOptional,
			enableSecondInstanceDecisionButton,
			isLaunchPhase,
		};
	}, [defaultValues?.faseEnum, defaultValues?.solicitacaoEnum, formValues.statusDeferimento, isListing]);

	const { indicatorStatus, indicatorStatusCalc } = useMemo(() => {
		const indicatorStatus = summary?.peso;
		const indicatorStatusCalc = summary?.pesoCalculado;

		return { indicatorStatus, indicatorStatusCalc };
	}, [summary?.peso, summary?.pesoCalculado]);

	const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event?.target?.files) {
			const file = event?.target?.files[0];

			await fileSchema?.validate(file);

			append({ name: file.name, size: String(file.size / CONVERSION_FACTOR), type: file.type, file });
		}
	};

	const handleDeleteFile = (value: IFile, index: number) => {
		remove(index);
	};

	const handleCancelButton = () => {
		if (isEditing) {
			setIsEditing(false);
			setIsListing(true);
		} else {
			handleOpenModal();
		}
	};

	const onChangeValues = () => {
		const data: ICalculateResultRequest = {
			meta: unMaskThousands(formValues.meta || ''),
			pesoCalculado: unMaskThousands(formValues.pesoCalculado || 0) / TOTAL_PERCENTAGE,
			polaridade: formValues.polaridade!,
			resultado: unMaskThousands(formValues.resultadoTotal || ''),
			idIndicadoresPactoGestao: formValues.idIndicadoresPactoGestao!,
		};

		calculateResult(data);
	};

	const debounceCalc = useDebounce(onChangeValues, 600);

	const handleExportPhase = () => {
		if (defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
			launchPhaseExportMutate(String(defaultValues.id));
		}

		if (defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO) {
			validationPhaseExportMutate(String(defaultValues.id));
		}

		if (defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA) {
			appealFirstInstancePhaseExportMutate(String(defaultValues.id));
		}

		if (defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA) {
			decisionFirstInstancePhaseExportMutate(String(defaultValues.id));
		}

		if (defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA) {
			appealSecondInstancePhaseExportMutate(String(defaultValues.id));
		}

		if (defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.CONTRARRAZOES) {
			counterReasonsPhaseExportMutate(String(defaultValues.id));
		}

		if (defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP) {
			caapDecisionPhaseExportMutate(String(defaultValues.id));
		}
	};

	const resultFields = () => {
		return (
			<Flex sx={styles.calcContainer}>
				<Controller
					name="resultadoTotal"
					control={control}
					render={({ field: { onChange, value } }) =>
						formValues.naoSeAplica ? (
							<InputText
								name="resultadoTotal"
								label="Resultado"
								tooltipInfo="A unidade de medida deve estar de acordo com	a indicada na matriz. Selecione o checkbox para requerir a suspenção do indicador."
								sx={styles.calcInput}
								onChange={onChange}
								value={'N/A'}
								placeholder="Valor"
								data-testid="input--resultado"
								isReadOnly
							/>
						) : (
							<InputNumeric
								name="resultadoTotal"
								label="Resultado"
								tooltipInfo="A unidade de medida deve estar de acordo com	a indicada na matriz. Selecione o checkbox para requerir a suspenção do indicador."
								sx={styles.calcInput}
								fixedDecimalScale
								isAllowed={values => allowedPorcentage(values, 9)}
								onKeyUp={debounceCalc}
								onChange={onChange}
								value={value || ''}
								errorMessage={errors?.resultadoTotal?.message}
								placeholder="Valor"
								data-testid="input--resultado"
								isEditing={isEditing && !!dirtyFields?.resultadoTotal}
								isDisabled={isDisabled}
							/>
						)
					}
				/>

				<Flex sx={{ ...styles.checkboxContainer, mb: !!errors?.resultadoTotal?.message ? '3.3rem' : '0.7rem' }}>
					<Checkbox {...register?.('naoSeAplica')} sx={styles.checkbox} isDisabled={isDisabled}>
						N/A
					</Checkbox>
				</Flex>

				<Controller
					name="desempenho"
					control={control}
					render={({ field: { onChange, value } }) =>
						formValues.naoSeAplica ? (
							<InputText
								name="desempenho"
								label="Desempenho"
								sx={styles.calcInput}
								onChange={onChange}
								value={'N/A'}
								placeholder="0%"
								data-testid="input--desempenho"
								isReadOnly
								disabled
							/>
						) : (
							<InputNumeric
								name="desempenho"
								label="Desempenho"
								suffix="%"
								decimalScale={2}
								fixedDecimalScale
								disabled
								onChange={onChange}
								value={value || ''}
								sx={styles.calcInput}
								errorMessage={errors?.desempenho?.message}
								placeholder="0%"
								data-testid="input--desempenho"
								isEditing={isEditing && !!dirtyFields?.desempenho}
								isDisabled={isDisabled}
							/>
						)
					}
				/>
				<Controller
					name="pesoCalculado"
					control={control}
					render={({ field: { onChange, value } }) =>
						formValues.naoSeAplica ? (
							<InputText
								name="pesoCalculado"
								label="Peso"
								sx={styles.calcInput}
								onChange={onChange}
								value={'N/A'}
								placeholder="0,00"
								data-testid="input--peso"
								isReadOnly
								disabled
							/>
						) : (
							<InputNumeric
								name="pesoCalculado"
								label="Peso"
								disabled
								onChange={onChange}
								value={value || ''}
								sx={styles.calcInput}
								errorMessage={errors?.pesoCalculado?.message}
								placeholder="0,00"
								data-testid="input--peso"
								isEditing={isEditing && !!dirtyFields?.pesoCalculado}
								isDisabled={isDisabled}
							/>
						)
					}
				/>
				<Controller
					name="pontosObtidos"
					control={control}
					render={({ field: { onChange, value } }) =>
						formValues.naoSeAplica ? (
							<InputText
								name="pontosObtidos"
								label="Pontos obtidos"
								sx={styles.calcInput}
								onChange={onChange}
								value={'N/A'}
								placeholder="0,00"
								data-testid="input--pontosObtidos"
								isReadOnly
								disabled
							/>
						) : (
							<InputNumeric
								name="pontosObtidos"
								label="Pontos obtidos"
								disabled
								onChange={onChange}
								value={value || ''}
								decimalScale={2}
								fixedDecimalScale
								isAllowed={values => allowedPorcentage(values, 3)}
								sx={styles.calcInput}
								errorMessage={errors?.pontosObtidos?.message}
								placeholder="0,00"
								data-testid="input--pontosObtidos"
								isEditing={isEditing && !!dirtyFields?.pontosObtidos}
								isDisabled={isDisabled}
							/>
						)
					}
				/>
			</Flex>
		);
	};

	const onHandleSubmit = async (data: IResultEvaluationForm) => {
		if (isEditing) {
			const isSuccessfullyUpdated = await onUpdate?.(data);
			isSuccessfullyUpdated && setIsListing(isSuccessfullyUpdated);
			isSuccessfullyUpdated && setIsEditing(!isSuccessfullyUpdated);
		} else {
			if (isListing) {
				onNewPhase(data.faseEnum!);
			} else {
				const isSuccessfullyCreated = await onCreate?.(data);
				isSuccessfullyCreated && setIsListing(isSuccessfullyCreated);
			}
		}
	};

	const handleGoBack = () => {
		onCloseCancelModal();
		navigate(ROUTES.results);

		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpenCancelModal();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onCloseCancelModal();
	};

	useEffect(() => {
		const numberBimesterEnum = Number(
			BIMESTER_ENUM_TO_VALUE[defaultValues?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE],
		);
		const isOneBimesterDifferenceInTheSameYear =
			(currentBimester === numberBimesterEnum || currentBimester - numberBimesterEnum === 1) &&
			Number(currentYear) === Number(defaultValues?.ano);
		const isOneBimesterDifferenceWithTheLastYear =
			currentBimester - numberBimesterEnum === -5 && Number(currentYear) - Number(defaultValues?.ano) === 1;

		if (isOneBimesterDifferenceInTheSameYear || isOneBimesterDifferenceWithTheLastYear) {
			setIsDisabled(false);
			setIsButtonDisabled(false);
		} else {
			setIsDisabled(true);
			setIsButtonDisabled(true);
		}

		if (Number(indicatorStatus) <= 0 && isLaunchPhase) {
			setValue('naoSeAplica', true);
			setIsDisabled(true);
		}

		if (Number(indicatorStatusCalc) <= 0 && isLaunchPhase) {
			setValue('naoSeAplica', true);
			setIsDisabled(true);
		}

		if (enableSecondInstanceDecisionButton && !hasPresidentRole) {
			setIsButtonDisabled(true);
		}
	}, [
		currentBimester,
		currentYear,
		indicatorStatus,
		setValue,
		indicatorStatusCalc,
		apiFinalFase,
		hasPresidentRole,
		enableSecondInstanceDecisionButton,
		isLaunchPhase,
		defaultValues?.bimestre,
		defaultValues?.ano,
	]);

	useEffect(() => {
		reset(defaultValues);

		defaultValues?.id ? setIsListing(true) : setIsListing(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValues]);

	return (
		<Flex sx={styles.container} as="form" onSubmit={handleSubmit(onHandleSubmit)}>
			{showAlert && isOpen && <ResultEvaluationCardAlert data={defaultValues} onClose={onClose} />}
			{defaultValues?.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP && hasVotingForm && (
				<ResultEvaluationVoteCard data={defaultValues} />
			)}
			<Flex sx={styles.content}>
				<Text sx={styles.textSmall} color="#0A77D2">
					{defaultValues?.pactuado}
				</Text>
				<Flex sx={styles.headerContainer}>
					<Flex sx={styles.headerContent}>
						<Flex sx={styles.headerTextContent}>
							<Text sx={styles.headerText}>
								{`${defaultValues?.nomeIndicador} — ${defaultValues?.ano} / ${
									BIMESTERS_ENUM_TO_MONTHS[defaultValues?.bimestre as keyof typeof BIMESTERS_ENUM_TO_MONTHS]
								}`}
							</Text>

							<Tooltip label="Nome da Fase" sx={styles.tooltip} placement="top-start">
								<Text sx={styles.faseText}>
									{
										RESULT_EVALUATION_PHASES_ENUM[defaultValues?.etapa as keyof typeof RESULT_EVALUATION_PHASES_ENUM][
											defaultValues?.faseEnum!
										]
									}
								</Text>
							</Tooltip>

							{isListing && (
								<>
									<Button
										variant="unstyled"
										rightIcon={<DownloadIcon />}
										sx={styles.upperButton}
										onClick={handleExportPhase}
									>
										Baixar em PDF
									</Button>
								</>
							)}
						</Flex>
					</Flex>
					{(isEditing || isListing) && (
						<Text sx={styles.textSmall}>
							Data da última atualização: {defaultValues?.ultimaAtualizacao?.split(' ').at(0)}
						</Text>
					)}
				</Flex>
				{isListing || defaultValues?.faseFinalizada ? (
					<ResultEvaluationCard
						key={index}
						onDelete={onDelete}
						setIsEditing={setIsEditing}
						setIsListing={setIsListing}
						data={defaultValues}
						isBtnDisabled={isDisabled}
						isBtnFaseDisabeld={isButtonDisabled}
					/>
				) : (
					<>
						{showDeferralFields && (
							<Flex sx={styles.calcContainer}>
								<Controller
									name="statusDeferimento"
									control={control}
									render={({ field: { onChange, value } }) => (
										<RadioButtonValidateResults
											radio={{
												data: [
													{
														label: 'Resultado deferido',
														value: RESULT_EVALUATION.DEFERIDO,
														dataTestId: 'radio--deferido',
													},
													{
														label: 'Resultado indeferido',
														value: RESULT_EVALUATION.INDEFERIDO,
														dataTestId: 'radio--indeferido',
													},
												],
												errorMessage: errors?.statusDeferimento?.message,
												onChange,
												value,
											}}
										/>
									)}
								/>
							</Flex>
						)}

						{showAppealFields && (
							<Flex sx={styles.calcContainer}>
								<Controller
									name="solicitacaoEnum"
									control={control}
									render={({ field: { onChange, value } }) => (
										<RadioButtonValidateResults
											radio={{
												data: [
													{
														label: 'Revisão de cálculo',
														value: RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO,
														dataTestId: 'radio--revisao',
													},
													{
														label: 'Suspensão do indicador',
														value: RESULT_EVALUATION_APPEAL.SUSPENSAO_DE_INDICADOR,
														dataTestId: 'radio--suspensao',
													},
												],
												errorMessage: errors?.solicitacaoEnum?.message,
												onChange,
												value,
											}}
											checkbox={{
												label: 'Revisar as penalizações facultativas (Opcional)',
												name: 'revisarPenalizacao',
												dataTestId: 'checkbox--revisarPenalizacao',
												register,
											}}
										/>
									)}
								/>
							</Flex>
						)}

						{showResults && resultFields()}

						<Controller
							name="descricao"
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputTextarea
									name="descricao"
									label={showOptional ? 'Descrição (Opcional)' : 'Descrição'}
									onChange={onChange}
									value={value || ''}
									sx={styles.textarea}
									errorMessage={errors?.descricao?.message}
									placeholder="Descreva o resultado"
									data-testid="input--descricao"
									isEditing={isEditing && !!dirtyFields?.descricao}
									maxLength={100}
									required={!showOptional}
									isDisabled={isDisabled}
								/>
							)}
						/>

						<Flex sx={styles.anexoContainer}>
							<Text sx={styles.text}>Fontes de comprovação</Text>
							<Grid sx={styles.filesContainer}>
								{fields?.map((item, index) => (
									<Box key={item.id} display="flex">
										<Controller
											name="documentos"
											control={control}
											render={() => (
												<InputFile
													file={item}
													data-testid="input--upload"
													onDeleteDoc={() => handleDeleteFile(item, index)}
													disabled={isDisabled}
												/>
											)}
										/>
									</Box>
								))}
								<Box>
									<InputFile
										label="Adicionar um novo arquivo"
										subLabel="Arquivo '.pdf', '.docx', '.xlxs' ou '.pptx' de até 10mb"
										name="documentos"
										acceptValues={ALLOWED_EXTENSIONS}
										data-testid="input--upload"
										onChangeDoc={onChangeFile}
										disabled={isDisabled}
									/>
								</Box>
							</Grid>
						</Flex>

						<Controller
							name="responsavelLancamento"
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputText
									name="responsavel"
									label="Responsável pelo preenchimento"
									disabled
									onChange={onChange}
									value={value || ''}
									sx={styles.responsavelInput}
									errorMessage={errors?.responsavelLancamento?.message}
									data-testid="input--responsavel"
								/>
							)}
						/>

						<Flex sx={styles.buttonContainer}>
							<Button
								type="submit"
								sx={styles.button}
								data-testid="button--submit"
								isDisabled={isDisabled || !hasPermission}
							>
								Salvar
							</Button>
							<Button
								variant="secondary"
								sx={styles.button}
								data-testid="button--cancel"
								onClick={handleCancelButton}
								isDisabled={isDisabled}
							>
								Cancelar
							</Button>
						</Flex>
					</>
				)}
			</Flex>
			<CustomModal
				icons={[{ type: 'info' }]}
				title="Aviso!"
				body="O formato de todos os arquivos devem ser: '.pdf', '.docx', '.xlsx' ou '.pptx' de no máximo 10mb."
				isOpen={isOpenModal}
				onClose={onCloseModal}
				actions={[
					{
						label: 'Entendido',
						type: 'primary',
						onClick: onCloseModal,
						datatestid: 'button--confirm',
					},
				]}
			/>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você realmente quer cancelar?"
				body="Todas as informações inseridas serão perdidas caso você cancele."
				isOpen={isOpenCancelModal}
				onClose={onCloseCancelModal}
				actions={[
					{
						label: 'Quero cancelar',
						type: 'cancel',
						onClick: handleGoBack,
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleCloseModal,
						datatestid: 'button--cancel',
					},
				]}
			/>

			<Prompt when={showPrompt} />
		</Flex>
	);
};

export default ResultEvaluationForm;
