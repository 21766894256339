/**
 *
 * ReportMonitoringReducedModal
 *
 */

import ReportMonitoringModalTable from '../ReportMonitoringModalTable';
import { useMemo } from 'react';
import React from 'react';
import {
	Button,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	SystemStyleObject,
	Box,
	Flex,
	Heading,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { DownloadPdfIconModal, SighMainLogoIconModal } from 'assets/icons';
import { AxiosError } from 'axios';
import { useCustomToast } from 'hooks/useToast';
import {
	downloadReducedReportMonitoringPdf,
	downloadReducedReportMonitoringXlxs,
} from 'services/http/monitoringReport';
import { IReportMonitoringResponse } from 'types/reportMonitoring';
import { IColumn } from 'types/table';
import { API_DEFAULT_ERROR, BIMESTERS_ENUM, BIMESTER_ENUM_TO_VALUE } from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { formatDecimalNumber } from 'utils/formatPerformanceNumber';
import { ResponseErrors } from 'utils/parseErrors';

export interface IUnityReducedModal {
	id?: string;
	unidade: string;
	setor: string;
	desempenho: number | undefined;
}

export interface ReportMonitoringReducedData {
	id?: string;
	ano?: number;
	bimestre: string;
	mediaDesempenho?: number;
	unidades: IUnityReducedModal[];
}

interface ReportMonitoringReducedModalProps {
	onClose: () => void;
	isOpen: boolean;
	data: IReportMonitoringResponse;
}

const ReportMonitoringReducedModal = ({ onClose, isOpen, data }: ReportMonitoringReducedModalProps) => {
	const { addToast } = useCustomToast();

	const styles: Record<string, SystemStyleObject> = {
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		principal: {
			maxWidth: '68.75rem',
			w: '100%',
			alignItems: 'center',
			padding: '0.5rem 3rem',
			margin: '2.5rem',
		},
		header: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%',
			flexDirection: { base: 'column', lg: 'row' },
			alignItems: { base: 'baseline', lg: 'center' },
		},
		title: { fontSize: '1.5rem', fontWeight: 600, textAlign: 'center' },
		boxB: { m: { base: '1.5rem 0', lg: '0 9rem' } },
		head: {
			flexDirection: { base: 'column', lg: 'row' },
			m: { base: '0', lg: '1rem 0' },
			gap: '0.625rem',
			alignItems: { base: 'baseline', lg: 'center' },
		},
		headText: { fontSize: '1.5rem', fontWeight: 800 },
		titleThirdBox: {
			display: 'flex',
			maxW: '59.25rem',
			color: 'white',
			borderRadius: '0.25rem',
			p: '1rem',
			bgColor: '#004A73',
			minHeight: '2.188rem',
			fontSize: '1rem',
			fontWeight: 700,
			alignItems: 'center',
			justifyContent: 'flex-start',
			textAlign: 'left',
		},
		thirdBox: {
			maxW: { base: '100%', lg: '59.25rem' },
			w: '100%',
			borderRadius: '0.25rem',
			p: '0.8rem 1rem',
			bgColor: '#ddeffd',
			minHeight: '8rem',
			gap: { base: '1rem', lg: '2rem' },
			th: {
				width: '3rem',
				textAlign: 'center',
				fontWeight: 'bold',
				height: '4rem',
				borderBottom: 'none',
			},
			'th:first-of-type': {
				paddingLeft: ' 2rem',
				paddingRight: '0',
				borderRight: '1px solid #E1E2E5',
			},
		},
		titleContent: {
			flexDirection: { base: 'column', lg: 'row' },
			gap: { base: '1.5rem', lg: '3.5rem' },
			mb: '2rem',
			mt: '0.5rem',
		},
		footerContent: {
			maxW: { base: '100%', lg: '40rem' },
			w: '100%',
			justifyContent: 'center',
			alignSelf: 'center',
			gap: { base: '1.5rem', sm: '1.875rem' },
			flexDirection: { base: 'column', lg: 'row' },
		},
		button: { fontSize: '1rem', h: '2.75rem', w: '100%' },
		modalCloseBtn: {
			margin: '0.5rem 1rem',
			padding: '0.625rem',
		},
		modalTextSubtitle: {
			fontSize: '24px',
			fontWeight: 'bold',
			color: 'black',
		},
		modalTextSubtitleResponse: {
			fontSize: { base: '35px', xl: '49px' },
			fontWeight: 'bold',
			color: '#004A73',
		},
	};

	const { mutate: downloadMutatePdf, isLoading: isLoadingDownloadPdf } = useMutation<Blob, AxiosError<ResponseErrors>>(
		() => downloadReducedReportMonitoringPdf(data!),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);
				downloadFile(href, 'relatorio_reduzido', true);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				});
			},
		},
	);

	const handleExportPdf = () => {
		downloadMutatePdf();
	};

	const { mutate: downloadMutateXlxs, isLoading: isLoadingDownloadXlxs } = useMutation<
		Blob,
		AxiosError<ResponseErrors>
	>(() => downloadReducedReportMonitoringXlxs(data!), {
		onSuccess: data => {
			const href = URL.createObjectURL(data);
			downloadFile(href, 'relatorio_reduzido', false);
		},
		onError: ({ response }) => {
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			});
		},
	});

	const handleExportXlxs = () => {
		downloadMutateXlxs();
	};

	const columns: IColumn[] = [
		{
			key: 'unidade',
			label: 'Unidade',
		},
		{
			key: 'setor',
			label: 'Setor',
		},
		{
			key: 'desempenho',
			label: 'Desempenho',
		},
	];

	const modalReducedData = useMemo(() => {
		let _relatorio: ReportMonitoringReducedData[] = [];

		data.relatorios?.forEach(relatorio => {
			relatorio.setores?.forEach(setor => {
				setor.totalizacao?.forEach(bimestre => {
					const mediaDesempenho = data.totalizacaoBimestral?.find(
						item => item.bimestre === bimestre.bimestre && item.ano === bimestre.ano,
					)?.desempenhoInstituicional;

					const unidade = {
						unidade: relatorio.unidade,
						setor: setor.nomeSetor,
						desempenho: bimestre.desempenho,
					};

					const resultado = {
						ano: bimestre.ano,
						bimestre: bimestre.bimestre,
						mediaDesempenho,
						unidades: [unidade],
					};

					const exists = _relatorio?.findIndex(
						item => item.ano === resultado.ano && item.bimestre === resultado.bimestre,
					);

					if (exists < 0) {
						_relatorio.push(resultado);
					} else {
						let currentValue = _relatorio?.find(
							item => item.ano === resultado.ano && item.bimestre === resultado.bimestre,
						)!;

						currentValue?.unidades.push(unidade);

						_relatorio.splice(exists, 1, currentValue);
					}
				});
			});
		});

		return _relatorio;
	}, [data.relatorios, data.totalizacaoBimestral]);

	const dataRow = useMemo(() => {
		return (
			modalReducedData
				?.sort((a, b) => {
					if (
						a.ano + BIMESTER_ENUM_TO_VALUE[a?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE] >
						b.ano + BIMESTER_ENUM_TO_VALUE[b?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE]
					)
						return -1;
					if (
						a.ano + BIMESTER_ENUM_TO_VALUE[a?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE] <
						b.ano + BIMESTER_ENUM_TO_VALUE[b?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE]
					)
						return 1;
					return 0;
				})
				.map(unidade => {
					return unidade.unidades.map(item => {
						const desempenho = item?.desempenho! < 0 ? 'N/A' : `${formatDecimalNumber(item?.desempenho, '%')}`;
						const unidade = item?.unidade;
						const setor = item?.setor;

						return {
							desempenho,
							unidade,
							setor,
						};
					});
				}) || []
		);
	}, [modalReducedData]);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent sx={styles.principal}>
					<ModalHeader sx={styles.header}>
						<SighMainLogoIconModal />

						<Box sx={styles.boxB}>
							<Text sx={styles.title}>Relatórios Consolidados</Text>
						</Box>

						<ModalCloseButton size="2rem" sx={styles.modalCloseBtn} />
					</ModalHeader>

					<ModalBody w="100%">
						<Flex flexDirection="column">
							{modalReducedData?.map((item, index) => (
								<React.Fragment key={index}>
									<Flex sx={styles.head}>
										<Heading sx={styles.headText} data-testid="download--reduce-name">
											Avaliação do desempenho setorial
										</Heading>
									</Flex>
									<Flex sx={styles.titleContent}>
										<Box>
											<Text sx={styles.modalTextSubtitle}>Ano</Text>
											<Text sx={styles.modalTextSubtitleResponse} data-testid={`download--reduce-year-${item?.ano}`}>
												{item?.ano}
											</Text>
										</Box>
										<Box>
											<Text sx={styles.modalTextSubtitle}>Bimestre</Text>
											<Text
												sx={styles.modalTextSubtitleResponse}
												data-testid={`download--reduce-bimester-${item?.bimestre}`}
											>
												{BIMESTERS_ENUM[item?.bimestre as keyof typeof BIMESTERS_ENUM]}
											</Text>
										</Box>
										<Box>
											<Text sx={styles.modalTextSubtitle}>Desempenho institucional</Text>
											<Text
												sx={styles.modalTextSubtitleResponse}
												data-testid={`download--reduce-institutional-${item?.mediaDesempenho}`}
											>
												{item?.mediaDesempenho! < 0 ? 'N/A' : `${formatDecimalNumber(item?.mediaDesempenho, '%')}`}
											</Text>
										</Box>
									</Flex>
									<Flex flexDirection="column" marginBottom={'20px'}>
										<Text sx={styles.titleThirdBox} data-testid={`download--reduce-${item?.id}`}>
											{`Avaliação do desempenho setorial - ${
												BIMESTERS_ENUM[item?.bimestre as keyof typeof BIMESTERS_ENUM]
											} - ${item?.ano}`}
										</Text>

										<ReportMonitoringModalTable columns={columns} data={dataRow[index]} />
									</Flex>
								</React.Fragment>
							))}
						</Flex>
					</ModalBody>

					<ModalFooter sx={styles.footerContent}>
						<Button
							leftIcon={<DownloadPdfIconModal />}
							sx={styles.button}
							onClick={handleExportPdf}
							data-testid="download--bt-reduce-pdf"
							isLoading={isLoadingDownloadPdf}
						>
							Baixar em PDF
						</Button>
						<Button
							leftIcon={<DownloadPdfIconModal />}
							sx={styles.button}
							onClick={handleExportXlxs}
							data-testid="download--bt-reduce-xlsx"
							isLoading={isLoadingDownloadXlxs}
						>
							Baixar em XLSX
						</Button>
					</ModalFooter>
					<Box marginBottom={'20px'}>
						<Button variant={'unstyled'} sx={styles.button} onClick={onClose} data-testid="cancel--bt-reduce">
							Voltar
						</Button>
					</Box>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ReportMonitoringReducedModal;
