import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { IPagination, IPaginationParams } from 'types/pagination';
import { IPerspective } from 'types/perspective';
import { IStrategicObjective } from 'types/strategicObjetive';
import { Perspective } from 'types/strategicPlanning';

export interface IRequestStrategicObjective {
	objetivo: string;
	descricao: string;
	periodo: string;
	codigo: string | null;
	idPerspectiva: number;
}

export interface IRequestGetStrategicObjectives extends IPaginationParams<IStrategicObjective> {
	objetivo?: string;
	periodo?: string;
	anos?: string;
}

export interface IStrategicObjectivesParams {
	periodo?: string;
	ano?: string;
}

export const getPeriod = async (): Promise<string[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.periods);
	return response.data;
};

export const getPerspective = async (perspectiveValue: string): Promise<Perspective[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.getPerspective, {
		params: { periodo: perspectiveValue },
	});
	return response.data;
};

export const getPerspectiveByYear = async (year: string): Promise<IPerspective[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.getPerspectiveByYear, {
		params: { ano: year },
	});
	return response.data;
};

export const createStrategicObjective = async (data: IRequestStrategicObjective): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.strategicObjectiveCreate, data);
	return response.data;
};

export const getStrategicObjectiveById = async (objectiveId: string): Promise<IStrategicObjective> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.strategicObjectiveGetById(objectiveId));
	return response.data;
};

export const editStrategicObjective = async (data: IRequestStrategicObjective, objectiveId: string): Promise<void> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.strategicObjectiveUpdate(objectiveId), data);
	return response.data;
};

export const getStrategicObjectives = async (
	params?: IRequestGetStrategicObjectives,
): Promise<IPagination<IStrategicObjective[]>> => {
	const api = getAxios();
	const response = await api.get<IPagination<IStrategicObjective[]>>(ENDPOINTS.strategicObjectivesList, {
		params: {
			...params,
			page: params?.page! - 1,
		},
	});
	return response.data;
};

export const deleteStrategicObjective = async (objectiveId: string): Promise<any> => {
	const api = getAxios();
	await api.delete(ENDPOINTS.strategicObjectiveDelete(objectiveId));
};

export const getAllStrategicObjective = async (params?: IStrategicObjectivesParams): Promise<IStrategicObjective[]> => {
	const api = getAxios();
	const response = await api.get<IStrategicObjective[]>(ENDPOINTS.strategicObjectivesListAll, { params });

	return response.data;
};
