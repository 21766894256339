/**
 *
 * PerformanceRangeModal
 *
 */
import Select from '../Select';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { SighMainLogoIconModal } from 'assets/icons';
import { useCustomToast } from 'hooks/useToast';
import { getPerformanceRangeById } from 'services/http/performanceRange';
import { IPerformanceRange } from 'types/performanceRange';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR } from 'utils/constants';

interface PerformanceRangeModalProps {
	onClose: () => void;
	isOpen: boolean;
	performanceSelected: IOption<IPerformanceRange>;
	options?: IOption[];
	id?: string;
	isEditing?: boolean;
	onPerformanceChange?: (performance: IOption<IPerformanceRange>) => void;
}

const PerformanceRangeModal = ({
	onClose,
	isOpen,
	performanceSelected,
	options,
	isEditing,
	onPerformanceChange,
}: PerformanceRangeModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			minW: {
				base: '100%',
				md: '40rem',
			},
			maxW: {
				base: '100%',
				md: 'fit-content',
			},
			w: '100%',
			pl: '2.5rem',
			pr: '2.5rem',
			pt: '1.19rem',
			pb: '0.81rem',
		},
		header: {
			w: '100%',
			display: 'flex',
			flexDir: 'row',
			alignItems: 'center',
			paddingTop: '2rem',
			paddingLeft: 0,
			paddingRight: 0,
		},
		headerContent: {
			w: '100%',
			mr: {
				base: '2.5rem',
				// xl: '6.25rem',
			},
			justifyContent: 'center',
		},
		headerContentText: {
			fontSize: '1.5rem',
			fontWeight: 'medium',
		},
		headerCloseButton: {
			top: '1.55rem',
			right: '2rem',
			color: '#C6C6C6',
		},
		modalFooter: {
			marginBottom: '2rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
			gap: '2.5rem',
			w: '100%',
			p: 0,
		},
		button: {
			px: '5rem',
			w: {
				base: '100%',
				lg: '11.5rem',
			},
			textAlign: 'center',
			cursor: 'pointer',
		},
		contentFieldsCard: {
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			alignItems: { base: 'center' },
			flexDirection: {
				base: 'column',
			},
			gap: '1.5rem',
		},
		cardDataField: {
			flexDirection: { base: 'column', sm: 'row' },
			gap: '2rem',
			padding: '1rem',
			backgroundColor: '#ddeffd',
			borderRadius: '4px',
			marginBottom: '1.5rem',
			width: '100%',
		},
		cardDataHeaders: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		cardDataResultValues: {
			fontSize: '1rem',
			color: '#0A77D2',
			fontWeight: '700',
		},
		cardDataValues: {
			fontSize: '1rem',
			fontWeight: '500',
		},
		selectPerformance: {
			maxW: {
				base: '100%',
			},
			w: '100%',
			marginBottom: '2rem',
			marginTop: '1.5rem',
		},
		spinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const [selectedPerformance, setSelectedPerformance] = useState(performanceSelected);

	useEffect(() => {
		setSelectedPerformance(performanceSelected);
	}, [performanceSelected]);

	const { addToast } = useCustomToast();

	const format = (value: string | number) => {
		return `${new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 1 }).format(Number(value))}%`;
	};

	const { data, isLoading } = useQuery(
		['performance-range', selectedPerformance?.value],
		() => getPerformanceRangeById(String(selectedPerformance?.value)),
		{
			onError: ({ response }) => {
				addToast({
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
					type: 'error',
				});
			},
			enabled: !!selectedPerformance?.value,
		},
	);

	const handleSelectChange = (option: IOption<IPerformanceRange>) => {
		setSelectedPerformance(option);
		onPerformanceChange?.(option);
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent sx={styles.container}>
					<ModalHeader sx={styles.header}>
						<SighMainLogoIconModal />

						<Flex sx={styles.headerContent}>
							<Text sx={styles.headerContentText}>Faixa de Desempenho</Text>
						</Flex>

						<ModalCloseButton size="2rem" sx={styles.headerCloseButton} />
					</ModalHeader>

					<ModalBody p="0">
						<Box sx={styles.selectPerformance}>
							<Select
								label="Conjunto"
								placeholder="Selecione a faixa de Desempenho"
								isEditing={isEditing}
								value={selectedPerformance}
								options={options}
								onChange={e => handleSelectChange(e as IOption<IPerformanceRange>)}
								dataTestId="select--performance"
							/>
						</Box>
						{isLoading ? (
							<Flex sx={styles.spinner}>
								<Spinner size="xl" color="#3EA2A2" />
							</Flex>
						) : (
							data?.faixasDesempenho?.map((item, index) => (
								<React.Fragment key={index}>
									<Flex sx={styles.contentFieldsCard}>
										<Flex sx={styles.cardDataField}>
											<Box>
												<Text sx={styles.cardDataHeaders}>Porcentagem incial</Text>
												<Text
													sx={styles.cardDataValues}
													data-testid={`perfomance--range--card-initialPercentage-${index}`}
												>
													{format(item.porcentagemInicial)}
												</Text>
											</Box>
											<Box>
												<Text sx={styles.cardDataHeaders}>Porcentagem final</Text>
												<Text
													sx={styles.cardDataValues}
													data-testid={`perfomance--range--card-finalPercentage-${index}`}
												>
													{format(item.porcentagemFinal)}
												</Text>
											</Box>
											<Box>
												<Text sx={styles.cardDataHeaders}>Resultado do desempenho</Text>
												<Text sx={styles.cardDataResultValues} data-testid={`perfomance--range--card-result-${index}`}>
													{item.resultadoProporcional ? 'Proporcional' : format(item.resultadoDesempenho || 0)}
												</Text>
											</Box>
										</Flex>
									</Flex>
								</React.Fragment>
							))
						)}

						<ModalFooter sx={styles.modalFooter}>
							<Button onClick={onClose} sx={styles.button} data-testid={'btn--close'}>
								Fechar
							</Button>
						</ModalFooter>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default PerformanceRangeModal;
