/**
 *
 * PerformanceRangeForm
 *
 */

import CustomModal from '../CustomModal';
import InputNumeric from '../InputNumeric';
import InputText from '../InputText';
import Prompt from '../Prompt';
import { useMemo, useState } from 'react';
import { Box, HStack, SystemStyleObject, Button, Checkbox, useDisclosure, Flex, Divider, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { IndicatorPactDelete } from 'assets/icons';
import { InfoIcon } from 'assets/icons';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { IPerformanceRange, Ranges } from 'types/performanceRange';
import { formatStringPorcentageToNumber } from 'utils/formatStringPorcentageToNumber';
import { maskThousands, unMaskThousands } from 'utils/Numbers';
import * as yup from 'yup';

export interface PerformanceRangeProps {
	onSubmitForm: (values: IPerformanceRange) => void;
	isEditing?: boolean;
	isLoading?: boolean;
	defaultValues?: IPerformanceRange;
}

const PerformanceRangeForm = ({ onSubmitForm, isEditing, isLoading = false, defaultValues }: PerformanceRangeProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			w: '100%',
			p: '2rem',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'white',
			gap: '2rem',
			flexDir: 'column',
			justifyContent: { base: 'center', md: 'flex-start' },
		},
		content: {
			width: '100%',
		},
		fieldsContent: {
			gap: '2rem',
			alignItems: 'center',
			flexDirection: { base: 'column', lg: 'row' },
		},
		jointName: {
			width: '100%',
			maxWidth: { base: '100%', lg: '9.998rem' },
			fontSize: '16px',
		},
		inputContainer: {
			w: {
				base: '100%',
				'2xl': 'fit-content',
			},
			alignItems: 'baseline',
			gap: '2rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		inputBox: {
			width: { base: '100%', lg: '12rem', xl: '13.5rem' },
		},
		inputBoxEdit: {
			width: { base: '100%', lg: 'max-content' },
			marginTop: { base: '1rem', lg: '2rem' },
			minWidth: { base: '100%', lg: '12rem', xl: '13.5rem' },
		},
		setName: {
			mb: '1rem',
			width: '100%',
			maxWidth: { base: '100%', lg: '37.75rem' },
		},
		buttonfieldsContent: {
			mt: '2rem',
			gap: '2rem',
			alignItems: 'center',
			flexDirection: {
				base: 'column',
				lg: 'row',
			},
			width: '100%',
		},
		button: {
			w: {
				base: '100%',
				lg: '14.625rem',
			},
			fontSize: '1rem',
			h: '2.75rem',
		},
		addingTrack: {
			w: '100%',
			justifyContent: 'center',
			mt: '2rem',
			flexDir: 'column',
			alignItems: 'center',
		},
		addingTrackButton: {
			maxW: {
				base: '100%',
				lg: '18.813rem',
			},
			w: '100%',
		},
		tooltipContainer: {
			backgroundColor: '#F9FAFC',
			border: '1px solid',
			borderColor: '#3498DB30',
			borderRadius: '8px',
			px: '0.75rem',
			w: 'fit-content',
			alignItems: 'center',
			gap: '1rem',
			mb: '2rem',

			svg: {
				minW: '1.5rem',
				w: '1.5rem',
			},
		},
		dividerDeleteButtonContainer: {
			w: '100%',
			flexDir: 'column',
			pr: {
				base: '0',
				'2xl': '15.125rem',
			},
			mt: {
				base: '2rem',
				lg: '1.25rem',
			},
		},
		deleteButton: {
			color: '#CC3300',
			fontSize: '0.875rem',
			fontWeight: 'bold',
			display: 'flex',
			alignItems: 'center',
			h: '0',
			mt: {
				base: '2rem',
				lg: '1.5rem',
			},
			mb: {
				base: '1.5rem',
				lg: '0',
			},
		},
	};

	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const [rangeHasError, setRangeHasError] = useState(false);
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const schema = yup.object().shape({
		nome: yup.string().required('Este campo é obrigatório.'),
		faixasDesempenho: yup.array().of(
			yup.object().shape({
				resultadoProporcional: yup.boolean().nullable(),
				porcentagemInicial: yup.string().required('Este campo é obrigatório.'),
				porcentagemFinal: yup.string().required('Este campo é obrigatório.'),
				resultadoDesempenho: yup.string().when('resultadoProporcional', {
					is: true,
					then: yup.string().nullable().notRequired(),
					otherwise: yup.string().required('Este campo é obrigatório.'),
				}),
			}),
		),
	});

	const defaultValuesRanges = useMemo(() => {
		return defaultValues?.faixasDesempenho?.length
			? defaultValues?.faixasDesempenho?.map(
					range =>
						({
							...range,
							porcentagemInicial: maskThousands(Number(range.porcentagemInicial), '%'),
							porcentagemFinal: maskThousands(Number(range.porcentagemFinal), '%'),
							resultadoDesempenho: Number(range.resultadoDesempenho),
						} as Ranges),
			  )
			: [
					{
						porcentagemInicial: '',
						porcentagemFinal: '',
						resultadoDesempenho: '',
						resultadoProporcional: false,
					} as Ranges,
			  ];
	}, [defaultValues]);

	const {
		control,
		register,
		getValues,
		watch,
		handleSubmit,
		resetField,
		formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitted },
	} = useForm<IPerformanceRange>({
		resolver: yupResolver(schema),
		defaultValues: {
			...defaultValues,
			faixasDesempenho: defaultValuesRanges,
		},
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const { fields, append, remove } = useFieldArray({
		name: 'faixasDesempenho',
		control,
		keyName: 'code',
	});

	const handleAddTrack = () => {
		const MAX_SIZE = 100;
		const NEXT_TRACK_ADDITONAL = 0.1;
		const formData = getValues('faixasDesempenho');
		const lastFinalTrack = formatStringPorcentageToNumber(formData[formData.length - 1].porcentagemFinal);
		const nextStartTrack = Number((lastFinalTrack + NEXT_TRACK_ADDITONAL).toFixed(1));

		append({
			porcentagemInicial: lastFinalTrack < MAX_SIZE ? maskThousands(nextStartTrack, '%') : '',
			porcentagemFinal: '',
			resultadoDesempenho: '',
			resultadoProporcional: false,
		});
	};

	const handleDeleteRanges = (range: Ranges, index: number) => {
		remove(index);
	};

	const onSubmit = (values: IPerformanceRange) => {
		const isValid = validateRange(values);

		if (!isValid) return setRangeHasError(true);

		onSubmitForm(values);
	};

	const validateRange = (values: IPerformanceRange) => {
		let percentagesArray: number[] = [];

		//Cria array de 0 a 100
		const integerArray = Array.from({ length: 101 }, (_, i) => i);

		// Percorre array para criar o array final com os números com casas decimais
		integerArray.forEach(number => {
			if (number < 100) {
				for (let i = 0; i < 10; i++) {
					const decimal = i / 10;
					const finalNumber = number + decimal;
					percentagesArray.push(finalNumber);
				}
			} else {
				percentagesArray.push(number);
			}
		});

		let hasGap = false;

		// Percorre os valores setados no formulário e retira do array de números
		values.faixasDesempenho.forEach(faixa => {
			const [faixaInicialIndex, faixaFinalIndex] = [faixa.porcentagemInicial, faixa.porcentagemFinal]
				.map(item => unMaskThousands(item))
				.map(item => percentagesArray.indexOf(item));

			// Indixes negativos representam valores maior que 100
			if (faixaInicialIndex < 0 || faixaFinalIndex < 0) hasGap = true;

			if (faixaInicialIndex >= 0 && faixaFinalIndex >= 0) {
				let deleteCount = faixaFinalIndex + 1 - faixaInicialIndex;

				percentagesArray.splice(faixaInicialIndex, deleteCount);
			}
		});

		hasGap = hasGap || !!percentagesArray.length;

		return !hasGap;
	};

	const allowedPorcentage = (value: NumberFormatValues) => {
		const MAX_INTEGER_NUMBERS = 3;
		return value.formattedValue.split(',')[0].length <= MAX_INTEGER_NUMBERS;
	};

	const clearPerformanceRangeResult = (index: number) => {
		resetField(`faixasDesempenho.${index}.resultadoDesempenho`, {
			defaultValue: '',
		});
	};

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.performanceRange);
		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	return (
		<>
			<Box sx={styles?.container}>
				<Flex sx={styles.tooltipContainer}>
					<InfoIcon color="#3498DB" />

					<Text fontSize="1rem">
						<strong>Aviso: </strong>O sistema exige que todas as faixas de um conjunto sejam especificadas de 0% a 100%
						e sem valores duplicados.
					</Text>
				</Flex>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
					<Box sx={styles?.setName}>
						<InputText
							label="Nome do conjunto"
							isEditing={isEditing && !!dirtyFields?.nome}
							maxLength={100}
							placeholder="Digite o nome do conjunto"
							name="nome"
							register={register}
							data-testid="input--jointName"
							errorMessage={errors?.nome?.message}
							required
						/>
					</Box>

					{fields.map((field, index) => (
						<Box key={field.code}>
							{index > 0 && (
								<Flex sx={styles.dividerDeleteButtonContainer}>
									<Flex ml={{ base: '0', lg: '2rem' }}>
										<Divider />
									</Flex>

									<Flex justifyContent="flex-end">
										<Button
											variant="unstyled"
											leftIcon={<IndicatorPactDelete />}
											onClick={() => handleDeleteRanges(field, index)}
											sx={styles.deleteButton}
											data-testid={`button--removePerformanceRange-${index}`}
										>
											Remover Faixa de Desempenho
										</Button>
									</Flex>
								</Flex>
							)}
							<Flex sx={styles?.fieldsContent}>
								<Flex sx={styles.inputContainer}>
									<Flex sx={isEditing ? styles.inputBoxEdit : styles?.inputBox}>
										<Controller
											control={control}
											name={`faixasDesempenho.${index}.porcentagemInicial`}
											render={({ field }) => (
												<InputNumeric
													label="Porcentagem inicial"
													isEditing={isEditing && !!dirtyFields?.faixasDesempenho?.[index]?.porcentagemInicial}
													name={`faixasDesempenho.${index}.porcentagemInicial`}
													value={field.value}
													onChange={field.onChange}
													suffix="%"
													decimalScale={1}
													fixedDecimalScale
													isAllowed={values => allowedPorcentage(values)}
													data-testid={`input--porcentagemInicial-${index}`}
													placeholder="Digite uma %"
													errorMessage={errors?.faixasDesempenho?.[index]?.porcentagemInicial?.message}
													required
												/>
											)}
										/>
									</Flex>

									<Flex sx={isEditing ? styles.inputBoxEdit : styles?.inputBox}>
										<Controller
											control={control}
											name={`faixasDesempenho.${index}.porcentagemFinal`}
											render={({ field }) => (
												<InputNumeric
													label="Porcentagem final"
													isEditing={isEditing && !!dirtyFields?.faixasDesempenho?.[index]?.porcentagemFinal}
													name={`faixasDesempenho.${index}.porcentagemFinal`}
													value={field.value}
													onChange={field.onChange}
													suffix="%"
													decimalScale={1}
													fixedDecimalScale
													isAllowed={values => allowedPorcentage(values)}
													data-testid={`input--porcentagemFinal-${index}`}
													placeholder="Digite uma %"
													errorMessage={errors?.faixasDesempenho?.[index]?.porcentagemFinal?.message}
													required
												/>
											)}
										/>
									</Flex>

									<Flex sx={isEditing ? styles.inputBoxEdit : styles?.inputBox}>
										<Controller
											control={control}
											name={`faixasDesempenho.${index}.resultadoDesempenho`}
											render={({ field }) => (
												<InputNumeric
													label="Resultado do desempenho"
													isEditing={isEditing && !!dirtyFields?.faixasDesempenho?.[index]?.resultadoDesempenho}
													name={`faixasDesempenho.${index}.resultadoDesempenho`}
													value={field.value}
													onChange={field.onChange}
													suffix="%"
													decimalScale={1}
													fixedDecimalScale
													isAllowed={values => allowedPorcentage(values)}
													data-testid={`input--resultadoDesempenho-${index}`}
													placeholder="Digite o resultado"
													errorMessage={errors?.faixasDesempenho?.[index]?.resultadoDesempenho?.message}
													isDisabled={watch(`faixasDesempenho.${index}.resultadoProporcional`)}
													required
												/>
											)}
										/>
									</Flex>
								</Flex>
								<Flex
									sx={isEditing ? styles.inputBoxEdit : styles?.inputBox}
									h="100%"
									pt={{
										base: '0',
										lg:
											errors?.faixasDesempenho?.[index]?.resultadoDesempenho?.message ||
											errors?.faixasDesempenho?.[index]?.porcentagemFinal?.message ||
											errors?.faixasDesempenho?.[index]?.porcentagemInicial?.message
												? '0.9rem'
												: '3.45rem',
										xl:
											errors?.faixasDesempenho?.[index]?.resultadoDesempenho?.message ||
											errors?.faixasDesempenho?.[index]?.porcentagemFinal?.message ||
											errors?.faixasDesempenho?.[index]?.porcentagemInicial?.message
												? '0.9rem'
												: '2.45rem',
									}}
								>
									<Controller
										control={control}
										defaultValue={false}
										name={`faixasDesempenho.${index}.resultadoProporcional`}
										render={({ field: { onChange, value } }) => (
											<Checkbox
												onChange={e => {
													clearPerformanceRangeResult(index);
													onChange(e);
												}}
												isChecked={value}
												data-testid={`checkbox--checkboxInput-${index}`}
											>
												Resultado proporcional
											</Checkbox>
										)}
									/>
								</Flex>
							</Flex>
						</Box>
					))}

					<Flex sx={styles.addingTrack}>
						{rangeHasError && (
							<Text fontSize="0.9rem" color="red.400" mb="0.5rem">
								Esta faixa de desempenho não segue os critérios estipulados.
							</Text>
						)}
						<Button sx={styles.addingTrackButton} onClick={handleAddTrack} data-testid="button--addingTrack">
							+ Adicionar Faixa
						</Button>
					</Flex>

					<HStack sx={styles?.buttonfieldsContent}>
						<Button sx={styles?.button} isLoading={isLoading} type="submit" data-testid="button--submit">
							Salvar
						</Button>

						<Button variant="secondary" onClick={handleOpenModal} sx={styles?.button} data-testid="button--cancel">
							Cancelar
						</Button>
					</HStack>
				</Box>

				<CustomModal
					icons={[{ type: 'error' }]}
					title="Você realmente quer cancelar?"
					body="Ao cancelar os campos anteriormente preenchidos serão perdidos."
					isOpen={isOpen}
					onClose={onClose}
					actions={[
						{
							label: 'Quero Cancelar',
							type: 'cancel',
							onClick: handleGoBack,
							datatestid: 'button--confirm',
						},
						{
							label: 'Voltar',
							type: 'secondary',
							onClick: handleCloseModal,
							datatestid: 'button--cancel',
						},
					]}
				/>
				<Prompt when={showPrompt} />
			</Box>
		</>
	);
};
export default PerformanceRangeForm;
