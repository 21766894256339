import { useSession } from './useSession';
import { UserPermission } from 'types/user';
import { USER_ROLES } from 'utils/constants';
import { isInsideOptionArray } from 'utils/insideArray';
import { parsedOptionArray } from 'utils/parseOptionArray';

export const useCanViewAction = (accessLevels?: string[]) => {
	const { session } = useSession();

	let permissions: UserPermission[] = session?.user?.permissoes.filter(
		permission => permission.nome !== USER_ROLES.REPRESENTANTE,
	);

	permissions?.push({
		nome: USER_ROLES.REPRESENTANTE,
		ativo: session.user.isRepresentante,
	});

	if (!accessLevels) return true;

	if (!session?.user?.idLogin) return false;

	const sessionPermissions = permissions?.filter(item => item.ativo).map(item => item.nome);

	return isInsideOptionArray(parsedOptionArray(accessLevels), parsedOptionArray(sessionPermissions));
};
