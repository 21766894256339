import { getAxios } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import {
	IRequestDownloadMatrix,
	IRequestEditIndicator,
	IResponseIndicatorById,
	IResponseIndicatorName,
	IResponseIndicators,
	Indicator,
} from 'types/indicators';
import { IRequestCreateIndicator } from 'types/indicators';
import { sleep } from 'utils/sleep';

export interface IIndicatorParams {
	idObjetivo?: string;
	nomeIndicador?: string;
	idSetor?: string;
	etapa?: string;
	ano?: string[];
	page?: number;
	size?: number;
}

export interface IIndicatorsPactLinkParams {
	idUnidade?: string;
	etapa?: string;
	ano?: string[];
}

export interface IRequestIndicatorWeight {
	id: number;
	peso: number;
	pesoCalculado: number;
}

export interface IIndicatorNameParams {
	idPacutados: string[];
}

export const createIndicator = async (data: IRequestCreateIndicator): Promise<void> => {
	const api = getAxios();

	const response = await api.post(ENDPOINTS.indicatorsCreate, data);
	return response.data;
};

export const getIndicators = async (params?: IIndicatorParams): Promise<IResponseIndicators> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.indicatorsList, {
		params: { ...params, allElements: true },
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

export const getIndicatorsPactLinkList = async (params?: IIndicatorsPactLinkParams): Promise<Indicator[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.indicatorsPactLinkList, {
		params: { ...params },
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

export const getIndicatorsList = async (params?: IIndicatorParams): Promise<IResponseIndicators> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.indicatorsList, {
		params: {
			...params,
			page: params?.page! - 1,
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});

	return response.data;
};

export const downloadIndicator = async (indicatorId: string): Promise<string> => {
	// eslint-disable-next-line no-console
	console.log(indicatorId);
	await sleep(1000);
	return indicatorId;
};

export const deleteIndicator = async (indicatorId: string): Promise<void> => {
	const api = getAxios();
	await api.delete(ENDPOINTS.indicatorsDelete, { params: { id: indicatorId } });
};

export const getIndicatorById = async (indicatorId: string): Promise<IResponseIndicatorById> => {
	const api = getAxios();

	const response = await api.get(ENDPOINTS.indicatorsGetById(indicatorId));
	return response.data;
};

export const updateIndicator = async (data: IRequestEditIndicator, indicatorId: number): Promise<void> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.indicatorsUpdate, { ...data }, { params: { id: indicatorId } });
	return response?.data;
};

export const updateIndicatorsWeight = async (data: IRequestIndicatorWeight[]): Promise<void> => {
	const api = getAxios();
	await api.put(ENDPOINTS.indicatorsUpdateWeight, data);
};

export const validateIndicator = async (indicatorId: string): Promise<void> => {
	const api = getAxios();
	await api.patch(ENDPOINTS.indicatorsValidate(indicatorId));
};

export const getIndicatorsName = async (params?: IIndicatorNameParams): Promise<IResponseIndicatorName[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.indicatorsNameList, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

export const downloadMatrixIndicator = async (data: IRequestDownloadMatrix): Promise<Blob> => {
	const api = getAxios();
	const response = await api.post<File>(ENDPOINTS.indicatorDownloadMatrix, data, {
		...data,
		responseType: 'blob',
	});
	return response.data;
};
