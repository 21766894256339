import { api } from '.';
import { ENDPOINTS } from 'services/endpoints';
import { IPagination } from 'types/pagination';
export interface IResponseNotifications {
	id: number;
	descricao: string;
	data: string;
	lida: boolean;
	moduloEnum: {
		titulo: string;
		name: string;
		path: string;
	};
}
export interface INotificationParams {
	searchText?: string;
	page?: number;
	size?: number;
}

export interface INotificationModule {
	titulo: string;
	name: string;
	path: string;
}

export interface INotifications {
	id?: number;
	lida: boolean;
	situacao: string;
	data: string;
	descricao: string;
	moduloEnum?: INotificationModule;
}

export const getAllNotificationsList = async (params?: INotificationParams): Promise<IPagination<INotifications[]>> => {
	const response = await api.get<IPagination<INotifications[]>>(ENDPOINTS.getAllNotifications, {
		params: {
			...params,
			page: params?.page! - 1,
			size: params?.size!,
		},
	});
	return response.data;
};

export const getRecentNotifications = async (): Promise<INotifications[]> => {
	const response = await api.get(ENDPOINTS.getRecentsNotifications);
	return response.data;
};

export const updateMarkedNotifications = async (data: INotifications[]) => {
	const response = await api.put(ENDPOINTS.updateMarkedNotifications, data);
	return response.data;
};

export const deleteMarkedNotifications = async (data: INotifications[]): Promise<any> => {
	const response = await api.delete(ENDPOINTS.deleteNotifications, {
		data: data,
	});
	return response.data;
};

export const checkAllNotifications = async () => {
	const response = await api.put(ENDPOINTS.updateAllNotifications);
	return response.data;
};
