import { useContext } from 'react';
import { SessionContext, SessionContextData } from 'contexts/SessionContext';
import { authManager } from 'services/authManager';
import { Session } from 'types/user';

export function useSession() {
	const payload = useContext(SessionContext);

	if (!payload) {
		throw new Error('useSession must be used inside SessionContext Provider');
	}

	try {
		let { user } = authManager.get();

		let parseUser = {} as Session;

		if (user) parseUser = JSON.parse(user);

		return {
			...payload,
			session: { ...parseUser },
		};
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
		return {
			...payload,
			isAuth: false,
		};
	}
}

export const sessionMock = (
	roles: string[],
	isRepresentante = false,
	unidadesRepresentantes = [],
	unidade = 'ADC',
): SessionContextData => {
	return {
		session: {
			user: {
				idLogin: '305',
				nomeCompleto: 'Usuário',
				cpf: '12312312312',
				permissoes: roles.map(item => ({ nome: item, ativo: true })),
				unidadePerfis: [
					{
						unidade: '1',
						perfis: [
							{
								id: '33',
								nome: 'PERFIL',
							},
						],
					},
				],
				idUser: '123',
				unidade,
				idUnidade: '98',
				nome: 'Usuário',
				masp: '123456',
				cargo: 'TOS',
				vinculo: 'EFETIVO APOS LEI 64/02',
				setor: 'COORDENACAO DAS UNIDADES CRITICAS',
				idSetor: '251',
				isRepresentante,
				unidadesRepresentantes,
			},
		},
		removeSession: () => {},
		startSession: () => {},
		updateSession: () => {},
	};
};
