/**
 *
 * IndicatorsWeightEditingModal
 *
 */

import InputNumeric from '../InputNumeric';
import InputText from '../InputText';
import { useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
	HStack,
	GridItem,
	FormControl,
	FormErrorMessage,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePrompt } from 'hooks/useCustomPrompt';
import { useDebounce } from 'hooks/useDebounce';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { BIMESTERS_ENUM } from 'utils/constants';
import { formatFromStringToNumber } from 'utils/formatBimesters';
import * as yup from 'yup';

export interface IIndicatorsWeight {
	id?: number;
	index?: number;
	indicador: string;
	peso: number;
	pesoCalculado: number;
}

export interface IIndicatorsWeightForm {
	indicatorsWeight: IIndicatorsWeight[];
}

interface IndicatorsWeightEditingModalProps {
	isOpen: boolean;
	onClose: () => void;
	defaultValues?: IIndicatorsWeightForm;
	onSubmitForm: (data: IIndicatorsWeightForm) => void;
	isLoading?: boolean;
	bimester: string;
	hasError: boolean;
}

const IndicatorsWeightEditingModal = ({
	isOpen,
	onClose,
	defaultValues,
	onSubmitForm,
	isLoading,
	bimester,
	hasError,
}: IndicatorsWeightEditingModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		modalContainer: {
			w: '100%',
			maxWidth: '86.25rem',
		},
		modalHeader: {
			width: '100%',
			p: '1.5rem 0rem 0rem 2rem',
		},
		modalTitle: {
			fontSize: '1.313rem',
			fontWeight: 700,
			p: '0',
		},
		modalBodyContainer: {
			p: '2.938rem 6.063rem 0rem 2rem',
			w: '100%',
		},
		indicatorsTitle: {
			fontSize: '1.25rem',
		},
		FisrtHStackRow: {
			w: '100%',
			mb: '1rem',
			justifyContent: 'space-between',
		},
		indicatorTitleContainer: {
			w: '100%',
			maxWidth: '33.313rem',
			mr: '3.938rem',
		},
		secondHStackRow: {
			w: '100%',
			maxWidth: '42.938rem',
		},
		indicatorValuesContainer: {
			w: '42.938rem',
			maxWidth: '42.938rem',
		},
		gridContainerPeso: {
			h: '2.75rem',
			w: '22.5rem',
			backgroundColor: 'white',
			border: '1px solid #E1E2E5',
			borderRadius: '1.375rem',
			p: '0.2525rem 1rem',
			display: 'flex',
			alignItems: 'center',
		},
		gridContainerPesoCalculado: {
			h: '2.75rem',
			w: '14.75rem',
			backgroundColor: '#F2F2F2',
			border: '1px solid #E1E2E5',
			borderRadius: '1.375rem',
			p: '0.2525rem 1rem',
			display: 'flex',
			alignItems: 'center',
		},
		pesoText: {
			fontSize: '1rem',
			fontWeight: 'bold',
			w: '8rem',
		},
		pesoRefValueText: {
			fontSize: '1rem',
			fontWeight: 'medium',
			w: '17rem',
		},
		pesoCalculadoText: {
			fontSize: '1rem',
			fontWeight: 'bold',
			w: '9.3rem',
			backgroundColor: '#F2F2F2',
		},
		inputPesoCalculado: {
			border: 'none',
			w: '3rem',
			h: '2.25rem',
			fontSize: '1rem',
			p: '0',
			pl: '1rem',
			backgroundColor: '#F2F2F2',
		},
		inputSpacer: {
			borderLeft: '1px solid #E1E2E5',
			alignItems: 'center',
			pl: '1rem',
		},
		spacerPesoCalculado: {
			borderLeft: '1px solid #E1E2E5',
			alignItems: 'center',
		},
		inputPeso: {
			border: 'none',
			w: '2.5rem',
			h: '2.25rem',
			fontSize: '1rem',
			p: '0',
		},
		spacer: {
			w: '100%',
			maxWidth: '3.125rem',
			border: '1px dashed #707070',
		},
		footerContainer: {
			justifyContent: 'right',
			w: '100%',
			h: '5.375rem',
			p: '1rem 6.063rem 2rem 0rem',
		},
		footerContent: {
			justifyContent: 'center',
			gap: { base: '3rem', sm: '1.875rem' },
			flexDirection: { base: 'column', sm: 'row' },
			alignItems: { base: 'center', sm: 'end' },
		},
		button: { fontSize: '1rem', h: '2.75rem', w: '13.813rem' },
		errorMessage: {
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'end',
			w: '73%',
			pt: '0.5rem',
			pb: '1rem',
		},
	};

	const schema = yup.object().shape({
		indicatorsWeight: yup.array().of(
			yup.object().shape({
				peso: yup
					.mixed()
					.required('Campo obrigatório.')
					.test('noValue', 'Campo obrigatório.', (value = '') => {
						const maxValue = '';
						const isInvalid = maxValue === value;
						return !isInvalid;
					})
					.test('maxValue', 'Limite máximo para Peso é 9.9.', value => {
						const maxValue = 9.9;
						const isInvalid = maxValue < formatFromStringToNumber(value);
						return !isInvalid;
					}),
				pesoCalculado: yup
					.mixed()
					.required('Campo obrigatório.')
					.test('noValue', 'Campo obrigatório.', (value = '') => {
						const maxValue = '';
						const isInvalid = maxValue === value;
						return !isInvalid;
					}),
			}),
		),
	});

	const {
		control,
		handleSubmit,
		formState: { errors, isDirty },
		watch,
		reset,
	} = useForm<IIndicatorsWeightForm>({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		defaultValues,
	});

	usePrompt('Alterações pendentes! Deseja sair da página mesmo assim?', isDirty);

	const { fields, replace } = useFieldArray({
		name: 'indicatorsWeight',
		control,
		keyName: 'code',
	});

	const parsedBimester = BIMESTERS_ENUM[bimester as keyof typeof BIMESTERS_ENUM];

	const formValues = watch('indicatorsWeight');

	const onChangeValues = () => {
		const newValues: IIndicatorsWeight[] = [];
		const weightSum = formValues.reduce((sum, currValue) => {
			return sum + formatFromStringToNumber(currValue.peso);
		}, 0);

		// peso / soma de todos os pesos
		formValues
			.map(item => ({ ...item, peso: formatFromStringToNumber(item.peso) }))
			.forEach(currValue => {
				const pesoCalc = Number(currValue.peso / weightSum);

				newValues.push({
					...currValue,
					pesoCalculado: pesoCalc || 0,
				});
			});

		replace(newValues);
	};

	const debounceCalc = useDebounce(onChangeValues, 600);

	useEffect(() => {
		reset({ indicatorsWeight: defaultValues?.indicatorsWeight.map((item, index) => ({ ...item, index })) });

		if (hasError) {
			replace(defaultValues?.indicatorsWeight || []);
		}
	}, [defaultValues, hasError, replace, reset]);

	const onSubmit = (values: IIndicatorsWeightForm) => {
		const indicatorsWeight = values?.indicatorsWeight?.map(value => {
			const pesoCalculado = value?.pesoCalculado === -1 ? 0 : value?.pesoCalculado;
			return { ...value, pesoCalculado };
		});

		isDirty ? onSubmitForm?.({ ...values, indicatorsWeight }) : onClose();
	};

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
				<ModalOverlay />
				<ModalContent sx={styles.modalContainer} data-testid="modal">
					<ModalHeader sx={styles.modalHeader}>
						<Text sx={styles.modalTitle}>Alterar peso de indicadores: {parsedBimester}</Text>
					</ModalHeader>
					<ModalCloseButton size="lg" />
					<ModalBody sx={styles.modalBodyContainer} data-testid="modal-modalBody">
						{fields.map((field, index) => (
							<Box key={field.code}>
								<FormControl isInvalid={!!errors?.indicatorsWeight?.[index]?.peso}>
									<HStack sx={styles?.FisrtHStackRow}>
										<Box sx={styles?.indicatorTitleContainer}>
											<Text sx={styles.indicatorsTitle}>{field.indicador}</Text>
										</Box>

										<Box sx={styles?.indicatorValuesContainer}>
											<HStack sx={styles?.secondHStackRow}>
												<GridItem sx={styles.gridContainerPeso} data-testid="peso-row">
													<Flex flex="1">
														<Text sx={styles.pesoText}>{parsedBimester}</Text>
													</Flex>
													<Flex sx={styles.inputSpacer}>
														<Text sx={styles.pesoRefValueText}>Valor de referência:</Text>
														<Controller
															name={`indicatorsWeight.${index}.peso`}
															control={control}
															render={({ field: { value, onChange } }) => (
																<InputNumeric
																	name={`indicatorsWeight.${index}.peso`}
																	sx={styles.inputPeso}
																	required
																	value={value}
																	onChange={onChange}
																	onKeyUp={() => debounceCalc(index)}
																	maxLength={7}
																	data-testid={`input--indicatorsWeight.${index}.peso`}
																/>
															)}
														/>
													</Flex>
												</GridItem>
												<Box sx={styles?.spacer}></Box>
												<GridItem sx={styles.gridContainerPesoCalculado} data-testid="pesoCalculado-row">
													<Flex flex="1">
														<Text sx={styles.pesoCalculadoText}>Peso calculado</Text>
													</Flex>
													<Flex sx={styles.spacerPesoCalculado}>
														<Controller
															name={`indicatorsWeight.${index}.pesoCalculado`}
															control={control}
															render={({ field: { value, onChange } }) =>
																formValues?.at(index)?.pesoCalculado! <= 0 ? (
																	<InputText
																		name={`indicatorsWeight.${index}.pesoCalculado`}
																		sx={styles.inputPesoCalculado}
																		onChange={onChange}
																		value={'N/A'}
																		data-testid={`input--indicatorsWeight.${index}.pesoCalculado`}
																		isReadOnly
																	/>
																) : (
																	<InputNumeric
																		isReadOnly={true}
																		name={`indicatorsWeight.${index}.pesoCalculado`}
																		sx={styles.inputPesoCalculado}
																		required
																		value={(value * 100).toFixed(0)}
																		onChange={onChange}
																		maxLength={7}
																		data-testid={`input--indicatorsWeight.${index}.pesoCalculado`}
																	/>
																)
															}
														/>
													</Flex>
												</GridItem>
											</HStack>
										</Box>
									</HStack>
									<FormErrorMessage data-testid="text--error" sx={styles?.errorMessage}>
										{errors?.indicatorsWeight?.[index]?.peso?.message}
									</FormErrorMessage>
								</FormControl>
							</Box>
						))}
					</ModalBody>

					<ModalFooter sx={styles.footerContainer}>
						<Flex sx={styles.footerContent}>
							<Button
								sx={styles.button}
								type="submit"
								variant="primary"
								data-testid="button--submit"
								isLoading={isLoading}
							>
								Alterar
							</Button>
							<Button sx={styles?.button} variant={'secondary'} onClick={onClose} data-testid="button--cancel">
								Cancelar
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Box>
		</Modal>
	);
};

export default IndicatorsWeightEditingModal;
