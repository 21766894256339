import { ISelectedDate } from '.';
import { SystemStyleObject, Button, Text, Grid } from '@chakra-ui/react';
import { WEEK_DAYS } from 'utils/constants';
import { ICalendar } from 'utils/formatDate';

interface DaysViewProps {
	selectedDate: ISelectedDate;
	onChange: (value: ISelectedDate) => void;
	calendar: {
		daysOfLastMonth: ICalendar[];
		daysOfCurrentMonth: ICalendar[];
		daysOfNextMonth: ICalendar[];
	};
}

const DaysView = ({ calendar, onChange, selectedDate }: DaysViewProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			gridTemplateColumns: 'repeat(7, 1fr)',
			gap: '0.5rem',
		},
		buttonBase: {
			h: {
				base: '2rem',
				lg: '1.688rem',
			},
			w: {
				base: '2rem',
				lg: '1.75rem',
			},
			borderRadius: {
				base: '1rem',
				lg: '0.813rem',
			},
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			justifySelf: 'center',
			lineHeight: '0',
			border: '0.063rem solid transparent',
			fontWeight: 'medium',
		},
		buttonCurrent: {
			h: {
				base: '2rem',
				lg: '1.688rem',
			},
			w: {
				base: '2rem',
				lg: '1.75rem',
			},
			borderRadius: {
				base: '1rem',
				lg: '0.813rem',
			},
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			justifySelf: 'center',
			lineHeight: '0',
			fontWeight: 'medium',
			color: 'blue.700',
			backgroundColor: 'green.100',
		},
		prevNextButton: {
			h: {
				base: '2rem',
				lg: '1.688rem',
			},
			w: {
				base: '2rem',
				lg: '1.75rem',
			},
			borderRadius: {
				base: '1rem',
				lg: '0.813rem',
			},
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			justifySelf: 'center',
			lineHeight: '0',
			border: '0.063rem solid transparent',
			fontWeight: 'medium',
			color: 'gray.450',
			backgroundColor: 'gray.90',
		},
	};

	const currentDay = new Date().getDate();
	const currentMonth = new Date().getMonth();
	const currentYear = new Date().getFullYear();

	const currentMonthYear = currentMonth === selectedDate.month && currentYear === selectedDate.year;

	return (
		<Grid sx={styles.container}>
			{WEEK_DAYS.map((item, index) => (
				<Text fontSize="0.938rem" fontWeight="bold" textAlign="center" key={index}>
					{item}
				</Text>
			))}

			{calendar.daysOfLastMonth.map(item => (
				<Button
					sx={styles.prevNextButton}
					onClick={() => onChange({ day: item.day, month: item.month, year: item.year })}
					key={item.day}
					data-testid={`button-lastMonthday-${item.day}`}
				>
					{item.day}
				</Button>
			))}

			{calendar.daysOfCurrentMonth.map(item =>
				item.day === currentDay && currentMonthYear ? (
					<Button
						sx={
							currentDay === selectedDate.day && selectedDate.isCurrentSelected
								? styles.buttonBase
								: styles.buttonCurrent
						}
						onClick={() => onChange({ ...selectedDate, day: item.day })}
						key={item.day}
						data-testid={`button-currentMonthday-${item.day}`}
					>
						{item.day}
					</Button>
				) : item.day === selectedDate.day && selectedDate.isCurrentSelected ? (
					<Button
						sx={styles.buttonBase}
						onClick={() => onChange({ ...selectedDate, day: item.day })}
						key={item.day}
						data-testid={`button-currentMonthday-${item.day}`}
					>
						{item.day}
					</Button>
				) : (
					<Button
						variant="secondary"
						sx={styles.buttonBase}
						color="blue.700"
						onClick={() => onChange({ ...selectedDate, day: item.day })}
						key={item.day}
						data-testid={`button-currentMonthday-${item.day}`}
					>
						{item.day}
					</Button>
				),
			)}
			{calendar.daysOfNextMonth.map(item => (
				<Button
					sx={styles.prevNextButton}
					onClick={() => onChange({ day: item.day, month: item.month, year: item.year })}
					key={item.day}
					data-testid={`button-nextMonthday-${item.day}`}
				>
					{item.day}
				</Button>
			))}
		</Grid>
	);
};

export default DaysView;
