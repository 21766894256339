/**
 *
 * PerformanceRangeEdit
 *
 */

import { Box, Flex, SystemStyleObject, Spinner } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import PerformanceRangeForm from 'app/components/PerformanceRangeForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useParams, useNavigate } from 'react-router-dom';
import { getPerformanceRangeById, updatePerformanceRange } from 'services/http/performanceRange';
import { IPerformanceRange } from 'types/performanceRange';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { formatStringPorcentageToNumber } from 'utils/formatStringPorcentageToNumber';
import { ResponseErrors } from 'utils/parseErrors';

type IParams = {
	performanceRangeId: string;
};

const PerformanceRangeEdit = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.875rem',
			mb: '2rem',
			width: { base: '100%', lg: 'max-content', xl: '100%' },
		},
		spinner: {
			mt: '3.7rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { performanceRangeId = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { data, isLoading } = useQuery(
		['performance-range', performanceRangeId],
		() => getPerformanceRangeById(performanceRangeId),
		{
			onError: ({ response }) => {
				addToast({
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
					type: 'error',
				});
			},
			enabled: !!performanceRangeId,
		},
	);

	const { mutate: onEditPerformanceRangeCardMutate, isLoading: isEditonPerformanceRangeCardLoading } = useMutation<
		IPerformanceRange,
		AxiosError<ResponseErrors>,
		IPerformanceRange
	>(data => updatePerformanceRange(data), {
		onSuccess: () => {
			navigate(ROUTES.performanceRange);
			addToast({ type: 'success', description: 'A edição dos campos foi feita com sucesso.', title: 'Sucesso!' });
		},
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
	});

	const handleSubmit = (data: IPerformanceRange) => {
		const resultadoZerado = 0;
		const formatedValues: IPerformanceRange = {
			...data,
			faixasDesempenho: data.faixasDesempenho.map(item => ({
				id: item.id,
				porcentagemInicial: formatStringPorcentageToNumber(item.porcentagemInicial),
				porcentagemFinal: formatStringPorcentageToNumber(item.porcentagemFinal),
				resultadoDesempenho: formatStringPorcentageToNumber(item.resultadoDesempenho as string) || resultadoZerado,
				resultadoProporcional: item.resultadoProporcional,
			})),
		};
		onEditPerformanceRangeCardMutate(formatedValues);
	};

	return (
		<Flex sx={styles.container}>
			{isLoading ? (
				<Box sx={styles?.spinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				<>
					<PageWrapper title="Editar Faixa de Desempenho" goBackRoute={ROUTES.performanceRange} />
					<Box sx={styles.content}>
						<PerformanceRangeForm
							onSubmitForm={handleSubmit}
							isEditing
							defaultValues={data}
							isLoading={isEditonPerformanceRangeCardLoading}
						/>
					</Box>
				</>
			)}
		</Flex>
	);
};

export default PerformanceRangeEdit;
