/**
 *
 * ResulterIndicatorCard
 *
 */

import ResulterTooltip from './resulterTooltip';
import IndicatorMatrix from '../IndicatorMatrix';
import { useEffect, useState } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	Icon,
	IconButton,
	SystemStyleObject,
	Tag,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { ChartIcon, ViewMatrixIcon } from 'assets/icons';
import { PontuationErrorIcon } from 'assets/icons';
import { AutoTextSize } from 'auto-text-size';
import { ROUTES } from 'config/routes';
import { useNavigate } from 'react-router-dom';
import { IOption } from 'types/select';
import {
	BIMESTERS_ENUM_NUMBERS,
	BIMESTER_TOOLTIP_INFO,
	JAVASCRIPT_TYPES,
	POLARITY_STATUS,
	RESULTER_SCREEN_PHASES_ENUM,
	RESULTER_STATUS,
	TEXT_DATA_CONTROL_STEPS_ENUM,
} from 'utils/constants';
import { maskThousands } from 'utils/Numbers';

export interface Bimester {
	id: number;
	bimestre: string;
	ano: number;
	meta: number;
	peso: number;
	resultado: number;
	desempenho: number;
	desempenhoEnum: string;
	polaridade: string;
	pontuacao: string;
	faseEnum: string;
	etapa: string;
}

export interface ResulterIndicatorCardData {
	id: number;
	indicadorId: number;
	pactuado: string;
	indicador: string;
	anoInicial: number;
	anoFinal: number;
	bimestres: Bimester[];
	initialBimester: Bimester;
	responsavelLancamento: string;
}

interface ResulterIndicatorCardProps {
	pactuado?: IOption;
	pactuadosOptions?: IOption[];
	data: ResulterIndicatorCardData;
}

const ResulterIndicatorCard = ({ data }: ResulterIndicatorCardProps) => {
	const [currentSelected, setCurrentSelected] = useState<Bimester>(data?.initialBimester);
	const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
	const { isOpen: isOpenExportModal, onOpen: onOpenExportModal, onClose: onCloseExportModal } = useDisclosure();

	const styles: Record<string, SystemStyleObject> = {
		container: {
			w: '100%',
			border: '0.5px solid #DBDBDB',
			borderRadius: '8px',
			flexDir: 'column',
			p: '1rem',
			pos: 'relative',
			backgroundColor: currentSelected?.peso <= 0 ? '#F1EFEF' : '#FFFFFF',
		},
		textContainer: {
			gap: '16px',
			mr: '35px',
			justifyContent: 'space-between',
			paddingBottom: '15px',
		},
		pactuadoText: {
			fontSize: '0.875rem',
			fontWeight: 'medium',
			color: '#0A77D2',
			flexWrap: 'wrap',
			height: '42px',
			maxW: '80%',
			w: 'fit-content',
		},
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
		tooltipIcon: {
			boxSize: '0.8rem',
			ml: '0.5rem',
			color: '#C0C1C4',
		},
		matrizContainer: {
			pos: 'absolute',
			right: '1rem',
			top: '0.3625rem',
		},
		indicadorContainer: {
			h: '3.75rem',
			mt: '0.5rem',
			color: '#3E3E3E',
			fontWeight: 'bold',
		},
		resultadoDesempenhoContainer: {
			gap: '1.875rem',
			mt: '1rem',
		},
		resultadoContent: {
			alignItems: 'center',
			gap: '0.5rem',
		},
		smallText: {
			fontSize: '0.875rem',
		},
		boldSmallText: {
			fontSize: '0.875rem',
			fontWeight: 'bold',
		},
		semiboldText: {
			fontSize: '1.25rem',
			fontWeight: 'semibold',
		},
		desempenhoText: {
			border: '0.5px solid ',
			borderColor: currentSelected?.desempenhoEnum === 'NEGATIVO' ? '#C41616' : '#3498DB',
			borderRadius: '29px',
			fontSize: '0.75rem',
			w: 'fit-content',
			color: currentSelected?.desempenhoEnum === 'NEGATIVO' ? '#C41616' : '#3498DB',
			px: '0.375rem',
			mt: '0.375rem',
		},
		metaPolaridadePontuacaoContainer: {
			gap: '1rem',
			mt: '1.5rem',
		},
		polaridadeContainer: {
			gap: '0.5rem',
			alignItems: 'center',
		},
		bimestreAnoContainer: {
			mt: '1rem',
			flexDir: 'column',
		},
		bimestreAnoContent: {
			gap: '2rem',
			mt: '0.5rem',
		},
		bimestreContainer: {
			minW: '12.5rem',
			gap: '1rem',
		},
		bimestreButton: {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: 'black',
			backgroundColor: '#F1F9FF',
			h: '1.625rem',
			w: '1.563rem',
			borderRadius: '50%',
			_hover: {
				color: 'black',
				backgroundColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
			},
		},
		currentBimestreButton: {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: 'black',
			backgroundColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
			h: '1.625rem',
			w: '1.563rem',
			borderRadius: '50%',
			_hover: {
				color: 'black',
				backgroundColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
			},
		},
		anoButton: {
			fontSize: '0.75rem',
			fontWeight: 'medium',
			color: '#205072',
			backgroundColor: '#FFFFFF',
			h: '1.688rem',
			w: '4.25rem',
			border: '1px solid',
			borderColor: '#3EA2A2',
			borderRadius: '13px',
			_hover: {
				color: 'black',
				fontWeight: 'bold',
				backgroundColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
				borderColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
			},
		},
		currentAnoButton: {
			fontSize: '0.75rem',
			fontWeight: 'bold',
			color: 'black',
			backgroundColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
			h: '1.688rem',
			w: '4.25rem',
			border: '1px solid',
			borderColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
			borderRadius: '13px',
			_hover: {
				color: 'black',
				fontWeight: 'bold',
				backgroundColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
				borderColor: currentSelected?.peso <= 0 ? '#75C8FF' : '#B7E2FF',
			},
		},
		button: {
			alignSelf: 'flex-start',
			fontSize: '1rem',
			px: '1rem',
			mt: '1.5rem',
		},
		headTag: {
			fontSize: '0.75rem',
			borderRadius: '0.75rem',
			h: '0.938rem',
			backgroundColor: 'blue.400',
			minW: '56.5px',
		},
	};

	const navigate = useNavigate();

	// const isPactuado = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.PACTUADO, USER_ROLES.REPRESENTANTE]);
	// const isAreaTecnica = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.REPRESENTANTE]);

	// const canLaunchResult =
	// 	data.responsavelLancamento === RESPONSIBLE_REALESE_ENUM.AREA_TECNICA ? isAreaTecnica : isPactuado;
	// const canExportResult = useCanViewAction([
	// 	USER_ROLES.PRESIDENTE,
	// 	USER_ROLES.AREA_TECNICA,
	// 	USER_ROLES.PACTUADO,
	// 	USER_ROLES.REPRESENTANTE,
	// ]);

	const handleChangeBimester = (value: string) => {
		const filteredData = data?.bimestres?.find(bimestre => bimestre?.bimestre === value);

		setCurrentSelected(filteredData!);
	};

	const handleChangeYear = (value: number) => {
		const filteredData = data?.bimestres?.find(bimestre => bimestre?.ano === value);

		setCurrentSelected(filteredData!);
	};

	const handleSubmit = () => {
		navigate(ROUTES.resultEvaluation(String(currentSelected.id)), {
			state: {
				...currentSelected,
				pactuado: data.pactuado,
				indicador: data.indicador,
				idIndicadoresPactoGestao: currentSelected.id,
			},
		});
	};

	useEffect(() => {
		const faseDataApi = currentSelected?.faseEnum;
		const pointsDataApi = currentSelected?.pontuacao;

		if (faseDataApi === 'LANCAMENTO_RESULTADO' && pointsDataApi === 'N/A') {
			setIsBtnDisabled(true);
		} else {
			setIsBtnDisabled(false);
		}
	}, [currentSelected?.faseEnum, currentSelected?.pontuacao]);

	return (
		<>
			<Flex sx={styles.container}>
				<Flex sx={styles.textContainer}>
					<Text sx={styles.pactuadoText} data-testid="text--pactuado">
						{data?.pactuado}
					</Text>

					<Tag variant="solid" sx={styles.headTag}>
						{
							TEXT_DATA_CONTROL_STEPS_ENUM[
								String(data?.initialBimester?.etapa) as keyof typeof TEXT_DATA_CONTROL_STEPS_ENUM
							]
						}
					</Tag>
				</Flex>

				<Box sx={styles.matrizContainer}>
					{/* {canExportResult && ( */}
					<Tooltip label="Visualizar Matriz" placement="top-start" gutter={-5} sx={styles.tooltip}>
						<IconButton
							aria-label={'Visualizar Matriz'}
							variant="unstyled"
							icon={<ViewMatrixIcon />}
							onClick={onOpenExportModal}
						/>
					</Tooltip>
					{/* )} */}
				</Box>

				<Flex sx={styles.indicadorContainer}>
					<AutoTextSize maxFontSizePx={42} mode="box" data-testid="text--indicador">
						{data?.indicador}
					</AutoTextSize>
				</Flex>

				<Flex sx={styles.resultadoDesempenhoContainer}>
					<Box>
						<Text sx={styles.smallText}>Resultado</Text>
						<Flex sx={styles.resultadoContent}>
							<Text sx={styles.semiboldText} data-testid="text--resultado">
								{currentSelected?.resultado < 0 ? 'N/A' : maskThousands(currentSelected?.resultado)}
							</Text>
							<ResulterTooltip
								label={RESULTER_STATUS[currentSelected?.desempenhoEnum as keyof typeof RESULTER_STATUS]?.label}
								icon={RESULTER_STATUS[currentSelected?.desempenhoEnum as keyof typeof RESULTER_STATUS]?.icon}
							/>
						</Flex>
					</Box>
					<Box>
						<Text sx={styles.smallText}>Desempenho</Text>
						<Text sx={styles.desempenhoText} data-testid="text--desempenho">
							{currentSelected?.desempenho < 0 || typeof currentSelected?.desempenho !== JAVASCRIPT_TYPES?.number
								? 'N/A'
								: `${String(currentSelected?.desempenho).slice(0, 5).replace('.', ',')}%`}
						</Text>
					</Box>
				</Flex>

				<Flex sx={styles.metaPolaridadePontuacaoContainer}>
					<Flex gap="0.5rem">
						<Text sx={styles.smallText}>Meta </Text>
						<Text sx={styles.boldSmallText} data-testid="text--meta">
							{currentSelected?.peso <= 0 ? 'N/A' : maskThousands(currentSelected?.meta)}
						</Text>
					</Flex>

					<Flex sx={styles.polaridadeContainer}>
						<Text sx={styles.smallText}>Polaridade:</Text>
						<ResulterTooltip
							label={POLARITY_STATUS[currentSelected?.polaridade as keyof typeof POLARITY_STATUS]?.label}
							icon={POLARITY_STATUS[currentSelected?.polaridade as keyof typeof POLARITY_STATUS]?.icon}
						/>
					</Flex>
					<Flex gap="0.5rem">
						<Text sx={styles.smallText}>Pontuação</Text>
						<Text sx={styles.boldSmallText} data-testid="text--pontuacao">
							{currentSelected?.peso <= 0 ? 'N/A' : currentSelected?.pontuacao?.replace('.', ',')}
						</Text>
						{currentSelected?.peso <= 0 && (
							<ResulterTooltip
								label="Não se aplicam pontos ou penalizações."
								icon={<Icon as={PontuationErrorIcon} h="0.875rem" />}
							/>
						)}
					</Flex>
				</Flex>

				<Flex sx={styles.bimestreAnoContainer}>
					<Flex alignItems="center">
						<Text sx={styles.smallText}>Bimestre</Text>

						<Tooltip label={BIMESTER_TOOLTIP_INFO} placement="top-start" sx={styles.tooltip}>
							<InfoIcon sx={styles.tooltipIcon} />
						</Tooltip>
					</Flex>

					<Flex sx={styles.bimestreAnoContent}>
						<Flex sx={styles.bimestreContainer}>
							{data?.bimestres
								?.filter(bimestre => bimestre?.ano === currentSelected?.ano)
								?.map(item => (
									<Button
										key={item?.bimestre}
										sx={
											currentSelected?.bimestre === item?.bimestre
												? styles.currentBimestreButton
												: styles.bimestreButton
										}
										onClick={() => handleChangeBimester(item?.bimestre)}
										data-testid={`button--${item?.bimestre}`}
									>
										{BIMESTERS_ENUM_NUMBERS[item?.bimestre as keyof typeof BIMESTERS_ENUM_NUMBERS]}
									</Button>
								))}
						</Flex>

						<Flex gap="0.5rem">
							<Button
								sx={currentSelected?.ano === data.anoInicial ? styles.currentAnoButton : styles.anoButton}
								onClick={() => handleChangeYear(data.anoInicial)}
								data-testid="button--anoInicial"
							>
								{data?.anoInicial}
							</Button>
							{data?.anoInicial !== data?.anoFinal && (
								<Button
									sx={currentSelected?.ano === data?.anoFinal ? styles.currentAnoButton : styles.anoButton}
									onClick={() => handleChangeYear(data?.anoFinal)}
									data-testid="button--anoFinal"
								>
									{data?.anoFinal}
								</Button>
							)}
						</Flex>
					</Flex>
				</Flex>

				{/* {canLaunchResult && ( */}
				<Button leftIcon={<ChartIcon />} sx={styles.button} onClick={handleSubmit} isDisabled={isBtnDisabled}>
					{
						RESULTER_SCREEN_PHASES_ENUM[currentSelected?.etapa as keyof typeof RESULTER_SCREEN_PHASES_ENUM][
							currentSelected?.faseEnum!
						]
					}
				</Button>
				{/* )} */}
			</Flex>
			<IndicatorMatrix
				id={`${data?.id}_${data?.indicadorId}`}
				indicatorId={String(data?.indicadorId)}
				onClose={onCloseExportModal}
				isOpen={isOpenExportModal}
			/>
		</>
	);
};

export default ResulterIndicatorCard;
