import { CSSProperties } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SessionProvider from 'contexts/SessionContext';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useMockAxios } from 'hooks/useMockAxios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Router } from 'routes';
import 'styles/fonts.css';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
			cacheTime: 0,
			staleTime: 0,
			refetchOnMount: 'always',
		},
	},
});

// Breakpoints padrões do Chakra-UI
const breakpoints = {
	base: '23.438rem', // 375px
	sm: '30em', // 480px
	md: '48em', // 768px
	lg: '62em', // 992px
	xl: '80em', // 1280px
	'2xl': '96em', // 1536px
};

const ToastContainerStyle: CSSProperties = {
	maxWidth: '26rem',
	width: '100%',
};

const ToastContainerBodyStyle: CSSProperties = { padding: '0.8rem' };

export const App = () => {
	const customTheme = useCustomTheme(breakpoints);
	useMockAxios();

	return (
		<SessionProvider>
			<QueryClientProvider client={queryClient}>
				<ChakraProvider theme={customTheme}>
					<Router />
					<ToastContainer style={ToastContainerStyle} bodyStyle={ToastContainerBodyStyle} />
				</ChakraProvider>
			</QueryClientProvider>
		</SessionProvider>
	);
};
