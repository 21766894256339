/**
 *
 * RepresentativeAccordion
 *
 */

import { Fragment } from 'react';
import {
	SystemStyleObject,
	Accordion,
	AccordionItem,
	Box,
	AccordionButton,
	AccordionIcon,
	Flex,
	Text,
	Tag,
	IconButton,
	Divider,
	AccordionPanel,
	Grid,
	GridItem,
} from '@chakra-ui/react';
import { TableEditIcon, CalendarIcon } from 'assets/icons';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useNavigate } from 'react-router-dom';
import { IResponseRepresentatives } from 'types/representative';
import { RESULTER_FILTER_STEPS, USER_ROLES } from 'utils/constants';
import { formatDate } from 'utils/formatDate';

interface Props {
	representatives: IResponseRepresentatives[];
}

const RepresentativeAccordion = ({ representatives }: Props) => {
	const styles: Record<string, SystemStyleObject> = {
		accordionItem: {
			borderWidth: '1px',
			borderColor: 'gray.95',
			mb: '2rem',
			borderRadius: '0.25rem',
		},
		box: {
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			minH: { base: 'auto', md: '4.25rem' },
			wordBreak: 'break-word',
		},
		boxExpanded: {
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			minH: { base: 'auto', md: '5rem' },
			wordBreak: 'break-word',
		},
		accordionButton: {
			_hover: {},
			height: 'auto',
			paddingTop: '0.625rem',
		},
		accordionIcon: {
			h: '2.25rem',
			w: '2.25rem',
		},
		titleContainer: {
			flex: '1',
			textAlign: 'left',
			ml: { base: '0', lg: '2rem' },
			alignItems: {
				base: 'flex-start',
				lg: 'center',
			},
			gap: '1rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		titleIsExpanded: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
		},
		title: {
			fontSize: {
				base: '1rem',
				sm: '1.125rem',
			},
			textAlign: {
				base: 'start',
				sm: 'center',
			},
			fontWeight: 'bold',
		},
		headTag: {
			fontSize: '0.75rem',
			borderRadius: '0.75rem',
			h: '0.938rem',
			backgroundColor: 'blue.400',
			minW: '56.5px',
		},
		icons: {
			gap: '1.5rem',
			paddingRight: '2rem',
			alignSelf: 'center',
		},
		divider: {
			p: '0rem 2rem 0rem 1.938rem',
		},
		accordionPanel: {
			ps: '2rem',
			pe: '5rem',
		},
		accordionPanelContainer: {
			flexDir: 'column',
			h: '100%',
		},
		infosColumn: {
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(3, 1fr)',
			},
			flexWrap: 'wrap',
			gap: '1rem',
			pt: '2.625rem',
			pb: '1.5rem',
			mx: '2.5rem',
		},
		infoTitles: {
			fontWeight: '700',
			fontSize: '1rem',
			mb: '1.3rem',
		},
		infoValue: {
			fontWeight: '500',
			fontSize: '1rem',
		},
		dateLastUpdate: {
			justifyContent: 'end',
			pr: '2rem',
			pt: '0.7rem',
		},
		dateLastUpdateText: {
			fontSize: '0.875rem',
		},
		dateLastUpdateTextContainer: {
			ml: '0.3rem',
			mt: '0.2rem',
		},
	};

	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);

	const navigate = useNavigate();

	const handleNavigationEdit = (id: string) => navigate(ROUTES.editRepresentative(id));

	return (
		<>
			{representatives?.map((representative, index) => (
				<Accordion allowMultiple key={representative.id}>
					<AccordionItem sx={styles?.accordionItem} data-testid={`representative--item-${index}`}>
						{({ isExpanded }) => (
							<>
								<Box sx={isExpanded ? styles?.boxExpanded : styles?.box}>
									<AccordionButton sx={styles?.accordionButton} data-testid={`representative--item-btn-${index}`}>
										<AccordionIcon sx={styles?.accordionIcon} />
										<Flex sx={styles?.titleContainer}>
											<Text sx={isExpanded ? styles?.titleIsExpanded : styles?.title}>
												{representative?.unidadeRepresentante.nomePactuado}
											</Text>
											<Tag variant="solid" sx={styles?.headTag} data-testid={`representative--item-tag-${index}`}>
												{RESULTER_FILTER_STEPS[String(representative?.etapa) as keyof typeof RESULTER_FILTER_STEPS]}
											</Tag>
										</Flex>
									</AccordionButton>
									<Flex sx={styles?.icons}>
										{hasPermission && (
											<IconButton
												aria-label={'Editar representante'}
												variant="unstyled"
												icon={<TableEditIcon />}
												onClick={() => handleNavigationEdit(representative.id)}
												data-testid={`representative--edit-btn-${index}`}
											/>
										)}
									</Flex>
								</Box>

								<Box sx={styles?.divider}>
									<Divider />
								</Box>

								<AccordionPanel>
									<Box sx={styles?.accordionPanel}>
										<Flex sx={styles?.accordionPanelContainer}>
											{representative.dadosPessoaisRepresentantes.map((representativeItem, index) => (
												<Fragment key={representativeItem.id}>
													<Grid sx={styles?.infosColumn}>
														<GridItem minW="16.25rem" w="fit-content">
															<Text sx={styles?.infoTitles}>Nome</Text>
															<Text sx={styles?.infoValue} data-testid={`representative--name-${index}`}>
																{representativeItem?.nome}
															</Text>
														</GridItem>
														<GridItem w="10rem">
															<Text sx={styles?.infoTitles}>MASP</Text>
															<Text sx={styles?.infoValue} data-testid={`representative--masp-${index}`}>
																{representativeItem?.masp}
															</Text>
														</GridItem>
														<GridItem minW="12.5rem">
															<Text sx={styles?.infoTitles}>E-mail</Text>
															<Text sx={styles?.infoValue} data-testid={`representative--email-${index}`}>
																{representativeItem?.email}
															</Text>
														</GridItem>
													</Grid>
													{index !== representative.dadosPessoaisRepresentantes.length - 1 && <Divider />}
												</Fragment>
											))}
										</Flex>
									</Box>

									<Flex sx={styles?.dateLastUpdate}>
										<Text sx={styles?.dateLastUpdateText} data-testid={`representative--dateLastUpdate-${index}`}>
											<Flex>
												<CalendarIcon />
												<Flex sx={styles.dateLastUpdateTextContainer}>
													Data da última atualização: {formatDate(representative?.dataUltimaAtualizacao)}
												</Flex>
											</Flex>
										</Text>
									</Flex>
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
				</Accordion>
			))}
		</>
	);
};

export default RepresentativeAccordion;
