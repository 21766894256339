export const downloadFile = (href: string, filename: string, openNewTab = false) => {
	// create "a" HTML element with href to file & click
	const link = document.createElement('a');
	link.href = href;

	if (openNewTab) return window.open(href);

	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();

	// clean up "a" element & remove ObjectURL
	document.body.removeChild(link);
	URL.revokeObjectURL(href);
};
