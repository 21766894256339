import { DATE_FORMAT } from './constants';
import { eachDayOfInterval, format, nextSaturday, previousSunday, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const formatDate = (date: string = '', time = '00:00', formatDate = DATE_FORMAT) => {
	const parsedDate = date?.replace(/T.*/, '');

	return parsedDate ? format(new Date(`${parsedDate}T${time}`), formatDate) : '';
};

export const getFormattedMonth = (date: Date) => {
	const parsedDate = format(date, 'MMM', { locale: ptBR });

	return parsedDate.charAt(0).toUpperCase() + parsedDate.slice(1);
};

export interface ICalendar {
	day: number;
	weekDay: string;
	month: number;
	year: number;
}

export const createCalendar = (month: number, year: number) => {
	const daysOfCurrentMonth: ICalendar[] = Array.from({ length: new Date(year, month + 1, 0).getDate() }, (_, i) => ({
		day: new Date(year, month, i + 1).getDate(),
		weekDay: format(new Date(year, month, i + 1), 'cccccc', { locale: ptBR }),
		month: month,
		year: year,
	}));

	let daysOfLastMonth: ICalendar[] = [];

	if (daysOfCurrentMonth[0].weekDay !== 'dom') {
		const result = eachDayOfInterval({
			start: previousSunday(new Date(year, month, daysOfCurrentMonth[0].day)),
			end: new Date(year, month, daysOfCurrentMonth[0].day),
		});

		result.pop();

		daysOfLastMonth = result.map(item => ({
			day: item.getDate(),
			weekDay: format(item, 'cccccc', { locale: ptBR }),
			month: item.getMonth(),
			year: item.getFullYear(),
		}));
	}

	let daysOfNextMonth: ICalendar[] = [];

	if (daysOfCurrentMonth[daysOfCurrentMonth.length - 1].weekDay !== 'sab') {
		const result = eachDayOfInterval({
			start: new Date(year, month, daysOfCurrentMonth[daysOfCurrentMonth.length - 1].day),
			end: nextSaturday(new Date(year, month, daysOfCurrentMonth[daysOfCurrentMonth.length - 1].day)),
		});

		result.shift();

		daysOfNextMonth = result.map(item => ({
			day: item.getDate(),
			weekDay: format(item, 'cccccc', { locale: ptBR }),
			month: item.getMonth(),
			year: item.getFullYear(),
		}));
	}

	return {
		daysOfLastMonth,
		daysOfCurrentMonth,
		daysOfNextMonth,
	};
};

export const showCalendarFormattedDate = (date: Date, view: 'days' | 'months' | 'years') => {
	const day = date.getDate();
	const parsedMonth = format(date, 'MMM', { locale: ptBR });
	const month = parsedMonth.charAt(0).toUpperCase() + parsedMonth.slice(1);
	const year = date.getFullYear();

	const completeDate = `${day} ${month}, ${year}`;
	const monthYear = `${month}, ${year}`;

	if (view === 'days') return completeDate;
	if (view === 'months') return monthYear;
	if (view === 'years') return year;
};

export const formatDateToApi = (val: string = '', pattern = 'yyyy-MM-dd') => {
	const data = parse(val, "dd MMM',' yyyy", new Date(), {
		locale: ptBR,
	});

	return format(data, pattern);
};

export const currentDatePayload = (val: Date) => {
	const dateLocal = val.toLocaleDateString().split('/').reverse().join('-');
	return String(dateLocal);
};

export const formatDateApiToFront = (val: string) => {
	if (val !== null && val.length >= 10) {
		const data = parse(val, 'yyyy-MM-dd', new Date());
		return String(showCalendarFormattedDate(data, 'days'));
	}
	return String(showCalendarFormattedDate(new Date(), 'days'));
};

export const formatDateForDefaultValues = (val: string, view: 'days' | 'months' | 'years') => {
	let data = parse(val, 'yyyy', new Date(), { locale: ptBR }); // view === year

	if (view === 'days') data = parse(val, "dd MMM',' yyyy", new Date(), { locale: ptBR });

	if (view === 'months') data = parse(val, "MMM',' yyyy", new Date(), { locale: ptBR });

	return { day: data.getDate(), month: data.getMonth(), year: data.getFullYear() };
};
