/**
 *
 * ListStrategicObjective
 *
 */

import { useMemo, useState } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Button, Flex, IconButton, Spinner, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import CustomModal from 'app/components/CustomModal';
import PageWrapper from 'app/components/PageWrapper';
import Pagination from 'app/components/Pagination';
import StrategicObjectivesFilter, {
	IStrategicObjectiveFilterForm,
	StrategicObjectivParamsKeys,
} from 'app/components/StrategicObjectivesFilter';
import Table from 'app/components/Table';
import { DeleteIcon, RegisterIcon, TableEditIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteStrategicObjective, getStrategicObjectives } from 'services/http/strategicObjetive';
import { IRequestGetStrategicObjectives } from 'services/http/strategicObjetive';
import { getPeriod } from 'services/http/strategicPlanning';
import { IResponseErrors } from 'types/httpError';
import { IPagination, IPaginationDirection } from 'types/pagination';
import { IStrategicObjective } from 'types/strategicObjetive';
import { IColumn, IRow } from 'types/table';
import { API_DEFAULT_ERROR, USER_ROLES } from 'utils/constants';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';

const StrategicObjectiveList = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		button: {
			px: '1rem',
			mb: '1rem',
		},
		content: {
			flexDir: 'column',
			alignItems: 'center',
			gap: '1rem',
			mt: '1.5rem',
		},
		spinner: {
			justifyContent: 'center',
			alignItems: 'center',
			h: '50vh',
		},
		addStrategicObjective: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '2rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const [objectiveToDelete, setObjectiveToDelete] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [searchParams, setParams] = useSearchParams();
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const FIRST_PAGE = '1';

	const { data: periods, isLoading: isPeriodsLoading } = useQuery<string[], AxiosError<IResponseErrors>>(
		['strategicObjetivePeriods'],
		() => getPeriod(),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const planningPeriods = useMemo(() => parsedOptionArray<string>(periods), [periods]);

	const { params, defaultValues, queryParams } = useMemo(() => {
		const paramsArray: StrategicObjectivParamsKeys[] = ['objetivo', 'periodo', 'direction', 'sort', 'page'];
		const params = parseUrlParams<StrategicObjectivParamsKeys>(paramsArray, searchParams);

		const periodo = planningPeriods.find(item => item.value === params.periodo);

		const defaultValues = {
			objetivo: params.objetivo,
			periodo,
		};

		const queryParams: IRequestGetStrategicObjectives = cleanDeep({
			direction: params.direction as IPaginationDirection,
			objetivo: params.objetivo,
			periodo: String(periodo?.value || ''),
			sort: params.sort as keyof IStrategicObjective,
			page: Number(params.page || FIRST_PAGE),
		});

		return { params, defaultValues, queryParams };
	}, [planningPeriods, searchParams]);

	const {
		data: strategicObjetives,
		isLoading: isStrategicObjetivesLoading,
		refetch: refetchStrategicObjetivesList,
	} = useQuery<IPagination<IStrategicObjective[]>, AxiosError<IResponseErrors>>(
		['strategic-objetives', queryParams],
		() => getStrategicObjectives({ ...queryParams }),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { mutate: deleteStategicObjective } = useMutation<void, AxiosError<IResponseErrors>, string>(
		[objectiveToDelete],
		objectiveId => deleteStrategicObjective(objectiveId),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'Um objetivo estratégico foi excluído.',
				});
				onClose();
				refetchStrategicObjetivesList();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const objetivePeriods = useMemo(() => parsedOptionArray<string>(periods), [periods]);

	const columns: IColumn[] = useMemo(() => {
		let columns: IColumn[] = [
			{
				key: 'codigo',
				label: 'Código',
				order: true,
				columnCustomStyle: {
					w: '11.25rem',
				},
			},
			{
				key: 'objetivo',
				label: 'Objetivo',
				order: true,
				columnCustomStyle: {
					w: '44.5rem',
				},
			},
			{
				key: 'periodo',
				label: 'Período',
				order: true,
				columnCustomStyle: {
					w: '11.25rem',
				},
			},
		];

		if (hasPermission) {
			return [
				...columns,
				{
					key: 'actions',
					label: 'Ações',
					sticky: 'right',
				},
			];
		}

		return columns;
	}, [hasPermission]);

	const data: IRow[] = useMemo(() => {
		return (
			strategicObjetives?.content.map(item => ({
				id: item.id!,
				codigo: item.codigo || '',
				objetivo: item.objetivo,
				periodo: item.periodo,
				actions: (
					<Flex gap="1.125rem">
						{hasPermission && (
							<>
								<IconButton
									variant="unstyled"
									icon={<TableEditIcon />}
									aria-label="Editar Objetivo"
									onClick={() => navigate(ROUTES.editStrategicObjectives(item.id!))}
									data-testid={`button--editObjective-${item.id!}`}
								/>
								<IconButton
									variant="unstyled"
									icon={<DeleteIcon />}
									aria-label="Deletar Objetivo"
									onClick={() => {
										setObjectiveToDelete(item.id!);
										onOpen();
									}}
									data-testid={`button--deleteObjective-${item.id}`}
									visibility={item?.podeExcluir ? 'visible' : 'hidden'}
								/>
							</>
						)}
					</Flex>
				),
			})) || []
		);
	}, [strategicObjetives?.content, hasPermission, navigate, onOpen]);

	const onSort = (key: keyof IStrategicObjective) => {
		setParams(
			cleanDeep({
				...params,
				direction: params.direction === 'ASC' ? 'DESC' : 'ASC',
				sort: key,
			}),
		);
	};

	const onPaginate = (page: number) => {
		setParams(
			cleanDeep({
				...params,
				page: String(page),
			}),
		);
	};

	const onSubmit = (values: IStrategicObjectiveFilterForm) => {
		const periodo = cleanDeep({
			...params,
			objetivo: values.objetivo,
			periodo: String(values.periodo?.value || ''),
			page: FIRST_PAGE,
		});
		setParams(periodo);
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Objetivos Estratégicos" />
			<Flex sx={styles?.addStrategicObjective}>
				{canViewAuditHistory && (
					<Button
						variant="unstyled"
						leftIcon={<RepeatClockIcon />}
						sx={styles.buttonShowModalAudit}
						onClick={onOpenAuditModal}
					>
						Histórico de Alterações
					</Button>
				)}

				{hasPermission && (
					<Button
						leftIcon={<RegisterIcon color="white" />}
						sx={styles.button}
						onClick={() => navigate(ROUTES.createStrategicObjectives)}
						data-testid="button--createObjective"
					>
						Cadastrar Novo Objetivo
					</Button>
				)}
			</Flex>

			{isPeriodsLoading ? (
				<Flex sx={styles.spinner}>
					<Spinner />
				</Flex>
			) : (
				<StrategicObjectivesFilter
					defaultValues={defaultValues}
					isListing={false}
					objetivePeriods={objetivePeriods}
					onSubmitForm={onSubmit}
				/>
			)}

			{isStrategicObjetivesLoading ? (
				<Flex sx={styles.spinner}>
					<Spinner />
				</Flex>
			) : (
				<Flex sx={styles.content}>
					<Pagination
						currentPage={queryParams.page || 1}
						onPaginate={onPaginate}
						pageSize={strategicObjetives?.size}
						totalItems={strategicObjetives?.totalElements}
					/>
					<Table columns={columns} data={data} onSort={onSort} />
					<Pagination
						currentPage={queryParams.page || 1}
						onPaginate={onPaginate}
						pageSize={strategicObjetives?.size}
						totalItems={strategicObjetives?.totalElements}
					/>
				</Flex>
			)}
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Confirmar a exclusão?"
				body="Você realmente quer Excluir este Objetivo? Este processo não pode ser desfeito."
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero Excluir',
						type: 'cancel',
						onClick: () => deleteStategicObjective(objectiveToDelete),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: onClose,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</Flex>
	);
};

export default StrategicObjectiveList;
