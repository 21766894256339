import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { IPeriodsConcat, IResponsePeriodBimester } from 'types/period';

export interface IPeriodBimesterParams {
	anoInicio: string;
	anoFim: string;
}

export const getPeriodsConcat = async (): Promise<IPeriodsConcat[]> => {
	const api = getAxios();
	const response = await api.get<IPeriodsConcat[]>(ENDPOINTS.periodsConcats);
	return response.data;
};

export const getPeriodBimester = async (params: IPeriodBimesterParams): Promise<IResponsePeriodBimester[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.periodsBimester, { params });
	return response.data;
};
