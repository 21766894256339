/**
 *
 * RepresentativeForm
 *
 */

import CustomModal from '../CustomModal';
import InputText from '../InputText';
import Prompt from '../Prompt';
import Select from '../Select';
import { Fragment, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Divider,
	Flex,
	IconButton,
	Spinner,
	SystemStyleObject,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AddCaapMemberIcon, DeleteIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useIsMobile } from 'hooks/useIsMobile';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	IRepresentativeParams,
	IRequestRepresentativesCaapsMember,
	getRepresentativesCaapsMember,
	getRepresentativesList,
} from 'services/http/representative';
import { getSector } from 'services/http/sectors';
import { UserMailParams, getUserMail } from 'services/http/user';
import { IResponseCaapMemberFilter } from 'types/caapMembers';
import { IResponseRepresentatives, IResponseRepresentativesCaapsMember } from 'types/representative';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { UserMail } from 'types/user';
import {
	API_DEFAULT_ERROR,
	DATA_CONTROL_STEPS_OPTIONS,
	RESULTER_FILTER_STEPS,
	SECTOR_TYPE,
	STEPS_OPTIONS,
} from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

interface IRepresentativeItemForm {
	id?: string;
	masp?: string;
	nome?: IOption | null;
	email: string;
}

export interface IRepresentativeForm {
	id?: string;
	etapa: IOption;
	setor: IOption<ISector>;
	representantes: IRepresentativeItemForm[];
}

interface RepresentativeFormProps {
	defaultValues?: IRepresentativeForm;
	onSubmit: (values: IRepresentativeForm) => void;
	isSubmitLoading?: boolean;
	isEditing?: boolean;
}

const RepresentativeForm = ({
	onSubmit,
	defaultValues,
	isEditing = false,
	isSubmitLoading = false,
}: RepresentativeFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			p: '2.5rem 3.875rem 2.813rem 2.5rem',
			backgroundColor: '#F9FAFC',
			flexDir: 'column',
			gap: {
				base: '3rem',
				md: '2rem',
			},
			border: '1px solid #E1E2E5',
		},
		content: {
			flexDir: 'column',
			gap: {
				base: '1.5rem',
				md: '1.563rem',
			},
		},
		firstSectionContainer: {
			gap: {
				base: '1.5rem',
				md: '1.563rem',
			},
			flexDir: {
				base: 'column',
				md: 'row',
			},
		},
		etapaContainer: {
			maxW: {
				base: '100%',
				md: '8.875rem',
			},
			w: '100%',
		},
		setorContainer: {
			maxW: {
				base: '100%',
				md: '25.313rem',
			},
			w: '100%',
		},
		secondSectionContainer: {
			justifyContent: 'flex-start',
			gap: {
				base: '1.5rem',
				md: '1.563rem',
			},
			flexDir: {
				base: 'column',
				md: 'row',
			},
		},
		maspContainer: {
			maxW: {
				base: '100%',
				md: '9.125rem',
			},
			w: '100%',
		},
		nomeContainer: {
			maxW: {
				base: '100%',
				md: '17.688rem',
			},
			w: '100%',
		},
		emailContainer: {
			maxW: {
				base: '100%',
				md: '23.75rem',
			},
			w: '100%',
		},
		addButton: {
			alignSelf: 'center',
			px: '5.438rem',
			w: {
				base: '100%',
				md: 'fit-content',
			},
		},
		buttonContainer: {
			flexDir: {
				base: 'column',
				md: 'row',
			},
			gap: '2.5rem',
		},
		button: {
			w: {
				base: '100%',
				md: '14.75rem',
			},
		},
		mobileIconDelete: {
			display: 'flex',
			flexDirection: 'row-reverse',
			marginBottom: '-50px',
		},
		spinner: {
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const {
		session: { user },
	} = useSession();

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { isOpen: isCancelModalOpen, onOpen: onCancelModalOpen, onClose: onCancelModalClose } = useDisclosure();
	const { isOpen: isSaveModalOpen, onOpen: onSaveModalOpen, onClose: onSaveModalClose } = useDisclosure();

	const [formValues, setFormValues] = useState<IRepresentativeForm>({} as IRepresentativeForm);
	const [representativesValues, setRepresentativesValues] = useState<IRepresentativeItemForm[]>([]);
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const representativeObject = {
		nome: undefined,
		email: '',
		masp: '',
	};

	const optionSchema = yup
		.object()
		.shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		})
		.required('Este campo é obrigatório.')
		.default(undefined)
		.nullable();

	const schema = yup.object().shape({
		setor: optionSchema,
		representantes: yup.array().of(
			yup.object().shape({
				masp: yup.string().required('Este campo é obrigatório.').max(8, 'Máximo de 8 caracteres.'),
				nome: optionSchema,
				email: yup
					.string()
					.email('E-mail inválido.')
					.required('Este campo é obrigatório.')
					.max(256, 'Máximo de 8 caracteres.'),
			}),
		),
	});

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		clearErrors,
		resetField,
		reset,
		formState: { errors, isDirty, isSubmitting, isSubmitted },
	} = useForm<IRepresentativeForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			representantes: [representativeObject],
			...defaultValues,
		},
	});

	const { fields, append, remove, replace } = useFieldArray({ name: 'representantes', keyName: 'code', control });

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const step = watch('etapa');
	const sector = watch('setor');
	const isMobile = useIsMobile(768);

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const { data: sectors, isLoading: isSectorsLoading } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['sectors', step],
		() =>
			getSector(
				step?.value ? selectedStep[step?.value as keyof typeof selectedStep] : SECTOR_TYPE.ALL,
				stringifyCurrentUserUnits(user),
			),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { data: representativesListData } = useQuery<
		IResponseRepresentatives[],
		AxiosError<ResponseErrors, IRepresentativeParams>,
		IResponseRepresentatives[]
	>(
		['representatives', sector?.value],
		() =>
			getRepresentativesList({
				setorId: sector?.value?.toString(),
			}),
		{
			onSuccess: data => {
				const representative = data?.at(0);

				if (representative?.id) {
					const values = {
						id: representative?.id,
						etapa: {
							label: RESULTER_FILTER_STEPS[String(representative?.etapa) as keyof typeof RESULTER_FILTER_STEPS],
							value: representative?.etapa!,
						},
						setor: {
							label: representative?.unidadeRepresentante?.nomePactuado!,
							value: representative?.unidadeRepresentante?.idSetor!,
							data: {
								idSetor: representative?.unidadeRepresentante?.idSetor!,
								idUnidade: representative?.unidadeRepresentante?.idUnidade!,
								nome: representative?.unidadeRepresentante?.nomePactuado!,
								nomeSetor: representative?.unidadeRepresentante?.nomePactuado!,
								sigla: representative?.unidadeRepresentante?.nomePactuado!,
							},
						},
						representantes:
							representative?.dadosPessoaisRepresentantes?.map(item => ({
								id: String(item?.id || ''),
								email: item.email,
								nome: { value: item.nome, label: item.nome },
								masp: item.masp,
							})) || [],
					};

					reset(values);
					setRepresentativesValues(values.representantes);
					replace(values.representantes);
				} else {
					const values = {
						id: undefined,
						etapa: step,
						setor: sector,
						representantes: [{ nome: null, email: '', masp: '' }],
					};

					reset(values);
					setRepresentativesValues(values.representantes);
					replace(values.representantes);
				}
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!sector?.value,
		},
	);

	const { data: representativesCaapsMemberData, isLoading: isRepresentativesCaapsMemberLoading } = useQuery<
		IResponseRepresentativesCaapsMember[],
		AxiosError<ResponseErrors, IRequestRepresentativesCaapsMember>
	>(
		['representativesCaapsMember', representativesListData],
		() =>
			getRepresentativesCaapsMember({
				params: { unidadeId: sector?.data?.idUnidade?.toString() },
				body: representativesListData || [],
			}),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.msg || API_DEFAULT_ERROR,
				});
			},
			enabled: !!representativesListData,
		},
	);

	let _index: number;
	const { mutate: getUserMailMutation } = useMutation<UserMail, AxiosError<ResponseErrors>, UserMailParams>(
		params => getUserMail(params),
		{
			onSuccess: data => {
				setValue(`representantes.${_index}.email`, data.email);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.msg || API_DEFAULT_ERROR,
				});
				setValue(`representantes.${_index}.email`, '');
			},
		},
	);

	const caapMembers = useMemo(() => {
		const defaultMembers = defaultValues?.representantes?.map(data => ({
			id: Number(data?.id),
			unidade: '',
			nome: data?.nome?.value?.toString() || '',
			masp: data?.masp || '',
			cargo: '',
			vinculo: '',
			cpf: '',
		}));

		const optionsList =
			defaultMembers && representativesCaapsMemberData
				? [...representativesCaapsMemberData, ...defaultMembers]
				: representativesCaapsMemberData;

		return optionsList?.sort((a: IResponseRepresentativesCaapsMember, b: IResponseRepresentativesCaapsMember) =>
			a.nome.localeCompare(b.nome),
		);
	}, [representativesCaapsMemberData, defaultValues?.representantes]);

	const handleSetValues = (
		index: number,
		field: keyof Pick<IResponseRepresentativesCaapsMember, 'nome' | 'masp'>,
		value: string,
	) => {
		_index = index;
		const currentValues = watch(`representantes`);
		const membro = caapMembers?.find(membro => membro[field] === value);
		const email = defaultValues?.representantes?.find(
			representante => representante?.id?.toString() === membro?.id?.toString(),
		)?.email;

		if (membro?.nome) {
			currentValues[index] = {
				...currentValues[index],
				nome: { label: membro.nome, value: membro.nome },
				masp: membro.masp,
			};
			email ? setValue(`representantes.${index}.email`, email) : getUserMailMutation({ cpf: membro.cpf });
			setValue(`representantes`, currentValues);
			setValue(`representantes.${index}.nome`, { label: membro.nome, value: membro.nome });
			setValue(`representantes.${index}.masp`, membro.masp);
			clearErrors(`representantes.${index}.nome`);
			clearErrors(`representantes.${index}.masp`);
		} else {
			setValue(`representantes.${index}.nome`, null);
			setValue(`representantes.${index}.masp`, value);
			clearErrors(`representantes.${index}.nome`);
			clearErrors(`representantes.${index}.masp`);
		}
	};

	const filteredOptions = (members?: IResponseCaapMemberFilter[]) => {
		let membersCopy = members?.filter((e: IResponseCaapMemberFilter) => {
			return !fields.find(item => {
				return item.nome?.value === e.nome;
			});
		});
		return parsedOptionArray(membersCopy, 'nome', 'nome');
	};

	const handleGoBack = () => {
		onCancelModalClose();
		navigate(ROUTES.representatives);
		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleCancelModalOpen = () => {
		setIsCancelButtonClicked(true);
		onCancelModalOpen();
	};

	const handleCancelModalClose = () => {
		setIsCancelButtonClicked(false);
		onCancelModalClose();
	};

	const handleSaveModalOpen = (values: IRepresentativeForm) => {
		onSaveModalOpen();
		setFormValues(values);
	};

	const onHandleSubmit = (values: IRepresentativeForm) => {
		const formatedValues: IRepresentativeForm = {
			...values,
			etapa: selectedStep.ETAPA_UM.includes(values?.setor?.data?.hierarquia || '')
				? (STEPS_OPTIONS.at(0) as IOption)
				: (STEPS_OPTIONS.at(1) as IOption),
		};
		onSubmit(formatedValues);
	};

	useEffect(() => {
		const currentValues = watch(`representantes`);

		currentValues?.forEach((item, index) => {
			const hasValue = caapMembers?.find(member => member.nome === item.nome?.value);
			const hasValueMaps = caapMembers?.find(member => member.masp === item?.masp);
			const rep = representativesValues.find(representative => representative.id === item.id);

			if (!hasValue && !hasValueMaps && !rep) {
				setValue(`representantes.${index}.nome`, null);
				setValue(`representantes.${index}.masp`, '');
			}
		});

		const formValues = currentValues.filter(item => item.nome);

		formValues.length ? replace(formValues) : replace([representativeObject]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [caapMembers, replace, representativesValues, setValue, watch]);

	useEffect(() => {
		reset(defaultValues);
	}, [defaultValues, reset]);

	return (
		<Flex as="form" onSubmit={handleSubmit(handleSaveModalOpen)} sx={styles.container}>
			<Flex sx={styles.content}>
				<Flex sx={styles.firstSectionContainer}>
					<Flex sx={styles.etapaContainer}>
						<Controller
							control={control}
							name="etapa"
							render={({ field: { value, onChange } }) => (
								<Select
									label="Etapa"
									placeholder="Selecione a etapa"
									options={DATA_CONTROL_STEPS_OPTIONS}
									value={value}
									onChange={onChange}
									isDisabled={isEditing}
									errorMessage={errors?.etapa?.message}
									dataTestId="select--etapa"
								/>
							)}
						/>
					</Flex>

					<Flex sx={styles.setorContainer}>
						<Controller
							control={control}
							name="setor"
							render={({ field: { value, onChange } }) => (
								<Select
									label="Unidade de exercício"
									placeholder="Buscar uma unidade/setor"
									required
									options={parsedOptionArray(sectors, 'nome', 'idSetor')}
									value={value}
									onChange={e => {
										onChange(e);
										resetField('representantes');
									}}
									isDisabled={isEditing}
									isLoading={isSectorsLoading}
									errorMessage={errors?.setor?.message}
									dataTestId="select--sector"
								/>
							)}
						/>
					</Flex>
				</Flex>

				{sector?.value && isRepresentativesCaapsMemberLoading ? (
					<Flex sx={styles.spinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Flex>
				) : (
					<>
						{fields.map((item, index) => (
							<Fragment key={item.code}>
								{index > 0 && <Divider />}
								{fields.length > 1 && isMobile && (
									<Box sx={styles.mobileIconDelete}>
										<Tooltip
											label="Excluir permanentemente."
											color="#3B3333"
											bg="white"
											hasArrow
											arrowSize={15}
											placement="top-start"
											fontSize="sm"
											fontWeight="normal"
											arrowPadding={3}
										>
											<IconButton
												aria-label={'Excluir representante'}
												variant="unstyled"
												icon={<DeleteIcon />}
												data-testid={`button--remove-row-${index}`}
												onClick={() => remove(index)}
											/>
										</Tooltip>
									</Box>
								)}
								<Flex sx={styles.secondSectionContainer}>
									<Flex sx={styles.maspContainer}>
										<Controller
											control={control}
											name={`representantes.${index}.masp`}
											render={({ field: { value, onChange } }) => (
												<InputText
													type="number"
													name="masp"
													label="MASP"
													placeholder="MASP"
													value={value}
													onChange={e => {
														onChange(e);
														handleSetValues(index, 'masp', e.target.value);
													}}
													isDisabled={!sector?.value}
													maxLength={8}
													errorMessage={errors?.representantes?.[index]?.masp?.message}
													required
													data-testid={`input--masp-${index}`}
												/>
											)}
										/>
									</Flex>

									<Flex sx={styles.nomeContainer}>
										<Controller
											control={control}
											name={`representantes.${index}.nome`}
											render={({ field: { value, onChange } }) => (
												<Select
													name="nome"
													label="Nome"
													placeholder="Nome"
													options={filteredOptions(caapMembers)}
													value={value}
													onChange={e => {
														onChange(e);
														handleSetValues(index, 'nome', (e as unknown as IOption).label);
													}}
													isDisabled={!sector?.value}
													isLoading={isRepresentativesCaapsMemberLoading}
													required
													errorMessage={errors?.representantes?.[index]?.nome?.message}
													dataTestId={`select--nome-${index}`}
												/>
											)}
										/>
									</Flex>

									<Flex sx={styles.emailContainer}>
										<Controller
											control={control}
											name={`representantes.${index}.email`}
											render={({ field: { value, onChange } }) => (
												<InputText
													name="email"
													label="E-mail"
													placeholder="E-mail"
													value={value}
													onChange={onChange}
													isDisabled
													tooltipInfo="Caso seu email esteja incorreto, entre em contato com um administrador do Sistema de autenticação para corrigi-lo, caso permaneça incorreto afetará diretamente no recebimento das notificações por e-mail!"
													maxLength={256}
													errorMessage={errors?.representantes?.[index]?.email?.message}
													data-testid={`input--email-${index}`}
												/>
											)}
										/>
									</Flex>

									{fields.length > 1 && !isMobile && (
										<Box>
											<Tooltip
												label="Excluir permanentemente."
												color="#3B3333"
												bg="white"
												hasArrow
												arrowSize={15}
												placement="top-start"
												fontSize="sm"
												fontWeight="normal"
												arrowPadding={3}
											>
												<IconButton
													aria-label={'Excluir representante'}
													variant="unstyled"
													icon={<DeleteIcon />}
													data-testid={`button--remove-row-${index}`}
													onClick={() => remove(index)}
												/>
											</Tooltip>
										</Box>
									)}
								</Flex>
							</Fragment>
						))}
					</>
				)}

				<Tooltip
					hasArrow
					label="Somente são permitidos a inclusão de 3 representantes por setor."
					placement="auto"
					pb="1rem"
					background="white"
					bg="white"
					color="black"
					borderWidth="1px"
					borderColor="gray.300"
					borderRadius="4px"
					fontSize="0.8rem"
					isDisabled={fields.length < 3}
				>
					<Text as="span" width="100%" textAlign="center">
						<Button
							leftIcon={<AddCaapMemberIcon color="#FFF" />}
							sx={styles.addButton}
							onClick={() => append(representativeObject)}
							isDisabled={!sector?.value || fields.length >= 3}
							data-testid="button--add-row"
						>
							Adicionar Representante
						</Button>
					</Text>
				</Tooltip>
			</Flex>

			<Flex sx={styles.buttonContainer}>
				<Button
					sx={styles.button}
					type="submit"
					isDisabled={!sector?.value}
					isLoading={isSubmitLoading}
					data-testid="button--submit"
				>
					Vincular Representante(s)
				</Button>
				<Button
					variant="secondary"
					sx={styles.button}
					isDisabled={!sector?.value}
					onClick={() => handleCancelModalOpen()}
					data-testid="button--cancel"
				>
					Cancelar
				</Button>
			</Flex>

			<CustomModal
				icons={[{ type: 'info' }]}
				title="Você irá vincular representantes."
				body={`Você selecionou um ou mais representantes, ao prosseguir você os vinculará como representantes do setor <strong>${formValues?.setor?.label}</strong>.<br> Deseja continuar?`}
				isOpen={isSaveModalOpen}
				onClose={onSaveModalClose}
				actions={[
					{
						label: 'Vincular Representantes',
						type: 'primary',
						onClick: () => onHandleSubmit(formValues),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: onSaveModalClose,
						datatestid: 'button--cancel',
					},
				]}
			/>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você realmente quer cancelar?"
				body="Todas as informações inseridas serão perdidas caso você cancele."
				isOpen={isCancelModalOpen}
				onClose={onCancelModalClose}
				actions={[
					{
						label: 'Quero Cancelar',
						type: 'cancel',
						onClick: handleGoBack,
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleCancelModalClose,
						datatestid: 'button--cancel',
					},
				]}
			/>

			<Prompt when={showPrompt} />
		</Flex>
	);
};

export default RepresentativeForm;
