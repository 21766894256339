import { IOption } from 'types/select';

export const parsedOptionsObject = <T>(item: T, keyLabel?: keyof T, keyValue?: keyof T) => {
	return {
		label: item[keyLabel!] || item,
		value: item[keyValue!] || item,
		data: item,
	};
};

export const parsedOptionArray = <T>(array: T[] = [], keyLabel?: keyof T, keyValue?: keyof T) => {
	return array.map(item => parsedOptionsObject(item, keyLabel, keyValue)) as IOption[];
};
