/**
 *
 * StrategicPlanningFilter
 *
 */
import InputText from '../InputText';
import Select from '../Select';
import { useEffect } from 'react';
import { Flex, Box, SystemStyleObject, Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { IOption } from 'types/select';
import * as yup from 'yup';

export interface IStrategicPlanningFilterForm {
	nomePlanejamento?: string;
	periodo?: IOption;
}

export type paramsKeys = keyof IStrategicPlanningFilterForm;

interface IStrategicPlanningFilterProps {
	defaultValues: IStrategicPlanningFilterForm;
	isListing?: boolean;
	planningPeriods: IOption[];
	onSubmit: (values: IStrategicPlanningFilterForm) => void;
}

const StrategicPlanningFilter = ({
	isListing = true,
	planningPeriods,
	defaultValues,
	onSubmit,
}: IStrategicPlanningFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
		},
		content: {
			width: '100%',
		},
		fields: {
			display: 'flex',
			width: '100%',
			alignItems: 'baseline',
			gap: {
				base: '2rem',
				md: '2rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
		},

		planning: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '23rem',
			},
		},
		period: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '15rem',
			},
		},
		buttonSubmit: {
			width: '100%',
		},
		button: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '15rem' },
		},
	};

	const schema = yup.object().shape({
		nomePlanejamento: yup.string().required('Este campo é obrigatório.'),
		periodo: yup.object().shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		}),
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
		register,
		reset,
	} = useForm<IStrategicPlanningFilterForm>({
		resolver: !isListing ? undefined : yupResolver(schema),
	});

	useEffect(() => reset(defaultValues), [defaultValues, reset]);

	const onSubmitForm = (values: IStrategicPlanningFilterForm) => onSubmit?.(values);

	return (
		<>
			<Flex sx={styles.container}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmitForm)}>
					<Box sx={styles.fields}>
						<Box sx={styles.planning}>
							<InputText
								register={register}
								label="Nome do planejamento"
								name="nomePlanejamento"
								required={isListing}
								placeholder="Digite o planejamento"
								errorMessage={errors?.nomePlanejamento?.message}
								data-testid="input--planningName"
								type="text"
							/>
						</Box>

						<Box sx={styles.period}>
							<Controller
								name="periodo"
								control={control}
								render={({ field }) => (
									<>
										<Select
											label="Período do planejamento"
											dataTestId="input--planningPeriod"
											placeholder="Selecione o período"
											value={field?.value}
											onChange={field?.onChange}
											options={planningPeriods}
											placeholderSelectedItems={`${planningPeriods.values.name}`}
											errorMessage={errors?.periodo?.value?.message}
											required={isListing}
										/>
									</>
								)}
							/>
						</Box>
					</Box>
					<Box sx={styles.buttonSubmit}>
						<Button type="submit" sx={styles.button} data-testid="button--submit">
							Pesquisar
						</Button>
					</Box>
				</Box>
			</Flex>
		</>
	);
};

export default StrategicPlanningFilter;
