/**
 *
 * AuditRecordsModalDetails
 *
 */

import { useMemo } from 'react';
import {
	Button,
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { AuditRecord } from 'types/audit';
import { AUDIT_MODULES_ENUM } from 'utils/constants';
import { formatDate } from 'utils/formatDate';

interface AuditRecordsModalDetailsProps {
	onClose: () => void;
	isOpen: boolean;
	recordDetails: AuditRecord;
}

const AuditRecordsModalDetails = ({ isOpen, onClose, recordDetails }: AuditRecordsModalDetailsProps) => {
	const styles: Record<string, SystemStyleObject> = {
		modalTitle: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
		},
		modalContainer: {
			maxW: '82.125rem',
			w: 'full',
			borderRadius: '0.25rem',
		},
		modalHeader: {
			px: '1rem',
			py: '0.563rem',
			borderRadius: '0.25rem',
			bgColor: '#F9FAFC',
		},
		modalBody: {
			px: '2.5rem',
			py: '2.063rem',
			borderBottomWidth: '1px',
		},
		modalGrid: {
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
			gap: {
				base: '1.5rem',
				lg: 0,
			},
			pb: '1.5rem',
		},
		modalGridColumn: {
			flexDir: 'column',
			gap: '1.5rem',
		},
		modalGridFlexGap: {
			gap: '1.5rem',
		},
		modalGridRowText: {
			fontSize: '1.25rem',
			fontWeight: 'medium',
		},
		modalGridRowValue: {
			fontWeight: 'bold',
			fontSize: '1.25rem',
		},
		modalSubTitle: {
			fontSize: '1.125rem',
			fontWeight: 'bold',
		},
		modalDetails: {
			pt: '2rem',
			pb: '1rem',
			gap: '1.5rem',
			flexDirection: 'column',
			borderTopWidth: '1px',
		},
		modalTextDetails: {
			whiteSpace: 'break-spaces',
		},
		modalFooter: {
			px: '0.8rem',
			pt: '0.8rem',
			pb: '1.125rem',
		},
		closeButton: {
			w: '13.75rem',
			justifySelf: 'end',
		},
		capitalize: {
			textTransform: 'capitalize',
		},
	};

	const data = useMemo(() => {
		const { autorCriacao, dataCriacao, autorAlteracao, dataAlteracao } = recordDetails!;
		return {
			...recordDetails!,
			autorCriacao: autorCriacao?.toLowerCase(),
			dataCriacao: formatDate(dataCriacao),
			autorAlteracao: autorAlteracao?.toLowerCase(),
			dataAlteracao: formatDate(dataAlteracao),
		};
	}, [recordDetails]);

	return (
		<Modal isCentered isOpen={isOpen && !!recordDetails?.id} onClose={onClose}>
			<ModalOverlay />
			<ModalContent data-testid="modal" sx={styles.modalContainer}>
				<ModalHeader sx={styles.modalHeader}>
					<Text sx={styles.modalTitle}>Detalhes da alteração</Text>
				</ModalHeader>
				<ModalCloseButton size="md" />
				<ModalBody data-testid="modal-body" sx={styles.modalBody}>
					<Grid sx={styles.modalGrid}>
						<Flex sx={styles.modalGridColumn}>
							<Flex sx={styles.modalGridFlexGap}>
								<Text sx={styles.modalGridRowText}>Componente alterado</Text>
								<Text
									as="pre"
									dangerouslySetInnerHTML={{ __html: data.campoAlterado }}
									sx={styles.modalGridRowValue}
									data-testid={'text--componente'}
								/>
							</Flex>
							<Flex sx={styles.modalGridFlexGap}>
								<Text sx={styles.modalGridRowText}>Autor do registro</Text>
								<Text sx={{ ...styles.modalGridRowValue, ...styles.capitalize }} data-testid={'text--autorRegistro'}>
									{data.autorCriacao.toLowerCase()}
								</Text>
							</Flex>
							<Flex sx={styles.modalGridFlexGap}>
								<Text sx={styles.modalGridRowText}>Criação do registro</Text>
								<Text sx={styles.modalGridRowValue} data-testid={'text--criacao'}>
									{data.dataCriacao}
								</Text>
							</Flex>
						</Flex>
						<Flex sx={styles.modalGridColumn}>
							<Flex sx={styles.modalGridFlexGap}>
								<Text sx={styles.modalGridRowText}>Onde</Text>
								<Text sx={styles.modalGridRowValue} data-testid={'text--onde'}>
									{AUDIT_MODULES_ENUM[data.modulo]}
								</Text>
							</Flex>
							<Flex sx={styles.modalGridFlexGap}>
								<Text sx={styles.modalGridRowText}>Autor da alteração</Text>
								<Text sx={{ ...styles.modalGridRowValue, ...styles.capitalize }} data-testid={'text--autorAlteracao'}>
									{data.autorAlteracao}
								</Text>
							</Flex>
							<Flex sx={styles.modalGridFlexGap}>
								<Text sx={styles.modalGridRowText}>Última alteração realizada</Text>
								<Text sx={styles.modalGridRowValue} data-testid={'text--alteracaoData'}>
									{data.dataAlteracao}
								</Text>
							</Flex>
						</Flex>
					</Grid>

					<Flex sx={styles.modalDetails}>
						<Text sx={styles.modalSubTitle}>Detalhes da alteração</Text>
						<Text
							dangerouslySetInnerHTML={{ __html: data.detalhesAlteracao }}
							sx={styles.modalTextDetails}
							data-testid={'text--detalheAlteracao'}
						/>
					</Flex>
				</ModalBody>

				<ModalFooter sx={styles.modalFooter}>
					<Button sx={styles.closeButton} onClick={onClose}>
						Fechar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default AuditRecordsModalDetails;
