import { getYear } from 'date-fns';
import { IOption } from 'types/select';

export const yearsArray = (size = 5) => {
	const currentYear = new Date().getFullYear();
	const endYear = currentYear + size;

	const years: IOption[] = [];

	for (let year = currentYear; year <= endYear; year++) {
		years.push({ label: year.toString(), value: year.toString() });
	}

	return years;
};

export const getInitialYear = () => {
	const currentYear = getYear(new Date());

	if (currentYear === 2024) return currentYear - 1;

	return currentYear;
};
