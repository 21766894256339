/**
 *
 * ReportMonitoring
 *
 */

import { useMemo, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Spinner,
	SystemStyleObject,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import ReportMonitoringDetailedModal from 'app/components/ReportMonitoringDetailedModal';
import ReportMonitoringFilter, {
	ReportMonitoringFilterForm,
	ReportMonitoringFilterFormKeys,
} from 'app/components/ReportMonitoringFilter';
import ReportMonitoringModal from 'app/components/ReportMonitoringModal';
import ReportMonitoringReducedModal from 'app/components/ReportMonitoringReducedModal';
import ReportMonitoringTable from 'app/components/ReportMonitoringTable';
import ReportMonitoringVersionModal from 'app/components/ReportMonitoringVersionModal';
import { ExportAttachmentIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useCustomToast } from 'hooks/useToast';
import { useSearchParams } from 'react-router-dom';
import { ReportMonitoringRequest, getReportMonitoringList } from 'services/http/monitoringReport';
import { IReportMonitoringResponse, Report } from 'types/reportMonitoring';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { formatDecimalNumber } from 'utils/formatPerformanceNumber';
import { ResponseErrors } from 'utils/parseErrors';
import { parseUrlParams } from 'utils/parseUlrParams';

const ReportMonitoring = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
		},
		buttonContainer: {
			justifyContent: 'end',
			gap: {
				base: '0.75rem',
				lg: '1.5rem',
			},
			h: {
				base: '100%',
				lg: '3.875rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		button: {
			px: '0.625rem',
			mb: '1rem',
		},
		content: {
			mt: '2.5rem',
			flexDir: 'column',
		},
		tableContainer: {
			p: '3.75rem',
			backgroundColor: '#F9FAFC',
			border: '1px solid #E1E2E5',
			borderBottomRadius: '0.25rem',
			borderRightRadius: '0.25rem',
			flexDir: 'column',
		},
		tabContainer: {
			gap: '3.438rem',
			flexDir: {
				base: 'column',
				sm: 'row',
			},
		},
		title: {
			fontSize: '1.5rem',
			fontWeight: 'bold',
		},
		text: {
			fontSize: '3.063rem',
			fontWeight: 'bold',
			color: '#004A73',
		},
		tabButton: {
			px: '1.5rem',
			w: '10.813rem',
			textAlign: 'left',
			border: '1px solid #E1E2E5',
			borderBottomRadius: '0',
			borderBottomColor: 'transparent',
			mb: '-1px',
			color: '#000',
			fontSize: '1.125rem',
		},
		tableContent: {
			gap: '2.5rem',
			flexDir: 'column',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};
	const { onOpen: onOpenCompleteModal, onClose: onCloseCompleteModal, isOpen: isOpenCompleteModal } = useDisclosure();
	const { onOpen: onOpenReduceModal, onClose: onCloseReduceModal, isOpen: isOpenReduceModal } = useDisclosure();
	const { onOpen: onOpenVersionModal, onClose: onCloseVersionModal, isOpen: isOpenVersionModal } = useDisclosure();
	const { onOpen: onOpenDetailedModal, onClose: onCloseDetailedModal, isOpen: isOpenDetailedModal } = useDisclosure();

	const { addToast } = useCustomToast();
	const [searchParams, setParams] = useSearchParams();
	const [currentSelected, setCurrentSelected] = useState<{ unidade: string; etapa: string; unidadeId: number }>();

	const { queryValues } = useMemo(() => {
		const paramsKeys: ReportMonitoringFilterFormKeys[] = [
			'bimestre',
			'etapa',
			'indicador',
			'pactuado',
			'periodo',
			'unidade',
		];
		const queryValues = parseUrlParams<ReportMonitoringFilterFormKeys>(paramsKeys, searchParams);

		return { queryValues };
	}, [searchParams]);

	const { data: reportMonitoringList, isFetching: isReportMonitoringLoading } = useQuery<
		IReportMonitoringResponse,
		AxiosError<ResponseErrors>
	>(['report-monitoring', queryValues], () => getReportMonitoringList(queryValues as ReportMonitoringRequest), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data.message || API_DEFAULT_ERROR,
			}),
		enabled: !!Object.keys(queryValues).length,
	});

	const reportMonitoring = reportMonitoringList?.relatorios;

	const reportGroupedUnitId = useMemo(() => {
		let selected = {
			unidade: '',
			etapa: '',
			unidadeId: 0,
		};

		const unidadesAgrupadas: { [key: number]: Report } = {};

		reportMonitoring?.forEach(objeto => {
			if (unidadesAgrupadas[objeto.unidadeId]) {
				objeto.setores.forEach(setor => {
					setor.etapa = objeto.etapa;
				});
				unidadesAgrupadas[objeto.unidadeId].setores = unidadesAgrupadas[objeto.unidadeId].setores.concat(
					objeto.setores,
				);
				unidadesAgrupadas[objeto.unidadeId].numeroSetores += objeto.numeroSetores;
			} else {
				objeto.setores.forEach(setor => {
					setor.etapa = objeto.etapa;
				});
				unidadesAgrupadas[objeto.unidadeId] = {
					unidade: objeto.unidade,
					unidadeId: objeto.unidadeId,
					setores: objeto.setores.slice(),
					numeroSetores: objeto.numeroSetores,
					mediaDesempenho: objeto.mediaDesempenho,
					etapa: objeto.etapa,
					desempenhoGeralUnidade: objeto.desempenhoGeralUnidade,
				};
			}
		});

		const reportGrouped = Object.values(unidadesAgrupadas);

		reportGrouped.sort((a, b) => a.unidade.localeCompare(b.unidade));

		if (Boolean(reportGrouped)) {
			selected = {
				unidade: reportGrouped[0]?.unidade,
				etapa: reportGrouped[0]?.etapa,
				unidadeId: reportGrouped[0]?.unidadeId,
			};
		}
		setCurrentSelected(selected);

		return reportGrouped;
	}, [reportMonitoring]);

	const dataGrouped = useMemo(
		() =>
			reportGroupedUnitId?.find(
				item => item.unidadeId === currentSelected?.unidadeId && item.unidade === currentSelected?.unidade,
			),
		[currentSelected?.unidade, currentSelected?.unidadeId, reportGroupedUnitId],
	);

	const handleCompleteModalOpen = () => onOpenCompleteModal();

	const handleReducedModalOpen = () => onOpenReduceModal();

	const onSubmit = (values: ReportMonitoringFilterForm) => {
		const requestData = cleanDeep({
			bimestre: values.bimestre?.map(item => item.value) as string[],
			etapa: values.etapa?.value as string,
			indicador: values.indicador?.map(item => item.value) as string[],
			periodo: values.periodo?.value as string,
			pactuado: values.pactuado?.map(item => item.value) as string[],
			unidade: values.unidade?.map(item => item.value) as string[],
		});

		setParams(requestData);
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Resultados Consolidados" />

			<Flex sx={styles.buttonContainer}>
				{!!reportMonitoring?.length && (
					<>
						<Button leftIcon={<ExportAttachmentIcon />} sx={styles.button} onClick={onOpenDetailedModal}>
							Resultados Detalhados
						</Button>
						<Button
							leftIcon={<ExportAttachmentIcon color="#3EA2A2" />}
							variant="secondary"
							color="#3EA2A2"
							sx={styles.button}
							onClick={onOpenVersionModal}
						>
							Resultados Consolidados
						</Button>
					</>
				)}
			</Flex>

			<ReportMonitoringFilter onSubmit={onSubmit} />

			{isReportMonitoringLoading ? (
				<Box sx={styles?.loadingSpinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				!!reportMonitoring?.length && (
					<Tabs variant="enclosed" sx={styles.content}>
						<TabList overflowX="auto" overflowY="hidden" gap={'8px'}>
							{reportGroupedUnitId?.map(item => {
								const newSelected = {
									unidade: item.unidade,
									etapa: item.etapa,
									unidadeId: item.unidadeId,
								};

								return (
									<Tab
										key={`${item.unidade}-${item.unidadeId}`}
										sx={{
											...styles.tabButton,
											backgroundColor:
												currentSelected?.unidade === item.unidade && currentSelected.unidadeId === item.unidadeId
													? '#F9FAFC'
													: '#E1E2E5',
										}}
										onClick={() => setCurrentSelected(newSelected)}
									>
										{item.unidade}
									</Tab>
								);
							})}
						</TabList>
						<TabPanels sx={styles.tableContainer}>
							{reportMonitoring?.map(item => (
								<TabPanel key={item.unidade}>
									<Flex sx={styles.tabContainer}>
										<Box>
											<Text sx={styles.title}>Setores</Text>
											<Text sx={styles.text}>{dataGrouped?.setores.length}</Text>
										</Box>
										<Box>
											<Text sx={styles.title}>Média de desempenho</Text>
											<Text sx={styles.text}>{formatDecimalNumber(dataGrouped?.mediaDesempenho)}</Text>
										</Box>
									</Flex>
									<Flex sx={styles.tableContent}>
										{dataGrouped?.setores.map((item, index) => (
											<ReportMonitoringTable key={index} setor={item!} />
										))}
									</Flex>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				)
			)}

			<ReportMonitoringVersionModal
				isOpen={isOpenVersionModal}
				onClose={onCloseVersionModal}
				onCompleteVersion={handleCompleteModalOpen}
				onReducedVersion={handleReducedModalOpen}
			/>
			<ReportMonitoringModal onClose={onCloseCompleteModal} isOpen={isOpenCompleteModal} data={reportMonitoringList} />

			{!!reportMonitoringList && (
				<>
					<ReportMonitoringDetailedModal
						isOpen={isOpenDetailedModal}
						onClose={onCloseDetailedModal}
						data={reportMonitoringList}
						unitSelected={String(currentSelected?.unidade)}
					/>
					<ReportMonitoringReducedModal
						onClose={onCloseReduceModal}
						isOpen={isOpenReduceModal}
						data={reportMonitoringList}
					/>
				</>
			)}
		</Flex>
	);
};

export default ReportMonitoring;
