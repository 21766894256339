/**
 *
 * EditManagementPact
 *
 */

import { useMemo } from 'react';
import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PactForm, { IPactForm } from 'app/components/PactForm';
import PageWrapper from 'app/components/PageWrapper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { getIndicatorsPactLinkList } from 'services/http/indicators';
import { editManagementPact, getManagementPactById, validateManagementPact } from 'services/http/managementPact';
import { getSector } from 'services/http/sectors';
import { Indicator } from 'types/indicators';
import { IRequestEditManagementPact, IResponseManagementPactById } from 'types/managementPact';
import { ISector } from 'types/sectors';
import { API_DEFAULT_ERROR, SECTOR_TYPE } from 'utils/constants';
import { formatPeriod, formatResponsePeriodMetric, getPeriodsToDelete } from 'utils/formatBimesters';
import { ResponseErrors } from 'utils/parseErrors';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';

type IParams = {
	pactId: string;
};

const EditManagementPact = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.875rem',
			mb: '2rem',
			width: { base: '100%', lg: '65rem', '2xl': '100%' },
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const {
		session: { user },
	} = useSession();

	const { pactId = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const {
		data: managementPact,
		isLoading: isManagementPactLoading,
		refetch: refetchManagementPact,
	} = useQuery<IResponseManagementPactById, AxiosError<ResponseErrors>, IResponseManagementPactById>(
		['management-pact', pactId],
		() => getManagementPactById(pactId),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const stepsEnum = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const { data: pactuados, isLoading: isPactuadosLoading } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['pacts'],
		() =>
			getSector(
				stepsEnum[managementPact?.pactoGestao.etapa as keyof typeof stepsEnum],
				stringifyCurrentUserUnits(user),
			),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!managementPact,
		},
	);

	const { data: indicadores, isLoading: isLoadingIndicadores } = useQuery<
		Indicator[],
		AxiosError<ResponseErrors>,
		Indicator[]
	>(
		['indicators', managementPact?.pactoGestao.ano, managementPact?.pactoGestao.etapa],
		() =>
			getIndicatorsPactLinkList({
				ano: [
					String(managementPact?.atribuicaoIndicadores[0].periodos[0].ano),
					String(managementPact?.atribuicaoIndicadores[0].periodos[5].ano),
				],
				etapa: managementPact?.pactoGestao.etapa,
				idUnidade: String(pactuados?.find(item => item.idSetor === managementPact?.idPactuadoExterno)?.idUnidade),
			}),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!managementPact?.pactoGestao.ano && !!pactuados,
		},
	);

	const { mutate: validateManagementPactMutate } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => validateManagementPact(data),
		{
			onSuccess: () => {
				addToast({
					type: 'success',
					description: 'A alteração dos campos e a validação foram realizadas com sucesso.',
					title: 'Sucesso!',
				});
				refetchManagementPact();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const defaultValues: IPactForm = useMemo(() => {
		const pactuado = pactuados?.find(item => item.idSetor === managementPact?.idPactuadoExterno);
		const anoInicial = managementPact?.atribuicaoIndicadores[0].periodos[0].ano;
		const anoFinal = managementPact?.atribuicaoIndicadores[0].periodos[5].ano;

		return {
			anoInicial,
			anoFinal,
			pactuado: {
				label: String(pactuado?.nome),
				value: Number(pactuado?.idSetor),
				idUnidade: Number(pactuado?.idUnidade),
			},
			indicadores: managementPact?.atribuicaoIndicadores.map(item => {
				const nomeIndicador = indicadores && indicadores?.find(indicador => indicador.id === item.idIndicador);

				return {
					indicador: { label: String(nomeIndicador?.nome), value: Number(nomeIndicador?.id) },
					periodos: formatResponsePeriodMetric(item.periodos),
				};
			}),
			etapa: managementPact?.pactoGestao.etapa,
		};
	}, [managementPact, pactuados, indicadores]);

	let type = 'EDIT';

	const {
		mutate: editManagementPactMutate,
		isLoading: isEditManagementPactLoaging,
		isSuccess: isEditManagementPactSuccess,
	} = useMutation<void, AxiosError<ResponseErrors>, IRequestEditManagementPact>(
		data => editManagementPact(pactId, data),
		{
			onSuccess: () => {
				if (type === 'EDIT') {
					navigate(ROUTES.managementPact);
					addToast({ type: 'success', description: 'A edição dos campos foi feita com sucesso.', title: 'Sucesso!' });
				}
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const handleSubmit = (data: IPactForm) => {
		const indicatorsToDelete = data?.indicadores?.filter(indicador => indicador.request === 'DELETE');
		const indicatorsToUpdate = data.indicadores?.filter(indicador => indicador.request === 'PUT');

		const payload: IRequestEditManagementPact = {
			ano: data.anoInicial!,
			etapaEnum: data.etapa!,
			post: data.indicadores
				?.filter(indicador => indicador.request === 'POST' && indicador.periodos?.length)
				.map(indicador => ({
					id: Number(indicador.indicador?.value),
					metricas: formatPeriod(indicador?.periodos || []),
				})),
			put: indicatorsToUpdate
				? indicatorsToUpdate.map(indicador => ({
						id: Number(indicador.indicador?.value),
						periodos: formatPeriod(indicador?.periodos || []),
				  }))
				: [],
			delete: getPeriodsToDelete(indicatorsToDelete || []),
		};

		editManagementPactMutate(payload);
	};

	const handleEdit = (data: IPactForm) => {
		handleSubmit(data);
	};

	const handleValidate = (values: IPactForm, changed: boolean) => {
		type = 'VALIDATE';
		if (changed) {
			handleSubmit(values);
			if (isEditManagementPactSuccess) {
				validateManagementPactMutate(String(managementPact?.pactoGestao.id));
			}
		}
		validateManagementPactMutate(String(managementPact?.pactoGestao.id));
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Editar Pacto de Gestão" />

			{isManagementPactLoading || isPactuadosLoading || isLoadingIndicadores ? (
				<Box sx={styles?.loadingSpinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				<Box sx={styles.content}>
					<PactForm
						isEditing
						onSubmitForm={handleEdit}
						defaultValues={defaultValues}
						isSubmitLoading={isEditManagementPactLoaging}
						validationDate={managementPact?.pactoGestao.dataValidacao}
						onHandleValidate={handleValidate}
						indicadoresEdit={indicadores}
						isIndicadoresEditLoading={isLoadingIndicadores}
					/>
				</Box>
			)}
		</Flex>
	);
};

export default EditManagementPact;
