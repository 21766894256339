/**
 *
 * ResultEvaluationVotingForm
 *
 */

import React, { useEffect } from 'react';
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	Radio,
	RadioGroup,
	SystemStyleObject,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { NoCaapMemberIcon } from 'assets/icons';
import { ROUTES } from 'config/routes';
import { usePrompt } from 'hooks/useCustomPrompt';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CaapMember, CaapMembers } from 'types/caapMembers';
import { BIMESTERS_ENUM_TO_MONTHS, DTA_PHASES_ENUM, VOTING_ENUM } from 'utils/constants';
import * as yup from 'yup';

export interface IVotingRecord extends Pick<CaapMembers, 'id' | 'dataAtualizacao' | 'representacao'> {
	idVoto?: number;
	titular: Partial<CaapMember>;
	suplente: Partial<CaapMember>;
	voto?: string;
	idRepresentacao?: number;
}

export interface IResultEvaluationVotingForm {
	pactuado?: string;
	nomeIndicador?: string;
	ano?: string;
	bimestre?: string;
	faseEnum?: string;
	registrosVotacao?: IVotingRecord[];
}

interface ResultEvaluationVotingFormProps {
	defaultValues?: IResultEvaluationVotingForm;
	onSubmit: (values: IVotingRecord[]) => void;
	isDisabled: boolean;
}

const ResultEvaluationVotingForm = ({ defaultValues, onSubmit, isDisabled }: ResultEvaluationVotingFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			flexDir: 'column',
			p: '2rem 1.5rem',
			w: '100%',
		},
		headerContainer: {
			flexDir: 'column',
			my: '0.5rem',
			mb: '2.125rem',
		},
		headerContent: {
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		headerTextContent: {
			w: '100%',
			gap: '1.5rem',
			alignItems: 'center',
			flexWrap: 'wrap',
		},
		headerText: {
			fontSize: '1.25rem',
			fontWeight: 'semibold',
		},
		textSmall: {
			fontSize: '0.875rem',
			fontWeight: 'medium',
			color: '#0A77D2',
		},
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
		faseText: {
			backgroundColor: '#3498DB33',
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#000000',
			borderRadius: '16px',
			h: '2.063rem',
			px: '0.5rem',
			pt: '0.25rem',
		},
		buttonContainer: {
			mt: '2.5rem',
			gap: {
				base: '1.5rem',
				lg: '2.75rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		button: {
			px: '5.375rem',
		},
		content: {
			flexDir: 'column',
		},
		title: {
			fontWeight: 'semibold',
			fontSize: '1.25rem',
		},
		membrosContainer: {
			flexDir: 'column',
			mt: '2rem',
			gap: '2.5rem',
			w: '100%',
		},
		membrosContent: {
			w: '100%',
			backgroundColor: '#F4F6F8',
			p: '1.5rem',
			flexDir: 'column',
			borderRadius: '4px',
		},
		membrosText: {
			fontWeight: 'medium',
			fontSize: '1.25rem',
			mb: '1.5rem',
		},
		radioContainer: {
			gap: '5rem',
			flexWrap: 'wrap',
		},
		noCaapMembersContainer: {
			maxW: '100%',
			w: '100%',
			flexDirection: 'column',
			gap: '2.2rem',
			alignItems: 'center',
			py: '4rem',
			bgColor: '#F4F6F8',
			my: '3rem',
		},
	};
	const navigate = useNavigate();

	const schema = yup.object().shape({
		registrosVotacao: yup.array().of(
			yup.object({
				representacao: yup.string(),
				voto: yup.string().when('representacao', {
					is: '',
					then: yup.string(),
					otherwise: yup.string().required('Este campo é obrigatório.'),
				}),
			}),
		),
	});

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors, isDirty },
	} = useForm<IResultEvaluationVotingForm>({
		resolver: yupResolver(schema),
		defaultValues,
	});

	usePrompt('Alterações pendentes! Deseja sair da página mesmo assim?', isDirty);

	const { fields } = useFieldArray({
		control,
		name: 'registrosVotacao',
	});

	const radioArray = [
		{
			label: 'Inabilitado',
			variant: 'medium',
			value: VOTING_ENUM.INABILITADO,
			dataTestId: (index: number) => `radio--votacao.${index}.inabilitado`,
		},
		{
			label: 'Ausentes',
			variant: 'medium',
			value: VOTING_ENUM.AUSENTE,
			dataTestId: (index: number) => `radio--votacao.${index}.ausentes`,
		},
		{
			label: 'Favorável',
			variant: 'medium',
			value: VOTING_ENUM.FAVORAVEL,
			dataTestId: (index: number) => `radio--votacao.${index}.favoravel`,
		},
		{
			label: 'Contrário',
			variant: 'medium',
			value: VOTING_ENUM.CONTRARIO,
			dataTestId: (index: number) => `radio--votacao.${index}.contrario`,
		},
		{
			label: 'Abstenção',
			variant: 'medium',
			value: VOTING_ENUM.ABSTENCAO,
			dataTestId: (index: number) => `radio--votacao.${index}.abstencao`,
		},
	];

	const onHandleSubmit = (values: IResultEvaluationVotingForm) => {
		onSubmit(values.registrosVotacao!);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => reset(defaultValues), [defaultValues]);

	return (
		<Flex sx={styles.container} as="form" onSubmit={handleSubmit(onHandleSubmit)}>
			<Text sx={styles.textSmall} data-testid="text--pactuado">
				{defaultValues?.pactuado}
			</Text>
			<Flex sx={styles.headerContainer}>
				<Flex sx={styles.headerContent}>
					<Flex sx={styles.headerTextContent}>
						<Text sx={styles.headerText} data-testid="text--indicador">
							{`${defaultValues?.nomeIndicador} — ${defaultValues?.ano} / ${
								BIMESTERS_ENUM_TO_MONTHS[defaultValues?.bimestre as keyof typeof BIMESTERS_ENUM_TO_MONTHS]
							}`}
						</Text>

						<Tooltip label="Nome da Fase" sx={styles.tooltip} placement="top-start">
							<Text sx={styles.faseText} data-testid="text--fase">
								{DTA_PHASES_ENUM[defaultValues?.faseEnum as keyof typeof DTA_PHASES_ENUM]}
							</Text>
						</Tooltip>
					</Flex>
				</Flex>
			</Flex>

			<Flex sx={styles.content}>
				<Text sx={styles.title}>Votação CAAP (Parte 1)</Text>

				{defaultValues?.registrosVotacao?.length ? (
					<Flex sx={styles.membrosContainer}>
						{fields.map((item, index) => (
							<Flex key={index} sx={styles.membrosContent}>
								<Text sx={styles.membrosText}>{item.representacao}</Text>

								<Controller
									name={`registrosVotacao.${index}.voto`}
									control={control}
									render={({ field: { onChange, value } }) => (
										<FormControl isInvalid={!!errors?.registrosVotacao?.[index]?.voto?.message}>
											<RadioGroup onChange={onChange} value={value}>
												<Flex sx={styles.radioContainer}>
													{radioArray.map((radio, radioIndex) => (
														<Radio
															key={`${radio.value}-${radioIndex}`}
															variant={radio.variant}
															value={radio.value}
															data-testid={radio.dataTestId(index)}
															isReadOnly={isDisabled}
														>
															{radio.label}
														</Radio>
													))}
												</Flex>
											</RadioGroup>
											<FormErrorMessage data-testid="text--error">
												{errors?.registrosVotacao?.[index]?.voto?.message}
											</FormErrorMessage>
										</FormControl>
									)}
								/>
							</Flex>
						))}
					</Flex>
				) : (
					<Flex sx={styles.noCaapMembersContainer}>
						<NoCaapMemberIcon />
						<Text fontSize={'1.5rem'} fontWeight={'600'}>
							Nenhum participante cadastrado para votação até o momento.
						</Text>
					</Flex>
				)}
			</Flex>

			<Flex sx={styles.buttonContainer}>
				<Button type="submit" sx={styles.button} data-testid="button--submit" isDisabled={isDisabled}>
					Finalizar Votação
				</Button>
				<Button
					variant="secondary"
					sx={styles.button}
					data-testid="button--cancel"
					isDisabled={isDisabled}
					onClick={() => navigate(ROUTES.results)}
				>
					Cancelar
				</Button>
			</Flex>
		</Flex>
	);
};

export default ResultEvaluationVotingForm;
