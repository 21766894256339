import { API_DEFAULT_ERROR } from './constants';

export interface ResponseErrors {
	msg: string;
	message: string;
	code: number;
}

export const parseErrors = (errors?: ResponseErrors, defaultMessage = API_DEFAULT_ERROR) => {
	if (!errors) return defaultMessage;

	return Object.values(errors)?.join('; ');
};
