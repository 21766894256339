import cleanDeep from 'clean-deep';

type ReturnType<T extends string> = {
	[k in T]?: string;
};

export const parseUrlParams = <T extends string>(params: string[], searchParams: URLSearchParams) => {
	const paramsFromUrl = Object.fromEntries(
		params.map(item => {
			const values = searchParams.getAll(item);

			if (!values) return [item, ''];

			if (values.length > 1) return [item, searchParams.getAll(item)];

			return [item, searchParams.getAll(item).at(0)];
		}),
	);

	const cleanedParams = cleanDeep(paramsFromUrl);
	return cleanedParams as ReturnType<T>;
};
