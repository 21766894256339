import { MB_IN_KB } from './constants';
import { formatFromStringToNumber } from './formatBimesters';

const TenMbInKb = 10240;

export const isFileSizeValid = (fileSize: number, maxValue = TenMbInKb) => {
	const fileSizeKiloBytes = formatFromStringToNumber(fileSize) / MB_IN_KB;

	return maxValue >= fileSizeKiloBytes;
};
