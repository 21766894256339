import { getAxios } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import { IPaginationParams } from 'types/pagination';
import {
	ICalculateResultRequest,
	ICalculateResultResponse,
	IContinuousImprovementRequest,
	IDownloadFileRequest,
	IRequestAllResultConverted,
	IRequestResultConverted,
	IResponseAllResultsConverted,
	IResponseContinuousImprovement,
	IResponseContinuousImprovementRequest,
	IResponseResult,
	IResponseResultConverted,
	IResultEvaluation,
	IResultEvaluationDocument,
	IResultEvaluationGraphic,
	IResultEvaluationHistory,
	IResultEvaluationRequest,
	IResultEvaluationResponse,
	IResultEvaluationSummary,
	IUploadContinuousImprovement,
} from 'types/resultEvaluation';

export interface IResultsParams extends IPaginationParams {
	etapa?: string;
	anoInicio?: string;
	anoFim?: string;
	bimestre?: string;
	ano?: string;
	idPacutados?: string;
	idIndicadores?: string;
}

export type IResultsParamsKeys = keyof IResultsParams;

// TELA DE RESULTADOS
export const getResults = async (params?: IResultsParams): Promise<IResponseResult[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultsList, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

export const getResultsConverted = async (data: IRequestResultConverted): Promise<IResponseResultConverted> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultsListConverted, data);
	return response.data;
};

export const getAllResultsConverted = async (
	params: IRequestAllResultConverted,
): Promise<IResponseAllResultsConverted> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.allResultsListConverted, {
		params: {
			...params,
			page: params?.page! - 1,
		},
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

// TELA DE AVALIAÇÃO DE RESULTADOS
export const getResultEvaluationSummary = async (id: string): Promise<IResultEvaluationSummary> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationSummary(id));
	return response.data;
};

export const getResultEvaluation = async (id: string): Promise<IResultEvaluationResponse[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationList(id));
	return response.data;
};

export const getResultEvaluationHistory = async (id: string): Promise<IResultEvaluationHistory[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationHistory(id));
	return response.data;
};

export const getResultEvaluationGraphic = async (id: string): Promise<IResultEvaluationGraphic[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationGraphic(id));

	return response.data;
};

export const downloadFiles = async (params: IDownloadFileRequest): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationDownloadFile, {
		params: { path: params.path },
		responseType: 'blob',
	});
	return response.data;
};

export const uploadContinuousImprovement = async (id: string, data: IUploadContinuousImprovement): Promise<void> => {
	let form = new FormData();

	form.append('arquivo', data.arquivo);

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadContinuousImprovement(id), form, {
		params: { tipoArquivo: data.tipoArquivo },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const getContinuousImprovementArchives = async (id: string): Promise<IResponseContinuousImprovement[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationListContinuousImprovement(id));
	return response.data;
};

export const deleteContinuousImprovementArchives = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteContinuousImprovement(id));
	return response.data;
};

export const getContinuousImprovementRequest = async (id: string): Promise<IResponseContinuousImprovementRequest> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationListContinuousImprovementRequest(id));
	return response.data;
};

export const createContinuousImprovementRequest = async (
	id: string,
	data: IContinuousImprovementRequest,
): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateContinuousImprovementRequest(id), data);
	return response.data;
};

export const updateContinuousImprovementRequest = async (
	id: string,
	data: IContinuousImprovementRequest,
): Promise<void> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateContinuousImprovementRequest(id), data);
	return response.data;
};

// CALCULO DO RESULTADO
export const calculateResultEvaluation = async (params: ICalculateResultRequest): Promise<ICalculateResultResponse> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationCalc, { params });
	return response.data;
};

// LANÇAMENTO DE RESULTADOS
export const uploadLaunchPhase = async (
	files: File[],
	idIndicadoresPactoGestao: string,
): Promise<IResultEvaluationDocument[]> => {
	let data = new FormData();

	files.forEach(arquivo => {
		data.append('arquivos', arquivo);
	});

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadLaunchPhase, data, {
		params: { idIndicadoresPactoGestao },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const createLaunchPhase = async (data: IResultEvaluation): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateLaunchPhase, data);
	return response.data;
};

export const updateLaunchPhase = async (data: IResultEvaluation): Promise<void> => {
	const id = String(data?.id);
	delete data.id;

	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateLaunchPhase(id), data);
	return response.data;
};

export const deleteLaunchPhase = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteLaunchPhase(id));
	return response.data;
};

export const getLaunchPhaseNext = async (idIndicadorPactoGestao: string): Promise<IResultEvaluation> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationLaunchPhaseNext, { params: { idIndicadorPactoGestao } });
	return response.data;
};

export const launchPhaseExport = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationLaunchPhaseExport, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};

// VALIDAÇÃO DO RESULTADO
export const uploadValidationPhase = async (
	files: File[],
	idIndicadoresPactoGestao: string,
): Promise<IResultEvaluationDocument[]> => {
	let data = new FormData();

	files.forEach(arquivo => {
		data.append('arquivos', arquivo);
	});

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadValidationPhase, data, {
		params: { idIndicadoresPactoGestao },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const createValidationPhase = async (data: IResultEvaluation): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateValidationPhase, data);
	return response.data;
};

export const updateValidationPhase = async (data: IResultEvaluation): Promise<void> => {
	const id = String(data?.id);
	delete data.id;

	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateValidationPhase(id), data);
	return response.data;
};

export const deleteValidationPhase = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteValidationPhase(id));
	return response.data;
};

export const getValidationPhaseNext = async (idIndicadorPactoGestao: string): Promise<IResultEvaluation> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationValidationPhaseNext, { params: { idIndicadorPactoGestao } });
	return response.data;
};

export const validationPhaseExport = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationValidationPhaseExport, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};

// RECORRER 1ª INSTÂNCIA
export const uploadAppealFirstInstancePhase = async (
	files: File[],
	idIndicadoresPactoGestao: string,
): Promise<IResultEvaluationDocument[]> => {
	let data = new FormData();

	files.forEach(arquivo => {
		data.append('arquivos', arquivo);
	});

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadAppealFirstInstancePhase, data, {
		params: { idIndicadoresPactoGestao },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const createAppealFirstInstancePhase = async (data: IResultEvaluation): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateAppealFirstInstancePhase, data);
	return response.data;
};

export const updateAppealFirstInstancePhase = async (data: IResultEvaluation): Promise<void> => {
	const id = String(data?.id);
	delete data.id;

	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateAppealFirstInstancePhase(id), data);
	return response.data;
};

export const deleteAppealFirstInstancePhase = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteAppealFirstInstancePhase(id));
	return response.data;
};

export const getAppealFirstInstancePhaseNext = async (idIndicadorPactoGestao: string): Promise<IResultEvaluation> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationAppealFirstInstancePhaseNext, {
		params: { idIndicadorPactoGestao },
	});
	return response.data;
};

export const appealFirstInstancePhaseExport = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationAppealFirstInstancePhaseExport, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};

// DECISÃO 1ª INSTÂNCIA
export const uploadDecisionFirstInstancePhase = async (
	files: File[],
	idIndicadoresPactoGestao: string,
): Promise<IResultEvaluationDocument[]> => {
	let data = new FormData();

	files.forEach(arquivo => {
		data.append('arquivos', arquivo);
	});

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadDecisionFirstInstancePhase, data, {
		params: { idIndicadoresPactoGestao },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const createDecisionFirstInstancePhase = async (data: IResultEvaluation): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateDecisionFirstInstancePhase, data);
	return response.data;
};

export const updateDecisionFirstInstancePhase = async (data: IResultEvaluation): Promise<void> => {
	const id = String(data?.id);
	delete data.id;

	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateDecisionFirstInstancePhase(id), data);
	return response.data;
};

export const deleteDecisionFirstInstancePhase = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteDecisionFirstInstancePhase(id));
	return response.data;
};

export const getDecisionFirstInstancePhaseNext = async (idIndicadorPactoGestao: string): Promise<IResultEvaluation> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationDecisionFirstInstancePhaseNext, {
		params: { idIndicadorPactoGestao },
	});
	return response.data;
};

export const decisionFirstInstancePhaseExport = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationDecisionFirstInstancePhaseExport, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};

// RECORRER 2ª INSTÂNCIA
export const uploadAppealSecondInstancePhase = async (
	files: File[],
	idIndicadoresPactoGestao: string,
): Promise<IResultEvaluationDocument[]> => {
	let data = new FormData();

	files.forEach(arquivo => {
		data.append('arquivos', arquivo);
	});

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadAppealSecondInstancePhase, data, {
		params: { idIndicadoresPactoGestao },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const createAppealSecondInstancePhase = async (data: IResultEvaluation): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateAppealSecondInstancePhase, data);
	return response.data;
};

export const updateAppealSecondInstancePhase = async (data: IResultEvaluation): Promise<void> => {
	const id = String(data?.id);
	delete data.id;

	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateAppealSecondInstancePhase(id), data);
	return response.data;
};

export const deleteAppealSecondInstancePhase = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteAppealSecondInstancePhase(id));
	return response.data;
};

export const getAppealSecondInstancePhaseNext = async (idIndicadorPactoGestao: string): Promise<IResultEvaluation> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationAppealSecondInstancePhaseNext, {
		params: { idIndicadorPactoGestao },
	});
	return response.data;
};

export const appealSecondInstancePhaseExport = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationAppealSecondInstancePhaseExport, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};

// CONTRARRAZOES 2ª INSTÂNCIA
export const uploadCounterReasonsPhase = async (
	files: File[],
	idIndicadoresPactoGestao: string,
): Promise<IResultEvaluationDocument[]> => {
	let data = new FormData();

	files.forEach(arquivo => {
		data.append('arquivos', arquivo);
	});

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadCounterReasonsPhase, data, {
		params: { idIndicadoresPactoGestao },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const createCounterReasonsPhase = async (data: IResultEvaluation): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateCounterReasonsPhase, data);
	return response.data;
};

export const updateCounterReasonsPhase = async (data: IResultEvaluation): Promise<void> => {
	const id = String(data?.id);
	delete data.id;

	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateCounterReasonsPhase(id), data);
	return response.data;
};

export const deleteCounterReasonsPhase = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteCounterReasonsPhase(id));
	return response.data;
};

export const getCounterReasonsPhaseNext = async (idIndicadorPactoGestao: string): Promise<IResultEvaluation> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.resultEvaluationCounterReasonsPhaseNext, {
		params: { idIndicadorPactoGestao },
	});
	return response.data;
};

export const counterReasonsPhaseExport = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationCounterReasonsPhaseExport, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};

// DECISÃO CAAP
export const uploadCaapDecisionPhase = async (
	files: File[],
	idIndicadoresPactoGestao: string,
): Promise<IResultEvaluationDocument[]> => {
	let data = new FormData();

	files.forEach(arquivo => {
		data.append('arquivos', arquivo);
	});

	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationUploadCaapDecisionPhase, data, {
		params: { idIndicadoresPactoGestao },
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};

export const createCaapDecisionPhase = async (data: IResultEvaluationRequest): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.resultEvaluationCreateCaapDecisionPhase, data);
	return response.data;
};

export const updateCaapDecisionPhase = async (data: IResultEvaluationRequest): Promise<void> => {
	const id = String(data?.id);
	delete data.id;

	const api = getAxios();
	const response = await api.put(ENDPOINTS.resultEvaluationUpdateCaapDecisionPhase(id), data);
	return response.data;
};

export const deleteCaapDecisionPhase = async (id: string): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.resultEvaluationDeleteCaapDecisionPhase(id));
	return response.data;
};

export const caapDecisionPhaseExport = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationCaapDecisionPhaseExport, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};

export const caapDecisionPhaseExportVote = async (idAvaliacaoResultado: string): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.resultEvaluationCaapDecisionPhaseExportVote, {
		params: { idAvaliacaoResultado },
		responseType: 'blob',
	});
	return response.data;
};
