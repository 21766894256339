/**
 *
 * PenaltyList
 *
 */

import { useMemo, useState } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Button, Flex, Spinner, Stack, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import DeletePenaltyModal, { DeletePenaltyModalForm } from 'app/components/DeletePenaltyModal';
import PageWrapper from 'app/components/PageWrapper';
import PenaltyCard from 'app/components/PenaltyCard';
import PenaltyFilter, { paramsKeys } from 'app/components/PenaltyFilter';
import { RegisterIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IPenaltyDeleteRequest, deletePenalty, getPenaltyData } from 'services/http/penalty';
import { IResponseErrors } from 'types/httpError';
import { IPenaltyDataResponse } from 'types/penalty';
import { API_DEFAULT_ERROR, USER_ROLES } from 'utils/constants';
import { parseUrlParams } from 'utils/parseUlrParams';

const PenaltyList = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		button: {
			px: '1rem',
		},
		buttonContainer: {
			gap: '1rem',
			justifyContent: 'flex-end',
			mb: '1rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		spinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		content: {
			gap: '2.5rem',
			mt: '2rem',
		},
		viewAudit: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '2rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const [searchParams] = useSearchParams();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [deleteId, setDeleteId] = useState<string>('');
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const secondStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);
	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const { queryValues } = useMemo(() => {
		const paramsKeys: paramsKeys[] = ['etapa', 'bimestre', 'fase', 'idPactuadoExterno', 'anoInicio', 'anoFim'];

		const params = parseUrlParams<paramsKeys>(paramsKeys, searchParams);

		const queryValues = {
			etapa: params.etapa,
			anoInicio: params.anoInicio,
			anoFim: params.anoFim,
			bimestre: params.bimestre,
			fase: params.fase,
			idPactuadoExterno: params.idPactuadoExterno,
		};

		return { queryValues };
	}, [searchParams]);

	const {
		data: penaltiesData,
		isLoading: isPenaltiesDataLoading,
		refetch: refetchPenaltiesData,
	} = useQuery<IPenaltyDataResponse[], AxiosError<IResponseErrors>>(
		['penalties-data', queryValues],
		() => getPenaltyData(queryValues),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { mutate: deletePenaltyMutate } = useMutation<void, AxiosError<IResponseErrors>, IPenaltyDeleteRequest>(
		data => deletePenalty({ ...data }),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'Uma penalidade foi excluída.',
				});
				onClose();
				refetchPenaltiesData();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const handleOnDelete = ({ justificativaExclusao }: DeletePenaltyModalForm) => {
		deletePenaltyMutate({
			id: deleteId,
			justificativaExclusao,
		});
	};

	const handleOpenDelete = (id: string) => {
		onOpen();
		setDeleteId(id);
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Penalização" goBackRoute={ROUTES.home} />
			<Flex sx={styles.buttonContainer}>
				{canViewAuditHistory && (
					<Button
						variant="unstyled"
						leftIcon={<RepeatClockIcon />}
						sx={styles.buttonShowModalAudit}
						onClick={onOpenAuditModal}
					>
						Histórico de Alterações
					</Button>
				)}
				<Button
					leftIcon={<RegisterIcon color="white" />}
					sx={styles.button}
					onClick={() => navigate(ROUTES.results)}
					data-testid="button--results"
				>
					Tela de Resultados
				</Button>
				{secondStepPermission && (
					<Button
						variant="secondary"
						leftIcon={<RegisterIcon color="#3EA2A2" />}
						sx={styles.button}
						color="green.550"
						onClick={() => navigate(ROUTES.createPenalty)}
						data-testid="button--createPenalty"
					>
						Atribuir Penalidade
					</Button>
				)}
			</Flex>
			<PenaltyFilter isListing={false} />

			<Stack sx={styles.content}>
				{isPenaltiesDataLoading ? (
					<Flex sx={styles.spinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Flex>
				) : (
					penaltiesData?.map(item => (
						<PenaltyCard
							key={item.idPenalizacao}
							data={item}
							onDelete={() => handleOpenDelete(String(item.idPenalizacao!))}
							onEdit={() => navigate(ROUTES.editPenalty(String(item.idPenalizacao!)))}
						/>
					))
				)}
			</Stack>

			<DeletePenaltyModal isOpen={isOpen} onClose={onClose} onSubmit={handleOnDelete} />
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</Flex>
	);
};

export default PenaltyList;
