/**
 *
 * PerformanceRangeCreate
 *
 */

import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import PerformanceRangeForm from 'app/components/PerformanceRangeForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { createPerformanceRange } from 'services/http/performanceRange';
import { IPerformanceRange } from 'types/performanceRange';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { formatStringPorcentageToNumber } from 'utils/formatStringPorcentageToNumber';
import { ResponseErrors } from 'utils/parseErrors';

const PerformanceRangeCreate = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',

			flexDir: 'column',
		},

		content: {
			mt: '3.75rem',

			mb: '5.5rem',
		},
	};

	const { addToast } = useCustomToast();

	const navigate = useNavigate();

	const { mutate: createPerformanceRangeMutate, isLoading: createPerformanceRangeLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IPerformanceRange
	>(data => createPerformanceRange(data), {
		onSuccess: () => {
			navigate(ROUTES.performanceRange);
			addToast({ type: 'success', description: 'A faixa de desempenho foi criada com sucesso!', title: 'Sucesso!' });
		},

		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const handleSubmit = (data: IPerformanceRange) => {
		const parsedData = {
			nome: data.nome,
			habilitado: true,
			faixasDesempenho: data.faixasDesempenho?.map(item => {
				const resultadoZerado = 0;
				return {
					porcentagemInicial: formatStringPorcentageToNumber(item.porcentagemInicial),
					porcentagemFinal: formatStringPorcentageToNumber(item.porcentagemFinal),
					resultadoDesempenho: item.resultadoProporcional
						? resultadoZerado
						: formatStringPorcentageToNumber(item.resultadoDesempenho as string),
					resultadoProporcional: item.resultadoProporcional,
				};
			}),
		};
		createPerformanceRangeMutate(parsedData);
	};

	return (
		<>
			<Flex sx={styles.container}>
				<PageWrapper title="Cadastrar Faixa de Desempenho" goBackRoute={ROUTES.performanceRange} />

				<Box sx={styles.content}>
					<PerformanceRangeForm onSubmitForm={handleSubmit} isLoading={createPerformanceRangeLoading} />
				</Box>
			</Flex>
		</>
	);
};

export default PerformanceRangeCreate;
