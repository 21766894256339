/**
 *
 * InputWrapper
 *
 */

import { ReactNode } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import { FormControl, FormLabel, Tooltip, FormErrorMessage, Box, Text, SystemStyleObject } from '@chakra-ui/react';
import { TableEditIconEdited } from 'assets/icons';

interface InputWrapperProps {
	label?: string;
	errorMessage?: string;
	dataTestId?: string;
	labelVariant?: string;
	required?: boolean;
	isEditing?: boolean;
	tooltipInfo?: string;
	children: ReactNode;
}

const InputWrapper = ({
	children,
	dataTestId,
	errorMessage,
	isEditing,
	label,
	labelVariant,
	required,
	tooltipInfo,
}: InputWrapperProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			flex: 1,
		},
		labelBox: {
			H: '24px',
			maxH: '24px',
		},
		editingIcon: {
			marginLeft: '0.625rem',
		},
		tooltip: {
			pb: '1rem',
			background: 'white',
			bg: 'white',
			color: 'black',
			borderWidth: '1px',
			borderColor: 'gray.300',
			borderRadius: '4px',
			fontSize: '0.8rem',
		},
		tooltipIcon: {
			boxSize: '0.8rem',
			ml: '0.5rem',
			color: 'gray.300',
			svg: {
				path: {
					fill: 'blue.700',
				},
				rect: {
					fill: 'blue.700',
				},
			},
		},
	};
	return (
		<FormControl sx={styles.container} isInvalid={!!errorMessage}>
			{label && (
				<FormLabel htmlFor={dataTestId} variant={labelVariant}>
					<Box sx={styles.labelBox}>
						{label} {required && <Text as="span">*</Text>}
					</Box>
					{isEditing && (
						<Box sx={styles?.editingIcon}>
							<TableEditIconEdited />
						</Box>
					)}
					{tooltipInfo && (
						<Tooltip bg="white" placement="top-start" hasArrow sx={styles?.tooltip} label={tooltipInfo}>
							<InfoIcon sx={styles?.tooltipIcon} />
						</Tooltip>
					)}
				</FormLabel>
			)}
			{children}
			{errorMessage && <FormErrorMessage data-testid="text--error">{errorMessage}</FormErrorMessage>}
		</FormControl>
	);
};

export default InputWrapper;
