/**
 *
 * ListIndicators
 *
 */

import { useMemo } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Spinner, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AccordionIndicators from 'app/components/AccordionIndicators';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import IndicatorsFilter, { IndicatorsFilteKeys } from 'app/components/IndicatorsFilter';
import PageWrapper from 'app/components/PageWrapper';
import Pagination from 'app/components/Pagination';
import { RegisterIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IIndicatorParams, deleteIndicator, getIndicatorsList } from 'services/http/indicators';
import { IResponseIndicators } from 'types/indicators';
import { IStrategicObjective } from 'types/strategicObjetive';
import {
	API_DEFAULT_ERROR,
	FIRST_PAGE,
	POLARITIES,
	RESPONSAVELLANCAMENTO,
	UNIDADEMEDIDA,
	USER_ROLES,
} from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parseUrlParams } from 'utils/parseUlrParams';

const ListIndicators = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		button: {
			alignSelf: 'flex-end',
			px: '1rem',
			mb: '1rem',
		},
		addIndicators: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '1rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		content: {
			flexDir: 'column',
			gap: '1rem',
			mt: '1.5rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		pagination: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		addNewIndicator: {
			justifyContent: 'end',
			mb: '1rem',
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const [searchParams, setParams] = useSearchParams();

	const { params, queryParams } = useMemo(() => {
		const params = parseUrlParams<IndicatorsFilteKeys>(
			['nomeIndicador', 'idObjetivo', 'ano', 'etapaEnum', 'nomePactuado', 'areaTecnica', 'page', 'size'],
			searchParams,
		);

		const queryParams: IIndicatorParams = cleanDeep({
			ano: [params?.ano || ''],
			etapa: params?.etapaEnum,
			idObjetivo: params?.idObjetivo,
			nomeIndicador: params?.nomeIndicador,
			nomeAreaTecnica: params.areaTecnica,
			nomePactuado: params.nomePactuado,
			sort: params?.sort as keyof IStrategicObjective,
			page: Number(params?.page || FIRST_PAGE),
		});

		return { params, queryParams };
	}, [searchParams]);

	const canCreateIndicatorFirstStep = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA]);
	const canCreateIndicatoSecondStep = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
		USER_ROLES.REPRESENTANTE,
	]);

	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const {
		data: indicators,
		isLoading: isIndicatorsLoading,
		refetch: refetchIndicatorsList,
	} = useQuery<IResponseIndicators, AxiosError<ResponseErrors, IIndicatorParams>>(
		['indicators', params],
		() => getIndicatorsList(queryParams),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!queryParams.ano,
		},
	);

	const { mutate: deleteIndicatorMutation } = useMutation<void, AxiosError<ResponseErrors>, string>(
		indicatorId => deleteIndicator(indicatorId),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'Um indicador foi excluído.',
				});
				refetchIndicatorsList();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Ocorreu um erro!',
					description: `${response?.data.message}` || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const onPaginate = (page: number) => {
		setParams(
			cleanDeep({
				...params,
				page: String(page),
			}),
		);
	};

	return (
		<>
			<Flex sx={styles.container}>
				<PageWrapper title="Gerir Indicadores" />
				<Flex sx={styles.addIndicators}>
					{canViewAuditHistory && (
						<Button
							variant="unstyled"
							leftIcon={<RepeatClockIcon />}
							sx={styles.buttonShowModalAudit}
							onClick={onOpenAuditModal}
						>
							Histórico de Alterações
						</Button>
					)}
					{canCreateIndicatorFirstStep && (
						<Button
							leftIcon={<RegisterIcon color="white" />}
							sx={styles.button}
							onClick={() => navigate(ROUTES.createIndicators('etapa-1'))}
							data-testid="button--createIndicators-etapa1"
						>
							Cadastrar Indicadores 1ª Etapa
						</Button>
					)}
					{canCreateIndicatoSecondStep && (
						<Button
							leftIcon={<RegisterIcon color="#3EA2A2" />}
							sx={styles.button}
							onClick={() => navigate(ROUTES.createIndicators('etapa-2'))}
							data-testid="button--createIndicators-etapa2"
							variant={'redirect'}
						>
							Cadastrar Indicadores 2ª Etapa
						</Button>
					)}
				</Flex>

				<IndicatorsFilter />

				{isIndicatorsLoading && queryParams.ano ? (
					<Box sx={styles?.loadingSpinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Box>
				) : (
					<Flex sx={styles.content}>
						{indicators?.content.map(item => (
							<AccordionIndicators
								key={item.id}
								indicatorsGoals={{
									...item,
									id: String(item.id),
									ano: String(item.ano),
									metaCumulativa: item.metaCumulativa ? 'Sim' : 'Não',
									polaridade: POLARITIES[item.polaridade as keyof typeof POLARITIES],
									unidadeMedida: UNIDADEMEDIDA[item.unidadeMedida as keyof typeof UNIDADEMEDIDA],
									responsavelLancamento:
										RESPONSAVELLANCAMENTO[item.responsavelLancamento as keyof typeof RESPONSAVELLANCAMENTO],
									objetivoEstrategico: item.objetivoEstrategico.objetivo,
									email: item.email,
									faixaDesempenho: item.conjunto?.nome!,
									metodologiaCalculo: item.metodologiaCalculo,
									nome: item.nome,
									nomeResponsavel: item.nomeResponsavel,
									objetivo: item.objetivo,
									sei: item.sei || '',
									telefone: item.telefone,
									areaTecnica: item.areaTecnica,
									setor: item.setor.descricaoSetor,
									dataValidacao: item.dataValidacao,
								}}
								editIndicatorsGoals={indicatorId => navigate(ROUTES.editIndicators(indicatorId))}
								deleteIndicatorsGoals={value => deleteIndicatorMutation(value)}
							/>
						))}

						<Flex sx={styles.pagination}>
							<Pagination
								currentPage={Number(queryParams?.page) || 0}
								onPaginate={onPaginate}
								pageSize={indicators?.size}
								totalItems={indicators?.totalElements}
							/>
						</Flex>
					</Flex>
				)}
			</Flex>
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</>
	);
};

export default ListIndicators;
