/**
 *
 * ReportMonitoringVersionModal
 *
 */
import {
	Box,
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { PdfIcon, PdfIconWithArrows } from 'assets/icons';
import { InfoIcon } from 'assets/icons';

interface ReportMonitoringVersionModalProps {
	onClose: () => void;
	onCompleteVersion: () => void;
	onReducedVersion: () => void;
	isOpen: boolean;
}

const ReportMonitoringVersionModal = ({
	isOpen,
	onClose,
	onCompleteVersion,
	onReducedVersion,
}: ReportMonitoringVersionModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		content: {
			w: '41.56rem',
			maxW: 'none',
			borderRadius: '0.75rem',
		},
		header: {
			display: 'flex',
			justifyContent: 'left',
			py: '0.75rem',
			position: 'relative',
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			mb: '0.5rem',
		},
		h1: {
			fontSize: '1.5rem',
			fontWeight: '700',
		},
		h2: {
			textAlign: 'center',
			w: '13rem',
		},
		fileContainer: {
			mt: '0.5rem',
			display: 'flex',
			gap: '1.12rem',
		},
		fileBox: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			border: '1px solid #E2E2E2',
			borderRadius: '0.25rem',
			paddingX: '2rem',
			paddingY: '1rem',
			m: 0,
		},
		button: {
			w: '10.5rem',
			h: '2.75rem',
			mt: '0.81rem',
		},
		closeButtonBox: {
			right: '0.5rem',
			position: 'absolute',
		},
	};

	const optionsArray = [
		{
			icon: () => (
				<Box my={'1rem'}>
					<PdfIcon width="48" height="48" />
				</Box>
			),
			label: 'Resultados Consolidados Relatório Completo',
			buttonLabel: 'Versão Completa',
			buttonAction: onCompleteVersion,
		},
		{
			icon: () => (
				<Box my={'0.5rem'}>
					<PdfIconWithArrows />
				</Box>
			),
			label: 'Resultados Consolidados Relatório Reduzido',
			buttonLabel: 'Versão Reduzida',
			buttonAction: onReducedVersion,
		},
	];

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent sx={styles.content}>
				<ModalHeader sx={styles.header}>
					<Box sx={styles.closeButtonBox}>
						<ModalCloseButton size="1.5rem" color={'#C6C6C6'} />
					</Box>
				</ModalHeader>
				<ModalBody sx={styles.body}>
					<Box mb={'1rem'}>
						<InfoIcon width="73" height="73" />
					</Box>
					<Text sx={styles.h1}>Escolha uma versão para exportar</Text>
					<HStack sx={styles.fileContainer}>
						{optionsArray.map(item => (
							<Box sx={styles.fileBox} key={item.label}>
								{item.icon()}
								<Text sx={styles.h2}>{item.label}</Text>
								<Button sx={styles.button} onClick={item.buttonAction}>
									{item.buttonLabel}
								</Button>
							</Box>
						))}
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ReportMonitoringVersionModal;
