/**
 *
 * PerformanceRangeFilter
 *
 */

import InputText from '../InputText';
import { Box, SystemStyleObject, Button, Checkbox } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface IPerformanceRangeFilterForm {
	nomeConjunto?: string;
	mostrarFaixasInativas: boolean;
}

export type PerformanceListParamsKeys = keyof IPerformanceRangeFilterForm;

interface PerformanceRangeFilterProps {
	isListing?: boolean;
	defaultValues?: IPerformanceRangeFilterForm;
	onSubmitForm: (values: IPerformanceRangeFilterForm) => void;
}

const PerformanceRangeFilter = ({ onSubmitForm, isListing = true, defaultValues }: PerformanceRangeFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
		},
		content: {
			width: '100%',
		},
		fields: {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			gap: {
				base: '2rem',
				md: '2rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
		},

		setName: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '39.563rem',
			},
		},
		buttonSubmit: {
			width: '100%',
		},
		button: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '15rem' },
		},
		checkbox: {
			mt: '2rem',
			color: 'black',
		},
	};

	const schema = yup.object().shape({
		nomeConjunto: yup.string(),
		faixasInativas: yup.boolean().nullable(),
	});

	const {
		handleSubmit,
		formState: { errors },
		register,
		control,
	} = useForm<IPerformanceRangeFilterForm>({
		resolver: !isListing ? undefined : yupResolver(schema),
		defaultValues: defaultValues,
	});

	const onSubmit = (values: IPerformanceRangeFilterForm) => onSubmitForm?.(values);
	return (
		<>
			<Box sx={styles.container}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
					<Box sx={styles.fields}>
						<Box sx={styles.setName}>
							<InputText
								register={register}
								label="Nome do conjunto"
								name="nomeConjunto"
								maxLength={100}
								placeholder="Nome do conjunto"
								errorMessage={errors?.nomeConjunto?.message}
								data-testid="input--setName"
								type="text"
							/>
						</Box>

						<Box sx={styles.checkbox}>
							<Controller
								control={control}
								name={'mostrarFaixasInativas'}
								defaultValue={false}
								render={({ field: { onChange, value } }) => (
									<Checkbox
										onChange={onChange}
										sx={styles.checkboxInput}
										isChecked={value}
										data-testid="checkbox--checkBoxInactivesTracks"
									>
										Apresentar faixas inativas
									</Checkbox>
								)}
							/>
						</Box>
					</Box>
					<Box sx={styles.buttonSubmit}>
						<Button type="submit" sx={styles.button} data-testid="button--submit">
							Pesquisar
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default PerformanceRangeFilter;
