/**
 *
 * CaapMembersEdit
 *
 */

import { useMemo } from 'react';
import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import CaapMembersForm, { ICaapMembersForm } from 'app/components/CaapMembersForm';
import PageWrapper from 'app/components/PageWrapper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { getCaapMemberById, updateCaapMembers } from 'services/http/caapMember';
import { CaapMembers, IRequestCaap } from 'types/caapMembers';
import { API_DEFAULT_ERROR, STATUS_CAAP } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';

type IParams = {
	id: string;
};

const CaapMembersEdit = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.438rem',
			mb: '3.625rem',
		},
		spinner: {
			mt: '3.7rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { id = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { data: member, isLoading: isMemberLoading } = useQuery<CaapMembers, AxiosError<ResponseErrors>>(
		['member', id],
		() => getCaapMemberById(id),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const defaultValues: ICaapMembersForm = useMemo(() => {
		return {
			membrosDaCaap: [
				{
					...member!,
					representacao: member?.representacao!,
					unidadeSetorTitular: {
						label: `${member?.titular.nomeUnidade} - ${member?.titular.nomeSetor}`,
						value: member?.titular.idSetor!,
						data: {
							idUnidade: Number(member?.titular.idUnidade),
							nomeSetor: member?.titular.nomeSetor!,
							sigla: member?.titular.nomeUnidade!,
						},
					},
					nomeTitular: { label: member?.titular.nome!, value: member?.titular.nome! },
					maspTitular: { label: member?.titular.masp!, value: member?.titular.masp! },
					vinculoTitular: member?.titular.vinculo!,
					cargoTitular: member?.titular.cargo!,
					funcaoTitular: { label: member?.titular.funcao.funcaoDescricao!, value: member?.titular.funcao.id! },
					unidadeSetorSuplente: {
						label: `${member?.suplente.nomeUnidade} - ${member?.suplente.nomeSetor}`,
						value: member?.suplente.idSetor!,
						data: {
							idUnidade: Number(member?.suplente.idUnidade),
							nomeSetor: member?.suplente.nomeSetor!,
							sigla: member?.suplente.nomeUnidade!,
						},
					},
					nomeSuplente: { label: member?.suplente.nome!, value: member?.suplente.nome! },
					maspSuplente: { label: member?.suplente.masp!, value: member?.suplente.masp! },
					vinculoSuplente: member?.suplente.vinculo!,
					cargoSuplente: member?.suplente.cargo!,
					funcaoSuplente: { label: member?.suplente.funcao.funcaoDescricao!, value: member?.suplente.funcao.id! },
				},
			],
		};
	}, [member]);

	const { mutate: updateCaapMembersMutate, isLoading: isUpdateCaapMembersLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestCaap
	>(data => updateCaapMembers(id, data), {
		onSuccess: () => {
			navigate(ROUTES.caapMembers);
		},
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const handleSubmit = (values: ICaapMembersForm) => {
		const item = values.membrosDaCaap.find(item => item);

		const payload: IRequestCaap = {
			representacao: item?.representacao!,
			titular: {
				idUnidade: String(item?.unidadeSetorTitular?.data?.idUnidade),
				nomeUnidade: item?.unidadeSetorTitular?.data?.sigla!,
				idSetor: item?.unidadeSetorTitular?.value as string,
				nomeSetor: item?.unidadeSetorTitular?.data?.nomeSetor!,
				nome: item?.nomeTitular?.label as string,
				masp: item?.maspTitular?.label as string,
				vinculo: item?.vinculoTitular!,
				cargo: item?.cargoTitular!,
				funcao: {
					id: item?.funcaoTitular?.value as string,
					funcaoDescricao: item?.funcaoTitular?.label as string,
				},
				statusCaap: STATUS_CAAP.TITULAR,
			},
			suplente: {
				idUnidade: String(item?.unidadeSetorSuplente?.data?.idUnidade),
				nomeUnidade: item?.unidadeSetorSuplente?.data?.sigla!,
				idSetor: item?.unidadeSetorSuplente?.value as string,
				nomeSetor: item?.unidadeSetorSuplente?.data?.nomeSetor!,
				nome: item?.nomeSuplente?.label as string,
				masp: item?.maspSuplente?.label as string,
				vinculo: item?.vinculoSuplente!,
				cargo: item?.cargoSuplente!,
				funcao: {
					id: item?.funcaoSuplente?.value as string,
					funcaoDescricao: item?.funcaoSuplente?.label as string,
				},
				statusCaap: STATUS_CAAP.SUPLENTE,
			},
		};

		updateCaapMembersMutate(payload);
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Atualização da Composição" goBackRoute={ROUTES.caapMembers} />

			{isMemberLoading ? (
				<Box sx={styles?.spinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				<Box sx={styles.content}>
					<CaapMembersForm
						onSubmit={handleSubmit}
						isEditing
						defaultValues={defaultValues}
						isSubmitLoading={isUpdateCaapMembersLoading}
					/>
				</Box>
			)}
		</Flex>
	);
};

export default CaapMembersEdit;
