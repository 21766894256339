import { ISelectedDate } from '.';
import { SystemStyleObject, Button, Grid } from '@chakra-ui/react';
import { getFormattedMonth } from 'utils/formatDate';

interface MonthsViewProps {
	monthsRange: Date[];
	selectedDate: ISelectedDate;
	onChange: (value: ISelectedDate) => void;
}

const MonthsView = ({ onChange, selectedDate, monthsRange }: MonthsViewProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			gridTemplateColumns: 'repeat(4, 1fr)',
			gap: '0.5rem',
		},
		buttonBase: {
			h: {
				base: '2rem',
				lg: '1.688rem',
			},
			borderRadius: {
				base: '1rem',
				lg: '0.813rem',
			},
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			border: '0.063rem solid transparent',
			lineHeight: '0',
			fontWeight: 'medium',
		},
		buttonCurrent: {
			h: {
				base: '2rem',
				lg: '1.688rem',
			},
			borderRadius: {
				base: '1rem',
				lg: '0.813rem',
			},
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			fontWeight: 'medium',
			lineHeight: '0',
			color: 'blue.700',
			backgroundColor: 'green.100',
		},
	};

	const currentMonth = new Date().getMonth();
	const currentYear = new Date().getFullYear();

	return (
		<Grid sx={styles.container}>
			{monthsRange?.map(item =>
				item.getMonth() === currentMonth && currentYear === selectedDate.year ? (
					<Button
						sx={
							currentMonth === selectedDate.month && selectedDate.isCurrentSelected
								? styles.buttonBase
								: styles.buttonCurrent
						}
						onClick={() => onChange({ ...selectedDate, month: item.getMonth() })}
						key={item.getMonth()}
						data-testid={`button-month-${item.getMonth()}`}
					>
						{getFormattedMonth(item)}
					</Button>
				) : item.getMonth() === selectedDate.month && selectedDate.isCurrentSelected ? (
					<Button
						sx={styles.buttonBase}
						onClick={() => onChange({ ...selectedDate, month: item.getMonth() })}
						key={item.getMonth()}
						data-testid={`button-month-${item.getMonth()}`}
					>
						{getFormattedMonth(item)}
					</Button>
				) : (
					<Button
						variant="secondary"
						sx={styles.buttonBase}
						color="blue.700"
						onClick={() => onChange({ ...selectedDate, month: item.getMonth() })}
						key={item.getMonth()}
						data-testid={`button-month-${item.getMonth()}`}
					>
						{getFormattedMonth(item)}
					</Button>
				),
			)}
		</Grid>
	);
};

export default MonthsView;
