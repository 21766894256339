import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { CaapMembers } from 'types/caapMembers';
import { IPagination } from 'types/pagination';

export interface Pagination {
	page: number;
	size?: number;
}

export const getCaapMembers = async ({ page, size }: Pagination): Promise<IPagination<CaapMembers[]>> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.caapMembersList, {
		params: { page, size },
	});
	return response.data;
};

export const deleteCaapMembers = async (id: string): Promise<void> => {
	const api = getAxios();
	await api.delete(ENDPOINTS.caapMembersDelete(id));
};

export const exportCaapMembers = async (params: Pagination): Promise<Blob> => {
	const api = getAxios();
	const response = await api.get<File>(ENDPOINTS.caappMembersExport, {
		params,
		responseType: 'blob',
	});
	return response.data;
};
