/**
 *
 * NotificationsTable
 *
 */

import { Box, Flex, SystemStyleObject, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { IColumn, IRow } from 'types/table';

export interface NotificationsTableProps {
	columns: IColumn[];
	data: IRow[];
}

const NotificationsTable = ({ columns, data }: NotificationsTableProps) => {
	const styles: Record<string, SystemStyleObject> = {
		dot: {
			h: '0.5rem',
			w: '0.5rem',
			backgroundColor: '#2C6DE6',
			borderRadius: '0.25rem',
		},
		th: {
			backgroundColor: 'black.100',
			fontWeight: '700',
		},
	};

	return (
		<Table variant="notification">
			<Thead>
				<Tr>
					{columns.map((item, index) => (
						<Th key={item.key} data-testid={`coluna-${index}`} colSpan={1}>
							{item?.action ? item?.action : item?.label}
						</Th>
					))}
				</Tr>
			</Thead>
			<Tbody>
				{data.map((data, index) => (
					<Tr key={index}>
						{columns?.map((column, columnIndex) => (
							<Td key={`${index}-${column?.key}`} data-testid={`coluna-${columnIndex}-linha-${index}`}>
								{columnIndex === 1 && !data?.lida && (
									<>
										<Flex gap="0.5rem" float={'left'} paddingInlineEnd={'0.5rem'}>
											<Box sx={styles.dot} />
										</Flex>
									</>
								)}
								{data[column?.key] as string}
							</Td>
						))}
					</Tr>
				))}
			</Tbody>
		</Table>
	);
};

export default NotificationsTable;
