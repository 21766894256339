/**
 *
 * NotificationsFilter
 *
 */

import InputText from '../InputText';
import { Box, Button, SystemStyleObject } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface NotificationsFilterProps {
	onSubmitForm: (values: INotificationsForm) => void;
}

export interface INotificationsForm {
	searchText: string;
}

const NotificationsFilter = ({ onSubmitForm }: NotificationsFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		input: {
			minW: '200px',
			maxW: '632px',
		},
		searchButtonBox: {
			my: '2rem',
			mx: '1.5rem',
		},
		searchButton: {
			minW: '200px',
			maxW: '240px',
		},
		searchContainer: {
			border: '1px solid #E1E2E5',
			bgColor: '#F9FAFC',
			borderRadius: '8px',
		},
		inputBox: {
			mt: '2rem',
			mx: '1.5rem',
		},
	};

	const schema = yup.object().shape({
		searchText: yup.string(),
	});

	const { handleSubmit, register } = useForm<INotificationsForm>({
		resolver: yupResolver(schema),
	});

	const onSubmit = (values: INotificationsForm) => onSubmitForm(values);

	return (
		<Box sx={styles.searchContainer} as="form" onSubmit={handleSubmit(onSubmit)}>
			<Box sx={styles.inputBox}>
				<InputText
					name="searchText"
					label="Pesquisar"
					placeholder={'Validação técnica do recurso'}
					sx={styles.input}
					register={register}
					data-testid="input--search"
				/>
			</Box>
			<Box sx={styles.searchButtonBox}>
				<Button sx={styles.searchButton} type="submit" data-testid="button--submit">
					Pesquisar
				</Button>
			</Box>
		</Box>
	);
};

export default NotificationsFilter;
