/**
 *
 * NotificationsMenu
 *
 */

import { ReactNode, useMemo } from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	Text,
	SystemStyleObject,
	HStack,
	VStack,
	MenuList,
	IconButton,
	MenuButton,
	Menu,
	Spinner,
	useDisclosure,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { CloseXIcon, DoubleCheck } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { format } from 'date-fns';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { INotifications, checkAllNotifications } from 'services/http/notifications';
import {
	API_DEFAULT_ERROR,
	NOTIFICATIONS_MODULES,
	TIMEOUT_TO_REDIRECT_CLOSE_NOTIFICATIONS_MENU,
} from 'utils/constants';
import { formatDateToWeekday } from 'utils/formatDateToWeekDay';
import { ResponseErrors } from 'utils/parseErrors';

interface NotificationsMenuProps {
	label: string;
	children: ReactNode;
	isLoadingNotifications: boolean;
	notifications: INotifications[];
	notReadNotifications: INotifications[];
}

const NotificationsMenu = ({
	children,
	label,
	isLoadingNotifications,
	notifications,
	notReadNotifications,
}: NotificationsMenuProps) => {
	const styles: Record<string, SystemStyleObject> = {
		menuContentContainer: {
			w: '400px',
		},
		headerContainer: {
			width: '100%',
			height: '44px',
			padding: '8px 22px 1px',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		headerText: {
			fontSize: '24px',
		},
		contentHeader: {
			justifyContent: 'space-between',
			padding: '0 22px 1px',
		},
		spinnerContainer: { justifyContent: 'center', paddingBottom: '64px' },
		contentContainer: {
			padding: '0 4px 4px 22px',
		},
		contentSubcontainer: {
			display: 'flex',
			flexDir: 'column',
			w: '100%',
			maxHeight: '475px',
			gap: '10px',
			paddingRight: '7px',
			overflowY: 'auto',
			'::-webkit-scrollbar': {
				width: '6px',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.4rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#497C98',
				borderRadius: '0.4rem',
			},
		},
		footerCointaner: {
			width: '100%',
			display: 'flex',
			justifyContent: 'start',
			paddingBottom: '20px',
			paddingLeft: '22px',
		},
		footerText: {
			color: '#3EA2A2',
		},
		notificationsButtonText: {
			fontSize: '14px',
			padding: '3px 12px',
			border: '1px solid #3EA2A2',
			borderRadius: '1rem',
			_hover: {},
			backgroundColor: 'transparent',
		},
		bodyNewerBox: {
			fontSize: '14px',
			fontWeight: '900',
			borderBottom: '1px solid #000000',
			height: '24px',
			marginTop: '5px',
		},
		modalBody: {
			w: '100%',
			padding: 0,
			paddingRight: '4px',
		},
		checkNotificationsButton: {
			display: 'flex',
			gap: '6px',
			_hover: {},
			backgroundColor: 'transparent',
		},
		notificationsButton: {
			_hover: {},
			backgroundColor: 'transparent',
		},
		iconBox: {
			mt: '4px',
			_hover: {},
			backgroundColor: 'transparent',
		},
		menuListContainer: {
			zIndex: 10,
		},
		menuList: {
			zIndex: 10,
			position: 'absolute',
			top: '0px',
			right: '-50px',
		},
		menuOverlay: {
			zIndex: 10,
			backgroundColor: 'rgba(0,0,0,0.5)',
			position: 'absolute',
			height: '100%',
			width: '100%',
			top: 0,
			left: 0,
		},
		menuButton: {
			_hover: {},
			backgroundColor: 'transparent',
		},
		notificationContainer: {
			display: 'flex',
			flexDir: 'column',
			gap: '7px',
			w: '100%',
			borderBottom: '1px solid #d3d3d3',
			paddingBottom: '12px',
			paddingLeft: '5px',
		},
		boxCointainer: {
			w: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingRight: '1px',
		},
		boxHeading: {
			color: '#3498DB',
			fontSize: '14px',
		},
		ping: {
			width: '8px',
			height: '8px',
			borderRadius: '999999999px',
			backgroundColor: '#2C6DE6',
			marginRight: '5px',
		},
		boxDescription: {
			fontSize: '14px',
			maxW: '200px',
		},
		redirectButton: {
			borderRadius: '16px',
			backgroundColor: '#3EA2A2',
			fontSize: '12px',
			width: '75px',
			height: '27px',
		},
		boxDate: {
			fontSize: '12px',
		},
		footerCard: {
			w: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingRight: '1px',
			paddingTop: '4px',
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const { onClose, isOpen, onOpen } = useDisclosure();

	const { mutate: checkAllNotificationsMutate } = useMutation<void, AxiosError<ResponseErrors>, void>(
		() => checkAllNotifications(),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const notificationsList = useMemo(
		() =>
			notifications?.map((item, index) => (
				<Flex sx={styles.notificationContainer} key={index}>
					<Flex sx={styles.boxCointainer}>
						<Heading sx={styles.boxHeading} data-testid={`title-${index}`}>
							{item?.moduloEnum?.titulo}
						</Heading>
						{!item?.lida && <Box sx={styles.ping} />}
					</Flex>
					<Flex sx={styles.boxCointainer}>
						<Text sx={styles.boxDescription} data-testid={`description-${index}`}>
							{item?.descricao}
						</Text>
						<Button
							data-testid={`confer-${index}`}
							sx={styles.redirectButton}
							onClick={() => changeRoute(NOTIFICATIONS_MODULES?.[item?.moduloEnum?.name!]?.path)}
						>
							Conferir
						</Button>
					</Flex>
					<Flex sx={styles.footerCard}>
						<Text sx={styles.boxDate} data-testid={`dateText-${index}`}>
							{formatDateToWeekday(item?.data)}
						</Text>
						<Text sx={styles.boxDate} data-testid={`dateLiteral-${index}`}>
							{format(new Date(item?.data), 'dd/MM/yyyy')}
						</Text>
					</Flex>
				</Flex>
			)),
		[notifications],
	);

	const changeRoute = async (route: string) => {
		onClose();
		setTimeout(() => navigate(route), TIMEOUT_TO_REDIRECT_CLOSE_NOTIFICATIONS_MENU);
	};

	return (
		<Menu isOpen={isOpen} placement="bottom-start">
			<MenuButton
				data-testid="notification-btn"
				as={IconButton}
				variant={'unstyled'}
				sx={styles.menuButton}
				onClick={onOpen}
				aria-label={label}
			>
				{children}
			</MenuButton>
			{isOpen && <Box sx={styles.menuOverlay} />}
			<Flex sx={styles.menuListContainer}>
				<MenuList sx={styles.menuList}>
					<VStack sx={styles.menuContentContainer}>
						<Flex sx={styles.headerContainer}>
							<Heading sx={styles.headerText}>
								{isLoadingNotifications ? '' : `Notificações (${notReadNotifications?.length})`}
							</Heading>
							<Button sx={styles.iconBox} onClick={onClose}>
								<CloseXIcon color="fff" />
							</Button>
						</Flex>
						{isLoadingNotifications ? (
							<Flex sx={styles.spinnerContainer}>
								<Spinner />
							</Flex>
						) : (
							<>
								<Box sx={styles.modalBody}>
									<Flex sx={styles.contentHeader}>
										<Text sx={styles.bodyNewerBox}>Recentes</Text>
										<Button
											data-testid="all-notification-btn"
											onClick={() => changeRoute(ROUTES?.notifications)}
											sx={styles.notificationsButton}
										>
											<Text sx={styles.notificationsButtonText}>Todas as notificações</Text>
										</Button>
									</Flex>
									<Flex sx={styles.contentContainer}>
										<Flex sx={styles.contentSubcontainer}>{notificationsList}</Flex>
									</Flex>
								</Box>
								<HStack sx={styles.footerCointaner}>
									<Button
										data-testid="check-all-notification-btn"
										sx={styles.checkNotificationsButton}
										onClick={() => checkAllNotificationsMutate()}
									>
										<DoubleCheck />
										<Text sx={styles.footerText}>Marcar Tudo Como Lido</Text>
									</Button>
								</HStack>
							</>
						)}
					</VStack>
				</MenuList>
			</Flex>
		</Menu>
	);
};

export default NotificationsMenu;
