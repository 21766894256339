/**
 *
 * AccordionIndicators
 *
 */
import CustomModal from '../CustomModal';
import IndicatorMatrix from '../IndicatorMatrix';
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Box,
	Text,
	Flex,
	SystemStyleObject,
	Divider,
	useDisclosure,
	IconButton,
	Grid,
	GridItem,
	Stack,
	Tag,
} from '@chakra-ui/react';
import { DeleteIcon, DownloadIcon, TableEditIcon } from 'assets/icons';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { IOption } from 'types/select';
import { STEPS_ENUM, TEXT_DATA_CONTROL_STEPS_ENUM, USER_ROLES } from 'utils/constants';

export interface ISetor {
	idSetor?: number | string | boolean;
	descricaoSetor?: string;
}

export interface AccordionIndicatorForm {
	id?: string;
	nome: string;
	ano: string;
	objetivo: string;
	objetivoEstrategico: string;
	unidadeMedida: string;
	polaridade: string;
	faixaDesempenho: string;
	metodologiaCalculo: string;
	metaCumulativa: string;
	setor: string;
	nomeResponsavel: string;
	email: string;
	telefone: string;
	responsavelLancamento: string;
	sei: string;
	areaTecnica: {
		idPactuado: number;
		nome: string;
		idUnidade: number;
	};
	etapaEnum?: string;
	dataValidacao?: string;
}

interface AccordionIndicatorsProps {
	pactuados?: IOption[];
	indicatorsGoals: AccordionIndicatorForm;
	editIndicatorsGoals: (id: string) => void;
	deleteIndicatorsGoals: (id: string) => void;
}

const AccordionIndicators = ({
	indicatorsGoals,
	editIndicatorsGoals,
	deleteIndicatorsGoals,
}: AccordionIndicatorsProps) => {
	const styles: Record<string, SystemStyleObject> = {
		accordionItem: {
			borderWidth: '1px',
			borderColor: 'gray.95',
			mb: '0.1rem',
			borderRadius: '0.25rem',
		},
		accordionButton: {
			_hover: {},
			height: 'auto',
			paddingTop: '0.625rem',
		},
		titleContainer: {
			flex: '1',
			textAlign: 'left',
			ml: '1.5rem',
			alignItems: 'center',
			gap: '0.4rem',
			flexDirection: 'row',
		},
		title: {
			fontSize: { base: '1rem', sm: '1.125rem' },
			textAlign: { base: 'start', sm: 'center' },
		},
		period: {
			fontSize: { base: '1rem', sm: '1.125rem' },
			fontWeight: 'bold',
			textAlign: 'center',
			minW: '61px',
		},
		titleIsExpanded: {
			fontSize: '1.25rem',
		},
		periodIsExpanded: {
			fontSize: { base: '1rem', sm: '1.125rem' },
			fontWeight: 'bold',
			textAlign: 'center',
			minW: '61px',
		},
		icons: {
			gap: {
				base: '1rem',
				lg: '1.5rem',
			},
			paddingRight: {
				base: '0',
				md: '2rem',
				lg: '6rem',
			},
			alignSelf: 'center',
		},
		text: {
			fontWeight: 'medium',
			fontSize: '1rem',
			whiteSpace: 'pre-line',
		},
		textBold: {
			fontWeight: 'bold',
			fontSize: '1rem',
		},
		divider: {
			p: {
				base: '0rem 2rem 0rem 2rem',
				lg: '0rem 5rem 0rem 2rem',
			},
		},
		accordionIcon: {
			h: '2.25rem',
			w: '2.25rem',
		},
		box: {
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			height: 'auto',
		},
		boxExpanded: {
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			height: 'auto',
		},
		accordionPanelContainer: {
			w: '100%',
			p: '2rem',
			borderTopWidth: '1px',
		},
		firstSectionContainer: {
			mb: '1.75rem',

			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				xl: 'repeat(6, 1fr)',
			},
			gap: {
				base: '4.313rem',
				xl: '0',
			},
		},
		firstSectionObjectvesContainer: {
			gap: '3.438rem',
			maxW: {
				base: '100%',
				lg: '49.438rem',
			},
			width: '100%',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		firstSectionObjectvesLeftContent: {
			flexDir: 'column',
			maxW: {
				base: '100%',
				lg: '23.438rem',
			},
			width: '100%',
			gap: {
				base: '0.5rem',
				lg: '1rem',
			},
		},
		firstSectionObjectvesRightContent: {
			flexDir: 'column',
			maxW: {
				base: '100%',
				lg: '22.5rem',
			},
			width: '100%',
			gap: {
				base: '0.5rem',
				lg: '1rem',
			},
		},
		secondSectionContainer: {
			mt: '1.5rem',
			mb: '1.75rem',

			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				xl: 'repeat(6, 1fr)',
			},
			gap: {
				base: '4.125rem',
				xl: '0',
			},
		},
		methodologyContent: {
			flexDir: 'column',
			maxW: '49.438rem',
			w: '100%',
			gap: {
				base: '0.5rem',
				lg: '1rem',
			},
		},
		contactContainer: {
			flexDir: 'column',
			gap: '0.5rem',
			mt: {
				base: '0.5rem',
				lg: '1rem',
			},
		},
		thirdSectionContainer: {
			mt: '1.5rem',

			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				xl: 'repeat(6, 1fr)',
			},
		},
		technicalAreaContent: {
			maxW: '49.438rem',
			w: '100%',
			mb: '1rem',
			gap: '0.5rem',
		},
		columnSpacing: {
			flexDir: 'column',
			gap: {
				base: '0.5rem',
				lg: '1rem',
			},
		},
		seiContent: {
			border: '1px solid #7070701A',
			borderRadius: '4px',
			h: '1.875rem',
			p: '0.5rem',
			alignItems: 'center',
			w: 'fit-content',
			gap: '0.5rem',
		},
		seiText: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		containerSecondColumn: {
			borderLeftWidth: { base: 0, xl: '1px' },
			paddingLeft: { base: 0, xl: '25px' },
		},
		headTag: {
			fontSize: '0.75rem',
			borderRadius: '0.75rem',
			h: '0.938rem',
			backgroundColor: 'blue.400',
			minW: '56.5px',
		},
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenExportModal, onOpen: onOpenExportModal, onClose: onCloseExportModal } = useDisclosure();

	const {
		session: { user },
	} = useSession();

	const userUnit = String(user.idUnidade || '');
	const technicalAreaUnit = String(indicatorsGoals?.areaTecnica?.idUnidade || '');
	const representativeUnits = user?.unidadesRepresentantes?.map(unidade => unidade.idUnidade);

	const presidentPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);
	const secondStepPermission = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
		USER_ROLES.REPRESENTANTE,
	]);

	const technicalAreaUnitIqualsUserUnit = technicalAreaUnit === userUnit;

	const representativeUnitsHasTechnicalAreaUnit = representativeUnits.some(unit => unit === technicalAreaUnit);

	const isTechnicalArea = technicalAreaUnitIqualsUserUnit || representativeUnitsHasTechnicalAreaUnit;

	const isSecondStep = indicatorsGoals.etapaEnum === STEPS_ENUM.ETAPA_DOIS;

	const canHandleSecondStep = presidentPermission ? presidentPermission : secondStepPermission && isTechnicalArea;

	const hasPermission = isSecondStep ? canHandleSecondStep : firstStepPermission;

	const handleDelete = (indicatorId: string) => {
		onClose();
		deleteIndicatorsGoals(indicatorId);
	};

	const handleGoBack = () => {
		onClose();
	};

	return (
		<>
			<Accordion allowMultiple>
				<AccordionItem key={indicatorsGoals.id} sx={styles.accordionItem} data-testid="indicators--item">
					{({ isExpanded }) => (
						<>
							<Box sx={isExpanded ? styles.boxExpanded : styles.box}>
								<AccordionButton sx={styles.accordionButton} data-testid="indicators--item-btn">
									<AccordionIcon sx={styles.accordionIcon} />
									<Flex sx={styles.titleContainer}>
										<Tag variant="solid" sx={styles.headTag}>
											{
												TEXT_DATA_CONTROL_STEPS_ENUM[
													String(indicatorsGoals?.etapaEnum) as keyof typeof TEXT_DATA_CONTROL_STEPS_ENUM
												]
											}
										</Tag>
										<Text sx={isExpanded ? styles.titleIsExpanded : styles.title}>{indicatorsGoals.nome}</Text>
										<Text sx={isExpanded ? styles.periodIsExpanded : styles.period}>
											{' — '}
											{indicatorsGoals.ano}
										</Text>
									</Flex>
								</AccordionButton>
								<Flex sx={styles.icons}>
									<IconButton
										aria-label={'Baixar indicador'}
										variant="unstyled"
										icon={<DownloadIcon />}
										data-testid={`indicators--item-download-${indicatorsGoals.id}`}
										onClick={onOpenExportModal}
									/>

									{hasPermission && (
										<IconButton
											aria-label={'Editar indicador'}
											variant="unstyled"
											icon={<TableEditIcon />}
											data-testid={`indicators--item-edit-${indicatorsGoals.id}`}
											onClick={() => editIndicatorsGoals(String(indicatorsGoals.id))}
										/>
									)}
									{hasPermission && (
										<IconButton
											aria-label={'Excluir indicador'}
											variant="unstyled"
											icon={<DeleteIcon />}
											data-testid={`indicators--item-delete-${indicatorsGoals.id}`}
											onClick={onOpen}
											visibility={indicatorsGoals?.dataValidacao ? 'hidden' : 'visible'}
										/>
									)}
								</Flex>
							</Box>

							<AccordionPanel sx={styles.accordionPanelContainer}>
								<Box>
									<Grid sx={styles.firstSectionContainer}>
										<GridItem colSpan={{ base: 1, xl: 4 }}>
											<Flex sx={styles.firstSectionObjectvesContainer}>
												<Flex sx={styles.firstSectionObjectvesLeftContent}>
													<Text sx={styles.text}>Objetivo:</Text>
													<Text sx={styles.text} data-testid="indicators--objective">
														{indicatorsGoals.objetivo}
													</Text>
												</Flex>
												<Flex sx={styles.firstSectionObjectvesRightContent}>
													<Text sx={styles.text}>Objetivo estratégico:</Text>
													<Text sx={styles.text} data-testid="indicators--strategicObjective">
														{indicatorsGoals.objetivoEstrategico}
													</Text>
												</Flex>
											</Flex>
										</GridItem>

										<GridItem colSpan={{ base: 1, xl: 2 }}>
											<Flex gap="1rem" sx={styles.containerSecondColumn}>
												<Flex sx={styles.columnSpacing}>
													<Text sx={styles.text}>Unidade de medida:</Text>
													<Text sx={styles.text}>Polaridade:</Text>
												</Flex>
												<Flex sx={styles.columnSpacing}>
													<Text sx={styles.textBold} data-testid="indicators--unityMeasure">
														{indicatorsGoals.unidadeMedida}
													</Text>
													<Text sx={styles.textBold} data-testid="indicators--polarity">
														{indicatorsGoals.polaridade}
													</Text>
												</Flex>
											</Flex>
										</GridItem>
									</Grid>

									<Box sx={styles.divider}>
										<Divider />
									</Box>

									<Grid sx={styles.secondSectionContainer}>
										<GridItem colSpan={{ base: 1, xl: 4 }}>
											<Flex sx={styles.methodologyContent} pr="3.438rem">
												<Flex gap="1.5rem">
													<Text sx={styles.text}>Faixa de desempenho</Text>
													<Text sx={styles.textBold} data-testid="indicators--performanceRange">
														{indicatorsGoals.faixaDesempenho}
													</Text>
												</Flex>
												<Text sx={styles.text}>Metodologia de cálculo</Text>
												<Text sx={styles.text} data-testid="indicators--calculationMethodology">
													{indicatorsGoals.metodologiaCalculo}
												</Text>
											</Flex>
										</GridItem>

										<GridItem colSpan={{ base: 1, xl: 2 }}>
											<Stack sx={styles.containerSecondColumn}>
												<Flex gap="2.5rem">
													<Flex sx={styles.columnSpacing}>
														<Text sx={styles.text}>Meta cumulativa</Text>
													</Flex>
													<Flex sx={styles.columnSpacing}>
														<Text sx={styles.textBold} data-testid="indicators--cumulativeGoal">
															{indicatorsGoals.metaCumulativa}
														</Text>
													</Flex>
												</Flex>

												<Flex sx={styles.contactContainer}>
													<Text sx={styles.textBold}>Contato de referência técnica:</Text>
													<Text sx={styles.text} data-testid="indicators--technicalReference-sector">
														{indicatorsGoals.setor}
													</Text>
													<Text
														sx={styles.text}
														data-testid="indicators--technicalReference-responsible"
													>{`${indicatorsGoals.nomeResponsavel} — ${indicatorsGoals.email}`}</Text>
													<Text sx={styles.text} data-testid="indicators--technicalReference-phone">
														{indicatorsGoals.telefone}
													</Text>
												</Flex>
											</Stack>
										</GridItem>
									</Grid>

									<Box sx={styles.divider}>
										<Divider />
									</Box>

									<Grid sx={styles.thirdSectionContainer}>
										<GridItem colSpan={{ base: 1, xl: 4 }}>
											<Flex sx={styles.technicalAreaContent}>
												<Text sx={styles.text}>Área técnica:</Text>
												<Text sx={styles.textBold} data-testid="indicators--technicalArea">
													{indicatorsGoals.areaTecnica.nome}
												</Text>
											</Flex>
										</GridItem>
										{indicatorsGoals?.sei && (
											<GridItem colSpan={{ base: 1, xl: 2 }}>
												<Box pl={{ base: 0, xl: '25px' }}>
													<Flex sx={styles.seiContent}>
														<Text sx={styles.seiText}>S.E.I.</Text>
														<Text sx={styles.text} data-testid="indicators--technicalReference-sei">
															{indicatorsGoals.sei}
														</Text>
													</Flex>
												</Box>
											</GridItem>
										)}
									</Grid>
								</Box>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
			<IndicatorMatrix
				indicatorId={String(indicatorsGoals?.id)}
				onClose={onCloseExportModal}
				isOpen={isOpenExportModal}
				showPactuado
			/>
			<CustomModal
				icons={[{ type: 'error' }]}
				title="Confirmar a exclusão?"
				body="Você realmente quer excluir este Indicador? Este processo não pode ser desfeito."
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero excluir',
						type: 'cancel',
						onClick: () => handleDelete(String(indicatorsGoals.id)),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleGoBack,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default AccordionIndicators;
