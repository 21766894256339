/**
 *
 * Prompt
 *
 */

import CustomModal from '../CustomModal';
import { useCallback } from 'react';
import { useCustomToast } from 'hooks/useToast';
import { unstable_BlockerFunction as BlockerFunction, useBlocker } from 'react-router-dom';

interface PromptProps {
	when: boolean;
}

const Prompt = ({ when }: PromptProps) => {
	const { addToast } = useCustomToast();
	const shouldBlock = useCallback<BlockerFunction>(() => when, [when]);
	const blocker = useBlocker(shouldBlock);

	const handleGoBack = () => {
		blocker.proceed?.();

		addToast({
			type: 'error',
			title: 'Cadastro cancelado',
			description: 'As informações não salvas foram perdidas.',
		});
	};

	if (blocker?.state === 'blocked') {
		return (
			<>
				<CustomModal
					icons={[{ type: 'error' }]}
					title="Você realmente quer cancelar?"
					body="Todas as informações inseridas serão perdidas caso você cancele."
					isOpen={true}
					onClose={() => blocker.reset?.()}
					actions={[
						{
							label: 'Quero cancelar',
							type: 'cancel',
							onClick: handleGoBack,
							datatestid: 'button--confirm',
						},
						{
							label: 'Voltar',
							type: 'secondary',
							onClick: () => blocker.reset?.(),
							datatestid: 'button--cancel',
						},
					]}
				/>
			</>
		);
	}

	return <></>;
};

export default Prompt;
