/**
 *
 * ResultsEvaluationIndicatorCard
 *
 */

import IndicatorMatrix from '../IndicatorMatrix';
import ResulterTooltip from '../ResulterIndicatorCard/resulterTooltip';
import { SystemStyleObject, Flex, Text, Box, Tooltip, IconButton, useDisclosure } from '@chakra-ui/react';
import { ViewMatrixIcon } from 'assets/icons';
import { BIMESTERS_ENUM_TO_MONTHS, POLARITY_STATUS, RESULTER_STATUS } from 'utils/constants';
import { maskThousands } from 'utils/Numbers';

export interface IResultsEvaluationIndicatorCardData {
	idIndicador: string;
	pactuado: string;
	nomeIndicador: string;
	ano: number;
	bimestre: string;
	resultadoTotal: number;
	meta: number;
	polaridade: string;
	pontuacao: string;
	desempenhoEnum: string;
}

interface ResultsEvaluationIndicatorCardProps {
	data: IResultsEvaluationIndicatorCardData;
}

const ResultsEvaluationIndicatorCard = ({ data }: ResultsEvaluationIndicatorCardProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			w: '100%',
			p: '1rem 2rem',
			border: '0.063rem solid #DBDBDB',
			borderRadius: '0.25rem',
			flexDir: 'column',
			background: 'white',
			pos: 'relative',
		},
		pactuadoText: {
			fontSize: '0.875rem',
			fontWeight: 'medium',
			color: '#0A77D2',
		},
		matrizContainer: {
			pos: 'absolute',
			top: '1rem',
			right: '2rem',
		},
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
		indicadorContainer: {
			mt: '0.5rem',
		},
		indicadorText: {
			fontSize: '1.25rem',
			color: '#000000',
			fontWeight: 'bold',
		},
		valoresContainer: {
			mt: '0.5rem',
			gap: '4rem',
		},
		smallText: {
			fontSize: '0.875rem',
		},
		valorContent: {
			alignItems: 'center',
		},
		resultadoboldText: {
			fontSize: '1.625rem',
			fontWeight: 'bold',
		},
		metaPontuacaoText: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
		},
		polaridadeContent: {
			pt: '0.43rem',
		},
	};

	const { isOpen, onOpen, onClose } = useDisclosure();

	const isNA = data.resultadoTotal === -1;

	return (
		<Flex sx={styles.container}>
			<Text sx={styles.pactuadoText} data-testid="text--pactuado">
				{data.pactuado}
			</Text>

			<Box sx={styles.matrizContainer}>
				<Tooltip label="Visualizar Matriz" placement="top-start" gutter={-5} sx={styles.tooltip}>
					<IconButton aria-label={'Visualizar Matriz'} variant="unstyled" icon={<ViewMatrixIcon />} onClick={onOpen} />
				</Tooltip>
			</Box>

			<Flex sx={styles.indicadorContainer}>
				<Text sx={styles.indicadorText} data-testid="text--indicador">
					{data.nomeIndicador} - {data.ano} /{' '}
					{BIMESTERS_ENUM_TO_MONTHS[data.bimestre as keyof typeof BIMESTERS_ENUM_TO_MONTHS]}
				</Text>
			</Flex>

			<Flex sx={styles.valoresContainer}>
				<Box>
					<Text sx={styles.smallText}>Resultado</Text>
					<Flex sx={styles.valorContent}>
						<Text sx={styles.resultadoboldText} data-testid="text--resultadoCard">
							{isNA ? 'N/A' : maskThousands(data.resultadoTotal)}
						</Text>
						<ResulterTooltip
							label={RESULTER_STATUS[data?.desempenhoEnum as keyof typeof RESULTER_STATUS]?.label}
							icon={RESULTER_STATUS[data?.desempenhoEnum as keyof typeof RESULTER_STATUS]?.icon}
						/>
					</Flex>
				</Box>
				<Box>
					<Text sx={styles.smallText}>Meta</Text>
					<Flex sx={styles.valorContent}>
						<Text sx={styles.metaPontuacaoText} data-testid="text--meta">
							{isNA ? 'N/A' : maskThousands(data.meta)}
						</Text>
					</Flex>
				</Box>
				<Box>
					<Text sx={styles.smallText}>Polaridade</Text>
					<Flex sx={styles.polaridadeContent}>
						<ResulterTooltip
							label={POLARITY_STATUS[data?.polaridade as keyof typeof POLARITY_STATUS]?.label}
							icon={POLARITY_STATUS[data?.polaridade as keyof typeof POLARITY_STATUS]?.icon}
						/>
					</Flex>
				</Box>
				<Box>
					<Text sx={styles.smallText}>Pontuação</Text>
					<Flex sx={styles.valorContent}>
						<Text sx={styles.metaPontuacaoText} data-testid="text--pontuacao">
							{isNA ? 'N/A' : data.pontuacao?.replace('.', ',')}
						</Text>
					</Flex>
				</Box>
			</Flex>

			<IndicatorMatrix indicatorId={data?.idIndicador} onClose={onClose} isOpen={isOpen} />
		</Flex>
	);
};

export default ResultsEvaluationIndicatorCard;
