/**
 *
 * InputFileModal
 *
 */

import { IFile } from '.';
import { ChangeEvent, useState } from 'react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
	Button,
	Box,
	Text,
	IconButton,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Input,
	Progress,
	SystemStyleObject,
	Flex,
} from '@chakra-ui/react';
import { AddDocIcon, PdfIcon } from 'assets/icons';
import { MB_IN_KB } from 'utils/constants';
import { isFileSizeValid } from 'utils/isFileSizeValid';
import { sleep } from 'utils/sleep';

interface InputFileModalProps {
	file?: IFile;
	label?: string;
	title?: string;
	subLabel?: string;
	required?: boolean;
	disabled?: boolean;
	name: string;
	onChangeDoc?: (doc: IFile) => void;
	onDownloadDoc?: (file: IFile) => void;
	onDeleteDoc?: (file: IFile) => void;
	acceptValues?: string;
	id: string;
	error?: boolean;
}

const InputFileModal = ({
	file,
	title,
	label,
	subLabel,
	required,
	disabled,
	name,
	acceptValues,
	onChangeDoc,
	onDeleteDoc,
	onDownloadDoc,
	error,
	id,
	...rest
}: InputFileModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		box: {
			w: '10.625rem',
			maxW: '10.625rem',
			margin: '0.313rem',
			bg: 'gray.80',
			borderRadius: '0.25rem',
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			height: '13.125rem',
		},
		text: {
			fontSize: '0.875rem',
		},
		box2: {
			w: '8.75rem',
			maxW: '8.75rem',
			bg: '#FFFFFF',
			p: '0.5 rem',
			borderRadius: '0.25rem',
			flexDirection: 'column',
			height: '11.25rem',
		},
		text2: {
			fontSize: '0.875rem',
			color: '#C6C6C6',
		},
		text3: {
			fontSize: '0.75rem',
			color: 'black',
			opacity: '60%',
			w: '50%',
		},
		button2: {
			w: '100%',
			maxW: '4rem',
			h: '1.688rem',
			borderRadius: '1rem',
			fontSize: '0.75rem',
			cursor: 'pointer',
		},
		button3: {
			w: '100%',
			maxW: '5.188rem',
			h: '1.688rem',
			borderRadius: '1rem',
			fontSize: '0.75rem',
		},
		headerCard: {
			textAlign: 'end',
		},
		infoBox: {
			p: '0.5rem',
			display: 'flex',
			flexDirection: 'column',
			gap: '0.25rem',
		},
		footerCard: {
			justifyContent: 'center',
			paddingBottom: '0.5rem',
		},
		boxBar: {
			width: '90%',
			height: '2.25rem',
			display: 'flex',
			flexDirection: 'column',
			gap: '0.5rem',
		},
	};

	const [selectDoc, setSelectDoc] = useState(file);
	const [progress, setProgress] = useState(0);

	const clickDelete = () => {
		setSelectDoc({});
		setProgress(0);
	};

	const hasValue = selectDoc && selectDoc.name;

	const handleFile = async (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files![0];
		onChangeFile(file, '1');
		const allowed = acceptValues?.includes(file?.type);
		const isValidSize = isFileSizeValid(file?.size);

		const shouldUpload = !error && allowed && isValidSize;

		if (shouldUpload) {
			const data = new FormData();
			data.append(name, file);

			for (let i = 0; i <= 100; i++) {
				await sleep(10);
				setProgress(i);
			}
			return;
		}

		clickDelete();
	};

	const onChangeFile = async (file: File, id: any) => {
		if (file) {
			const newFile = {
				name: file?.name,
				size: file?.size,
				type: file?.type,
				file: file,
				linkBy: id,
			};

			setSelectDoc(newFile);
			onChangeDoc?.(newFile);
		}
	};

	return (
		<>
			<Box sx={styles.box}>
				<Text sx={styles.text}>{title}</Text>
				{file ? (
					<Card variant="unstyled" sx={styles.box2}>
						<CardHeader sx={styles.headerCard}>
							<IconButton
								color="red.450"
								size="xs"
								aria-label="delete document"
								variant="unstyled"
								onClick={() => onDeleteDoc?.(file)}
								data-testid={`btn-delete-file--${name}`}
								icon={<DeleteIcon />}
							/>
						</CardHeader>
						<CardBody sx={styles.infoBox}>
							<AddDocIcon />
							<Text sx={styles.text}>{file?.name}</Text>
						</CardBody>
						<CardFooter sx={styles.footerCard}>
							<Button
								sx={styles.button2}
								onClick={() => onDownloadDoc?.(file)}
								data-testid={`btn-download-file--${name}`}
							>
								Visualizar
							</Button>
						</CardFooter>
					</Card>
				) : (
					<Card variant="unstyled" sx={styles.box2}>
						<CardHeader sx={styles.headerCard}>
							{hasValue ? (
								<IconButton
									color="red.450"
									size="xs"
									aria-label="remove document"
									variant="unstyled"
									onClick={clickDelete}
									data-testid={`btn-delete-upload--${name}`}
									icon={<CloseIcon />}
								/>
							) : (
								<IconButton
									color="transparent"
									size="xs"
									aria-label="remove document"
									variant="unstyled"
									isDisabled
									icon={<CloseIcon />}
								/>
							)}
						</CardHeader>
						<CardBody sx={styles.infoBox}>
							{hasValue ? (
								<>
									<PdfIcon />
									<Text sx={styles.text}>{selectDoc?.name}</Text>
								</>
							) : (
								<>
									<label htmlFor={id}>
										<AddDocIcon />
									</label>
									<Text sx={styles.text}>Anexar documento</Text>
									<Text sx={styles.text2}>{subLabel}</Text>
								</>
							)}
						</CardBody>
						<CardFooter sx={styles.footerCard}>
							{progress ? (
								<Box sx={styles.boxBar} data-testid={`progress-input-upload--${name}`}>
									<Flex>
										<Text sx={styles.text3}>{Math.round(selectDoc?.size! / MB_IN_KB)}KB</Text>
										<Text sx={styles.text3} textAlign="right">
											{progress}%
										</Text>
									</Flex>

									<Progress value={progress} size="xs" colorScheme="linkedin" borderRadius={10} />
								</Box>
							) : (
								<Button sx={styles.button2} data-testid={`btn-input-upload--${name}`} as="label" htmlFor={id}>
									{label}
								</Button>
							)}

							<>
								<Input
									name={name}
									type="file"
									id={id}
									data-testid={`input-upload--${name}`}
									required={required}
									disabled={disabled}
									{...rest}
									onChange={values => {
										handleFile(values);
									}}
									display="none"
									accept={acceptValues}
									variant={'unstyled'}
								/>
							</>
						</CardFooter>
					</Card>
				)}
			</Box>
		</>
	);
};

export default InputFileModal;
