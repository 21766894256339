import { ONLY_NUMBERS_AND_COMMA_REGEX, THOUSAND_REGEX } from './constants';
import { NumberFormatValues } from 'react-number-format';

export const unMaskThousands = (receivedValue: string | number) => {
	return Number(String(receivedValue)?.replace(ONLY_NUMBERS_AND_COMMA_REGEX, '')?.replace(',', '.'));
};

export const addThousandFormat = (receivedValue: string | number, suffix = '') => {
	const value = String(receivedValue)?.replace(ONLY_NUMBERS_AND_COMMA_REGEX, '');

	const [integers, decimals] = value.split(',');

	const integersFormatted = integers.replace(THOUSAND_REGEX, '.');
	const decimalsFormatted = decimals === '' || !!decimals ? `,${decimals}` : '';

	return integersFormatted + decimalsFormatted + suffix;
};

export const maskThousands = (value: number, suffix = '') => {
	return addThousandFormat(String(value)?.replace('.', ',') || '0', suffix);
};

export const allowedPorcentage = (value: NumberFormatValues, maxIntNumbers: number) => {
	const MAX_INTEGER_NUMBERS = maxIntNumbers;
	return value.formattedValue.split(',')[0].length <= MAX_INTEGER_NUMBERS;
};
