/**
 *
 * RepresentativeEdit
 *
 */

import { useMemo } from 'react';
import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import RepresentativeForm, { IRepresentativeForm } from 'app/components/RepresentativeForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import {
	IRequestRepresentive,
	IUpdateRepresentativeParams,
	getRepresentativeById,
	updateRepresentative,
} from 'services/http/representative';
import { IRepresentativeItem } from 'types/representative';
import { API_DEFAULT_ERROR, RESULTER_FILTER_STEPS } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';

type IParams = {
	id: string;
};

const RepresentativeEdit = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.875rem',
			mb: '2rem',
			width: { base: '100%', lg: 'max-content', xl: '100%' },
		},
		spinner: {
			mt: '3.7rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { id: representativeId = '' } = useParams<IParams>();

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { data: representative, isLoading: isLoadingRepresentative } = useQuery<
		IRequestRepresentive,
		AxiosError<ResponseErrors>,
		IRequestRepresentive
	>(['representative', representativeId], () => getRepresentativeById(representativeId), {
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
		enabled: !!representativeId,
	});

	const { mutate: updateRepresentativeMutate, isLoading: isUpdateLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IUpdateRepresentativeParams
	>(({ data }) => updateRepresentative({ data, representativeId }), {
		onSuccess: () => {
			addToast({
				type: 'success',
				description: 'O vínculo de representantes foi editado com sucesso!',
				title: 'Sucesso!',
			});
			navigate(ROUTES.representatives);
		},
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
	});

	const defaultValuesForm: IRepresentativeForm = useMemo(() => {
		return {
			...representative!,
			id: String(representative?.id || ''),
			etapa: {
				label: RESULTER_FILTER_STEPS[String(representative?.etapa) as keyof typeof RESULTER_FILTER_STEPS],
				value: representative?.etapa!,
			},
			setor: {
				label: representative?.unidadeRepresentante?.nomePactuado!,
				value: representative?.unidadeRepresentante?.idSetor!,
				data: {
					idSetor: representative?.unidadeRepresentante?.idSetor!,
					idUnidade: representative?.unidadeRepresentante?.idUnidade!,
					nome: representative?.unidadeRepresentante?.nomePactuado!,
					nomeSetor: representative?.unidadeRepresentante?.nomePactuado!,
					sigla: representative?.unidadeRepresentante?.nomePactuado!,
				},
			},

			representantes:
				representative?.dadosPessoaisRepresentantes?.map(item => ({
					id: item?.id,
					email: item.email,
					nome: { value: item.nome, label: item.nome },
					masp: item.masp,
				})) || [],
		};
	}, [representative]);

	const handleSubmit = (data: IRepresentativeForm) => {
		const editedRepresentantes = data?.representantes?.map((representante, index) => {
			const defaultRepresentante = defaultValuesForm?.representantes[index]!;
			const isEditing = !!defaultRepresentante?.id;

			if (
				isEditing &&
				(representante.nome?.value !== defaultRepresentante.nome?.value ||
					representante.masp !== defaultRepresentante.masp)
			) {
				return {
					email: representante?.email!,
					masp: representante?.masp!,
					nome: representante?.nome?.value!,
				};
			}

			if (
				isEditing &&
				(representante.masp === defaultRepresentante.masp ||
					representante.nome?.value === defaultRepresentante.nome?.value)
			) {
				return {
					id: representante?.id,
					email: representante?.email!,
					masp: representante?.masp!,
					nome: representante?.nome?.value!,
				};
			}

			return {
				email: representante?.email!,
				masp: representante?.masp!,
				nome: representante?.nome?.value!,
			};
		});

		updateRepresentativeMutate({ data: editedRepresentantes as IRepresentativeItem[], representativeId });
	};

	return (
		<>
			<Flex sx={styles.container}>
				{isLoadingRepresentative ? (
					<Box sx={styles?.spinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Box>
				) : (
					<>
						<PageWrapper title="Vincular Representante" />
						<Box sx={styles.content}>
							<RepresentativeForm
								onSubmit={handleSubmit}
								defaultValues={defaultValuesForm}
								isEditing
								isSubmitLoading={isUpdateLoading}
							/>
						</Box>
					</>
				)}
			</Flex>
		</>
	);
};

export default RepresentativeEdit;
