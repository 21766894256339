/**
 *
 * DataControlCreate
 *
 */

import { useState } from 'react';
import { Box, Flex, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import CustomModal from 'app/components/CustomModal';
import DataControlForm, { IDataControlForm } from 'app/components/DataControlForm';
import PageWrapper from 'app/components/PageWrapper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { createDateControl, getDateControlOverlap } from 'services/http/dateControl';
import { IRequestDateControl, IRequestDateControlOverlap, IResponseDateControlOverlap } from 'types/dateControl';
import { ALL_UNITS_VALUE, API_DEFAULT_ERROR, DATA_CONTROL_STEPS } from 'utils/constants';
import { getBimesterFromDateControlData } from 'utils/formatBimesters';
import { currentDatePayload, formatDateToApi } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';

type Params = {
	etapa: string;
};

interface IModalOverlapData {
	payload: IRequestDateControl;
	overlapUnits: IResponseDateControlOverlap[];
}

const createDate = new Date();

const DataControlCreate = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.75rem',
			mb: '5.5rem',
		},
		modalStyleBody: { '& ul': { m: '1rem', ml: '1.5rem' } },
		modalStyleBodyText: { textAlign: 'left' },
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const { etapa } = useParams<Params>();
	const {
		session: { user },
	} = useSession();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [modalData, setModalData] = useState<IModalOverlapData>();

	const { mutate: getDateControlOverlapMutate } = useMutation<
		IResponseDateControlOverlap[],
		AxiosError<ResponseErrors>,
		IRequestDateControlOverlap
	>(data => getDateControlOverlap(data), {
		onSuccess: (data, variables) => {
			if (!data?.length) createDataControlMutate(variables?.payload as IRequestDateControl);
			setModalData({ payload: variables?.payload as IRequestDateControl, overlapUnits: data });
			onOpen();
		},
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const { mutate: createDataControlMutate, isLoading: isCreatingDataControl } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestDateControl
	>(data => createDateControl(data), {
		onSuccess: () => {
			navigate(ROUTES.dateControl);
			addToast({ type: 'success', description: 'Data definida com sucesso!', title: 'Sucesso!' });
		},
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const handleSubmit = (data: IDataControlForm) => {
		const payload = {
			responsavelAtualizacao: user.nomeCompleto,
			dataCriacao: currentDatePayload(createDate),
			dataAtualizacao: currentDatePayload(createDate),
			faseEnum: String(data.faseEnum.value),
			faseAdcional: data.faseAdcional || '',
			descricao: data.descricao || '',
			clausulaJustificativa: data.clausulaJustificativa,
			justificativa: data.justificativa,
			penalidade: {
				aplicavel: data.penalidade.aplicavel === 'SIM' ? true : false,
				penalizadoEnum:
					data.penalidade.penalizadoEnum?.value && data.penalidade.aplicavel === 'SIM'
						? String(data.penalidade.penalizadoEnum?.value)
						: null,
				pontosPerdidos:
					data.penalidade.pontosPerdidos?.value && data.penalidade.aplicavel === 'SIM'
						? String(data.penalidade.pontosPerdidos?.value)
						: null,
				notificacao: data.penalidade.notificacao ? false : true,
				tempoNotificacoes:
					(!data.penalidade.notificacao &&
						data?.penalidade?.tempoNotificacoes?.map(item => {
							return String(item.value);
						})) ||
					null,
			},
			listaPrazos: data?.listaPrazos?.map(item => {
				return {
					dataPrazo: formatDateToApi(String(item?.dataPrazo)),
				};
			}),
			etapa: DATA_CONTROL_STEPS[etapa! as keyof typeof DATA_CONTROL_STEPS],
			unidadesSegundaEtapa:
				data?.unidade?.value === ALL_UNITS_VALUE && data?.unidadesSegundaEtapa
					? data?.unidadesSegundaEtapa?.map(unit => ({
							unidade: unit?.label,
							idUnidade: Number(unit?.value),
					  }))
					: [
							{
								unidade: data?.unidade?.data?.unidade,
								idUnidade: data?.unidade?.data?.id,
							},
					  ],
		} as IRequestDateControl;

		if (payload.etapa === DATA_CONTROL_STEPS['etapa-1']) {
			delete payload.unidadesSegundaEtapa;
			createDataControlMutate(payload);
		} else {
			getDateControlOverlapMutate({
				payload,
				params: {
					fase: String(data.faseEnum.value),
					etapa: DATA_CONTROL_STEPS[etapa! as keyof typeof DATA_CONTROL_STEPS],
					anos: data?.listaPrazos?.map(item => formatDateToApi(String(item?.dataPrazo), 'yyyy')),
					bimestres: data?.listaPrazos?.map(item =>
						getBimesterFromDateControlData(new Date(formatDateToApi(String(item?.dataPrazo), 'yyyy-MM-dd'))),
					),
					idsUnidades:
						data?.unidade?.value === ALL_UNITS_VALUE && data?.unidadesSegundaEtapa
							? data?.unidadesSegundaEtapa?.map(unit => Number(unit?.value!))
							: [data?.unidade?.data?.id!],
				},
			});
		}
	};

	const handleCloseModal = () => {
		onClose();
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Definir Datas" goBackRoute={ROUTES.dateControl} />

			<Box sx={styles.content}>
				<DataControlForm onSubmitForm={handleSubmit} isLoading={isCreatingDataControl} />
			</Box>
			<CustomModal
				icons={[{ type: 'info' }]}
				title="Aviso!"
				body={`
					As unidades listadas abaixo não puderam ser cadastradas, pois já possuem data cadastrada para essa fase:
					<ul>
						${modalData?.overlapUnits
							?.map(value => {
								return `<li><b>${value?.siglaUnidade}</b></li>`;
							})
							.join('')}
					</ul>
					Deseja cadastrar as unidades restantes?
				`}
				bodyCustomStyle={styles.modalStyleBody}
				bodyTextCustomStyle={styles.modalStyleBodyText}
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Cadastrar Datas',
						type: 'primary',
						onClick: () => createDataControlMutate(modalData?.payload as any),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleCloseModal,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</Flex>
	);
};

export default DataControlCreate;
