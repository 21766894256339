import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import {
	IRequestEditManagementPact,
	IRequestManagementPact,
	IResponseActivePact,
	IResponseGetManagementPact,
	IResponseManagementPact,
	IResponseManagementPactById,
} from 'types/managementPact';

export interface LastActivePactParams {
	pactuado: string;
	ano?: string;
}

export interface IResponseLastActivePact {
	anoInicio: number;
	anoFim: number;
	bimestreInicio: string;
	bimestreFim: string;
	periodo: string;
}

export interface IManagementPactParams {
	idPactuado: string;
	anoPactoGestao: string;
	etapa: string;
}

export const createManagementPact = async (data: IRequestManagementPact): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.managementPactCreate, data);
	return response.data;
};

export const getManagementPactById = async (pactId: string): Promise<IResponseManagementPactById> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.managementPactGetById(pactId));
	return response.data;
};

export const editManagementPact = async (pactId: string, data: IRequestEditManagementPact): Promise<void> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.managementPactUpdate(pactId), data);
	return response.data;
};

export const getLastActivePact = async (params: LastActivePactParams): Promise<IResponseLastActivePact[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.lastActivePact, { params });

	return response.data;
};

export const getLastActivePactCreate = async (params: LastActivePactParams): Promise<IResponseLastActivePact[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.lastActivePactCreate, { params });

	return response.data;
};

export const getManagementPact = async (params: IManagementPactParams): Promise<IResponseManagementPact> => {
	const api = getAxios();
	const response = await api.get<IResponseGetManagementPact[]>(ENDPOINTS.managementPactList, {
		params,
	});

	let parsedResponse = {} as IResponseManagementPact;

	response.data.forEach(item => {
		let indicador = {
			nome: item.indicadores.nome,
			polaridade: item.indicadores.polaridade,
			atribuicaoPactuados: item.atribuicaoPactuados,
		};

		if (!parsedResponse.id) {
			parsedResponse = {
				id: item.pactoGestao.id,
				ano: item.pactoGestao.ano,
				dataValidacao: item.pactoGestao.dataValidacao,
				habilitado: item.pactoGestao.habilitado,
				indicadores: [indicador],
				etapa: item.pactoGestao.etapa,
			};
		}

		if (!parsedResponse.indicadores.includes(indicador)) {
			parsedResponse.indicadores.push(indicador);
		}
	});

	return parsedResponse;
};

export const deleteManagementPact = async (managementPactId: string): Promise<void> => {
	const api = getAxios();
	await api.delete(ENDPOINTS.managementPactDelete(managementPactId));
};

export const validateManagementPact = async (managementPactId: string): Promise<void> => {
	const api = getAxios();
	await api.patch(ENDPOINTS.managementPactValidate(managementPactId));
};

export const getManagementPactPeriods = async (): Promise<string[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.managementPactPeriods);

	return response.data;
};

export const getActivePacts = async (etapa: string): Promise<IResponseActivePact[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.activePacts, { params: { etapa } });

	const linedUpPactArray = response.data.sort((a: IResponseActivePact, b: IResponseActivePact) =>
		a.pactuado.localeCompare(b.pactuado),
	);

	return linedUpPactArray;
};
