/**
 *
 * ContinuousImprovementModal
 *
 */

import { ChangeEvent } from 'react';
import {
	Button,
	Checkbox,
	Flex,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DeadlineIcon, PdfIcon } from 'assets/icons';
import { Controller, useForm } from 'react-hook-form';
import { IContinuousImprovementRequest } from 'types/resultEvaluation';
import * as yup from 'yup';

export type ContinuousImprovementType = 'solicitacaoPlanoAcao' | 'solicitacaoCausaRaiz' | 'solicitacaoAnaliseCritica';

export interface IContinuousImprovementForm {
	id?: string | number;
	solicitacaoPlanoAcao: boolean;
	solicitacaoCausaRaiz: boolean;
	solicitacaoAnaliseCritica: boolean;
}

interface ContinuousImprovementModalProps {
	onCreate: (data: IContinuousImprovementRequest) => void;
	onUpdate: (data: IContinuousImprovementRequest) => void;
	defaultValues?: IContinuousImprovementForm;
}

const ContinuousImprovementModal = ({ onCreate, onUpdate, defaultValues }: ContinuousImprovementModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		button: {
			pl: '1.375rem',
			pr: '0.5rem',
			fontSize: '1rem',
			h: '2.75rem',
			backgroundColor: '#FFF5EB',
			color: '#000000',
			border: '1px solid',
			borderColor: '#FFD0A0',
			iconSpacing: '0.5rem',

			_hover: {
				backgroundColor: '#FFCF5C',
			},
		},
		modalContainer: {
			width: '34.625rem',
			maxWidth: '34.625rem',
		},
		modalHeader: {
			fontSize: '1rem',
			marginLeft: '10px',
			color: '#000',
			fontWeight: 'medium',
			p: '2.5rem 2.75rem 2.75rem 2.5rem',
		},
		modalCloseButton: {
			top: '2.25rem',
			right: '2.25rem',
			color: '#C6C6C6',
		},
		modalContent: {
			display: 'flex',
			alignItems: 'center',
			gap: '0.5rem',
		},
		text: {
			fontSize: '0.875rem',
		},
		textMedium: {
			fontSize: '0.875rem',
			fontWeight: 'medium',
		},
		textTypeArchive: {
			fontSize: '0.875rem',
			color: '#999999',
			textAlign: 'center',
		},
		inputContainer: {
			flexDir: 'column',
			backgroundColor: '#F9FAFC',
			borderRadius: '4px',
			p: '0.5rem',
			maxW: '10.375rem',
		},
		inputContent: {
			flexDir: 'column',
			backgroundColor: '#FFFFFF',
			borderRadius: '4px',
			p: '0.75rem',
			mt: '0.375rem',
		},
		textContainer: {
			flexDir: 'column',
			gap: '0.75rem',
		},
		textContent: {
			flexDir: 'column',
			alignItems: 'center',
		},
	};

	const { isOpen, onOpen, onClose } = useDisclosure();

	const filesArray = [
		{
			title: 'Requerer plano de ação',
			name: 'solicitacaoPlanoAcao',
			defaultValue: defaultValues?.solicitacaoPlanoAcao,
		},
		{
			title: 'Requerer causa raiz',
			name: 'solicitacaoCausaRaiz',
			defaultValue: defaultValues?.solicitacaoCausaRaiz,
		},
		{
			title: 'Requerer análise crítica',
			name: 'solicitacaoAnaliseCritica',
			defaultValue: defaultValues?.solicitacaoAnaliseCritica,
		},
	];

	const schema = yup.object().shape({
		solicitacaoPlanoAcao: yup.boolean(),
		solicitacaoCausaRaiz: yup.boolean(),
		solicitacaoAnaliseCritica: yup.boolean(),
	});

	const { control } = useForm<IContinuousImprovementForm>({
		resolver: yupResolver(schema),
		defaultValues,
		mode: 'onChange',
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
		const fieldValue = event.target.checked;

		if (defaultValues?.id) {
			let payload = {
				solicitacaoPlanoAcao: defaultValues.solicitacaoPlanoAcao,
				solicitacaoCausaRaiz: defaultValues.solicitacaoCausaRaiz,
				solicitacaoAnaliseCritica: defaultValues.solicitacaoAnaliseCritica,
			};

			payload[fieldName as keyof typeof payload] = fieldValue;

			onUpdate(payload);
		} else {
			let payload = {
				solicitacaoPlanoAcao: false,
				solicitacaoCausaRaiz: false,
				solicitacaoAnaliseCritica: false,
			};

			payload[fieldName as keyof typeof payload] = fieldValue;

			onCreate(payload);
		}
	};

	return (
		<>
			<Button sx={styles.button} leftIcon={<DeadlineIcon />} onClick={onOpen}>
				Solicitar Melhoria Contínua
			</Button>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent sx={styles.modalContainer}>
					<ModalHeader sx={styles.modalHeader}>Qual arquivo deve ser requerido?</ModalHeader>
					<ModalCloseButton sx={styles.modalCloseButton} />
					<FormControl>
						<ModalBody sx={styles.modalContent} as="form">
							{filesArray.map((item, index) => (
								<Flex sx={styles.inputContainer} key={index}>
									<Text sx={styles.text}>{item.title}</Text>
									<Flex sx={styles.inputContent}>
										<Controller
											name={item.name as ContinuousImprovementType}
											control={control}
											defaultValue={item.defaultValue}
											render={() => (
												<Checkbox
													alignSelf="flex-end"
													onChange={e => handleChange(e, item.name)}
													defaultChecked={item.defaultValue}
												/>
											)}
										/>

										<Flex sx={styles.textContainer}>
											<PdfIcon />
											<Flex sx={styles.textContent}>
												<Text sx={styles.textMedium}>Arquivo de formato</Text>
												<Text sx={styles.textTypeArchive}>'.pdf', '.docx', '.xlsx' ou '.pptx'</Text>
											</Flex>
										</Flex>
									</Flex>
								</Flex>
							))}
						</ModalBody>
					</FormControl>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ContinuousImprovementModal;
