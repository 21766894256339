import AxiosMockAdapter from 'axios-mock-adapter';
import { ENDPOINTS } from 'services/endpoints';
import { api } from 'services/http';

export const mockMonitoringReportService = (delayResponse?: number) => {
	const mock = new AxiosMockAdapter(api, { delayResponse });

	mock
		.onGet(ENDPOINTS.getReportMonitoring)
		.reply(200, {
			desempenhoInstituicional: 0,
			relatorios: [
				{
					unidade: 'ADC ',
					numeroSetores: 2,
					mediaDesempenho: 0,
					desempenhoGeralUnidade: 0,
					etapa: 'ETAPA_UM',
					setores: [
						{
							nomeSetor: ' DIRETORIA DE CONTRATUALIZAÇÃO E GESTÃO DE INFORMAÇÃO',
							mediaPontos: 0,
							resultadoGeralSetor: 0,
							desempenhoGeralSetor: 0,
							totalizacao: [
								{
									bimestre: 'QUARTO_BIMESTRE',
									ano: 2024,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'QUINTO_BIMESTRE',
									ano: 2024,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'SEXTO_BIMESTRE',
									ano: 2024,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'PRIMEIRO_BIMESTRE',
									ano: 2025,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'SEGUNDO_BIMESTRE',
									ano: 2025,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'TERCEIRO_BIMESTRE',
									ano: 2025,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
							],
							indicadores: [
								{
									id: 213,
									nome: 'Indicador Pactuado',
									polaridade: 'MAIOR_MELHOR',
									unidadeMedida: 'PERCENTUAL',
									resultadoGeral: 0,
									pesoCalculadoGeral: 6,
									pontuacaoGeral: 0,
									desempenhoGeral: 0,
									metaGeral: 6,
									metricas: [
										{
											id: 2593,
											bimestre: 'QUARTO_BIMESTRE',
											ano: 2024,
											meta: 1,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2594,
											bimestre: 'QUINTO_BIMESTRE',
											ano: 2024,
											meta: 1,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2595,
											bimestre: 'SEXTO_BIMESTRE',
											ano: 2024,
											meta: 1,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2596,
											bimestre: 'PRIMEIRO_BIMESTRE',
											ano: 2025,
											meta: 1,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2597,
											bimestre: 'SEGUNDO_BIMESTRE',
											ano: 2025,
											meta: 1,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2598,
											bimestre: 'TERCEIRO_BIMESTRE',
											ano: 2025,
											meta: 1,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
									],
								},
							],
						},
						{
							nomeSetor: ' ASSESSORIA DE COMUNICACAO SOCIAL',
							mediaPontos: 0,
							resultadoGeralSetor: 0,
							desempenhoGeralSetor: 0,
							totalizacao: [
								{
									bimestre: 'TERCEIRO_BIMESTRE',
									ano: 2024,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'QUARTO_BIMESTRE',
									ano: 2024,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'QUINTO_BIMESTRE',
									ano: 2024,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'SEXTO_BIMESTRE',
									ano: 2024,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'PRIMEIRO_BIMESTRE',
									ano: 2025,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
								{
									bimestre: 'SEGUNDO_BIMESTRE',
									ano: 2025,
									totalPeso: 1,
									totalPontuacao: 0,
									desempenho: null,
									notaPenalizacao: 0,
								},
							],
							indicadores: [
								{
									id: 212,
									nome: 'Indicador Área Técnica',
									polaridade: 'MENOR_MELHOR',
									unidadeMedida: 'NUMERICO',
									resultadoGeral: 0,
									pesoCalculadoGeral: 6,
									pontuacaoGeral: 0,
									desempenhoGeral: 0,
									metaGeral: 300,
									metricas: [
										{
											id: 2557,
											bimestre: 'TERCEIRO_BIMESTRE',
											ano: 2024,
											meta: 50,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2558,
											bimestre: 'QUARTO_BIMESTRE',
											ano: 2024,
											meta: 50,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2559,
											bimestre: 'QUINTO_BIMESTRE',
											ano: 2024,
											meta: 50,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2560,
											bimestre: 'SEXTO_BIMESTRE',
											ano: 2024,
											meta: 50,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2561,
											bimestre: 'PRIMEIRO_BIMESTRE',
											ano: 2025,
											meta: 50,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
										{
											id: 2562,
											bimestre: 'SEGUNDO_BIMESTRE',
											ano: 2025,
											meta: 50,
											resultado: null,
											pesoCalculado: 1,
											pontuacao: null,
											desempenho: null,
										},
									],
								},
							],
						},
					],
				},
			],
			totalizacaoBimestral: [
				{
					bimestre: 'QUARTO_BIMESTRE',
					ano: 2024,
					desempenhoInstituicional: 10,
				},
				{
					bimestre: 'QUINTO_BIMESTRE',
					ano: 2024,
					desempenhoInstituicional: 20,
				},
				{
					bimestre: 'SEXTO_BIMESTRE',
					ano: 2024,
					desempenhoInstituicional: 30,
				},
				{
					bimestre: 'PRIMEIRO_BIMESTRE',
					ano: 2025,
					desempenhoInstituicional: 40,
				},
				{
					bimestre: 'SEGUNDO_BIMESTRE',
					ano: 2025,
					desempenhoInstituicional: 50,
				},
				{
					bimestre: 'TERCEIRO_BIMESTRE',
					ano: 2025,
					desempenhoInstituicional: 60,
				},
				{
					bimestre: 'TERCEIRO_BIMESTRE',
					ano: 2024,
					desempenhoInstituicional: 70,
				},
			],
		})
		.onAny()
		.passThrough();
};
