import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { TIME_TO_REDIRECT } from 'utils/constants';

export const redirect = (path: string) => setTimeout(() => (window.location.href = path), TIME_TO_REDIRECT);

export function createAxiosInstance() {
	const axios = Axios.create({
		baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
		withCredentials: true,
	});

	axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		response => response,
		async (error: AxiosError) => {
			return Promise.reject(error);
		},
	);

	return axios;
}

export default function useAxios() {
	return createAxiosInstance();
}

export function getAxios() {
	return createAxiosInstance();
}

export function createAxiosInstanceForSigesp() {
	const axios = Axios.create({
		baseURL: `${process.env.REACT_APP_API_BASE_URL_SIGESP}/api`,
		// withCredentials: true,
	});

	axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		response => response,
		async (error: AxiosError) => {
			return Promise.reject(error);
		},
	);

	return axios;
}

export function getAxiosForSigesp() {
	return createAxiosInstanceForSigesp();
}

export function createAxiosInstanceForSAU() {
	const axios = Axios.create({
		baseURL: `${process.env.REACT_APP_API_BASE_URL_SAU}/api`,
		// withCredentials: true,
	});

	axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		response => response,
		async (error: AxiosError) => {
			return Promise.reject(error);
		},
	);

	return axios;
}

export function getAxiosForSAU() {
	return createAxiosInstanceForSAU();
}
