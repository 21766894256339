/**
 *
 * AgreedTable
 *
 */

import React from 'react';
import { Box, Table as ChakraTable, SystemStyleObject, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { IColumn, IRow } from 'types/table';

export interface ICompositeColumns {
	columns: IColumn[];
	subColumns: IColumn[];
}

interface AgreedTableProps {
	compositeColumns: ICompositeColumns;
	data: IRow[];
}

const AgreedTable = ({ compositeColumns, data }: AgreedTableProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			overflowX: 'auto',

			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
			},
		},
		content: {
			minW: '62.5rem',
		},
		header: {
			backgroundColor: 'blue.700',
		},
		cell: {
			textAlign: 'center',
			textTransform: 'none',
		},
		left: {
			position: 'sticky',
			left: 0,
		},
		right: {
			position: 'sticky',
			right: 0,
		},
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<ChakraTable variant="secondary">
					<Thead>
						<Tr>
							{compositeColumns?.columns.map(column => (
								<React.Fragment key={column?.key}>
									{!column?.sub && (
										<Th colSpan={2} sx={styles.cell}>
											{column?.action ? column?.action : column?.label}
										</Th>
									)}
								</React.Fragment>
							))}
						</Tr>
					</Thead>
					<Tbody>
						<Tr>
							{compositeColumns?.columns.map((column, index) => (
								<React.Fragment key={column?.key}>
									{column?.sub && <Td>{column?.label}</Td>}
									{!column?.sub &&
										!column?.action &&
										compositeColumns?.subColumns.map(subColumn => (
											<Td key={`${index}-${subColumn?.key}`} onClick={() => subColumn.clickedColumn?.(column?.key)}>
												{subColumn?.action ? subColumn?.action : subColumn?.label}
											</Td>
										))}
								</React.Fragment>
							))}
						</Tr>

						{data?.map((data, index) => (
							<Tr key={index}>
								{compositeColumns?.columns?.map(column => {
									const isObject = typeof data[column?.key] === 'object';

									return (
										<React.Fragment key={column?.key}>
											{column?.sub && <Td key={`${index}-${column?.key}`}>{data[column?.key] as string}</Td>}
											{!column?.sub &&
												isObject &&
												compositeColumns?.subColumns.map(subColumn => {
													const objectData = data[column?.key] as IRow;

													return (
														<Td key={`${index}-${column?.key}-${subColumn?.key}`}>
															{objectData[subColumn?.key] as string}
														</Td>
													);
												})}
										</React.Fragment>
									);
								})}
							</Tr>
						))}
					</Tbody>
				</ChakraTable>
			</Box>
		</Box>
	);
};

export default AgreedTable;
