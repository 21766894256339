/**
 *
 * InputText
 *
 */
import { InfoIcon } from '@chakra-ui/icons';
import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputProps,
	SystemStyleObject,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { TableEditIconEdited } from 'assets/icons';
import { UseFormRegister } from 'react-hook-form';

interface InputTextProps extends InputProps {
	name: string;
	register?: UseFormRegister<any>;
	label?: string;
	labelVariant?: string;
	errorMessage?: string;
	errorMessageVariant?: string;
	required?: boolean;
	disabled?: boolean;
	isEditing?: boolean;
	tooltipInfo?: string;
}

const InputText = ({
	name,
	label,
	errorMessage,
	required,
	register,
	disabled,
	labelVariant,
	errorMessageVariant,
	isEditing,
	tooltipInfo,
	...rest
}: InputTextProps) => {
	const styles: Record<string, SystemStyleObject> = {
		isEditingIcon: {
			marginLeft: '0.625rem',
		},
		tooltip: {
			pb: '1rem',
			background: 'white',
			bg: 'white',
			color: 'black',
			borderWidth: '1px',
			borderColor: 'gray.300',
			borderRadius: '4px',
			fontSize: '0.8rem',
		},
		tooltipIcon: {
			boxSize: '0.8rem',
			ml: '0.5rem',
			color: 'gray.300',
			svg: {
				path: {
					fill: 'blue.700',
				},
				rect: {
					fill: 'blue.700',
				},
			},
		},
	};

	return (
		<>
			<FormControl isInvalid={!!errorMessage}>
				{label && (
					<FormLabel variant={labelVariant}>
						<Box sx={styles.labelBox}>
							{label} {required && <Text as="span">*</Text>}
						</Box>
						{isEditing && (
							<Box sx={styles?.isEditingIcon}>
								<TableEditIconEdited />
							</Box>
						)}
						{tooltipInfo && (
							<Tooltip bg="white" placement="top-start" hasArrow sx={styles?.tooltip} label={tooltipInfo}>
								<InfoIcon sx={styles?.tooltipIcon} />
							</Tooltip>
						)}
					</FormLabel>
				)}
				<Input {...register?.(name)} disabled={disabled} {...rest} />
				{errorMessage && (
					<FormErrorMessage data-testid="text--error" variant={errorMessageVariant}>
						{errorMessage}
					</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default InputText;
