import { UNIDADEMEDIDA } from './constants';
import { verifyCell } from './verifyCell';

interface INumberFormatOptions extends Intl.NumberFormatOptions {
	roundingMode?: 'ceil' | 'floor' | 'round' | 'truncate';
}

export const formatDecimalNumber = (number?: string | number, symbol?: '*' | '%') => {
	const options: INumberFormatOptions = { maximumFractionDigits: 2, roundingMode: 'floor' };
	if (number !== 0 && !number) return '';

	const formated = Number(number)?.toLocaleString('pt-BR', options);

	if (symbol) return `${formated}${symbol}`;

	return formated;
};

export const formatAndMaskCell = (number: number | undefined | null, type?: string): string => {
	const verifiedValue = verifyCell(number, type);

	if (verifiedValue === '' || verifiedValue === 'N/A') {
		return verifiedValue;
	}

	const isPercentual = type === UNIDADEMEDIDA?.PERCENTUAL?.toUpperCase();

	const numericValue = parseFloat(verifiedValue.replace(',', '.'));

	let formattedValue = formatDecimalNumber(numericValue);

	if (isPercentual) {
		formattedValue += '%';
	}

	return formattedValue;
};
