/**
 *
 * CreateIndicatorsI
 *
 */

import { useState } from 'react';
import { SystemStyleObject, Flex, Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import AttributionAgreedForm from 'app/components/AttributionOfAgreedForm';
import IndicatorsForm from 'app/components/IndicatorsForm';
import Stepper from 'app/components/Stepper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { createIndicator } from 'services/http/indicators';
import { IndicatorFormSteps, IRequestCreateIndicator } from 'types/indicators';
import { API_DEFAULT_ERROR, DATA_CONTROL_STEPS } from 'utils/constants';
import { formatPeriod } from 'utils/formatBimesters';
import { ResponseErrors } from 'utils/parseErrors';

type Params = {
	etapa: keyof typeof DATA_CONTROL_STEPS;
};

const CreateIndicatorsI = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mb: '5.5rem',
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const [formValues, setFormValues] = useState<IndicatorFormSteps>({} as IndicatorFormSteps);
	const { etapa } = useParams<Params>();

	const { mutate: createIndicatorMutate, isLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestCreateIndicator
	>(data => createIndicator(data), {
		onSuccess: () => {
			navigate(ROUTES.indicators);
			addToast({ type: 'success', description: 'O indicador foi criado com sucesso!', title: 'Sucesso!' });
		},
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const onSubmitForm = (data: IndicatorFormSteps) => {
		setFormValues(prevState => ({ ...prevState, ...data }));
	};

	const onCreateIndicatorMutate = (data: IndicatorFormSteps) => {
		const payload: IRequestCreateIndicator = {
			nome: formValues?.nome || '',
			ano: Number(formValues.ano),
			responsavelLancamento: String(formValues.responsavelLancamento?.value),
			objetivo: formValues.objetivo || '',
			polaridade: String(formValues.polaridade?.value),
			unidadeMedida: String(formValues.unidadeMedida?.value),
			metaCumulativa: formValues.metaCumulativa === 'Sim' ? true : false,
			metodologiaCalculo: formValues.metodologiaCalculo || '',
			fonteDados: formValues.fonteDados || '',
			fonteComprovacao: formValues.fonteComprovacao || '',
			nomeResponsavel: formValues.nomeResponsavel || '',
			email: formValues.email || '',
			telefone: formValues.telefone || '',
			sei: formValues?.sei || null,
			areaTecnica: {
				idPactuado: Number(formValues.areaTecnica?.value),
				nome: String(formValues.areaTecnica?.label),
				idUnidade: Number(formValues.areaTecnica?.data?.idUnidade),
			},
			objetivoEstrategico: {
				id: Number(formValues.objetivoEstrategico?.value),
				objetivo: formValues.objetivoEstrategico?.label || '',
			},
			perspectiva: {
				id: Number(formValues.perspectiva?.value),
				perspectiva: formValues.perspectiva?.label || '',
			},
			conjunto: formValues.faixasDesempenho?.data!,
			setor: {
				idSetor: Number(formValues.setor?.value),
				descricaoSetor: String(formValues.setor?.label),
			},
			atribuicaoPactuados:
				data?.pactuados?.map(item => ({
					idPactuado: Number(item.idPactuado),
					idUnidade: Number(item.idUnidade),
					pactuado: String(item.pactuado),
					periodos: formatPeriod(item.periodos!),
				})) || [],
			etapaEnum: DATA_CONTROL_STEPS[etapa!],
		};

		createIndicatorMutate(payload);
	};

	return (
		<Flex sx={styles.container}>
			<Box sx={styles.content}>
				<Stepper
					elements={[
						<IndicatorsForm onSubmit={onSubmitForm} defaultValues={formValues} />,
						<AttributionAgreedForm
							onSubmit={onCreateIndicatorMutate}
							defaultValues={formValues}
							isSubmitingForm={isLoading}
							watching={onSubmitForm}
						/>,
					]}
				/>
			</Box>
		</Flex>
	);
};

export default CreateIndicatorsI;
