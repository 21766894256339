/**
 *
 * StrategicObjectivesFilter
 *
 */

import InputText from '../InputText';
import Select from '../Select';
import { Box, SystemStyleObject, Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { IPaginationParams } from 'types/pagination';
import { IOption } from 'types/select';
import { IStrategicObjective } from 'types/strategicObjetive';
import * as yup from 'yup';

export interface IStrategicObjectiveFilterForm {
	objetivo?: string;
	periodo?: IOption;
}

interface IStrategicObjectiveParams extends IPaginationParams<IStrategicObjective>, IStrategicObjectiveFilterForm {}

export type StrategicObjectivParamsKeys = keyof IStrategicObjectiveParams;

interface StrategicObjectivesFilterProps {
	objetivePeriods: IOption[];
	isListing?: boolean;
	defaultValues?: IStrategicObjectiveFilterForm;
	onSubmitForm: (values: IStrategicObjectiveFilterForm) => void;
}

const StrategicObjectivesFilter = ({
	objetivePeriods,
	onSubmitForm,
	isListing = true,
	defaultValues,
}: StrategicObjectivesFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
		},
		content: {
			width: '100%',
		},
		fields: {
			display: 'flex',
			width: '100%',
			alignItems: 'baseline',
			gap: {
				base: '2rem',
				md: '2rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
		},

		objective: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '23rem',
			},
		},
		period: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '15rem',
			},
		},
		buttonSubmit: {
			width: '100%',
		},
		button: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '15rem' },
		},
	};

	const schema = yup.object().shape({
		objetivo: yup.string().required('Este campo é obrigatório.'),
		periodo: yup.object().shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		}),
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
		register,
	} = useForm<IStrategicObjectiveFilterForm>({
		resolver: !isListing ? undefined : yupResolver(schema),
		defaultValues: defaultValues,
	});

	const onSubmit = (values: IStrategicObjectiveFilterForm) => onSubmitForm?.(values);

	return (
		<>
			<Box sx={styles.container}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
					<Box sx={styles.fields}>
						<Box sx={styles.objective}>
							<InputText
								register={register}
								label="Objetivos estratégicos"
								name="objetivo"
								required={isListing}
								placeholder="Digite o objetivo"
								errorMessage={errors?.objetivo?.message}
								data-testid="input--objectiveName"
								type="text"
							/>
						</Box>

						<Box sx={styles.period}>
							<Controller
								name="periodo"
								control={control}
								render={({ field }) => (
									<>
										<Select
											label="Período"
											dataTestId="input--period"
											placeholder="Selecione o período"
											value={field?.value}
											onChange={field?.onChange}
											options={objetivePeriods}
											placeholderSelectedItems={`${objetivePeriods.values.name}`}
											errorMessage={errors?.periodo?.value?.message}
											required={isListing}
										/>
									</>
								)}
							/>
						</Box>
					</Box>
					<Box sx={styles.buttonSubmit}>
						<Button type="submit" sx={styles.button} data-testid="button--submit">
							Pesquisar
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default StrategicObjectivesFilter;
