/**
 *
 * ExportCaapVotingModal
 *
 */

import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { DownloadPdfIconModal, SighMainLogoIconModal } from 'assets/icons';
import { AxiosError } from 'axios';
import { useCustomToast } from 'hooks/useToast';
import { caapDecisionPhaseExportVote } from 'services/http/resultEvaluation';
import { CaapMember, CaapMembers } from 'types/caapMembers';
import { STEPS_OPTIONS_FILTER } from 'utils/constants';
import { BIMESTERS_ENUM } from 'utils/constants';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { ResponseErrors } from 'utils/parseErrors';

export interface ICaapMembersVote extends Pick<CaapMembers, 'id' | 'dataAtualizacao' | 'representacao'> {
	titular: Partial<CaapMember>;
	suplente: Partial<CaapMember>;
	voto: string;
}

export interface IExportCaapVotingModalData {
	id?: string;
	etapa?: string;
	unidade?: string;
	setor?: string;
	ano?: number;
	bimestre?: string;
	nomeIndicador?: string;
	caapMembers?: ICaapMembersVote[];
}

export interface IExportCaapVotingModalModalProps {
	onClose: () => void;
	isOpen: boolean;
	data: IExportCaapVotingModalData;
}

const ExportCaapVotingModal = ({ isOpen, onClose, data }: IExportCaapVotingModalModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxWidth: '64.25rem',
			maxHeight: '100vh',
			alignItems: 'center',
			padding: '2.687rem 3rem 2rem',
			margin: '1rem',
			overflow: 'auto',
		},
		closeIcon: { top: '3.3rem', right: '2.3rem' },
		header: {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			width: '100%',
			p: '0.7rem 1.5rem 1rem 0',
			alignItems: 'center',
			justifyContent: 'center',
			mb: '1.687rem',
		},
		button: { fontSize: '1rem', h: '2.75rem', mb: '1.5rem', w: { base: '100%', lg: '18.625rem' } },
		cardTag: {
			bg: '#3498DB',
			color: 'white',
			py: '0.125rem',
			px: '0.5rem',
			borderRadius: '0.625rem',
			fontSize: '0.75rem',
			h: '1.25rem',
		},
		title: { maxWidth: '10.25rem', justifySelf: 'center', fontSize: '1.5rem', fontWeight: 500 },
		subtitleContainer: {
			alignItems: 'center',
			mb: '2.5rem',
			gap: '0.5rem',
		},
		subTitle: { fontSize: '1.687rem', fontWeight: 'bold' },
		tableInfoBox: { padding: '1rem 1.5rem', backgroundColor: '#e6e4e4', flexDirection: 'column', mb: '2.5rem' },
		tableInfoItem: {
			width: '100%',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: '1px solid white',
			padding: '0.531rem 0 0.468rem',
			fontWeight: 500,
			fontSize: '1rem',
		},
		tableInfoFirstItem: {
			width: '100%',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: '1px solid white',
			paddingBottom: '0.468rem',
			fontWeight: 500,
			fontSize: '1rem',
		},
		tableInfoLastItem: {
			width: '100%',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingTop: '0.531rem',
			fontWeight: 500,
			fontSize: '1rem',
		},
		tableInfoData: { fontWeight: 900, fontSize: '1rem' },
		bodyContainer: { p: '0', mb: '1rem', w: '100%' },
		cardContainer: {
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			gridTemplateRows: 'repeat(2, 1fr)',
			gap: '2.5rem',
			mb: '5.562rem',
		},
		cardItem: { flexDirection: 'column', flex: '0.5', bg: '#e6f1fa', borderRadius: '0.225rem', overflow: 'hidden' },
		cardTitle: {
			bg: 'blue.500',
			color: 'white',
			padding: '0.812rem 1rem',
			h: '3.937rem',
			fontSize: '1rem',
			lineHeight: '1.2rem',
			display: 'flex',
			alignItems: 'center',
		},
		cardBody: { padding: '1rem' },
		cardSection: { gap: '2.5rem', mb: '1rem' },
		cardElementTitle: { fontSize: '1rem', fontWeight: 'bold', mb: '0.25rem' },
		cardElementValue: {
			fontSize: '1rem',
			fontWeight: 500,
			textTransform: 'capitalize',
		},
		cardStatus: { fontWeight: 900, fontSize: '1.5rem' },
		cardDate: { fontWeight: 500, fontSize: '0.75rem' },
		modalActionContainer: { justifyContent: 'center' },
	};

	const { addToast } = useCustomToast();

	const { mutate: caapDecisionPhaseExportVoteMutate } = useMutation<Blob, AxiosError<ResponseErrors>, string>(
		data => caapDecisionPhaseExportVote(data),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'votos.pdf');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const handleExportFile = () => {
		caapDecisionPhaseExportVoteMutate(data.id!);
	};

	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent sx={styles.container} data-testid="modal">
				<ModalHeader sx={styles.header}>
					<SighMainLogoIconModal />
					<Text sx={styles.title}>Votação CAAP</Text>
				</ModalHeader>
				<ModalCloseButton size="lg" sx={styles.closeIcon} />
				<ModalBody sx={styles.bodyContainer} data-testid="modal-body">
					<Flex sx={styles.subtitleContainer}>
						<Text sx={styles.cardTag} data-testid="text--etapa">
							{STEPS_OPTIONS_FILTER[data?.etapa as keyof typeof STEPS_OPTIONS_FILTER]}
						</Text>
						<Text sx={styles.subTitle} data-testid="text--indicador">
							{data?.nomeIndicador}
						</Text>
					</Flex>
					<Flex sx={styles.tableInfoBox}>
						<Flex sx={styles.tableInfoFirstItem}>
							<Text>Unidade</Text>
							<Text sx={styles.tableInfoData} data-testid="text--unidade">
								{data?.unidade}
							</Text>
						</Flex>
						<Flex sx={styles.tableInfoItem}>
							<Text>Setor</Text>
							<Text sx={styles.tableInfoData} data-testid="text--setor">
								{data?.setor}
							</Text>
						</Flex>
						<Flex sx={styles.tableInfoItem}>
							<Text>Ano</Text>
							<Text sx={styles.tableInfoData} data-testid="text--ano">
								{data?.ano}
							</Text>
						</Flex>
						<Flex sx={styles.tableInfoLastItem}>
							<Text>Bimestre</Text>
							<Text sx={styles.tableInfoData} data-testid="text--bimestre">
								{BIMESTERS_ENUM[data?.bimestre as keyof typeof BIMESTERS_ENUM]}
							</Text>
						</Flex>
					</Flex>
					<Box sx={styles.cardContainer}>
						{data?.caapMembers?.map((caapMember, index) => (
							<Flex sx={styles.cardItem} key={index}>
								<Text sx={styles.cardTitle} data-testid="text--representacao">
									{caapMember?.representacao}
								</Text>
								<Box sx={styles.cardBody}>
									<Flex sx={styles.cardSection}>
										<Box>
											<Text sx={styles.cardElementTitle}>Titular</Text>
											<Text sx={styles.cardElementValue} data-testid="text--titular">
												{caapMember?.titular?.nome?.toLowerCase()}
											</Text>
										</Box>
										<Box>
											<Text sx={styles.cardElementTitle}>Suplente</Text>
											<Text sx={styles.cardElementValue} data-testid="text--suplente">
												{caapMember?.suplente?.nome?.toLowerCase()}
											</Text>
										</Box>
									</Flex>
									<Box>
										<Text sx={styles.cardStatus} data-testid="text--voto">
											{caapMember?.voto}
										</Text>
										<Text sx={styles.cardDate} data-testid="text--data">
											{`${caapMember?.dataAtualizacao?.replace(' ', ' — ').slice(0, -3)}h`}
										</Text>
									</Box>
								</Box>
							</Flex>
						))}
					</Box>
					<Flex sx={styles.modalActionContainer}>
						<Button
							sx={styles.button}
							leftIcon={<DownloadPdfIconModal />}
							data-testid="button--donwload"
							onClick={handleExportFile}
						>
							Baixar em PDF
						</Button>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ExportCaapVotingModal;
