import AxiosMockAdapter from 'axios-mock-adapter';
import { ENDPOINTS } from 'services/endpoints';
import { api } from 'services/http';

export const mockStrategicPlanningService = (delayResponse?: number) => {
	const mock = new AxiosMockAdapter(api, { delayResponse });

	mock
		.onGet(ENDPOINTS.strategicPlanningList)
		.reply(200, [
			{
				id: 22,
				nome: 'Planejamento 2027',
				anoInicial: 2027,
				anoFinal: 2028,
				perspectivas: [
					{
						id: 27,
						perspectiva: 'Financeiro',
					},
				],
				missao: 'Teste',
				visao: 'Teste',
				valor: 'Teste',
			},
			{
				id: 23,
				nome: 'Planejamento 123',
				anoInicial: 2028,
				anoFinal: 2029,
				perspectivas: [
					{
						id: 28,
						perspectiva: 'teste',
					},
				],
				missao: 'teste2',
				visao: 'teste2',
				valor: 'teste2',
			},
			{
				id: 49,
				nome: 'Planejamento 1502',
				anoInicial: 2024,
				anoFinal: 2025,
				perspectivas: [
					{
						id: 57,
						perspectiva: 'Perspectiva Testes',
					},
				],
				missao: 'Teste',
				visao: 'Teste',
				valor: 'Teste',
			},
		])
		.onAny()
		.passThrough();
};
