/**
 *
 * Table
 *
 */

import { ArrowDownIcon } from '@chakra-ui/icons';
import {
	Box,
	Table as ChakraTable,
	Flex,
	IconButton,
	SystemStyleObject,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { IColumn, IRow, IStyle } from 'types/table';

interface TableProps {
	columns: IColumn[];
	data: IRow[];
	onSort?: (key: any) => void;
	headerCustomStyle?: SystemStyleObject;
	cellCustomStyle?: SystemStyleObject;
}

const Table = ({ columns, data, onSort, cellCustomStyle, headerCustomStyle }: TableProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			overflowX: 'auto',
			border: '1px solid',
			borderColor: 'gray.95',
			borderRadius: '4px',
		},
		content: {
			minW: '62.5rem',
		},
		header: {
			backgroundColor: 'gray.80',
			alignItems: 'center',
			gap: '0.75rem',
			...headerCustomStyle,
		},
		cell: {
			background: 'white',
			...cellCustomStyle,
		},
		left: {
			position: 'sticky',
			left: 0,
		},
		right: {
			position: 'sticky',
			right: 0,
		},
	};

	const getStyle = ({ column, isHeader = false, row }: IStyle) => {
		// Monta estilização das células da tabela
		let style = { ...styles?.cell, ...column?.columnCustomStyle };

		if (column?.sticky) style = { ...style, ...styles[column?.sticky] };

		if (isHeader) style = { ...style, ...styles?.header };

		if (row) style = { ...style, ...row };

		return style;
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<ChakraTable>
					<Thead>
						<Tr>
							{columns?.map(column => (
								<Th
									key={column?.key}
									sx={getStyle({
										column,
										isHeader: true,
									})}
								>
									<Flex sx={styles.header}>
										{column?.label}
										{column?.order && (
											<IconButton
												aria-label="sort"
												variant="unstyled"
												icon={<ArrowDownIcon />}
												onClick={() => onSort?.(column?.key)}
												data-testid="button--sort"
											/>
										)}
									</Flex>
								</Th>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{data?.map((data, index) => (
							<Tr key={index}>
								{columns?.map(column => (
									<Td
										sx={getStyle({
											column,
											index,
											row: data?.row as SystemStyleObject,
										})}
										key={`${index}-${column?.key}`}
									>
										{data[column?.key] as string}
									</Td>
								))}
							</Tr>
						))}
					</Tbody>
				</ChakraTable>
			</Box>
		</Box>
	);
};

export default Table;
