/**
 *
 * IndicatorsFormValidationHover
 *
 */
import { useState } from 'react';
import { Box, Button, SystemStyleObject } from '@chakra-ui/react';
import { FailBoldIcon, SuccessBoldIcon } from 'assets/icons';
import { formatDate } from 'utils/formatDate';

interface IndicatorsFormValidationHoverProps {
	onValidationCheck: () => void;
	validationDate?: string | null;
	canValidateManagementPact?: boolean;
}

const IndicatorsFormValidationHover = ({
	onValidationCheck,
	validationDate,
	canValidateManagementPact = false,
}: IndicatorsFormValidationHoverProps) => {
	const [isValidated, setIsValidated] = useState(!!validationDate);
	const [isExpanded, setIsExpanded] = useState(false);

	const styles: Record<string, SystemStyleObject> = {
		validationHoverBox: {
			position: 'absolute',
			right: isExpanded ? -2 : -6,
			top: '2.5%',
			transform: 'translateY(-2.5%)',
		},
		container: {
			backgroundColor: isValidated ? '#5EA82F' : '#ABABAB',
			width: isExpanded ? '9.11rem' : '3.75rem',
			height: isExpanded ? '3.875rem' : '2.5rem',
			display: 'flex',
			borderRadius: '0.25rem',
			transition: 'width 0.2s linear',
		},
		successIcon: {
			height: '100%',
			maxHeight: '2.5rem',
			paddingTop: isExpanded ? '0.875rem' : '0.841rem',
			paddingRight: isExpanded ? '0.63rem' : '1.801rem',
			paddingLeft: isExpanded ? '1.188rem' : '0.767rem',
			paddingBottom: isExpanded ? '0.841rem' : '0.841rem',
		},
		failIcon: {
			height: '100%',
			maxHeight: '2.5rem',
			paddingTop: '0.841rem',
			paddingRight: '1.801rem',
			paddingLeft: '0.767rem',
			paddingBottom: '0.841rem',
		},
		text: {
			height: '100%',
			maxHeight: '2.5rem',
			color: 'white',
			fontSize: '1rem',
			textAlign: 'left',
			paddingTop: isValidated ? '0.65rem' : '0.5rem',
			paddingRight: isValidated ? '1.5rem' : '1.313rem',
			paddingLeft: isValidated ? '0rem' : '1.313rem',
			paddingBottom: isValidated ? '0.75rem' : '0rem',
		},
		validado: {
			backgroundColor: isValidated ? '#D9D9D9' : '#5EA82F',
			height: '100%',
			maxHeight: '1.5rem',
			position: 'absolute',
			color: isValidated ? 'black' : 'white',
			fontSize: '0.75rem',
			textAlign: 'center',
			borderBottomRadius: '0.25rem',
			px: isValidated ? '0.4rem' : '3.44rem',
			py: '0.15rem',
			opacity: isExpanded ? 1 : 0,
			transition: 'opacity 0.2s linear',
			bottom: '-0.1rem',
		},
	};

	const handleMouseEnter = () => {
		setIsExpanded(true);
	};

	const handleMouseLeave = () => {
		setIsExpanded(false);
	};

	const validationCheck = () => {
		if (!isValidated) {
			onValidationCheck();
			setIsValidated(true);
		}
	};

	return (
		<Box sx={styles?.validationHoverBox}>
			<Box
				sx={styles?.container}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				data-testid="box-validation-hover"
			>
				{isValidated ? (
					<Box sx={styles?.successIcon}>
						<SuccessBoldIcon width="1.183rem" height="0.971rem" />
					</Box>
				) : isExpanded ? null : (
					<Box sx={styles?.failIcon}>
						<FailBoldIcon width="1.183rem" height="0.971rem" />
					</Box>
				)}

				{isExpanded && (
					<>
						{isValidated ? <Box sx={styles?.text}>Validado</Box> : <Box sx={styles?.text}>Não-Validado</Box>}
						{isValidated ? (
							<Box sx={styles?.validado}>Validado em: {validationDate && formatDate(validationDate)}</Box>
						) : (
							canValidateManagementPact && (
								<Box sx={styles?.validado}>
									<Button
										variant="link"
										color="white"
										fontSize="0.75rem"
										fontWeight="normal"
										data-testid="button--validate"
										onClick={() => validationCheck()}
									>
										Validar
									</Button>
								</Box>
							)
						)}
					</>
				)}
			</Box>
		</Box>
	);
};

export default IndicatorsFormValidationHover;
