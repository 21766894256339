/**
 *
 * Pagination
 *
 */

import React from 'react';
import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';

interface PaginationProps {
	onPaginate: (page: number) => void;
	currentPage: number;
	totalItems?: number;
	pageSize?: number;
	siblingCount?: number;
}

const Pagination = ({ onPaginate, currentPage, totalItems = 0, pageSize = 0, siblingCount = 1 }: PaginationProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			gap: '0.4375rem',
			fontFamily: 'Lato',
			fontSize: '1rem',
			flexWrap: 'wrap',
		},
		defaultButtonStyle: {
			userSelect: 'none',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: 'white',
			color: 'black.800',
			w: '1.625rem',
			h: '1.625rem',
			borderRadius: '0.25rem',
			border: '0.063rem solid',
			borderColor: 'gray.95',
			transition: '0.2s',
			_hover: {
				backgroundColor: 'transparent',
				filter: 'brightness(0.8)',
			},
			padding: '1rem',
		},
		activePage: {
			backgroundColor: 'gray.80',
			color: 'black.800',
			border: '0.063rem solid',
			borderColor: 'gray.95',
			_hover: {
				backgroundColor: 'gray.80',
				filter: 'brightness(0.8)',
			},
		},
		inactivePage: {
			w: {
				base: '1.625rem',
			},
			cursor: 'not-allowed',
			color: 'black.800',
			borderRadius: '0.25rem',
			border: '0',
			_hover: {
				backgroundColor: 'transparent',
				filter: 'brightness(0.8)',
			},
		},
	};

	const maxCountPaginationButtons = 8;

	const pages = Math.ceil(totalItems / pageSize);

	const showPrevious = () => {
		if (currentPage > 1) onPaginate(currentPage - 1);
	};

	const showNext = () => {
		if (currentPage < pages) onPaginate(currentPage + 1);
	};

	return (
		<>
			<Flex sx={styles?.container}>
				{Array.from({ length: pages }, (_, index) => {
					const page = index + 1;

					const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
					const rightSiblingIndex = Math.min(currentPage + siblingCount, pages);

					const shouldShowLeftDots = pages > 7 && leftSiblingIndex >= 2 && currentPage - 1 > 2;
					const shouldShowRightDots = pages > 7 && rightSiblingIndex <= pages - 2 && currentPage + 2 < pages;

					if (page === 1) {
						return (
							<React.Fragment key={`group-dots-${page}`}>
								<Box
									disabled={currentPage < 1 ? true : false}
									as="button"
									key={'previousPage'}
									onClick={showPrevious}
									data-testid="button--previous-page"
									sx={{ ...styles?.defaultButtonStyle }}
								>
									{'<'}
								</Box>

								<Box
									key={page}
									onClick={() => onPaginate(page)}
									data-testid={currentPage === page ? 'button--paginate--selected' : `button--paginate-${page}`}
									sx={
										currentPage === page
											? { ...styles?.defaultButtonStyle, ...styles?.activePage }
											: styles?.defaultButtonStyle
									}
								>
									{page}
								</Box>

								{shouldShowLeftDots && (
									<Box
										key={`dots-left`}
										data-testid="button--dots-init"
										sx={{ ...styles?.defaultButtonStyle, ...styles?.inactivePage }}
									>
										...
									</Box>
								)}
							</React.Fragment>
						);
					}

					if (page === pages) {
						return (
							<React.Fragment key={`group-dots-${page}`}>
								{shouldShowRightDots && (
									<Box
										key={`dots-right`}
										data-testid="button--dots-finish"
										sx={{ ...styles?.defaultButtonStyle, ...styles?.inactivePage }}
									>
										...
									</Box>
								)}
								<Box
									key={page}
									onClick={() => onPaginate(page)}
									data-testid={currentPage === page ? 'button--paginate--selected' : `button--paginate-${page}`}
									sx={
										currentPage === page
											? { ...styles?.defaultButtonStyle, ...styles?.activePage }
											: styles?.defaultButtonStyle
									}
								>
									{page}
								</Box>

								<Box
									key={'nextPage'}
									data-testid="button--next-page"
									sx={{ ...styles?.defaultButtonStyle }}
									onClick={showNext}
								>
									{'>'}
								</Box>
							</React.Fragment>
						);
					}

					if (
						currentPage === page ||
						currentPage - 1 === page ||
						currentPage + 1 === page ||
						(!shouldShowLeftDots && page + 3 <= maxCountPaginationButtons) ||
						(!shouldShowRightDots && pages - page + 3 < maxCountPaginationButtons)
					) {
						return (
							<Box
								key={page}
								onClick={() => onPaginate(page)}
								data-testid={currentPage === page ? 'button--paginate--selected' : `button--paginate-${page}`}
								sx={
									currentPage === page
										? { ...styles?.defaultButtonStyle, ...styles?.activePage }
										: styles?.defaultButtonStyle
								}
							>
								{page}
							</Box>
						);
					}

					return false;
				})}
			</Flex>
		</>
	);
};

export default Pagination;
