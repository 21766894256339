/**
 *
 * DeletePenaltyModal
 *
 */

import InputTextarea from '../InputTextarea';
import {
	Box,
	Button,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorIconModal } from 'assets/icons';
import { usePrompt } from 'hooks/useCustomPrompt';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface DeletePenaltyModalForm {
	justificativaExclusao: string;
}

interface DeletePenaltyModalProps {
	onClose: () => void;
	isOpen: boolean;
	onSubmit: (values: DeletePenaltyModalForm) => void;
}

const DeletePenaltyModal = ({ isOpen, onClose, onSubmit }: DeletePenaltyModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxWidth: '70.625rem',
			alignItems: 'center',
			padding: '2rem 3rem',
			margin: '1rem',
		},
		header: {
			display: 'flex',
			flexDir: 'column',
			alignItems: 'center',
			width: '100%',
		},
		icon: {
			width: '100%',
			alignContent: 'center',
		},
		title: { fontSize: '1.5rem', fontWeight: 700, textAlign: 'center' },
		bodyContainer: { p: '0', mb: '1rem', w: '100%' },
		body: { fontWeight: '600', textAlign: 'center', mb: '1rem' },
		footerContainer: { justifyContent: 'center', w: '100%', p: '0' },
		footerContent: {
			w: '100%',
			gap: { base: '1.5rem', md: '1.875rem' },
			flexDirection: { base: 'column', md: 'row' },
			alignItems: { base: 'center', md: 'end' },
		},
		button: {
			fontSize: '1rem',
			h: '2.75rem',
			px: '5.375rem',
			w: '100%',
			maxW: {
				base: '100%',
				lg: 'fit-content',
			},
		},
		text: {
			fontSize: '1.125rem',
			fontWeight: 'bold',
		},
		textarea: {
			minH: '5rem',
			h: '100%',
			resize: 'none',
		},
	};

	const schema = yup.object().shape({
		justificativaExclusao: yup
			.string()
			.required('Este campo é obrigatório.')
			.min(20, 'Mínimo de 20 caracteres.')
			.max(100, 'Máximo de 100 caracteres.'),
	});

	const {
		handleSubmit,
		formState: { errors, isDirty },
		register,
		reset,
	} = useForm<DeletePenaltyModalForm>({
		defaultValues: { justificativaExclusao: '' },
		resolver: yupResolver(schema),
	});

	usePrompt('Alterações pendentes! Deseja sair da página mesmo assim?', isDirty);

	const handleOnSubmit = (values: DeletePenaltyModalForm) => {
		reset();
		onSubmit(values);
	};

	const handleClose = () => {
		reset();
		onClose();
	};

	return (
		<Modal isCentered isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />

			<ModalContent sx={styles.container} data-testid="modal" as="form" onSubmit={handleSubmit(handleOnSubmit)}>
				<Box sx={styles.icons}>{<ErrorIconModal />}</Box>

				<ModalHeader sx={styles.header}>
					<Text sx={styles.title}>Confirmação de exclusão de penalidade</Text>
				</ModalHeader>
				<ModalCloseButton size="lg" />
				<ModalBody sx={styles.bodyContainer} data-testid="modal-body">
					<Text sx={styles.body}>
						Você tem certeza que deseja excluir esta penalidade? Este processo não pode ser desfeito.
					</Text>
					<Flex flexDir="column" gap="0.5rem" mb="2rem">
						<Box m="1rem">
							<Divider />
						</Box>
						<Text sx={styles.text}>Justificativa</Text>
						<InputTextarea
							name="justificativaExclusao"
							placeholder="Descreva a justificativa"
							sx={styles.textarea}
							register={register}
							errorMessage={errors.justificativaExclusao?.message}
							data-testid="input--justificativa"
							maxLength={100}
						/>
					</Flex>
				</ModalBody>

				<ModalFooter sx={styles.footerContainer}>
					<Flex sx={styles.footerContent}>
						<Button variant="cancel" sx={styles.button} type="submit" data-testid="button--submit">
							Quero Excluir
						</Button>
						<Button variant="secondary" sx={styles.button} data-testid="button--cancel" onClick={handleClose}>
							Voltar
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeletePenaltyModal;
