/**
 *
 * RadioButtonValidateResults
 *
 */

import {
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Radio,
	RadioGroup,
	RadioGroupProps,
	SystemStyleObject,
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';

export interface IRadioData {
	label: string;
	value: string;
	dataTestId: string;
}

interface IRadioProps extends Omit<RadioGroupProps, 'children'> {
	label?: string;
	data: IRadioData[];
	errorMessage?: string;
}

interface ICheckboxProps {
	name: string;
	label: string;
	register?: UseFormRegister<any>;
	dataTestId: string;
}

interface RadioButtonValidateResultsProps {
	radio: IRadioProps;
	checkbox?: ICheckboxProps;
}

const RadioButtonValidateResults = ({
	radio: { data, label, errorMessage, ...rest },
	checkbox,
}: RadioButtonValidateResultsProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			gap: '1.25rem',
			flexDir: 'column',
		},
		radioContent: {
			gap: {
				base: '1.5rem',
				lg: '1.125rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		checkbox: {
			fontFamily: 'Lato',
			fontWeight: 'medium',
			fontSize: '1rem',
			color: 'black.800',
		},
	};

	return (
		<Flex sx={styles.container}>
			<FormControl isInvalid={!!errorMessage}>
				{label && (
					<FormLabel variant="medium" data-testid="text--label" mb="1.5rem">
						{label}
					</FormLabel>
				)}
				<RadioGroup {...rest}>
					<Flex sx={styles.radioContent}>
						{data.map((item, index) => (
							<Radio key={index} value={item.value} variant="medium" data-testid={item.dataTestId}>
								{item.label}
							</Radio>
						))}
					</Flex>
				</RadioGroup>
				{errorMessage && <FormErrorMessage data-testid="text--error">{errorMessage}</FormErrorMessage>}
			</FormControl>

			{checkbox?.register && (
				<Checkbox {...checkbox?.register?.(checkbox.name)} variant="medium" data-testid={checkbox.dataTestId}>
					{checkbox.label}
				</Checkbox>
			)}
		</Flex>
	);
};

export default RadioButtonValidateResults;
