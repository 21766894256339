/**
 *
 * EditStrategicPlanningWithId
 *
 */

import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import StrategicPlanningForm from 'app/components/StrategicPlanningForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { getStrategicPlanningById, updateStrategicPlanning } from 'services/http/strategicPlanning';
import { IResponseErrors } from 'types/httpError';
import { StrategicPlanning, StrategicPlanningEdit } from 'types/strategicPlanning';
import { API_DEFAULT_ERROR } from 'utils/constants';

type IParams = {
	strategicPlanningId: string;
};

const EditStrategicPlanningWithId = () => {
	const { strategicPlanningId = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const styles: Record<string, SystemStyleObject> = {
		form: {
			mt: '3.875rem',
			mb: '3.625rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { data, isLoading } = useQuery(
		['strategicPlanning', strategicPlanningId],
		() => getStrategicPlanningById(strategicPlanningId),
		{
			onError: ({ response }) => {
				addToast({
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
					type: 'error',
				});
			},
			enabled: !!strategicPlanningId,
		},
	);

	const { mutate: updateStrategicPlanningMutate, isLoading: isLoadingSubmit } = useMutation<
		StrategicPlanning,
		AxiosError<IResponseErrors>,
		StrategicPlanningEdit
	>(data => updateStrategicPlanning(data), {
		onSuccess: () => {
			addToast({
				title: 'Sucesso!',
				description: 'O planejamento estratégico foi editado com sucesso!',
				type: 'success',
			});
			navigate(ROUTES.strategicPlanning);
		},
		onError: ({ response }) => {
			addToast({
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
				type: 'error',
			});
		},
	});

	const handleSubmit = (values: StrategicPlanning) => {
		const formatedValues = {
			...values,
			perspectivas: {
				post: values.perspectivas?.filter(perspective => perspective.status === 'POST'),
				put: values.perspectivas?.filter(perspective => perspective.status === 'PUT'),
				delete: values.perspectivas?.filter(perspective => perspective.status === 'DELETE'),
			},
		};
		updateStrategicPlanningMutate(formatedValues);
	};

	return (
		<>
			<Flex flex="1" flexDir="column">
				<PageWrapper title="Editar Planejamento Estratégico" goBackRoute={ROUTES.strategicPlanning} />
			</Flex>
			{isLoading ? (
				<Box sx={styles?.loadingSpinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				<Box sx={styles?.form}>
					<StrategicPlanningForm
						isEditing
						onSubmit={handleSubmit}
						defaultValues={data}
						isLoadingSubmit={isLoadingSubmit}
					/>
				</Box>
			)}
		</>
	);
};

export default EditStrategicPlanningWithId;
