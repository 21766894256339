/**
 *
 * RepresentativeList
 *
 */

import { useMemo } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Spinner, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import PageWrapper from 'app/components/PageWrapper';
import RepresentativeAccordion from 'app/components/RepresentativeAccordion';
import RepresentativeFilter, { RepresentativeFilteKeys } from 'app/components/RepresentativeFilter';
import { RegisterIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IRepresentativeParams, getRepresentativesList } from 'services/http/representative';
import { IResponseRepresentatives } from 'types/representative';
import { API_DEFAULT_ERROR, USER_ROLES } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parseUrlParams } from 'utils/parseUlrParams';

const RepresentativeList = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		button: {
			alignSelf: 'flex-end',
			px: '1rem',
			mb: '1rem',
		},
		addRepresentatives: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '1rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		content: {
			flexDir: 'column',
			gap: '1rem',
			mt: '1.5rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);

	const [searchParams] = useSearchParams();

	const { params, queryParams } = useMemo(() => {
		const params = parseUrlParams<RepresentativeFilteKeys>(['etapaEnum', 'setorId'], searchParams);

		const queryParams: IRepresentativeParams = cleanDeep({
			etapaEnum: params?.etapaEnum,
			setorId: params?.setorId,
		});

		return { params, queryParams };
	}, [searchParams]);

	const { data: representatives, isLoading: isRepresentativesLoading } = useQuery<
		IResponseRepresentatives[],
		AxiosError<ResponseErrors, IRepresentativeParams>
	>(['representatives', params], () => getRepresentativesList(queryParams), {
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data.message || API_DEFAULT_ERROR,
			});
		},
		enabled: !!queryParams.setorId,
	});

	/* 	const representativesAccordion = useMemo(
		() =>
			representatives?.map(representative => ({
				id: representative?.id?.toString(),
				pactuadoId: representative?.unidadeRepresentante?.idSetor?.toString(),
				nomePactuado: representative?.unidadeRepresentante?.nomePactuado,
				etapaEnum: representative?.etapa,
				representantes:
					representative?.dadosPessoaisRepresentantes?.map(data => ({
						id: data?.id?.toString(),
						name: data?.nome?.toString(),
						masp: data?.masp?.toString(),
						email: data?.email?.toString(),
					})) || [],
				dataUltimaAtualizacao: representative?.dataUltimaAtualizacao,
			})) || [],
		[representatives],
	); */

	return (
		<>
			<Flex sx={styles.container}>
				<PageWrapper title="Consulta de Representantes" />
				<Flex sx={styles.addRepresentatives}>
					{canViewAuditHistory && (
						<Button
							variant="unstyled"
							leftIcon={<RepeatClockIcon />}
							sx={styles.buttonShowModalAudit}
							onClick={onOpenAuditModal}
						>
							Histórico de Alterações
						</Button>
					)}
					{hasPermission && (
						<Button
							leftIcon={<RegisterIcon color="#3EA2A2" />}
							sx={styles.button}
							onClick={() => navigate(ROUTES.createRepresentative)}
							data-testid="button--createRepresentatives"
							variant={'redirect'}
						>
							Vincular Representante
						</Button>
					)}
				</Flex>

				<RepresentativeFilter />

				{isRepresentativesLoading && queryParams.setorId ? (
					<Box sx={styles?.loadingSpinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Box>
				) : (
					<Flex sx={styles.content}>
						<RepresentativeAccordion representatives={representatives || []} />
					</Flex>
				)}
			</Flex>
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</>
	);
};

export default RepresentativeList;
