/**
 *
 * Stepper
 *
 */

import React, { cloneElement, useCallback, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface StepperProps {
	elements: JSX.Element[];
}

const Stepper = ({ elements }: StepperProps) => {
	const [stepNumber, setStepNumber] = useState(0);

	const nextStep = useCallback(() => {
		if (stepNumber >= elements.length - 1) return;
		setStepNumber(stepNumber + 1);
	}, [elements.length, stepNumber]);

	const prevStep = useCallback(() => {
		if (stepNumber <= 0) return;
		setStepNumber(stepNumber - 1);
	}, [stepNumber]);

	return (
		<Flex>
			{elements.map((element, index) => {
				return stepNumber === index ? (
					<Box key={index} w="100%">
						{cloneElement(element, { nextStep, prevStep })}
					</Box>
				) : (
					<React.Fragment key={index}></React.Fragment>
				);
			})}
		</Flex>
	);
};

export default Stepper;
