/**
 *
 * EditIndicators
 *
 */
import { useState } from 'react';
import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttributionAgreedForm from 'app/components/AttributionOfAgreedForm';
import IndicatorsForm from 'app/components/IndicatorsForm';
import Stepper from 'app/components/Stepper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { getIndicatorById, updateIndicator, validateIndicator } from 'services/http/indicators';
import { IndicatorFormSteps, IRequestEditIndicator, IResponseIndicatorById } from 'types/indicators';
import { API_DEFAULT_ERROR, POLARITIES, RESPONSAVELLANCAMENTO, UNIDADEMEDIDA } from 'utils/constants';
import { formatPeriod, formatResponsePeriodMetric, getAgreedsPeriodsToDelete } from 'utils/formatBimesters';
import { ResponseErrors } from 'utils/parseErrors';

type IParams = {
	indicatorId: string;
};

const EditIndicators = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mb: '5.5rem',
		},
		spinner: {
			mt: '3.7rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { indicatorId = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const [formValues, setFormValues] = useState<IndicatorFormSteps>({});

	const {
		data: indicator,
		isLoading: isIndicadoresLoading,
		refetch: refetchIndicator,
	} = useQuery<IResponseIndicatorById, AxiosError<ResponseErrors>, IResponseIndicatorById>(
		['indicador', indicatorId],
		() => getIndicatorById(indicatorId),
		{
			onSuccess: data => {
				setFormValues({
					...data?.indicador,
					sei: data?.indicador?.sei || '',
					id: String(data?.indicador?.id),
					ano: data?.indicador?.ano,
					areaTecnica: {
						value: Number(data?.indicador?.areaTecnica.idPactuado),
						label: String(data?.indicador?.areaTecnica.nome),
						data: data.indicador.areaTecnica,
					},
					responsavelLancamento: {
						value: String(data?.indicador?.responsavelLancamento),
						label: RESPONSAVELLANCAMENTO[data?.indicador?.responsavelLancamento as keyof typeof RESPONSAVELLANCAMENTO],
					},
					objetivoEstrategico: {
						value: Number(data?.indicador?.objetivoEstrategico.id),
						label: String(data?.indicador?.objetivoEstrategico.objetivo),
					},
					perspectiva: {
						value: Number(data?.indicador?.perspectiva.id),
						label: String(data?.indicador?.perspectiva.perspectiva),
					},
					unidadeMedida: {
						value: String(data?.indicador?.unidadeMedida),
						label: UNIDADEMEDIDA[data?.indicador?.unidadeMedida as keyof typeof UNIDADEMEDIDA],
					},
					polaridade: {
						value: String(data?.indicador?.polaridade),
						label: POLARITIES[data?.indicador?.polaridade as keyof typeof POLARITIES],
					},
					metaCumulativa: data?.indicador?.metaCumulativa === true ? 'Sim' : 'Não',
					faixasDesempenho: {
						value: Number(data?.indicador?.conjunto?.id),
						label: String(data?.indicador?.conjunto?.nome),
						data: data?.indicador?.conjunto,
					},
					etapaEnum: data?.indicador?.etapaEnum,
					setor: {
						value: Number(data?.indicador?.setor.idSetor),
						label: String(data?.indicador?.setor.descricaoSetor),
					},
					tags: data?.pactuados.map(item => ({
						value: item.idPactuadoSigesp,
						label: item.pactuado,
						idUnidade: item.idUnidade,
					})),
					pactuados: data?.pactuados.map(item => ({
						id: item.idAtribuicaoPactuado,
						idAtribuicaoPactuado: item.idAtribuicaoPactuado,
						idPactuadoSigesp: item.idPactuadoSigesp,
						idPactuado: item.idPactuadoSigesp,
						idUnidade: item.idUnidade,
						pactuado: item.pactuado,
						periodos: formatResponsePeriodMetric(item.periodos),
					})),
				});
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!indicatorId,
		},
	);

	const { mutate: validateIndicatorMutate } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => validateIndicator(data),
		{
			onSuccess: () => {
				addToast({
					type: 'success',
					description: 'A alteração dos campos e a validação foram realizadas com sucesso.',
					title: 'Sucesso!',
				});
				refetchIndicator();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	let type = 'EDIT';
	const {
		mutate: updateIndicatorMutate,
		isLoading,
		isSuccess: isUpdateIndicatorSuccess,
	} = useMutation<void, AxiosError<ResponseErrors>, IRequestEditIndicator>(
		data => updateIndicator(data, Number(indicatorId)),
		{
			onSuccess: () => {
				if (type === 'EDIT') {
					navigate(ROUTES.indicators);
					addToast({ type: 'success', description: 'Indicador foi editado!', title: 'Sucesso!' });
				}
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const onSubmitForm = (data: IndicatorFormSteps) => {
		setFormValues(prevState => ({ ...prevState, ...data }));
	};

	const handleSubmit = (data: IndicatorFormSteps) => {
		const agreedToDelete = data.pactuados?.filter(pactuado => pactuado.request === 'DELETE');

		const payload: IRequestEditIndicator = {
			nome: formValues.nome || '',
			ano: Number(formValues.ano),
			objetivo: formValues.objetivo || '',
			perspectiva: {
				id: Number(formValues.perspectiva?.value),
				perspectiva: String(formValues.perspectiva?.label),
			},
			objetivoEstrategico: {
				id: Number(formValues.objetivoEstrategico?.value),
				objetivo: String(formValues.objetivoEstrategico?.label),
			},
			conjunto: formValues.faixasDesempenho?.data!,
			metodologiaCalculo: formValues.metodologiaCalculo || '',
			polaridade: String(formValues.polaridade?.value),
			unidadeMedida: String(formValues.unidadeMedida?.value),
			nomeResponsavel: formValues.nomeResponsavel || '',
			email: formValues.email || '',
			telefone: formValues.telefone || '',
			metaCumulativa: formValues.metaCumulativa === 'Sim' ? true : false,
			responsavelLancamento: String(formValues.responsavelLancamento?.value),
			fonteComprovacao: formValues.fonteComprovacao || '',
			fonteDados: formValues.fonteDados || '',
			areaTecnica: {
				idPactuado: Number(formValues.areaTecnica?.value),
				nome: String(formValues.areaTecnica?.label),
				idUnidade: Number(formValues.areaTecnica?.data?.idUnidade),
			},
			setor: {
				idSetor: Number(formValues.setor?.value),
				descricaoSetor: String(formValues.setor?.label),
			},
			sei: formValues.sei || null,
			atribuicaoPactuados: {
				post:
					data.pactuados
						?.filter(pactuado => pactuado.request === 'POST')
						.map(item => ({
							idPactuado: Number(item.idPactuado),
							pactuado: String(item.pactuado),
							idUnidade: Number(item.idUnidade),
							ano: Number(item.periodos?.[0].ano),
							periodos: formatPeriod(item.periodos || []),
						})) || [],
				put:
					data.pactuados
						?.filter(pactuado => pactuado.request === 'PUT')
						.map(item => ({
							id: Number(item.id),
							periodos: formatPeriod(item.periodos || []),
						})) || [],
				delete: agreedToDelete ? getAgreedsPeriodsToDelete(agreedToDelete) : [],
			},
			etapaEnum: String(formValues?.etapaEnum),
		};

		updateIndicatorMutate(payload);
	};

	const handleEdit = (data: IndicatorFormSteps) => {
		handleSubmit(data);
	};

	const handleValidate = (values: IndicatorFormSteps, changed: boolean) => {
		type = 'VALIDATE';
		if (changed) {
			handleSubmit(values);
			if (isUpdateIndicatorSuccess) {
				validateIndicatorMutate(indicatorId);
			}
		}
		validateIndicatorMutate(indicatorId);
	};

	return (
		<>
			<Flex sx={styles.container}>
				{isIndicadoresLoading ? (
					<Box sx={styles?.spinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Box>
				) : (
					<Box sx={styles.content}>
						<Stepper
							elements={[
								<IndicatorsForm
									onSubmit={onSubmitForm}
									isEditing
									defaultValues={formValues}
									validationDate={indicator?.indicador.dataValidacao}
									onHandleValidate={handleValidate}
								/>,
								<AttributionAgreedForm
									onSubmit={handleEdit}
									defaultValues={formValues}
									isSubmitingForm={isLoading}
									watching={onSubmitForm}
									isEditing
								/>,
							]}
						/>
					</Box>
				)}
			</Flex>
		</>
	);
};

export default EditIndicators;
