import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatDateToWeekday(dateString: string): string {
	const date = parseISO(dateString);
	const weekDAy = format(date, 'EEEE', { locale: ptBR });
	const hour = format(date, 'HH:mm');

	return `${weekDAy.toLowerCase()} ${hour}h`;
}
