import { IPactForm } from '.';
import GoalInput from './GoalInput';
import { Box, Grid, SystemStyleObject, Text } from '@chakra-ui/react';
import { Control, useFieldArray } from 'react-hook-form';

export interface GoalGroupProps {
	control: Control<IPactForm>;
	isReadOnly?: boolean;
	nestedIndex: number;
	fieldErrors: any;
	isEditing: boolean;
	onHandleReplicateBimester: () => void;
}

const GoalGroup = ({
	control,
	isReadOnly = false,
	nestedIndex,
	fieldErrors,
	isEditing = false,
	onHandleReplicateBimester,
}: GoalGroupProps) => {
	const styles: Record<string, SystemStyleObject> = {
		title: {
			color: 'black.800',
			fontSize: '1.25rem',
			fontWeight: 'bold',
			paddingBottom: '1.5rem',
			paddingTop: '1.5rem',
		},
		gridFields: {
			w: { base: '100%', lg: '60rem' },
			gap: '3.75rem',
			rowGap: '2rem',
			gridTemplateColumns: {
				md: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
			maxWidth: { base: '18.75rem', md: '40.625rem', lg: '56.25rem' },
		},
	};

	const { fields } = useFieldArray({
		name: `indicadores[${nestedIndex}].periodos` as `indicadores.0.periodos`,
		control,
	});

	return (
		<>
			{fields.map((item, index) => (
				<Box key={item.id}>
					<Text sx={styles.title} data-testid={`label--indicadores.${nestedIndex}.ano.${index}`}>
						{item.ano}
					</Text>
					<Grid sx={styles.gridFields}>
						<GoalInput
							control={control}
							isReadOnly={isReadOnly}
							fieldErrors={fieldErrors}
							nestedIndex={nestedIndex}
							nestedGroupIndex={index}
							isEditing={isEditing}
							onReplicateBimester={onHandleReplicateBimester}
						/>
					</Grid>
				</Box>
			))}
		</>
	);
};

export default GoalGroup;
