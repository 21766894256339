import { BIMESTER_ENUM_TO_VALUE, BIMESTERS_ENUM, TEXT_TO_BIMESTERS_ENUM } from './constants';
import { unMaskThousands } from './Numbers';
import { AttributionAgreed } from 'app/components/AttributionOfAgreedForm';
import { getMonth, getYear } from 'date-fns';
import { IPeriodYear } from 'types/agreeds';
import { IRequestEditAgreedDelete } from 'types/indicators';
import { IRequestEditManagementPactDelete, Pacts } from 'types/managementPact';
import { IResponsePeriodMetric } from 'types/period';

export interface LastActivePact {
	ano: number;
	bimestre: string;
}

interface Period {
	id?: number;
	ano: number;
	periodoAtribuicaoPactuado: string;
	meta: number;
	peso: number;
}

export const getBimester = (date: Date) => Math.ceil((getMonth(date) + 1) / 2);

export const getBimesterFromDate = (year: number, date: Date) => {
	const bimester = getBimester(date);

	return {
		ano: year,
		bimestre: TEXT_TO_BIMESTERS_ENUM[`${bimester}º Bimestre` as keyof typeof TEXT_TO_BIMESTERS_ENUM],
	};
};

export const getBimesterFromDateControlData = (date: Date) => {
	const bimester = getBimester(date);

	return TEXT_TO_BIMESTERS_ENUM[`${bimester}º Bimestre` as keyof typeof TEXT_TO_BIMESTERS_ENUM];
};

export const getLastBimester = (currentYear: number, date: Date) => {
	const currentBimester = getBimester(date);

	let bimester = 0;
	let year = currentYear;

	if (currentBimester < 2) {
		bimester = 6;
		year = currentYear - 1;
	} else {
		bimester = currentBimester;
	}

	return {
		ano: year,
		bimestre: TEXT_TO_BIMESTERS_ENUM[`${bimester}º Bimestre` as keyof typeof TEXT_TO_BIMESTERS_ENUM],
	};
};

export const getNextSixBimesters = (data: LastActivePact) => {
	const initialBimester = Number(BIMESTER_ENUM_TO_VALUE[data.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE]);
	const MIN_RANGE_TOBE_RETROACTIVE = 2;

	const currentBimester = Math.round((new Date().getMonth() + 1) / 2);

	let qtd = initialBimester;
	let periodosAnoAtual = [];
	let periodosProxAno = [];

	const currentYear = getYear(new Date());

	const isRetroactiveAndDisable = (ano: number, bimester: number) => {
		const isCurrentYear = ano === currentYear;

		if (isCurrentYear && currentBimester - bimester >= MIN_RANGE_TOBE_RETROACTIVE) return true;

		return false;
	};

	const isRetroactive = (ano: number, bimester: number) => {
		const isCurrentYear = ano === currentYear;

		if (ano > currentYear) return false;
		if (isCurrentYear && bimester >= currentBimester) return false;
		if (isCurrentYear && currentBimester - bimester > 0) return false;

		return true;
	};

	if (initialBimester === 6) {
		let qtdProxAno = 1;

		while (qtdProxAno <= 6) {
			periodosProxAno.push({
				id: undefined,
				periodoAtribuicaoPactuado: `${qtdProxAno}º Bimestre`,
				meta: 1,
				peso: 1.0,
				isEditavel: true,
			});

			qtdProxAno++;
		}

		return [
			{
				ano: data.ano + 1,
				periodos: periodosProxAno,
			},
		];
	} else {
		while (qtd <= 6) {
			periodosAnoAtual.push({
				id: undefined,
				periodoAtribuicaoPactuado: `${qtd}º Bimestre`,
				meta: 1,
				peso: isRetroactiveAndDisable(data.ano, qtd) || isRetroactive(data.ano, qtd) ? 0 : 1.0,
				isEditavel: isRetroactiveAndDisable(data.ano, qtd) || isRetroactive(data.ano, qtd) ? false : true,
			});

			qtd++;
		}

		let qtdProxAno = 1;
		const tamanho = 6 - periodosAnoAtual.length;

		while (qtdProxAno <= tamanho) {
			periodosProxAno.push({
				id: undefined,
				periodoAtribuicaoPactuado: `${qtdProxAno}º Bimestre`,
				meta: 1,
				peso: isRetroactiveAndDisable(data.ano + 1, qtdProxAno) ? 0 : 1.0,
				isEditavel: isRetroactiveAndDisable(data.ano + 1, qtdProxAno) ? false : true,
			});

			qtdProxAno++;
		}

		return [
			{
				ano: data.ano,
				periodos: periodosAnoAtual,
			},
			{
				ano: data.ano + 1,
				periodos: periodosProxAno,
			},
		];
	}
};

export const formatPeriod = (data: IPeriodYear[]) => {
	let periodos: Period[] = [];

	data.forEach(item => {
		item.periodos.forEach(periodo => {
			periodos.push({
				id: periodo.id,
				ano: item.ano,
				periodoAtribuicaoPactuado:
					TEXT_TO_BIMESTERS_ENUM[periodo.periodoAtribuicaoPactuado as keyof typeof TEXT_TO_BIMESTERS_ENUM],
				meta: formatFromStringToNumber(periodo.meta!),
				peso: formatFromStringToNumber(periodo.peso),
			});
		});
	});

	return periodos;
};

export const formatResponsePeriodMetric = (data: IResponsePeriodMetric[]) => {
	let periodos: IPeriodYear[] = [];
	let auxPeriodo1: IPeriodYear = {} as IPeriodYear;
	let auxPeriodo2: IPeriodYear = {} as IPeriodYear;

	data.forEach(item => {
		const formatedPeriod = {
			id: item.idMetrica,
			idIndicadorPactoGestao: item.idIndicadorPactoGestao,
			periodoAtribuicaoPactuado: BIMESTERS_ENUM[item.periodoAtribuicaoPactuadoEnum as keyof typeof BIMESTERS_ENUM],
			meta: item.meta,
			peso: item.peso,
			isEditavel: item.isEditavel,
			pesoCalculado: item.pesoCalculado,
			isRetroativo: item.isRetroativo,
		};

		if (!auxPeriodo1.ano && !auxPeriodo2.ano) {
			auxPeriodo1 = {
				ano: item.ano,
				periodos: [formatedPeriod],
			};
		} else if (auxPeriodo1.ano === item.ano && !auxPeriodo1.periodos.some(item => item === formatedPeriod)) {
			auxPeriodo1.periodos.push(formatedPeriod);
		}

		if (auxPeriodo1.ano !== item.ano && !auxPeriodo2.ano) {
			auxPeriodo2 = {
				ano: item.ano,
				periodos: [formatedPeriod],
			};
		}

		if (auxPeriodo2.ano === item.ano && !auxPeriodo2?.periodos?.some(item => item === formatedPeriod)) {
			auxPeriodo2.periodos.push(formatedPeriod);
		}
	});

	periodos.push(auxPeriodo1);
	periodos.push(auxPeriodo2);

	return periodos;
};

export const getPeriodsToDelete = (indicatorsToDelete: Pacts[]) => {
	let indicatorsPeriodsIds: IRequestEditManagementPactDelete[] = [];

	indicatorsToDelete?.forEach(indicador => {
		indicador.periodos?.forEach(indicadorPeriodo => {
			indicadorPeriodo?.periodos?.forEach(periodo => {
				if (periodo.idIndicadorPactoGestao) {
					indicatorsPeriodsIds.push({ idPactoGestaoIndicadores: periodo.idIndicadorPactoGestao });
				}
			});
		});
	});

	return indicatorsPeriodsIds;
};

export const getAgreedsPeriodsToDelete = (agreedsToDelete: AttributionAgreed[]) => {
	let agreedsPeriodsIds: IRequestEditAgreedDelete[] = [];

	agreedsToDelete.forEach(pactuado => {
		pactuado.periodos?.forEach(pactuadoPeriodo => {
			pactuadoPeriodo.periodos?.forEach(periodo => {
				if (periodo.idIndicadorPactoGestao) {
					agreedsPeriodsIds.push({ idPactoGestaoIndicadores: periodo.idIndicadorPactoGestao });
				}
			});
		});
	});

	return agreedsPeriodsIds;
};

export const getLastBimesterEnum = (bimester: string) => {
	let lastBimester = '';

	if (bimester === 'PRIMEIRO_BIMESTRE') lastBimester = 'SEXTO_BIMESTRE';
	else if (bimester === 'SEGUNDO_BIMESTRE') lastBimester = 'PRIMEIRO_BIMESTRE';
	else if (bimester === 'TERCEIRO_BIMESTRE') lastBimester = 'SEGUNDO_BIMESTRE';
	else if (bimester === 'QUARTO_BIMESTRE') lastBimester = 'TERCEIRO_BIMESTRE';
	else if (bimester === 'QUINTO_BIMESTRE') lastBimester = 'QUARTO_BIMESTRE';
	else if (bimester === 'SEXTO_BIMESTRE') lastBimester = 'QUINTO_BIMESTRE';

	return lastBimester;
};

export const formatFromStringToNumber = (value: string | number) => {
	return typeof value === 'string' ? unMaskThousands(value) : value;
};
