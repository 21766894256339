export const formatEnum = (value: string | number) => {
	const palavras = String(value)
		.split('_')
		.map(item => {
			return item[0].toUpperCase() + item.substring(1).toLowerCase();
		})
		.join(' ');
	return String(palavras);
};

export const formatDateLate = (value: string | number) => {
	const anyDate = String(value).slice(1);
	return Number(anyDate);
};

export const formatDesempenhoEnum = (currValue: number, prevValue?: number) => {
	let formattedDesempenhoEnum = 'NEUTRO';

	if (prevValue) {
		if (prevValue === currValue) formattedDesempenhoEnum = 'NEUTRO';
		if (prevValue > currValue) formattedDesempenhoEnum = 'NEGATIVO';
		if (prevValue < currValue) formattedDesempenhoEnum = 'POSITIVO';
	}

	return formattedDesempenhoEnum;
};
