/**
 *
 * ResultEvaluationForm
 *
 */

import { IResultEvaluationForm } from '.';
import { Box, Divider, Flex, SystemStyleObject, Text } from '@chakra-ui/react';

interface ResultEvaluationVoteCardProps {
	data?: IResultEvaluationForm;
}

const ResultEvaluationVoteCard = ({ data }: ResultEvaluationVoteCardProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
			px: '2rem',
			pt: '1.5rem',
		},
		title: {
			fontSize: '1rem',
			fontWeight: 'bold',
			mb: '1.25rem',
		},
		content: {
			gap: '4.125rem',
			px: '1.5rem',
			mb: '2rem',
		},
		text: {
			fontSize: '1rem',
			fontWeight: 'medium',
		},
		lineContainer: {
			h: '0.5rem',
			border: '1px solid',
			borderColor: '#3EA2A2',
			borderRadius: '0.5rem',
		},
		line: {
			backgroundColor: '#3EA2A2',
			maxW: '100%',
			h: '100%',
		},
		votingContainer: {
			flexDir: 'column',
			w: '100%',
		},
	};

	const votingCalc = (value: number) => {
		return (value * 100) / data?.votacoes?.totalVotos!;
	};

	const votes = [
		{
			label: 'Inabilitado',
			value: data?.votacoes?.totalInabilitado,
		},
		{
			label: 'Ausentes',
			value: data?.votacoes?.totalAusente,
		},
		{
			label: 'Favorável',
			value: data?.votacoes?.totalFavoravel,
		},
		{
			label: 'Contrário',
			value: data?.votacoes?.totalContrario,
		},
		{
			label: 'Abstenção',
			value: data?.votacoes?.totalAbstencao,
		},
	];

	return (
		<Flex sx={styles.container}>
			<Text sx={styles.title}>Resultado da Votação</Text>
			<Flex sx={styles.content}>
				{votes.map(item => (
					<Flex key={item.label} sx={styles.votingContainer}>
						<Text sx={styles.text}>{item.label}</Text>
						<Flex>
							<Flex sx={styles.votingContainer}>
								<Text sx={styles.text}>{item.value ? item.value : 0}</Text>
								<Box sx={styles.lineContainer}>
									{item?.value! > 0 && <Box sx={styles.line} w={`${votingCalc(item.value!)}%`} />}
								</Box>
							</Flex>
						</Flex>
					</Flex>
				))}
			</Flex>
			<Divider />
		</Flex>
	);
};

export default ResultEvaluationVoteCard;
