/**
 *
 * EditStrategicObjective
 *
 */

import { useMemo } from 'react';
import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import StrategicObjectivesForm, { IStrategicObjectiveForm } from 'app/components/StrategicObjectivesForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import {
	IRequestStrategicObjective,
	editStrategicObjective,
	getStrategicObjectiveById,
} from 'services/http/strategicObjetive';
import { IStrategicObjective } from 'types/strategicObjetive';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';

type IParams = {
	objectiveId: string;
};

const EditStrategicObjective = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.875rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { objectiveId = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { data: strategicObjective, isLoading: isStrategicObjectiveLoading } = useQuery<
		IStrategicObjective,
		AxiosError<ResponseErrors>,
		IStrategicObjective
	>(['strategic-objectve', objectiveId], () => getStrategicObjectiveById(objectiveId), {
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data?.message || API_DEFAULT_ERROR }),
		enabled: !!objectiveId,
	});

	const defaultValues: IStrategicObjectiveForm = useMemo(() => {
		return {
			objetivo: strategicObjective?.objetivo || '',
			descricao: strategicObjective?.descricao || '',
			codigo: strategicObjective?.codigo || '',
			perspectiva: {
				label: strategicObjective?.perspectiva?.perspectiva || '',
				value: strategicObjective?.perspectiva?.id || '',
			},
			periodo: {
				label: strategicObjective?.periodo || '',
				value: strategicObjective?.periodo || '',
			},
		};
	}, [strategicObjective]);

	const { mutate: editStrategicObjectveMutate, isLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestStrategicObjective
	>(data => editStrategicObjective(data, objectiveId), {
		onSuccess: () => {
			navigate(ROUTES.strategicObjectives);
			addToast({ type: 'success', description: 'Objetivo estratégico foi editado!', title: 'Sucesso!' });
		},
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data?.message || API_DEFAULT_ERROR }),
	});

	const handleSubmit = (data: IStrategicObjectiveForm) => {
		editStrategicObjectveMutate({
			objetivo: data.objetivo,
			descricao: data.descricao,
			periodo: String(data.periodo.value),
			codigo: data.codigo || null,
			idPerspectiva: Number(data.perspectiva.value),
		});
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Editar Objetivo" goBackRoute={ROUTES.strategicObjectives} />

			{isStrategicObjectiveLoading ? (
				<Box sx={styles?.loadingSpinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				<Box sx={styles.content}>
					<StrategicObjectivesForm
						isEditing
						defaultValues={defaultValues}
						onSubmitForm={handleSubmit}
						isSubmitingForm={isLoading}
					/>
				</Box>
			)}
		</Flex>
	);
};

export default EditStrategicObjective;
