/**
 *
 * CreateStrategicPlanning
 *
 */

import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import StrategicPlanningForm from 'app/components/StrategicPlanningForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { createStrategicPlanning } from 'services/http/strategicPlanning';
import { StrategicPlanning } from 'types/strategicPlanning';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';

const CreateStrategicPlanning = () => {
	const styles: Record<string, SystemStyleObject> = {
		form: {
			mt: '3.875rem',
			mb: '3.625rem',
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { mutate: createPlanning, isLoading: isCreating } = useMutation<
		StrategicPlanning,
		AxiosError<ResponseErrors>,
		StrategicPlanning
	>(data => createStrategicPlanning(data), {
		onSuccess: () => {
			addToast({
				type: 'success',
				title: 'Sucesso!',
				description: 'O planejamento estratégico foi cadastrado com sucesso!',
			});
			navigate(ROUTES.strategicPlanning);
		},
		onError: error => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: error?.response?.data?.message || API_DEFAULT_ERROR,
			});
		},
	});

	const handleSubmit = (values: StrategicPlanning) => {
		const perspectives = values.perspectivas.map(value => ({ perspectiva: value.perspectiva }));
		createPlanning({
			...values,
			perspectivas: perspectives,
		});
	};

	return (
		<Flex flex="1" flexDir="column">
			<PageWrapper title="Cadastrar Planejamento Estratégico" goBackRoute={ROUTES.strategicPlanning} />

			<Box sx={styles?.form}>
				<StrategicPlanningForm onSubmit={handleSubmit} isLoadingSubmit={isCreating} />
			</Box>
		</Flex>
	);
};

export default CreateStrategicPlanning;
