import cleanDeep from 'clean-deep';
import { getAxios, getAxiosForSigesp } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import {
	CaapMemberFunction,
	CaapMembers,
	ICaapMemberFilterParams,
	IRequestCaap,
	IResponseCaapMember,
	IResponseCaapMemberFilter,
} from 'types/caapMembers';

export interface ICaapMemberParams {
	siglaUnidade?: string;
	nomeMembro?: string;
	masp?: string;
	idsUnidades: string[];
}

export const getCaapMembersList = async (): Promise<CaapMembers[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.caapMembersListAll);
	return response.data;
};

export const getCaapMembersFromSapt = async (params?: ICaapMemberParams): Promise<IResponseCaapMember[]> => {
	const api = getAxiosForSigesp();
	const response = await api.get(ENDPOINTS.caapMembers, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

export const getCaapFunctions = async (): Promise<CaapMemberFunction[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.caapFunctions);
	return response.data;
};

export const createCaapMembers = async (data: CaapMembers[]): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.caapCreate, {
		listaComposicao: data,
	});
	return response.data;
};

export const getCaapMemberFilter = async (params: ICaapMemberFilterParams): Promise<IResponseCaapMemberFilter[]> => {
	const api = getAxiosForSigesp();
	const response = await api.get(ENDPOINTS.caapMembersFilter, {
		params: cleanDeep(params),
	});
	return response.data;
};

export const getCaapMemberById = async (id: string): Promise<CaapMembers> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.caapGetById(id));
	return response.data;
};

export const updateCaapMembers = async (id: string, data: IRequestCaap): Promise<void> => {
	const api = getAxios();
	const response = await api.put(ENDPOINTS.caapEdit(id), data);
	return response.data;
};
