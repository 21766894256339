import { IPactForm } from '.';
import InputNumeric from '../InputNumeric';
import { Flex, FormControl, GridItem, SystemStyleObject, Text, FormErrorMessage, Button } from '@chakra-ui/react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';

export interface GoalInputProps {
	control: Control<IPactForm>;
	isReadOnly?: boolean;
	nestedIndex: number;
	nestedGroupIndex: number;
	fieldErrors: any;
	isEditing: boolean;
	onReplicateBimester: () => void;
}

const GoalInput = ({
	control,
	isReadOnly = false,
	nestedGroupIndex,
	nestedIndex,
	fieldErrors,
	isEditing = false,
	onReplicateBimester,
}: GoalInputProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			h: '2.75rem',
			w: { base: '20rem', md: '22rem', lg: '24.5rem' },
			backgroundColor: 'white',
			border: '1px solid #E1E2E5',
			borderRadius: '1.375rem',
			p: '0.25rem 1rem',
			display: 'flex',
			alignItems: 'center',
		},
		periodText: {
			fontSize: { base: '12px', md: '14px', lg: '1rem' },
			fontWeight: 'bold',
		},
		inputLabel: {
			fontSize: { base: '12px', md: '14px', lg: '1rem' },
			fontWeight: 'medium',
		},
		goalContainer: {
			borderRight: '1px solid #E1E2E5',
			borderLeft: '1px solid #E1E2E5',
			alignItems: 'center',
			pl: '1rem',
		},
		goalInput: {
			border: 'none',
			w: { base: '4rem', md: '5rem', lg: '6rem' },
			h: '2.25rem',
			fontSize: { base: '12px', md: '14px', lg: '1rem' },
			p: '0',
			pl: '0.25rem',
		},
		weightContainer: {
			alignItems: 'center',
			pl: '1rem',
		},
		weightInput: {
			border: 'none',
			w: '2.25rem',
			h: '2.25rem',
			fontSize: { base: '12px', md: '14px', lg: '1rem' },
			p: '0',
			pl: '0.25rem',
		},
		wrapper: {
			gap: '1rem',
			w: {
				base: '100%',
				lg: '33.75rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			alignItems: {
				base: 'flex-start',
				lg: 'center',
			},
		},
		button: {
			h: '1.688rem',
			borderRadius: '1rem',
			fontSize: '0.75rem',
			fontWeight: 'medium',
			px: '1rem',
		},
	};

	const { fields } = useFieldArray({
		name: `indicadores[${nestedIndex}].periodos[${nestedGroupIndex}].periodos` as `indicadores.0.periodos.0.periodos`,
		control,
	});

	const allowValueInput = (value: NumberFormatValues, maxIntegerNumber: number) => {
		return value.formattedValue.split(',')[0].length <= maxIntegerNumber;
	};

	return (
		<>
			{fields.map((item, index) => (
				<Flex key={item.id} sx={styles.wrapper}>
					<FormControl
						isInvalid={
							!!fieldErrors?.indicadores?.[nestedIndex]?.periodos?.[nestedGroupIndex]?.periodos?.[index]?.meta
								?.message ||
							!!fieldErrors?.indicadores?.[nestedIndex]?.periodos?.[nestedGroupIndex]?.periodos?.[index]?.peso?.message
						}
					>
						<GridItem
							sx={{
								...styles.container,
								backgroundColor:
									!isEditing && item.isEditavel ? '#fff' : isEditing && item.isEditavel ? '#fff' : 'gray.90',
							}}
						>
							<Flex flex="1">
								<Text sx={styles.periodText}>{item.periodoAtribuicaoPactuado}</Text>
							</Flex>
							<Flex sx={styles.goalContainer}>
								<Text sx={styles.inputLabel}>Meta:</Text>
								<Controller
									name={`indicadores.${nestedIndex}.periodos.${nestedGroupIndex}.periodos.${index}.meta`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<InputNumeric
											name={`indicadores.${nestedIndex}.periodos.${nestedGroupIndex}.periodos.${index}.meta`}
											sx={styles.goalInput}
											required
											value={value}
											onChange={onChange}
											decimalScale={2}
											isAllowed={values => allowValueInput(values, 9)}
											isReadOnly={isReadOnly}
											data-testid={`input--indicadores.${nestedIndex}.periodos.${nestedGroupIndex}.periodos.${index}.meta`}
											disabled={(isEditing && !item.isEditavel) || (!isEditing && !item.isEditavel)}
											_disabled={{ color: 'gray.450' }}
										/>
									)}
								/>
							</Flex>
							<Flex sx={styles.weightContainer}>
								<Text sx={styles.inputLabel}>Peso:</Text>

								<Controller
									name={`indicadores.${nestedIndex}.periodos.${nestedGroupIndex}.periodos.${index}.peso`}
									control={control}
									render={({ field: { value, onChange } }) => (
										<InputNumeric
											name={`indicadores.${nestedIndex}.periodos.${nestedGroupIndex}.periodos.${index}.peso`}
											sx={styles.weightInput}
											required
											value={value}
											onChange={onChange}
											decimalScale={1}
											isAllowed={values => allowValueInput(values, 1)}
											isReadOnly={isReadOnly}
											data-testid={`input--indicadores.${nestedIndex}.periodos.${nestedGroupIndex}.periodos.${index}.peso`}
											disabled={(isEditing && !item.isEditavel) || (!isEditing && !item.isEditavel)}
											_disabled={{ color: 'gray.450' }}
										/>
									)}
								/>
							</Flex>
						</GridItem>
						<FormErrorMessage data-testid="text--error" ml="8.5rem">
							{fieldErrors?.indicadores?.[nestedIndex]?.periodos?.[nestedGroupIndex]?.periodos?.[index]?.meta?.message}
						</FormErrorMessage>
						<FormErrorMessage data-testid="text--error" ml="8.5rem">
							{fieldErrors?.indicadores?.[nestedIndex]?.periodos?.[nestedGroupIndex]?.periodos?.[index]?.peso?.message}
						</FormErrorMessage>
					</FormControl>

					{nestedGroupIndex === 0 && index === 0 && (
						<Button sx={styles.button} onClick={onReplicateBimester}>
							Replicar Bimestres
						</Button>
					)}
				</Flex>
			))}
		</>
	);
};

export default GoalInput;
