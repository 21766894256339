import { Suspense, lazy } from 'react';
import DefaultLayout from 'app/components/DefaultLayout';
import CaapMembersCreate from 'app/pages/CaapMembersCreate';
import CaapMembersEdit from 'app/pages/CaapMembersEdit';
import CaapMembersList from 'app/pages/CaapMembersList';
import CreateIndicatorsI from 'app/pages/CreateIndicatorsI';
import CreateManagementPact from 'app/pages/CreateManagementPact';
import CreateStrategicObjective from 'app/pages/CreateStrategicObjective';
import CreateStrategicPlanning from 'app/pages/CreateStrategicPlanning';
import DataControlCreate from 'app/pages/DataControlCreate';
import DataControlEdit from 'app/pages/DataControlEdit';
import DataControlList from 'app/pages/DataControlList';
import EditIndicators from 'app/pages/EditIndicators';
import EditManagementPact from 'app/pages/EditManagementPact';
import EditStrategicObjective from 'app/pages/EditStrategicObjective';
import EditStrategicPlanningWithId from 'app/pages/EditStrategicPlanningWithId';
import ListIndicators from 'app/pages/ListIndicators';
import ListManagementPacts from 'app/pages/ListManagementPacts';
import NotificationsList from 'app/pages/NotificationsList';
import PenaltyCreate from 'app/pages/PenaltyCreate';
import PenaltyEdit from 'app/pages/PenaltyEdit';
import PenaltyList from 'app/pages/PenaltyList';
import PerformanceRangeCreate from 'app/pages/PerformanceRangeCreate';
import PerformanceRangeEdit from 'app/pages/PerformanceRangeEdit';
import PerformanceRangeList from 'app/pages/PerformanceRangeList';
import ReportMonitoring from 'app/pages/ReportMonitoring';
import RepresentativeCreate from 'app/pages/RepresentativeCreate';
import RepresentativeEdit from 'app/pages/RepresentativeEdit';
import RepresentativeList from 'app/pages/RepresentativeList';
import ResultEvaluationScreen from 'app/pages/ResultEvaluationScreen';
import ResultScreen from 'app/pages/ResultScreen';
import StrategicObjectiveList from 'app/pages/StrategicObjectiveList';
import StrategicPlanningList from 'app/pages/StrategicPlanningList';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import {
	Navigate,
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
	useParams,
} from 'react-router-dom';
import { AuthRouteProps, RoutesProps } from 'types/routes';
import { DATA_CONTROL_STEPS, USER_ROLES } from 'utils/constants';

type Params = {
	etapa: keyof typeof DATA_CONTROL_STEPS;
};

// Auth
const Home = lazy(() => import('app/pages/Home'));

const AuthRoute = ({ children, noLayout = false, hasHeader, headerProps, accessLevels }: AuthRouteProps) => {
	const { etapa } = useParams<Params>();
	let canView: boolean;
	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA]);
	const secondStepPermission = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
		USER_ROLES.REPRESENTANTE,
	]);

	const defaultCanView = useCanViewAction(accessLevels);

	if (etapa) {
		canView = etapa === 'etapa-1' ? firstStepPermission : secondStepPermission;
	} else {
		canView = defaultCanView;
	}

	if (!canView) return <Navigate to={ROUTES.home} replace />;

	return noLayout ? children : <DefaultLayout {...{ hasHeader, headerProps }}>{children}</DefaultLayout>;
};

const NoAuthRoute = ({ children, noLayout = false, hasHeader, headerProps }: AuthRouteProps) => {
	// const { isAuth } = useSession();

	// if (isAuth) return <Navigate to={ROUTES.home} replace />;

	return noLayout ? children : <DefaultLayout {...{ hasHeader, headerProps }}>{children}</DefaultLayout>;
};

const noProtectRoutes: RoutesProps[] = [];

const protectedRoutes: RoutesProps[] = [
	{
		path: ROUTES.home,
		element: <Home />,
	},
	{
		path: ROUTES.strategicPlanning,
		element: <StrategicPlanningList />,
		accessLevels: [
			USER_ROLES.PRESIDENTE,
			USER_ROLES.AREA_TECNICA,
			USER_ROLES.PACTUADO,
			USER_ROLES.SERVIDOR,
			USER_ROLES.REPRESENTANTE,
		],
	},
	{
		path: ROUTES.createStrategicPlanning,
		element: <CreateStrategicPlanning />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: `${ROUTES.editStrategicPlanningWithId(':strategicPlanningId')}`,
		element: <EditStrategicPlanningWithId />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: ROUTES.strategicObjectives,
		element: <StrategicObjectiveList />,
		accessLevels: [
			USER_ROLES.PRESIDENTE,
			USER_ROLES.AREA_TECNICA,
			USER_ROLES.PACTUADO,
			USER_ROLES.SERVIDOR,
			USER_ROLES.REPRESENTANTE,
		],
	},
	{
		path: ROUTES.createStrategicObjectives,
		element: <CreateStrategicObjective />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: ROUTES.editStrategicObjectives(':objectiveId'),
		element: <EditStrategicObjective />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: ROUTES.editIndicators(':indicatorId'),
		element: <EditIndicators />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.REPRESENTANTE],
	},
	{
		path: ROUTES.indicators,
		element: <ListIndicators />,
		accessLevels: [
			USER_ROLES.PRESIDENTE,
			USER_ROLES.AREA_TECNICA,
			USER_ROLES.PACTUADO,
			USER_ROLES.SERVIDOR,
			USER_ROLES.REPRESENTANTE,
		],
	},
	{
		path: ROUTES.createIndicators(':etapa'),
		element: <CreateIndicatorsI />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA],
	},
	{
		path: ROUTES.managementPact,
		element: <ListManagementPacts />,
		accessLevels: [
			USER_ROLES.PRESIDENTE,
			USER_ROLES.AREA_TECNICA,
			USER_ROLES.PACTUADO,
			USER_ROLES.SERVIDOR,
			USER_ROLES.REPRESENTANTE,
		],
	},
	{
		path: ROUTES.createManagementPact(':etapa'),
		element: <CreateManagementPact />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: ROUTES.editManagementPact(':pactId'),
		element: <EditManagementPact />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
	{
		path: ROUTES.performanceRange,
		element: <PerformanceRangeList />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.REPRESENTANTE],
	},
	{
		path: ROUTES.createPerformanceRange,
		element: <PerformanceRangeCreate />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
	{
		path: ROUTES.editPerformanceRange(':performanceRangeId'),
		element: <PerformanceRangeEdit />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
	{
		path: ROUTES.dateControl,
		element: <DataControlList />,
		accessLevels: [
			USER_ROLES.PRESIDENTE,
			USER_ROLES.AREA_TECNICA,
			USER_ROLES.PACTUADO,
			USER_ROLES.SERVIDOR,
			USER_ROLES.REPRESENTANTE,
		],
	},
	{
		path: ROUTES.createDateControl(':etapa'),
		element: <DataControlCreate />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: ROUTES.editDateControl(':dataControlId'),
		element: <DataControlEdit />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
	{
		path: ROUTES.penalty,
		element: <PenaltyList />,
		accessLevels: [
			USER_ROLES.PRESIDENTE,
			USER_ROLES.AREA_TECNICA,
			USER_ROLES.PACTUADO,
			USER_ROLES.SERVIDOR,
			USER_ROLES.REPRESENTANTE,
		],
	},
	{
		path: ROUTES.createPenalty,
		element: <PenaltyCreate />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
	{
		path: ROUTES.editPenalty(':penaltyId'),
		element: <PenaltyEdit />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
	{
		path: ROUTES.results,
		element: <ResultScreen />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.SERVIDOR],
	},
	{
		path: ROUTES.caapMembers,
		element: <CaapMembersList />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.SERVIDOR],
	},
	{
		path: ROUTES.caapMembersCreate,
		element: <CaapMembersCreate />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: ROUTES.caapMembersEdit(':id'),
		element: <CaapMembersEdit />,
		accessLevels: [USER_ROLES.PRESIDENTE],
	},
	{
		path: ROUTES.resultEvaluation(':idIndicadorPacto'),
		element: <ResultEvaluationScreen />,
	},
	{
		path: ROUTES.notifications,
		element: <NotificationsList />,
	},
	{
		path: ROUTES.reports,
		element: <ReportMonitoring />,
	},
	{
		path: ROUTES.representatives,
		element: <RepresentativeList />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.REPRESENTANTE],
	},
	{
		path: ROUTES.editRepresentative(':id'),
		element: <RepresentativeEdit />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
	{
		path: ROUTES.createRepresentative,
		element: <RepresentativeCreate />,
		accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO],
	},
];

export const Router = () => {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route>
				{noProtectRoutes?.map((route, index) => (
					<Route
						key={index}
						{...{ ...route }}
						element={
							<NoAuthRoute {...{ ...route }}>
								<>{route.element}</>
							</NoAuthRoute>
						}
					/>
				))}
				{protectedRoutes?.map((route, index) => (
					<Route
						key={index}
						{...{ ...route }}
						element={
							<AuthRoute {...{ ...route }}>
								<>{route.element}</>
							</AuthRoute>
						}
					/>
				))}
				<Route path="*" element={<Navigate to={ROUTES.home} />} />
			</Route>,
		),
	);

	return (
		<Suspense fallback={''}>
			<RouterProvider router={router} />
		</Suspense>
	);
};
