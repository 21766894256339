import { getIsMobile, useIsMobile } from './useIsMobile';
import { Box, SystemStyleObject, Text } from '@chakra-ui/react';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from 'assets/icons';
import { toast } from 'react-toastify';

export interface ToastProps {
	title?: string;
	description: string;
	type?: 'info' | 'success' | 'warning' | 'error';
}

const styles: Record<string, SystemStyleObject> = {
	container: { ml: '2rem' },
	title: {
		fontWeight: 'bold',
		fontStyle: 'italic',
		fontSize: '0.875rem',
	},
	description: {
		color: 'gray.750',
		fontWeight: '500',
		fontSize: '0.875rem',
	},
	error: {
		color: 'red.500',
	},
	success: {
		color: 'green.800',
	},
	info: {
		color: 'blue.300',
	},
	warning: {
		color: 'yellow.500',
	},
	icon: {
		margin: '1rem',
	},
};

const ICONS = {
	error: <ErrorIcon />,
	success: <SuccessIcon />,
	warning: <WarningIcon />,
	info: <InfoIcon />,
};

export const useCustomToast = () => {
	const isMobile = useIsMobile();
	const addToast = ({ title, description, type = 'info' }: ToastProps) => {
		toast(
			<div>
				<Box sx={styles.container}>
					<Text sx={{ ...styles.title, ...styles[type] }}>{title}</Text>
					<Text sx={styles.description}>{description}</Text>
				</Box>
			</div>,
			{
				autoClose: 5000,
				position: isMobile ? 'top-center' : 'bottom-right',
				theme: 'light',
				icon: <Box>{ICONS[type]}</Box>,
				type,
			},
		);
	};
	return { addToast };
};

export const getToast = ({ title, description, type = 'info' }: ToastProps) => {
	const isMobile = getIsMobile();
	toast(
		<div>
			<Box sx={styles.container}>
				<Text sx={{ ...styles.title, ...styles[type] }}>{title}</Text>
				<Text sx={styles.description}>{description}</Text>
			</Box>
		</div>,
		{
			autoClose: 5000,
			position: isMobile ? 'top-center' : 'bottom-right',
			theme: 'light',
			icon: <Box>{ICONS[type]}</Box>,
			type,
		},
	);
};
