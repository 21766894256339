/**
 *
 * AccordionCaapMember
 *
 */

import CustomModal from '../CustomModal';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	Grid,
	GridItem,
	IconButton,
	SystemStyleObject,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { DeleteIcon, RedirectTooltipIcon } from 'assets/icons';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { CaapMembers } from 'types/caapMembers';
import { USER_ROLES } from 'utils/constants';
import { formatDate } from 'utils/formatDate';

interface AccordionCaapMemberProps {
	data: CaapMembers;
	onDeleteMember: (id: string) => void;
	onEditMember: (id: string) => void;
}

const AccordionCaapMember = ({ data, onDeleteMember, onEditMember }: AccordionCaapMemberProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			maxWidth: 'auto',
			minWidth: '30rem',
		},
		containerExpanded: {
			width: '100%',
			maxWidth: '90rem',
			minWidth: '59rem',
		},
		accordionItem: {
			borderWidth: '0.063rem',
			borderColor: 'gray.95',
			mb: '0.1rem',
			borderRadius: '0.25rem',
		},
		accordionButtonContainer: {
			alignContent: 'center',
			minH: {
				base: 'auto',
				md: '4.25rem',
			},
			wordBreak: 'break-word',
		},
		accordionButtonContainerExpanded: {
			alignContent: 'center',
			minH: {
				base: 'auto',
				md: '4.25rem',
			},
			wordBreak: 'break-word',
		},
		accordionButton: {
			_hover: {},
			height: 'auto',
			paddingTop: '0.625rem',
		},
		accordionIcon: {
			h: '2.25rem',
			w: '2.25rem',
		},
		titleContainer: {
			flex: '1',
			textAlign: 'left',
			ml: '2rem',
			alignItems: {
				base: 'flex-start',
				lg: 'center',
			},
			gap: '0.4rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		title: {
			fontSize: {
				base: '1rem',
				sm: '1.25rem',
			},
			textAlign: {
				base: 'start',
				sm: 'center',
			},
			fontWeight: 'medium',
		},
		titleExpanded: {
			fontSize: '1.25rem',
		},
		icons: {
			gap: '1.5rem',
			paddingRight: {
				base: '2rem',
				md: '2rem',
				lg: '6rem',
			},
			alignSelf: 'center',
		},
		divider: {
			p: '0rem 5rem 0rem 2rem',
		},
		accordionPanel: {
			ps: '0.5rem',
			pe: '2.5rem',
			pb: '0.5rem',
		},
		accordionPanelContainer: {
			flexDir: 'column',
			h: '100%',
			ml: {
				base: '0rem',
				lg: '1.25rem',
			},
			overflowX: 'scroll',
			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
			},
		},
		flexFirstContentContainer: {
			justifyContent: { base: 'start', lg: 'start' },
			display: 'flex',
			gap: { base: '0.625rem', lg: '3.75rem' },
			flexDir: { base: 'row' },
			alignItems: 'start',
		},
		flexSecondContentContainer: {
			justifyContent: { base: 'space-between', lg: 'start' },
			display: 'flex',
			gap: { base: '0.625rem', lg: '3.75rem' },
			flexDir: { base: 'row' },
			alignItems: 'start',
		},
		fieldsContent: {
			gap: '2rem',
			rowGap: '0.5rem',
			gridTemplateColumns: {
				lg: 'repeat(1, 1fr)',
				xl: 'repeat(1, 1fr)',
			},
			height: 'fit-content',
		},
		textTitle: { fontSize: '1rem', opacity: '0.5', color: 'black.800', mt: '1.5rem', fontWeight: 'bold' },
		textTitleStatus: {
			fontSize: '1rem',
			opacity: '0.5',
			color: 'black.800',
			mt: '1.5rem',
			ml: { base: '0', lg: '0.875rem' },
			fontWeight: 'bold',
		},

		text: { fontSize: '1rem', color: 'black.800', mt: '1.5rem', mb: '1.5rem' },
		textFirstContent: {
			fontSize: '1rem',
			color: 'black.800',
			mt: { base: 'auto', lg: '1.5rem' },
			mb: { base: 'auto', lg: '1.5rem' },
		},
		textSecondContent: {
			fontSize: '1rem',
			color: 'black.800',
			mt: { base: 'auto', lg: '1.5rem' },
			mb: { base: 'auto', lg: '1.5rem' },
		},
		textThirdContent: {
			fontSize: '1rem',
			color: 'black.800',
			mt: { base: '1.5rem' },
			mb: { base: '1.5rem' },
		},

		textTitularSuplente: {
			fontSize: '1rem',
			color: 'black.800',
			fontWeight: 'semibold',
			textAlign: 'center',
		},
		boxContent1: { maxW: '14.375rem', width: '100%' },
		boxContent: { maxW: '7.5rem', width: '100%' },
		boxTitular: {
			mt: '1rem',
			p: '0.438rem 1rem',
			backgroundColor: 'rgba(10, 119, 210, 0.25)',
			borderRadius: '0.375rem',
			width: '5.875rem',
		},
		boxSuplente: {
			mt: '1rem',
			p: '0.438rem 1rem',
			backgroundColor: 'rgba(241, 196, 15, 0.25)',
			borderRadius: '0.375rem',
			mr: { base: 'auto', lg: '0.313rem' },
			width: '5.9rem',
		},
		panelSectionContainer: {
			flexDir: 'column',
			mr: '2rem',
			ml: '2rem',
			width: { base: '55rem', lg: '72rem' },
		},
		footerContent: {
			justifyContent: 'end',
			mt: { base: '1rem', lg: '0' },
			mb: '0.5rem',
		},
		footerData: {
			fontSize: '0.875rem',
			fontWeight: 'normal',
		},
		dividerFlex: { display: 'flex', justifyContent: 'center', padding: '1rem' },
		capitalize: {
			textTransform: 'capitalize',
		},
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);

	const handleDelete = (dateControlId: string) => {
		onClose();
		onDeleteMember(dateControlId);
	};

	return (
		<>
			<Accordion allowMultiple>
				<AccordionItem sx={styles.accordionItem} key={data.id}>
					{({ isExpanded }) => (
						<>
							<Flex sx={isExpanded ? styles.accordionButtonContainerExpanded : styles.accordionButtonContainer}>
								<AccordionButton sx={styles.accordionButton} data-testid={`accordionMemberCaap--btn-${data.id}`}>
									<AccordionIcon sx={styles.accordionIcon} />
									<Flex sx={styles.titleContainer}>
										<Flex>
											<Text
												sx={isExpanded ? styles.titleExpanded : styles.title}
												data-testid={`representation--caap-${data.id}`}
											>
												{data.representacao}
											</Text>
										</Flex>
									</Flex>
								</AccordionButton>
								<Flex sx={styles.icons}>
									{hasPermission && (
										<Tooltip
											label="Atualizar composição."
											color="#3B3333"
											bg="white"
											hasArrow
											arrowSize={15}
											placement="top-start"
											fontSize="sm"
											fontWeight="normal"
											arrowPadding={3}
										>
											<IconButton
												aria-label={'Editar membro caap'}
												variant="unstyled"
												icon={<RedirectTooltipIcon />}
												onClick={() => onEditMember(data.id!)}
												data-testid={`accordionMemberCaap--edit-btn-${data.id}`}
											/>
										</Tooltip>
									)}
									{hasPermission && (
										<Tooltip
											label="Excluir permanentemente."
											color="#3B3333"
											bg="white"
											hasArrow
											arrowSize={15}
											placement="top-start"
											fontSize="sm"
											fontWeight="normal"
											arrowPadding={3}
										>
											<IconButton
												aria-label={'Excluir membro CAAP'}
												variant="unstyled"
												icon={<DeleteIcon />}
												onClick={onOpen}
												data-testid={`accordionMemberCaap--delete-btn-${data.id}`}
											/>
										</Tooltip>
									)}
								</Flex>
							</Flex>

							<Box sx={styles.divider}>
								<Divider />
							</Box>

							<AccordionPanel sx={styles.accordionPanel}>
								<Flex sx={styles.accordionPanelContainer}>
									<Flex sx={styles.panelSectionContainer}>
										<Grid sx={styles.fieldsContent}>
											<GridItem sx={styles.flexFirstContentContainer}>
												<Box sx={styles.boxContent1}>
													<Text sx={styles.textTitle}>Unidade de exercício</Text>
													<Text sx={styles.text} data-testid="unity-holder--caap">
														{`${data.titular.nomeUnidade} - ${data.titular.nomeSetor}`}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text sx={styles.textTitle}>Função</Text>
													<Text sx={styles.text} data-testid="function-holder--caap">
														{data.titular.funcao.funcaoDescricao}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text sx={styles.textTitle}>Nome</Text>
													<Text sx={{ ...styles.text, ...styles.capitalize }} data-testid="name-holder--caap">
														{data.titular.nome.toLowerCase()}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text sx={styles.textTitle}>Cargo</Text>
													<Text sx={styles.text} data-testid="role-holder--caap">
														{data.titular.cargo}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text sx={styles.textTitle}>MASP</Text>
													<Text sx={styles.text} data-testid="masp-holder--caap">
														{data.titular.masp}
													</Text>
												</Box>

												<Box sx={styles.boxContent}>
													<Text sx={styles.textTitleStatus}>Status</Text>

													<Box sx={styles.boxTitular}>
														<Text
															sx={{ ...styles.textTitularSuplente, ...styles.capitalize }}
															data-testid="status-holder--caap"
														>
															{data.titular.statusCaap.toLowerCase()}
														</Text>
													</Box>
												</Box>
											</GridItem>
											<GridItem sx={styles.dividerFlex}>
												<Divider />
											</GridItem>
											<GridItem sx={styles.flexSecondContentContainer}>
												<Box sx={styles.boxContent1}>
													<Text sx={styles.textFirstContent} data-testid="unity-substitute--caap">
														{`${data.suplente.nomeUnidade} - ${data.suplente.nomeSetor}`}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text sx={styles.text} data-testid="function-substitute--caap">
														{data.suplente.funcao.funcaoDescricao}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text
														sx={{ ...styles.textSecondContent, ...styles.capitalize }}
														data-testid="name-substitute--caap"
													>
														{data.suplente.nome.toLowerCase()}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text sx={styles.textThirdContent} data-testid="role-substitute-caap">
														{data.suplente.cargo}
													</Text>
												</Box>
												<Box sx={styles.boxContent}>
													<Text sx={styles.text} data-testid="masp-substitute--caap">
														{data.suplente.masp}
													</Text>
												</Box>

												<Box sx={styles.boxSuplente}>
													<Text
														sx={{ ...styles.textTitularSuplente, ...styles.capitalize }}
														data-testid="status-substitute--caap"
													>
														{data.suplente.statusCaap.toLowerCase()}
													</Text>
												</Box>
											</GridItem>
										</Grid>
									</Flex>

									<Flex sx={styles.footerContent}>
										<Text sx={styles.footerData} data-testid="date-atualization--caap">
											Data de Atualização: {formatDate(data.dataAtualizacao)}
										</Text>
									</Flex>
								</Flex>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você irá remover este membro."
				body="Tem certeza que deseja realizar esta ação?"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero Remover',
						type: 'cancel',
						onClick: () => handleDelete(data.id!),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: onClose,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default AccordionCaapMember;
