import { getAxios, getAxiosForSigesp } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import { IRepresentative, IResponseRepresentatives, IResponseRepresentativesCaapsMember } from 'types/representative';
import { IRepresentativeItem } from 'types/representative';

export interface IRequestRepresentive {
	id?: number;
	etapa: string;
	dadosPessoaisRepresentantes: IRepresentativeItem[];
	unidadeRepresentante: {
		id?: number;
		idUnidade: number;
		idSetor: number;
		nomePactuado: string;
	};
}

export interface IRepresentativeParams {
	etapaEnum?: string;
	setorId?: string;
}

export interface IRequestRepresentativesCaapsMember {
	params: {
		unidadeId?: string;
		setorId?: string;
	};
	body: IResponseRepresentatives[];
}

export const getRepresentativesList = async (params?: IRepresentativeParams): Promise<IResponseRepresentatives[]> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.representativeList, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});

	return response.data;
};

export const getRepresentativesCaapsMember = async ({
	params,
	body,
}: IRequestRepresentativesCaapsMember): Promise<IResponseRepresentativesCaapsMember[]> => {
	const api = getAxiosForSigesp();
	const response = await api.post(ENDPOINTS.representativesCaapsMemberList, body, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});

	return response.data;
};

export const getRepresentativeById = async (representativeId: string): Promise<IRequestRepresentive> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.representativeGetBy(representativeId));

	return response.data;
};

export interface IUpdateRepresentativeParams {
	data: IRepresentativeItem[];
	representativeId: string;
}
export const updateRepresentative = async ({ data, representativeId }: IUpdateRepresentativeParams): Promise<void> => {
	const api = getAxios();
	await api.put<IRepresentativeItem[]>(ENDPOINTS.representativeEdit(representativeId), data);
	return;
};

export const createRepresentative = async (data: IRepresentative) => {
	const api = getAxios();
	const response = await api.post<IRepresentative>(ENDPOINTS.representativeCreate, data);
	return response.data;
};
