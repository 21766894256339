/**
 *
 * ResulterPactCard
 *
 */

import { useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Heading,
	IconButton,
	Slide,
	SystemStyleObject,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { SetRedirectIcon } from 'assets/icons';

export interface IPeriodResulterPactEvaluation {
	id?: string | number;
	nomePactuado: string;
	indicadoresApurados: string;
	periodoBimestre: string;
	totalPontos: string | number;
	saldo: string | number;
}

export interface IResulterPactEvaluation {
	id?: string | number;
	nomePactuado: string;
	dataCard: IPeriodResulterPactEvaluation[];
}

interface ResulterPactCardProps {
	data: IResulterPactEvaluation;
	onRedirect: () => void;
}

const ResulterPactCard = ({ data, onRedirect }: ResulterPactCardProps) => {
	const [isExpanded, setIsExpanded] = useState(data.dataCard.map(() => false));

	const { onOpen, onClose } = useDisclosure();

	const handleMouseEnter = (index: number) => {
		let arr = isExpanded;
		arr[index] = true;
		setIsExpanded([...arr]);
		onOpen();
	};

	const handleMouseLeave = (index: number) => {
		let arr = isExpanded;
		arr[index] = false;
		setIsExpanded([...arr]);
		onClose();
	};

	const styles: Record<string, SystemStyleObject> = {
		container: {
			w: '100%',
			p: '1rem',
			border: '0.063rem solid',
			borderRadius: '0.5rem',
			borderColor: '#DBDBDB',
			background: 'white',
			height: 'auto',
			flexDir: 'column',
		},
		content: {
			width: '100%',
		},
		fieldsContent: {
			gap: '2rem',
			rowGap: '1rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
				xl: 'repeat(3, 1fr)',
			},
			height: 'fit-content',
		},
		boxTitle: {
			marginBottom: '1.25rem',
		},
		headerTitle: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			textColor: 'black',
		},
		headerSubTitle: {
			fontSize: '1rem',
			fontWeight: 'normal',
			textColor: 'black',
			opacity: '0.6',
		},
		principalBox: {
			_hover: {
				background: '#FCFCFC 0% 0%',
				boxShadow: '3px 3px 6px #00000014',
				borderRadius: '0.5rem',
			},
			cursor: 'pointer',
		},
		cardContent: {
			backgroundColor: 'gray.75',
			borderRadius: '0.5rem',
			display: 'flex',
			flexDirection: { base: 'column', md: 'row' },
			p: '0.75rem',
			alignContent: 'space-around',
			gap: { base: '3rem', xl: '0' },
			rowGap: '1rem',
		},
		cardBoxName: { fontSize: '0.875rem', fontWeight: 'bold', textColor: 'black', textAlign: 'start' },
		cardBoxPoints: {
			fontSize: '0.875rem',
			fontWeight: 'normal',
			textColor: 'black',
			display: 'flex',
			flexDir: 'row',
			alignItems: { base: 'flex-start', lg: 'center' },
		},
		cardBoxTextTotal: { fontSize: '1.063rem', fontWeight: 'bold', textColor: 'blue.500', paddingLeft: '0.25rem' },

		cardBoxTextMin: { fontSize: '0.75rem', fontWeight: 'normal', textColor: 'black' },

		cardBoxCard1: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			alignItems: 'flex-start',
			rowGap: { base: '1rem', md: '0.2rem' },
		},
		cardBoxCard2: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			alignItems: { base: 'flex-start', md: 'flex-end' },
		},
		buttonContent: {
			justifyContent: { base: 'flex-start', md: 'flex-end' },
			width: '100%',
			gap: '1.25rem',
			paddingTop: '0.85rem',
			transition: '2s ease-out',
			_hover: {
				animationDuration: '0.5s',
				transitionDuration: '0.6s',
			},
		},
		buttonPositive: {
			borderRadius: 'full',
			w: '2.125rem',
			fontSize: '0.875rem',
			h: '2.125rem',
			backgroundColor: 'green.550',
		},
		buttonNegative: {
			borderRadius: 'full',
			w: '2.125rem',
			fontSize: '0.875rem',
			h: '2.125rem',
			backgroundColor: 'red.550',
			_hover: {
				background: 'red.350',
			},
		},
		buttonRedirect: {
			w: '2.125rem',
			fontSize: '0.875rem',
			h: '2.125rem',
			paddingLeft: '1.25rem',
			opacity: isExpanded ? '1' : '0',
		},
	};

	return (
		<>
			<Flex sx={styles.container} data-testid="resulter-card--item" key={data.id}>
				<Box sx={styles.boxTitle}>
					<Heading sx={styles.headerTitle} data-testid="resulter-card--title">
						{data.nomePactuado}
					</Heading>
					<Text sx={styles.headerSubTitle}>Estatísticas gerais</Text>
				</Box>

				<Grid sx={styles.fieldsContent} data-testid="resulter-card--content">
					{data?.dataCard?.map((item, index) => (
						<Box
							sx={styles.principalBox}
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseLeave={() => handleMouseLeave(index)}
							onClick={() => onRedirect()}
							key={`${item.nomePactuado}_${item.periodoBimestre}_${index}`}
						>
							<GridItem sx={styles.cardContent}>
								<Box sx={styles.cardBoxCard1}>
									<Text sx={styles.cardBoxName} data-testid="resulter-card--subtitle">
										{item.nomePactuado}
									</Text>
									<Text sx={styles.cardBoxPoints}>
										Total de pontos:{' '}
										<Text as="span" sx={styles.cardBoxTextTotal} data-testid="resulter-card--points">
											{item.totalPontos}
										</Text>
									</Text>
									<Text sx={styles.cardBoxTextMin} data-testid="resulter-card--months">
										{item.periodoBimestre}
									</Text>
								</Box>
								<Box sx={styles.cardBoxCard2}>
									<Text sx={styles.cardBoxTextMin} data-testid="resulter-card--indicators">
										Indicadores apurados: {item.indicadoresApurados}
									</Text>
									<Flex
										sx={styles.buttonContent}
										flexDirection={isExpanded[index] ? 'row-reverse' : 'row'}
										justifyContent="flex-end"
										data-testid="box-validation-hover"
									>
										<Box>
											<Slide
												in={isExpanded[index]}
												direction="left"
												style={{ position: 'relative', opacity: isExpanded[index] ? '1' : '0' }}
											>
												<IconButton
													sx={styles.buttonRedirect}
													variant="unstyled"
													aria-label="redirect"
													fontSize="1.25rem"
													data-testid={`resulter-card-redirect-btn--${index}`}
													icon={<SetRedirectIcon />}
												/>
											</Slide>
										</Box>

										<Box paddingLeft={isExpanded[index] ? '100px' : '5px'}>
											<Button
												sx={Number(item.saldo) >= 0 ? styles.buttonPositive : styles.buttonNegative}
												data-testid={`resulter-card-balance-btn--${index}`}
											>
												{item.saldo}
											</Button>
										</Box>
									</Flex>
								</Box>
							</GridItem>
						</Box>
					))}
				</Grid>
			</Flex>
		</>
	);
};

export default ResulterPactCard;
