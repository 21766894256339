import { DATA_CONTROL_STEPS } from 'utils/constants';

type StepControl = keyof typeof DATA_CONTROL_STEPS | ':etapa';

export const ROUTES = {
	// No auth routes
	login: '/login',

	// Auth routes
	home: '/',

	// Strategic Planning
	strategicPlanning: '/planejamento-estrategico',
	createStrategicPlanning: '/planejamento-estrategico/cadastrar',
	editStrategicPlanningWithId: (strategicPlanningId: string) =>
		`/planejamento-estrategico/editar/${strategicPlanningId}`,

	// Strategic Objectives
	strategicObjectives: '/objetivos-estrategicos',
	createStrategicObjectives: '/objetivos-estrategicos/cadastrar',
	editStrategicObjectives: (objectiveId: string) => `/objetivos-estrategicos/editar/${objectiveId}`,

	// Indicators
	indicators: '/indicadores',
	createIndicators: (step: StepControl) => `/indicadores/cadastrar/${step}`,
	editIndicators: (indicatorId: string) => `/indicadores/editar/${indicatorId}`,

	// Management Pact
	managementPact: '/pacto-de-gestao',
	createManagementPact: (step: StepControl) => `/pacto-de-gestao/cadastrar/${step}`,
	editManagementPact: (pactId: string) => `/pacto-de-gestao/${pactId}`,

	// Performance Range
	performanceRange: '/faixa-de-desempenho',
	createPerformanceRange: '/faixa-de-desempenho/cadastrar',
	editPerformanceRange: (performanceRangeId: string) => `/faixa-de-desempenho/editar/${performanceRangeId}`,

	// Data Control
	dateControl: '/controle-de-datas',
	createDateControl: (etapa: StepControl) => `/controle-de-datas/cadastrar/${etapa}`,
	editDateControl: (dataControlId: string) => `/controle-de-datas/editar/${dataControlId}`,

	// Result Evaluation
	resultEvaluation: (id: string) => `/avaliacao-de-resultado/${id}`,
	results: '/tela-de-resultados',

	// Penalty
	penalty: '/penalizacao',
	createPenalty: '/penalizacao/cadastrar',
	editPenalty: (penaltyId: string) => `/penalizacao/editar/${penaltyId}`,

	// CAAP Members
	caapMembers: '/membros-caap',
	caapMembersCreate: '/membros-caap/cadastrar',
	caapMembersEdit: (id: string) => `/membros-caap/editar/${id}`,

	// Notifications
	notifications: '/notificacoes',

	// Monitoring Reports
	reports: '/resultados-consolidados',

	// Representatives
	representatives: '/representantes',
	createRepresentative: '/representantes/cadastrar',
	editRepresentative: (id: string) => `/representantes/editar/${id}`,

	// Critical Analysis
	criticalAnalysis: (id: string) => `/analise-critica/editar/${id}`,
};
