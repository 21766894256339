/**
 *
 * PenaltyCard
 *
 */

import { Button, Flex, IconButton, SystemStyleObject, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon } from 'assets/icons';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { IPenaltyDataResponse } from 'types/penalty';
import {
	BIMESTERS_ENUM,
	BIMESTERS_ENUM_TO_MONTHS,
	DTA_PHASES_ENUM,
	OTHER_PHASES,
	STEPS_ENUM,
	STEPS_OPTIONS_FILTER,
	USER_ROLES,
} from 'utils/constants';
import { formatDate } from 'utils/formatDate';

interface PenaltyCardProps {
	data: IPenaltyDataResponse;
	onEdit?: (id: string | number) => void;
	onDelete?: (id: string | number) => void;
}

const PenaltyCard = ({ data, onDelete, onEdit }: PenaltyCardProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
		},
		content: {
			mt: '1rem',
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			justifyContent: 'space-between',
			flexDir: { base: 'column', xl: 'row' },
		},
		pactuadoContainer: {
			flexDir: 'column',
			p: '1.5rem 4rem 1rem 2rem',
			width: '100%',
		},
		pactuadoContent: {
			gap: {
				base: 0,
				lg: '1rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		clausulaJustificativaContent: {
			flexDir: 'column',
			mb: '1.5rem',
			whiteSpace: 'pre-line',
		},
		pontosContent: {
			flexDir: 'column',
			border: '1px solid #C56443',
			borderRadius: '4px',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '1rem',
			p: '2rem',
			maxW: {
				base: '100%',
				xl: '24.313rem',
			},
			w: '100%',
		},
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
		textBold: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		textMedium: {
			fontSize: '1.25rem',
			fontWeight: 'medium',
		},
		textSmall: {
			fontSize: '0.875rem',
		},
		faseText: {
			fontSize: '1rem',
			fontWeight: 'medium',
			px: '0.5rem',
			mt: '0.1rem',
			border: '1px solid #E1E2E5',
			borderRadius: '16px',
			backgroundColor: '#3498DB3f',
			h: 'fit-content',
		},
		dataLancamentoText: {
			fontSize: '0.875rem',
			textAlign: { base: 'start', xl: 'end' },
		},
		pontosText: {
			fontSize: '2.5rem',
			fontWeight: 'medium',
		},
		button: {
			maxW: '10rem',
			w: '100%',
		},
		indicatorsName: {
			mb: '0.5rem',
			fontWeight: 'bold',
			fontSize: '1.125rem',
		},
	};

	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const secondStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);

	const isFirstStep = data.etapaEnum === STEPS_ENUM.ETAPA_UM;

	const hasPermission = isFirstStep ? firstStepPermission : secondStepPermission;

	return (
		<Flex sx={styles.container}>
			<Text sx={styles.textBold} data-testid={`text--bimestre-etapa-${data.idPenalizacao}`}>
				{`${BIMESTERS_ENUM[data.bimestreEnum as keyof typeof BIMESTERS_ENUM].toLocaleUpperCase()} — 
				${BIMESTERS_ENUM_TO_MONTHS[data.bimestreEnum as keyof typeof BIMESTERS_ENUM_TO_MONTHS]} (${STEPS_OPTIONS_FILTER[
					data?.etapaEnum as keyof typeof STEPS_OPTIONS_FILTER
				]!})`}
			</Text>

			<Flex sx={styles.content}>
				<Flex sx={styles.pactuadoContainer}>
					<Flex mb="0.5rem">
						<Flex sx={styles.pactuadoContent}>
							<Flex flexDir="column">
								<Text sx={styles.textMedium} data-testid={`text--pactuado-${data.idPenalizacao}`}>
									{data.faseEnum === OTHER_PHASES.value ? data.nomePenalizado : data.nomePactuado}
								</Text>
								<Text sx={styles.textSmall} data-testid={`text--dataLimite-${data.idPenalizacao}`}>
									Data limite: {formatDate(data.prazoResponseDTO.dataPrazo)}
								</Text>
							</Flex>
							<Tooltip label="Nome da Fase" placement="top" sx={styles.tooltip}>
								<Text sx={styles.faseText} data-testid={`text--fase-${data.idPenalizacao}`}>
									{DTA_PHASES_ENUM[data.faseEnum as keyof typeof DTA_PHASES_ENUM]}
								</Text>
							</Tooltip>
						</Flex>
						{hasPermission && data.isModificavel && (
							<Tooltip label="Exclusão" placement="top" sx={styles.tooltip}>
								<IconButton
									aria-label="Exclusão"
									variant="unstyled"
									icon={<DeleteIcon />}
									onClick={() => onDelete?.(data?.idPenalizacao!)}
									ml="auto"
									data-testid={`button--delete-${data.idPenalizacao}`}
								/>
							</Tooltip>
						)}
					</Flex>

					<Text sx={styles.indicatorsName}>{data.nomeIndicador}</Text>

					<Flex sx={styles.clausulaJustificativaContent}>
						<Text sx={styles.textBold}>Cláusula contratual</Text>
						<Text fontSize="1rem" data-testid={`text--clausula-${data.idPenalizacao}`}>
							{data.clausulaContratual}
						</Text>
					</Flex>
					<Flex sx={styles.clausulaJustificativaContent}>
						<Text sx={styles.textBold}>Justificativa</Text>
						<Text fontSize="1rem" data-testid={`text--justificativa-${data.idPenalizacao}`}>
							{data.justificativa}
						</Text>
					</Flex>

					<Text sx={styles.dataLancamentoText} data-testid={`text--dataLancamento-${data.idPenalizacao}`}>
						Data de lançamento: {formatDate(data.dataLancamento)}
					</Text>
				</Flex>

				<Flex sx={styles.pontosContent}>
					<Text sx={styles.textMedium}>Pontos perdidos:</Text>
					<Text sx={styles.pontosText} data-testid={`text--pontos-${data.idPenalizacao}`}>
						{data.pontosPerdidos}
					</Text>

					{hasPermission && data.faseEnum === OTHER_PHASES.value && data.isModificavel && (
						<Button
							sx={styles.button}
							onClick={() => onEdit?.(data?.idPenalizacao!)}
							data-testid={`button--edit-${data.idPenalizacao}`}
						>
							Editar Penalidade
						</Button>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default PenaltyCard;
