/**
 *
 * LineChart
 *
 */

import { useEffect, useRef, useState } from 'react';
import { Box, Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import { DEFAULT_LABELS_INDICATOR_AXIS_X } from 'utils/constants';
import { maskThousands } from 'utils/Numbers';

interface IToolTipConfig {
	indexSelectedItem: string;
	position: {
		x: number;
		y: number;
		dots: {
			dotX: number;
			dotY: number;
			isVisible: boolean;
		}[];
		lineHighlight: {
			x: number;
			y: number;
			height: number;
		};
	};
}

interface LineChartProps {
	goals: number[];
	results: number[];
	labelsIndicatosAxisX?: { axiX: string; tooltip: string }[];
}

const tooltipDefaultValue = {
	indexSelectedItem: '',
	position: {
		x: 0,
		y: 0,
		dots: [],
		lineHighlight: { x: 0, y: 0, height: 0 },
	},
};
const tooltipeIdleDefaultValues = {
	position: {
		dots: {
			dotX: 0,
			dotY: 0,
		},
		lineHighlight: { x: 0, y: 0, height: 0 },
	},
};

const LineChart = ({ goals, results, labelsIndicatosAxisX = DEFAULT_LABELS_INDICATOR_AXIS_X }: LineChartProps) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const [tooltipConfig, setTooltipConfig] = useState<IToolTipConfig>(tooltipDefaultValue);
	const [tooltipIdle, setTooltiIdle] = useState(tooltipeIdleDefaultValues);

	const chartConfig = {
		scaleFactorX: 100,
		initialPositionX: 0,
		initialPsitionY: 0,
		adjustDotCenterY: 5,
		adjustDotCenterX: 5,
		resultColor: '#7446B9',
		goalColor: '#0000009E',
		scaleFactorY: 100,
		labelsPositionX: [0, 20, 40, 60, 80, 100],
		intervalY: 25,
	};

	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: '8.313rem',
			minWidth: 0,
			minHeight: 0,
			maxHeight: '100%',
			maxWidth: '100%',
			position: 'relative',
		},
		tooltipBox: {
			position: 'absolute',
			top: `${tooltipConfig.position.y}px`,
			left: `${tooltipConfig.position.x}px`,
			background: 'rgba(0, 0, 0, .7)',
			padding: '0.5rem',
			zIndex: 1000,
			flexDirection: 'column',
			borderRadius: '0.375rem',
			color: 'white',
		},
		tooltipDot: {
			position: 'absolute',
			background: 'white',
			borderRadius: '100%',
			borderWidth: '0.125rem',
			width: '0.625rem',
			height: '0.625rem',
			zIndex: 10,
			borderColor: chartConfig.resultColor,
		},
		tooltipItem: { alignItems: 'center', gap: '.3rem' },
		tooltipLegend: {
			borderRadius: '100%',
			minWidth: '0.625rem',
			minHeight: '0.625rem',
			width: '0.625rem',
			height: '0.625rem',
			border: '0.063rem solid white',
			background: chartConfig.resultColor,
		},
		tooltipTextTitle: {
			fontWeight: 600,
			fontSize: '0.75rem',
			color: 'white',
		},
		tooltipTextDescription: {
			fontWeight: 400,
			fontSize: '0.75rem',
			color: 'white',
		},
		tooltipLineHighlight: {
			position: 'absolute',
			zIndex: 5,
			opacity: 0.05,
			width: '0.75rem',
			height: `${tooltipConfig?.position?.lineHighlight.height}px`,
			top: `${tooltipConfig?.position?.lineHighlight.y}px`,
			left: `${tooltipConfig?.position?.lineHighlight.x}px`,
			background: chartConfig.resultColor,
		},
		idleTooltipBox: {
			opacity: !tooltipConfig?.indexSelectedItem ? '1' : '0',
			transition: 'linear .3s',
			position: 'absolute',
			bottom: '13px',
			right: '-87px',
			background: 'rgba(0, 0, 0, .7)',
			padding: '0.5rem',
			zIndex: 1000,
			flexDirection: 'column',
			borderRadius: '0.375rem',
			color: 'white',
		},
		idleTooltipLineHighlight: {
			position: 'absolute',
			zIndex: 5,
			opacity: 0.05,
			width: '0.75rem',
			height: `${tooltipIdle?.position?.lineHighlight.height}px`,
			top: `${tooltipIdle?.position?.lineHighlight.y}px`,
			left: `${tooltipIdle?.position?.lineHighlight.x}px`,
			background: chartConfig.resultColor,
		},
		idleTooltipDot: {
			position: 'absolute',
			background: 'white',
			borderRadius: '100%',
			borderWidth: '0.125rem',
			width: '0.625rem',
			height: '0.625rem',
			zIndex: 10,
			top: `${tooltipIdle?.position?.dots?.dotY}px`,
			left: `${tooltipIdle?.position?.dots?.dotX}px`,
			borderColor: '#7446B9',
		},
	};

	useEffect(() => {
		const handleResize = () => {
			const canvas = document.getElementById('myCanvas') as HTMLCanvasElement;
			const context = canvas.getContext('2d');

			if (context && canvas.parentElement) {
				canvas.width = canvas.parentElement.clientWidth;

				// Configurações de escala e tamanho
				const padding = 20;
				const horizontalPadding = padding * 2;
				const scaleX = (canvas.width - horizontalPadding) / chartConfig.scaleFactorX;
				const scaleY = (canvas.height - horizontalPadding) / chartConfig.scaleFactorY;

				// Limpa o canvas
				context.clearRect(chartConfig.initialPositionX, chartConfig.initialPsitionY, canvas.width, canvas.height);

				// Desenha fundo do gráfico
				context.fillStyle = 'white';
				context.fillRect(chartConfig.initialPositionX, chartConfig.initialPsitionY, canvas.width, canvas.height);

				// Legenda do eixo X (Valores dos dados)
				context.fillStyle = 'black';

				chartConfig.labelsPositionX.forEach((value, index) => {
					const positionX = padding + value * scaleX;

					// Insere os elementos no eixo x
					context.fillText(labelsIndicatosAxisX[index]?.axiX.toString(), positionX, canvas.height);

					// Insere linhas verticais
					context.beginPath();
					context.strokeStyle = 'lightgray';
					context.lineWidth = 1;
					context.moveTo(positionX, canvas.height - padding);
					context.lineTo(positionX, padding);
					context.stroke();
				});

				// Insere Valores do eixo Y
				context.fillStyle = 'black';
				context.textAlign = 'end';

				for (let i = 0; i <= chartConfig.scaleFactorY; i += chartConfig.intervalY) {
					const valueY = canvas.height - padding - i * scaleY;

					if (i !== 0) {
						//Escreve os valores no exio y
						context.fillText(i.toString(), padding - 5, valueY);
					}
				}

				// Desenha gráfico dos resultados
				context.beginPath();
				results.forEach((value, index) =>
					context.lineTo(
						padding + chartConfig.labelsPositionX[index] * scaleX,
						canvas.height - padding - value * scaleY,
					),
				);
				context.strokeStyle = chartConfig.resultColor;
				context.lineWidth = 2;
				context.stroke();

				// Desenha gráfico das metas
				context.beginPath();
				context.setLineDash([5, 5]);
				goals.forEach((value, index) =>
					context.lineTo(
						padding + chartConfig.labelsPositionX[index] * scaleX,
						canvas.height - padding - value * scaleY,
					),
				);
				context.strokeStyle = chartConfig.goalColor;
				context.lineWidth = 2;
				context.stroke();

				// Insere highlight na barra e o ponto inicial para o gráfico em idle state
				const canvasLimits = canvas.getBoundingClientRect();
				const dotY =
					canvasLimits.top +
					window.scrollY +
					canvas.height -
					padding -
					results[results.length - 1] * scaleY -
					chartConfig.adjustDotCenterY;
				const dotX =
					canvasLimits.left +
					padding -
					chartConfig.adjustDotCenterY +
					chartConfig.labelsPositionX[chartConfig.labelsPositionX.length - 1] * scaleX +
					window.scrollX;
				const adjustLinehilightCenterX = 6;
				const lineHighlightX =
					window.scrollX +
					canvasLimits.left +
					padding +
					chartConfig.labelsPositionX[chartConfig.labelsPositionX.length - 1] * scaleX -
					adjustLinehilightCenterX;
				const lineHighlightY = canvasLimits.top + padding + window.scrollY;

				setTooltiIdle(state => ({
					...state,
					position: {
						dots: {
							dotX: dotX,
							dotY: dotY,
						},
						lineHighlight: { x: lineHighlightX, y: lineHighlightY, height: canvasLimits.height - 2 * padding },
					},
				}));
			}
		};

		// Chama a função de redimensionamento inicialmente
		handleResize();

		// Adiciona um ouvinte de evento para o redimensionamento da janela (mantém o canvas responsivo)
		window.addEventListener('resize', handleResize);

		// Limpa o ouvinte de evento quando o componente é desmontado
		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartConfig.scaleFactorY, chartConfig.scaleFactorX, results, goals]);

	const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
		const canvas = canvasRef.current;
		if (!canvas) return;

		const padding = 20;
		const horizontalPadding = padding * 2;
		const scaleX = (canvas.width - horizontalPadding) / chartConfig.scaleFactorX;
		const scaleY = (canvas.height - horizontalPadding) / chartConfig.scaleFactorY;
		const canvasLimits = canvas.getBoundingClientRect();
		const mouseX = e.clientX - canvasLimits.left;
		const hightLabelsX = 5;
		const isInCanvasHigh =
			e.clientY - padding > canvasLimits.top &&
			e.clientY + padding + hightLabelsX < canvasLimits.height + canvasLimits.top;
		const tooltipBoxHeight = 70;
		const tooltipPositionY = -tooltipBoxHeight + canvasLimits.top + window.scrollY + canvas.height - padding;

		for (let index = 0; index < results.length; index++) {
			const x = padding + chartConfig.labelsPositionX[index] * scaleX;
			const isLastVerticalLine = results.length - 1 === index;
			const dotY =
				canvasLimits.top +
				window.scrollY +
				canvas.height -
				padding -
				results[index] * scaleY -
				chartConfig.adjustDotCenterY;
			const dotX =
				window.scrollX +
				canvasLimits.left +
				padding +
				chartConfig.labelsPositionX[index] * scaleX -
				chartConfig.adjustDotCenterX;
			const spaceToRight = 15;
			const spaceToLeft = 120;
			const verticalLineHoverBounders = 10;
			const adjustLinehilightCenterX = 6;
			const lineHighlightX =
				window.scrollX +
				canvasLimits.left +
				padding +
				chartConfig.labelsPositionX[index] * scaleX -
				adjustLinehilightCenterX;
			const lineHighlightY = canvasLimits.top + padding + window.scrollY;

			// Verifica a proximidade do mouse com a posição x da linha
			if (Math.abs(mouseX - x) <= verticalLineHoverBounders && isInCanvasHigh) {
				setTooltipConfig(state => ({
					indexSelectedItem: String(index),
					position: {
						x: e.clientX + spaceToRight,
						y: tooltipPositionY,
						dots: [
							...state.position.dots,
							{
								dotX,
								dotY,
								color: chartConfig.resultColor,
								isVisible: true,
							},
						],
						lineHighlight: {
							x: lineHighlightX,
							y: lineHighlightY,
							height: canvasLimits.height - 2 * padding,
						},
					},
				}));

				if (isLastVerticalLine)
					setTooltipConfig(state => ({ ...state, position: { ...state.position, x: e.clientX - spaceToLeft } }));

				return;
			}
		}

		setTooltipConfig(state => ({
			...state,
			indexSelectedItem: '',
			position: { x: 0, y: 0, dots: [], lineHighlight: { x: 0, y: 0, height: 0 } },
		}));
	};

	const tooltipResultado =
		results[Number(tooltipConfig?.indexSelectedItem)] < 0
			? 'N/A'
			: maskThousands(results[Number(tooltipConfig?.indexSelectedItem)] || 0);

	const tooltipMeta =
		goals[Number(tooltipConfig?.indexSelectedItem)] < 0
			? 'N/A'
			: maskThousands(goals[Number(tooltipConfig?.indexSelectedItem)]);

	return (
		<>
			{!!tooltipConfig?.indexSelectedItem ? (
				<>
					<Flex sx={styles.tooltipBox}>
						<Text sx={styles.tooltipTextTitle}>
							{labelsIndicatosAxisX[Number(tooltipConfig?.indexSelectedItem)]?.tooltip}
						</Text>
						<Flex sx={styles.tooltipItem}>
							<Box sx={{ ...styles.tooltipLegend, background: chartConfig.resultColor }}></Box>
							<Text sx={styles.tooltipTextDescription}>{`Resultado: ${tooltipResultado}`}</Text>
						</Flex>
						<Flex sx={styles.tooltipItem}>
							<Box sx={{ ...styles.tooltipLegend, background: chartConfig.goalColor }}></Box>
							<Text sx={styles.tooltipTextDescription}>{`Meta: ${tooltipMeta}`}</Text>
						</Flex>
					</Flex>
					{tooltipConfig?.position?.dots?.map(
						(value, index) =>
							value.isVisible && (
								<Box
									sx={{
										...styles.tooltipDot,
										top: `${value.dotY}px`,
										left: `${value.dotX}px`,
									}}
									key={index}
								/>
							),
					)}
					<Box sx={styles.tooltipLineHighlight} />
				</>
			) : (
				<>
					<Box sx={styles.idleTooltipDot} />
					<Box sx={styles.idleTooltipLineHighlight} />
				</>
			)}
			<Flex sx={styles.container}>
				<canvas id="myCanvas" onMouseMove={handleMouseMove} ref={canvasRef} height="133px"></canvas>
				<Flex sx={styles.idleTooltipBox}>
					<Text sx={styles.tooltipTextTitle}>{labelsIndicatosAxisX[labelsIndicatosAxisX.length - 1]?.tooltip}</Text>
					<Flex sx={styles.tooltipItem}>
						<Box sx={{ ...styles.tooltipLegend, background: chartConfig.resultColor }}></Box>
						<Text sx={styles.tooltipTextDescription}>{`Resultado: ${maskThousands(
							results[results?.length - 1] || 0,
						)}`}</Text>
					</Flex>
					<Flex sx={styles.tooltipItem}>
						<Box sx={{ ...styles.tooltipLegend, background: chartConfig.goalColor }}></Box>
						<Text sx={styles.tooltipTextDescription}>{`Meta: ${maskThousands(goals[goals?.length - 1] || 0)}`}</Text>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default LineChart;
