/**
 *
 * DataControlForm
 *
 */

import CustomCalendar from '../CustomCalendar';
import CustomModal from '../CustomModal';
import InputText from '../InputText';
import InputTextarea from '../InputTextarea';
import Multiselect from '../Multiselect';
import Prompt from '../Prompt';
import Select from '../Select';
import { useState } from 'react';
import { useMemo } from 'react';
import { InfoIcon, SmallAddIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	SystemStyleObject,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { CloseXIcon, TableEditIconEdited } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { addYears } from 'date-fns';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getSector } from 'services/http/sectors';
import { IDeadline, IPenalty, IPrazo } from 'types/dateControl';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import {
	LOST_POINTS,
	DATA_CONTROL_NOTIFICATION_INTERVAL,
	OTHER_PHASES,
	DATA_CONTROL_PENALIZED,
	STEPS_ENUM,
	USER_ROLES,
	DATA_CONTROL_PENALIZED_OTHER,
	DATA_CONTROL_DISABLED,
	RESULT_EVALUATION_PHASES,
	DATA_PHASES,
	DATA_CONTROL_STEPS,
	DATA_VALUE_RADIO,
	SECTOR_TYPE,
	API_DEFAULT_ERROR,
	ALL_UNITS_VALUE,
} from 'utils/constants';
import { showCalendarFormattedDate } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

type Params = {
	etapa: string;
};

export interface IUnit {
	id: number;
	unidade: string;
}

export interface IDataControlForm {
	id?: string;
	faseEnum: IOption;
	faseAdcional?: string;
	descricao: string;
	clausulaJustificativa: string;
	justificativa: string;
	penalidade: IPenalty;
	listaPrazos: IDeadline[];
	prazo?: IPrazo;
	ano?: number;
	dataCriacao?: string;
	etapa?: string;
	unidade?: IOption<IUnit>;
	idUnidade?: number;
	unidadesSegundaEtapa?: IOption<IUnit>[];
}

interface DataControlFormProps {
	onSubmitForm: (values: IDataControlForm) => void;
	isLoading?: boolean;
	isEditing?: boolean;
	defaultValues?: IDataControlForm;
}

const DataControlForm = ({
	onSubmitForm,
	defaultValues,
	isEditing = false,
	isLoading = false,
}: DataControlFormProps) => {
	const [hasError, setHasError] = useState(false);
	const MAX_DEADLINES = 12;

	const styles: Record<string, SystemStyleObject> = {
		container: {
			pt: '1.375rem',
			pb: '2.625rem',
			pl: '2rem',
			pr: '3.563rem',
			backgroundColor: '#F9FAFC',
			border: '1px solid',
			borderColor: '#E1E2E5',
			borderRadius: '4px',
			flexDir: 'column',
			gap: '1.5rem',
		},
		phaseContainer: {
			gap: {
				base: '1.5rem',
				lg: '2.5rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		nameContainer: {
			maxW: {
				base: '100%',
				lg: '21.313rem',
			},
			w: '100%',
		},
		otherPhaseContainer: {
			maxW: {
				base: '100%',
				lg: '26.063rem',
			},
			w: '100%',
			mt: {
				base: '0',
				lg: '2.5rem',
			},
		},
		textArea: {
			resize: 'none',
			h: '6.7rem',
		},
		divider: {
			pl: '2.875rem',
			pr: '1.625rem',
		},
		isEditingIcon: {
			marginLeft: '0.625rem',
		},
		penalitiesContainer: {
			gap: '2.5rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		penalityContainer: {
			maxW: '9.125ren',
		},
		penalizedContainer: {
			maxW: {
				base: '100%',
				lg: '14.813rem',
			},
			w: '100%',
		},
		lostPointsContainer: {
			maxW: {
				base: '100%',
				lg: '11rem',
			},
			w: '100%',
		},
		tooltip: {
			bg: 'white',
			color: 'black',
			fontSize: '0.875rem',
			fontWeight: 'normal',
		},
		tooltipIcon: {
			color: '#C0C1C4',
			h: '0.875rem',
			w: '0.875rem',
		},
		intervalContainer: {
			maxW: {
				base: '100%',
				lg: '14rem',
			},
			w: '100%',
			mt: {
				base: '0',
				lg: '2.5rem',
			},
		},
		deadlinesContainer: {
			flexDir: 'column',
			gap: '1.5rem',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight: 'bold',
			mb: '1.5rem',
		},
		deadlinesContent: {
			gap: '2.5rem',
			flexWrap: 'wrap',
		},
		deadlineInputContainer: {
			minH: '4rem',
			h: '100%',
			maxW: '10.5rem',
			w: '100%',
			pos: 'relative',
			alignSelf: 'baseline',
		},
		deadlineInputAddButton: {
			px: '0.813rem',
			mt: hasError ? '0.5rem' : '1.3rem',
			w: {
				base: '100%',
				lg: 'max-content',
			},
		},
		deadlineInputDeleteButton: {
			h: '1rem',
			w: '1rem',
			pos: 'absolute',
			top: '-7',
			right: '1',
		},
		buttonsContainer: {
			gap: '2.5rem',
			justifyItems: 'flex-start',
			mt: {
				base: '0',
				lg: '2.5rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		button: {
			px: '5.438rem',
		},
		unidadeContent: {
			maxW: {
				base: '100%',
				'2xl': '20rem',
			},
			w: '100%',
		},
	};
	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);
	const { etapa } = useParams<Params>();

	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const secondStepPermissions = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);
	const isRepresentative = useCanViewAction([USER_ROLES.REPRESENTANTE]);
	const stepEnum = etapa ? DATA_CONTROL_STEPS[etapa! as keyof typeof DATA_CONTROL_STEPS] : defaultValues?.etapa;

	const schema = yup.object().shape({
		faseEnum: yup.object().required('Este campo é obrigatório.').default(undefined).nullable(),
		unidade: yup
			.object()
			.test('unitInput', 'Este campo é obrigatório.', inputValue => stepEnum === STEPS_ENUM.ETAPA_UM || !!inputValue)
			.default(undefined)
			.nullable(),
		faseAdcional: yup.string().when('faseEnum.value', {
			is: OTHER_PHASES.value,
			then: yup.string().required('Este campo é obrigatório.').max(100, 'Máximo de 100 caracteres.'),
		}),
		descricao: yup.string().nullable().max(1000, 'Máximo de 1000 caracteres.'),
		clausulaJustificativa: yup.string().when('faseEnum.value', {
			is: OTHER_PHASES.value,
			then: yup.string().nullable().max(1000, 'Máximo de 1000 caracteres.'),
			otherwise: yup.string().required('Este campo é obrigatório.').max(1000, 'Máximo de 1000 caracteres.'),
		}),
		justificativa: yup.string().required('Este campo é obrigatório.').max(1000, 'Máximo de 1000 caracteres.'),
		penalidade: yup.object().shape({
			aplicavel: yup.string().required('Este campo é obrigatório.'),
			penalizadoEnum: yup.object().when('aplicavel', {
				is: 'NAO',
				then: yup.object().shape({
					label: yup.string().notRequired(),
					value: yup.string().notRequired(),
				}),
				otherwise: yup.object().shape({
					label: yup.string().required('Este campo é obrigatório.'),
					value: yup.string().required('Este campo é obrigatório.'),
				}),
			}),
			pontosPerdidos: yup.object().when('aplicavel', {
				is: 'NAO',
				then: yup.object().shape({
					label: yup.string().notRequired(),
					value: yup.string().notRequired(),
				}),
				otherwise: yup.object().shape({
					label: yup.string().required('Este campo é obrigatório.'),
					value: yup.string().required('Este campo é obrigatório.'),
				}),
			}),
			notificacao: yup.boolean(),
			tempoNotificacoes: yup
				.array()
				.of(
					yup.object().shape({
						label: yup.string().notRequired(),
						value: yup.string().notRequired(),
					}),
				)
				.when('notificacao', {
					is: true,
					then: yup.array().notRequired(),
					otherwise: yup.array().notRequired(),
				}),
		}),

		listaPrazos: yup.array().of(
			yup.object().shape({
				dataPrazo: yup
					.mixed()
					.default(undefined)
					.required('Este campo é obrigatório.')
					.test('noValue', 'Este campo é obrigatório.', (value = '') => {
						const maxValue = '';
						const isInvalid = maxValue === value;

						setHasError(isInvalid);
						return !isInvalid;
					}),
			}),
		),
	});

	const {
		control,
		register,
		setValue,
		handleSubmit,
		watch,
		resetField,
		reset,
		getValues,
		formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitted },
	} = useForm<IDataControlForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			listaPrazos: [
				{
					dataPrazo: undefined,
				},
			],
			...defaultValues,
		},
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const { fields, append, remove } = useFieldArray({
		name: 'listaPrazos',
		keyName: 'code',
		control,
	});

	const addNewDeadline = () => {
		append({
			dataPrazo: String(showCalendarFormattedDate(new Date(), 'days')),
		});
	};

	const deleteNewDeadline = (index: number) => {
		remove(index);
	};

	const isOtherPhase = watch('faseEnum')?.value === OTHER_PHASES.value;
	const selectedPhase = watch('faseEnum')?.value;
	const disablePenaltyDropdown = watch('faseEnum')?.value === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO;
	const disablePenaltyRadio = DATA_CONTROL_DISABLED.includes(String(selectedPhase));
	const hasPenalty = watch('penalidade') && watch('penalidade.aplicavel') === 'SIM';
	const disableInterval = watch('penalidade.notificacao') === true;
	const hasDate = watch('listaPrazos')[0]?.dataPrazo === undefined;

	const {
		session: { user },
	} = useSession();
	const userUnits = stringifyCurrentUserUnits(user);

	const secondStep = [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION];

	const { data: agreeds, isLoading: isAgreedsLoading } = useQuery<ISector[], AxiosError<ResponseErrors>>(
		['agreeds'],
		() => getSector(secondStep, userUnits),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const unidades = useMemo(() => {
		const map = new Map();
		const _unidades: IUnit[] = [];

		agreeds?.forEach(item => {
			if (!map.has(item.idUnidade)) map.set(item.idUnidade, item.sigla);
		});

		map.forEach((value, key) => _unidades.push({ id: key, unidade: value }));

		const unitParsed = parsedOptionArray(_unidades, 'unidade', 'id');
		const currentUnit = unitParsed?.find(unit => unit?.value === defaultValues?.idUnidade);

		setValue('unidadesSegundaEtapa', unitParsed as IOption<IUnit>[]);

		if (isEditing && currentUnit) setValue('unidade', currentUnit as IOption<IUnit>);

		return hasPermission ? [{ id: ALL_UNITS_VALUE, unidade: 'Todas' }, ..._unidades] : _unidades;
	}, [agreeds]);

	const onHandleSubmit = (values: IDataControlForm) => {
		onSubmitForm(values);
	};

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.dateControl);

		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const onChangeApplicablePenality = (value: string) => {
		if (value === DATA_VALUE_RADIO.SIM) {
			if (selectedPhase === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
				resetField('penalidade.penalizadoEnum', { defaultValue: DATA_CONTROL_PENALIZED_OTHER });
			}
			if (
				selectedPhase === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO ||
				selectedPhase === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA ||
				selectedPhase === RESULT_EVALUATION_PHASES.CONTRARRAZOES
			) {
				resetField('penalidade.penalizadoEnum', { defaultValue: { label: 'Área técnica', value: 'AREA_TECNICA' } });
			}
		} else {
			resetField('penalidade.penalizadoEnum', { defaultValue: { label: '', value: '' } });
			resetField('penalidade.pontosPerdidos', { defaultValue: { label: '', value: '' } });
		}
	};

	const onChangeApplicableNotifications = () => resetField('penalidade.tempoNotificacoes', { defaultValue: [] });

	const onChangePhase = (phase: IOption) => {
		const selectPhase = phase?.value;

		if (selectPhase) {
			if (
				selectPhase === RESULT_EVALUATION_PHASES.SOLICITACAO_PRIMEIRA_INSTANCIA ||
				selectPhase === RESULT_EVALUATION_PHASES.SOLICITACAO_SEGUNDA_INSTANCIA ||
				selectPhase === RESULT_EVALUATION_PHASES.DECISAO_CAAP
			) {
				reset({
					...getValues(),
					penalidade: {
						aplicavel: 'NAO',
						notificacao: false,
						tempoNotificacoes: [],
					},
				});
			}

			if (selectPhase === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO) {
				reset({
					...getValues(),
					penalidade: {
						aplicavel: 'SIM',
						notificacao: false,
						tempoNotificacoes: [],
						penalizadoEnum: DATA_CONTROL_PENALIZED_OTHER,
						pontosPerdidos: { label: '', value: '' },
					},
				});
			}
			if (
				selectPhase === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO ||
				selectPhase === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA ||
				selectPhase === RESULT_EVALUATION_PHASES.CONTRARRAZOES
			) {
				reset({
					...getValues(),
					penalidade: {
						aplicavel: 'SIM',
						notificacao: false,
						tempoNotificacoes: [],
						penalizadoEnum: { label: 'Área técnica', value: 'AREA_TECNICA' },
						pontosPerdidos: { label: '', value: '' },
					},
				});
			}
			if (selectPhase === OTHER_PHASES.value) {
				reset({
					...getValues(),
					penalidade: {
						aplicavel: '',
						notificacao: false,
						tempoNotificacoes: [],
						penalizadoEnum: { label: '', value: '' },
						pontosPerdidos: { label: '', value: '' },
					},
				});
			}
		}
		if (!selectPhase) {
			reset({
				...getValues(),
				penalidade: {
					aplicavel: '',
					notificacao: false,
					tempoNotificacoes: [],
					penalizadoEnum: { label: '', value: '' },
					pontosPerdidos: { label: '', value: '' },
				},
			});
		}
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	if (stepEnum === STEPS_ENUM.ETAPA_DOIS && isRepresentative && !secondStepPermissions)
		return <Navigate to={ROUTES.home} replace />;

	if (isEditing && defaultValues?.etapa === STEPS_ENUM.ETAPA_UM && !hasPermission) {
		return <Navigate to={ROUTES.home} replace />;
	}

	return (
		<Flex sx={styles.container} as="form" onSubmit={handleSubmit(onHandleSubmit)}>
			<Flex sx={styles.phaseContainer}>
				<Box sx={styles.nameContainer}>
					<Controller
						name="faseEnum"
						control={control}
						render={({ field }) => (
							<Select
								label="Nome da fase"
								value={field?.value}
								onChange={values => {
									field?.onChange(values);
									onChangePhase(values as IOption);
								}}
								options={DATA_PHASES[stepEnum as keyof typeof DATA_PHASES]}
								placeholder="Selecione o nome da fase"
								errorMessage={errors?.faseEnum?.message}
								isEditing={isEditing && !!dirtyFields?.faseEnum}
								dataTestId="input--nomeDaFase"
								required
							/>
						)}
					/>
				</Box>
				{isOtherPhase && (
					<Flex sx={styles.otherPhaseContainer}>
						<InputText
							name="faseAdcional"
							register={register}
							placeholder="Defina outra fase"
							errorMessage={errors?.faseAdcional?.message}
							isEditing={isEditing && !!dirtyFields?.faseAdcional}
							data-testid="input--outraFase"
							required={isOtherPhase}
						/>
					</Flex>
				)}
				{stepEnum === STEPS_ENUM.ETAPA_DOIS && (
					<Box sx={styles.unidadeContent}>
						<Controller
							name="unidade"
							control={control}
							render={({ field }) => (
								<Select
									label="Unidade"
									value={field?.value}
									onChange={field?.onChange}
									options={parsedOptionArray(unidades, 'unidade', 'id')}
									placeholder={'Selecione a unidade'}
									errorMessage={errors?.unidade?.message}
									dataTestId="select--unidade"
									isLoading={isAgreedsLoading}
									required
									isDisabled={isEditing}
								/>
							)}
						/>
					</Box>
				)}
			</Flex>

			<InputTextarea
				name="descricao"
				register={register}
				label="Descrição (Opcional)"
				placeholder="Insira a descrição"
				errorMessage={errors?.descricao?.message}
				isEditing={isEditing && !!dirtyFields?.descricao}
				data-testid="input--descricao"
				sx={styles.textArea}
				maxLength={1000}
			/>

			<Flex sx={styles.divider}>
				<Divider />
			</Flex>

			<InputTextarea
				register={register}
				name="clausulaJustificativa"
				label="Cláusula de justificativa"
				placeholder="Insira a cláusula de justificativa"
				isEditing={isEditing && !!dirtyFields?.clausulaJustificativa}
				errorMessage={errors?.clausulaJustificativa?.message}
				data-testid="input--clausulaJustificativa"
				sx={styles.textArea}
				maxLength={1000}
				required={!isOtherPhase}
			/>

			<InputTextarea
				register={register}
				name="justificativa"
				label="Justificativa"
				placeholder="Insira a justificativa"
				errorMessage={errors?.justificativa?.message}
				isEditing={isEditing && !!dirtyFields?.justificativa}
				data-testid="input--justificativa"
				sx={styles.textArea}
				maxLength={1000}
				required
			/>

			<Flex sx={styles.divider}>
				<Divider />
			</Flex>

			<Flex sx={styles.penalitiesContainer}>
				<Box sx={styles.penalityContainer}>
					<Controller
						name="penalidade.aplicavel"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControl isInvalid={!!errors?.penalidade?.aplicavel?.message}>
								<FormLabel>
									Aplica penalidade *
									{isEditing && !!dirtyFields?.penalidade?.aplicavel && (
										<Box sx={styles?.isEditingIcon}>
											<TableEditIconEdited />
										</Box>
									)}
								</FormLabel>
								<RadioGroup
									onChange={values => {
										onChange(values);
										onChangeApplicablePenality(values);
									}}
									value={value}
									isDisabled={disablePenaltyRadio}
								>
									<Flex gap="1.5rem" h="3rem">
										<Radio value="SIM" size="lg" data-testid="radio--penaliadeSim">
											Sim
										</Radio>
										<Radio value="NAO" size="lg" data-testid="radio--penaliadeNao">
											Não
										</Radio>
									</Flex>
								</RadioGroup>
								{errors?.penalidade?.message && (
									<FormErrorMessage data-testid="text--error">
										{errors?.penalidade?.aplicavel?.message}
									</FormErrorMessage>
								)}
							</FormControl>
						)}
					/>
				</Box>

				{hasPenalty && (
					<>
						<Box sx={styles.penalizedContainer}>
							<Controller
								name="penalidade.penalizadoEnum"
								control={control}
								render={({ field }) => (
									<Select
										label="Penalizado"
										value={field?.value}
										onChange={field?.onChange}
										options={DATA_CONTROL_PENALIZED}
										placeholder={'Selecione o penalizado'}
										errorMessage={errors?.penalidade?.penalizadoEnum?.value?.message}
										isEditing={isEditing && !!dirtyFields?.penalidade?.penalizadoEnum}
										dataTestId="input--penalizado"
										isDisabled={disablePenaltyDropdown}
										required={!disablePenaltyDropdown}
									/>
								)}
							/>
						</Box>

						<Box sx={styles.lostPointsContainer}>
							<Controller
								name="penalidade.pontosPerdidos"
								control={control}
								render={({ field }) => (
									<Select
										label="Pontos perdidos/dia"
										value={field?.value}
										onChange={field?.onChange}
										options={LOST_POINTS}
										placeholder="Selecione pontos perdidos"
										errorMessage={errors?.penalidade?.pontosPerdidos?.value?.message}
										isEditing={isEditing && !!dirtyFields?.penalidade?.pontosPerdidos}
										dataTestId="input--pontosPerdidos"
										required
									/>
								)}
							/>
						</Box>
					</>
				)}

				<Box sx={styles.penalityContainer}>
					<Controller
						name="penalidade.notificacao"
						control={control}
						render={({ field: { onChange, value } }) => (
							<FormControl isInvalid={!!errors?.penalidade?.notificacao?.message}>
								<FormLabel>
									Notificação
									<Box sx={styles?.isEditingIcon}>
										<Tooltip
											hasArrow
											placement="top-start"
											label="A notificação será enviada para o penalizado."
											sx={styles.tooltip}
										>
											<InfoIcon sx={styles.tooltipIcon} />
										</Tooltip>
									</Box>
									{isEditing && !!dirtyFields?.penalidade?.notificacao && (
										<Box sx={styles?.isEditingIcon}>
											<TableEditIconEdited />
										</Box>
									)}
								</FormLabel>
								<Flex gap="1.5rem" h="3rem">
									<Checkbox
										size="lg"
										onChange={values => {
											onChange(values);
											onChangeApplicableNotifications();
										}}
										isChecked={value}
										data-testid="checkbox--notificacao"
									>
										Não se aplica
									</Checkbox>
								</Flex>
								{errors?.penalidade?.notificacao?.message && (
									<FormErrorMessage data-testid="text--error">
										{errors?.penalidade?.notificacao?.message}
									</FormErrorMessage>
								)}
							</FormControl>
						)}
					/>
				</Box>

				<Box sx={styles.intervalContainer}>
					<Controller
						name="penalidade.tempoNotificacoes"
						control={control}
						render={({ field }) => (
							<Multiselect
								value={field?.value}
								onChange={field?.onChange}
								options={DATA_CONTROL_NOTIFICATION_INTERVAL}
								placeholder="Intervalo"
								errorMessage={errors?.penalidade?.tempoNotificacoes?.message}
								isEditing={isEditing && !!dirtyFields?.penalidade?.tempoNotificacoes}
								dataTestId="input--intervalo"
								isDisabled={disableInterval}
								required={!disableInterval}
							/>
						)}
					/>
				</Box>
			</Flex>

			<Flex sx={styles.divider}>
				<Divider />
			</Flex>

			<Flex sx={styles.deadlinesContainer}>
				<Text sx={styles.title}>Prazos a definir</Text>

				<Flex sx={styles.deadlinesContent}>
					{fields.map((item, index) => (
						<Flex sx={styles.deadlineInputContainer} key={item.code}>
							<Box w="100%">
								<CustomCalendar
									fieldName={`listaPrazos.${index}.dataPrazo`}
									label="Prazo"
									control={control}
									maxDate={addYears(new Date(), 5)}
									minDate={new Date()}
									setValue={setValue}
									placeholder="Defina prazo"
									errorMessage={hasDate ? errors.listaPrazos?.[index]?.dataPrazo?.message : ''}
									view="days"
									showDays
									dataTestId={`input--prazos.${index}.prazo`}
									required
									defaultValue={defaultValues?.prazo?.dataPrazo}
								/>
							</Box>
							{fields.length > 1 && (
								<Box sx={styles.deadlineInputDeleteButton}>
									<IconButton
										aria-label={'Deletar Prazo'}
										variant="unstyled"
										icon={<CloseXIcon />}
										data-testid="button--deleteDeadline"
										onClick={() => deleteNewDeadline(index)}
									/>
								</Box>
							)}
						</Flex>
					))}

					{fields.length < MAX_DEADLINES && !hasDate && !isEditing && (
						<Button
							sx={styles.deadlineInputAddButton}
							leftIcon={<SmallAddIcon />}
							onClick={addNewDeadline}
							data-testid="button--addDeadline"
						>
							Novo Prazo
						</Button>
					)}
				</Flex>
			</Flex>

			<Flex sx={styles.divider}>
				<Divider />
			</Flex>

			<Flex sx={styles.buttonsContainer}>
				<Button type="submit" data-testid="button--submit" isLoading={isLoading} sx={styles.button}>
					Salvar
				</Button>
				<Button variant="secondary" data-testid="button--cancel" onClick={handleOpenModal} sx={styles.button}>
					Cancelar
				</Button>
			</Flex>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você realmente quer cancelar?"
				body="Todas as informações inseridas serão perdidas caso você cancele."
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero Cancelar',
						type: 'cancel',
						onClick: handleGoBack,
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleCloseModal,
						datatestid: 'button--cancel',
					},
				]}
			/>
			<Prompt when={showPrompt} />
		</Flex>
	);
};

export default DataControlForm;
