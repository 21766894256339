/**
 *
 * StrategicPlanningList
 *
 */

import { useMemo } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Button, Stack, SystemStyleObject, Flex, Box, Spinner, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Accordions from 'app/components/Accordions';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import PageWrapper from 'app/components/PageWrapper';
import StrategicPlanningFilter, {
	IStrategicPlanningFilterForm,
	paramsKeys,
} from 'app/components/StrategicPlanningFilter';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getStrategicPlanningList, getPeriod } from 'services/http/strategicPlanning';
import { deleteStrategicPlanning } from 'services/http/strategicPlanning';
import { IResponseErrors } from 'types/httpError';
import { StrategicPlanning } from 'types/strategicPlanning';
import { API_DEFAULT_ERROR, USER_ROLES } from 'utils/constants';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';

export interface IStrategicPlanningFilterRequest {
	nomePlanejamento?: string;
	periodo?: string;
}

const StrategicPlanningList = () => {
	const styles: Record<string, SystemStyleObject> = {
		grid2: {
			gap: '3.75rem',
			rowGap: '2.5rem',
			gridTemplateColumns: {
				md: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
		},
		grid1: {
			gap: '1.75rem',
			rowGap: '1.8rem',
			gridTemplateColumns: {
				md: 'repeat(1, 1fr)',
			},
		},
		sidebar: {
			minW: '17.75rem',
			minH: '100vh',
			flexDir: 'column',
		},
		addStrategicPlanning: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '2rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		buttonAddStrategicPlanning: {
			padding: '0.5rem 1rem',
		},
		listStrategicPlanning: {
			mt: '2.5rem',
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};
	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const [searchParams, setParams] = useSearchParams();

	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const { data: periods, refetch: refetchPeriods } = useQuery<string[], AxiosError<IResponseErrors>>(
		['strategicPlanningPeriods'],
		() => getPeriod(),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const planningPeriods = useMemo(() => {
		return parsedOptionArray<string>(periods);
	}, [periods]);

	const { params, defaultValues } = useMemo(() => {
		const params = parseUrlParams<paramsKeys>(['nomePlanejamento', 'periodo'], searchParams);

		const periodo = planningPeriods.find(item => item.value === params.periodo);

		const defaultValues: IStrategicPlanningFilterForm = {
			nomePlanejamento: params.nomePlanejamento,
			periodo,
		};
		return { params, defaultValues };
	}, [planningPeriods, searchParams]);

	const {
		data: strategicPlans,
		isLoading,
		refetch: refetchStrategicPlanningList,
	} = useQuery<StrategicPlanning[], AxiosError<IResponseErrors>>(
		['strategicPlanningList', params],
		() => getStrategicPlanningList(params),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { mutate: deteleMutation } = useMutation<void, AxiosError<IResponseErrors>, string>(
		['strategicPlanningDelete'],
		id => deleteStrategicPlanning(id),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'Um planejamento estratégico foi excluído.',
				});
				refetchStrategicPlanningList();
				refetchPeriods();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const onSubmit = (values: IStrategicPlanningFilterForm) => {
		const periodo = cleanDeep({
			nomePlanejamento: values.nomePlanejamento,
			periodo: String(values.periodo?.value || ''),
		});
		setParams(periodo);
	};

	const handleCreate = () => navigate(ROUTES.createStrategicPlanning);

	const handleEdit = (id: string) => navigate(ROUTES.editStrategicPlanningWithId(id));

	const handleDelete = (id: string) => deteleMutation(id);

	return (
		<>
			<Flex flex="1" flexDir="column">
				<PageWrapper title="Planejamento Estratégico" goBackRoute={ROUTES.home} />

				<Flex sx={styles?.addStrategicPlanning}>
					{canViewAuditHistory && (
						<Button
							variant="unstyled"
							leftIcon={<RepeatClockIcon />}
							sx={styles.buttonShowModalAudit}
							onClick={onOpenAuditModal}
						>
							Histórico de Alterações
						</Button>
					)}

					{hasPermission && (
						<Button sx={styles?.buttonAddStrategicPlanning} onClick={handleCreate} data-testid="button--createPlanning">
							Cadastrar Novo Planejamento
						</Button>
					)}
				</Flex>

				<Box sx={styles?.container}>
					<StrategicPlanningFilter
						defaultValues={defaultValues}
						isListing={false}
						planningPeriods={planningPeriods}
						onSubmit={onSubmit}
					/>

					{isLoading ? (
						<Flex justifyContent="center" mt="1rem">
							<Spinner />
						</Flex>
					) : (
						<Stack sx={styles.listStrategicPlanning}>
							{strategicPlans?.map(planning => (
								<Accordions
									key={planning.id}
									strategicPlanning={planning}
									editStrategicPlanning={handleEdit}
									deleteStrategicPlanning={handleDelete}
								/>
							))}
						</Stack>
					)}
				</Box>
			</Flex>
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</>
	);
};

export default StrategicPlanningList;
