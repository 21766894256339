import { api } from '.';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import { Login, User } from 'types/user';

export const login = async (): Promise<Login> => {
	const response = await api.get<Login>(ENDPOINTS.login);
	return response.data;
};

export const getUserData = async (cpf: string, idsUnidades: string[]): Promise<User> => {
	const response = await api.get<User>(ENDPOINTS.userData, {
		params: { cpf, idsUnidades },
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};
