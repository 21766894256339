/**

 *

 * DataControlEdit

 *

 */

import { useMemo } from 'react';
import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import DataControlForm, { IDataControlForm } from 'app/components/DataControlForm';
import PageWrapper from 'app/components/PageWrapper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { getDateControlById, updateDateControl } from 'services/http/dateControl';
import { IRequestUpdateDateControl, IResponseDateControlById } from 'types/dateControl';
import {
	API_DEFAULT_ERROR,
	DATA_CONTROL_NOTIFICATION_INTERVAL_ENUM,
	DATA_CONTROL_PENALIZED_ENUM,
	DATA_CONTROL_STEPS,
	DATA_PHASES,
	TEXT_DATA_EDIT_POINTS,
} from 'utils/constants';
import { currentDatePayload, formatDateApiToFront, formatDateToApi } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';

type IParams = {
	dataControlId: string;
};

const updateDate = new Date();

const DataControlEdit = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},

		content: {
			mt: '3.75rem',
			mb: '5.5rem',
		},

		spinner: {
			mt: '3.7rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { dataControlId = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const {
		session: { user },
	} = useSession();

	const { data: dateControl, isLoading: isLoadingDates } = useQuery<
		IResponseDateControlById,
		AxiosError<ResponseErrors>,
		IResponseDateControlById
	>(['date', dataControlId], () => getDateControlById(dataControlId), {
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
		enabled: !!dataControlId,
	});

	const { mutate: updateDateControlMutate, isLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestUpdateDateControl
	>(data => updateDateControl(data, dataControlId), {
		onSuccess: () => {
			navigate(ROUTES.dateControl);
			addToast({ type: 'success', description: 'Data definida com sucesso!', title: 'Sucesso!' });
		},
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
	});

	const defaultValues: IDataControlForm = useMemo(() => {
		return {
			...dateControl!,
			id: String(dateControl?.id),
			prazo: {
				id: dateControl?.prazo?.id!,
				dataPrazo: formatDateApiToFront(String(dateControl?.prazo?.dataPrazo)),
			},
			faseEnum: DATA_PHASES[dateControl?.etapa! as keyof typeof DATA_PHASES]?.find(
				item => item.value === dateControl?.faseEnum,
			)!,
			penalidade: {
				id: dateControl?.penalidade?.id,
				pontosPerdidos: {
					label:
						TEXT_DATA_EDIT_POINTS[
							String(dateControl?.penalidade?.pontosPerdidos) as keyof typeof TEXT_DATA_EDIT_POINTS
						],
					value: String(dateControl?.penalidade?.pontosPerdidos),
				},
				aplicavel: Boolean(dateControl?.penalidade?.aplicavel) === true ? 'SIM' : 'NAO',
				notificacao: Boolean(dateControl?.penalidade?.notificacao) === false ? true : false,
				penalizadoEnum: {
					label:
						DATA_CONTROL_PENALIZED_ENUM[
							String(dateControl?.penalidade?.penalizadoEnum) as keyof typeof DATA_CONTROL_PENALIZED_ENUM
						],
					value: String(dateControl?.penalidade?.penalizadoEnum),
				},
				tempoNotificacoes:
					dateControl?.penalidade?.tempoNotificacoes?.map(item => {
						return {
							label:
								DATA_CONTROL_NOTIFICATION_INTERVAL_ENUM[
									String(item) as keyof typeof DATA_CONTROL_NOTIFICATION_INTERVAL_ENUM
								],
							value: String(item),
						};
					}) || [],
			},
			listaPrazos: [
				{
					dataPrazo: formatDateApiToFront(String(dateControl?.prazo?.dataPrazo)),
					id: dateControl?.prazo?.id,
				},
			],
			etapa: String(dateControl?.etapa),
		};
	}, [dateControl]);

	const onSubmitDateControl = (data: IDataControlForm) => {
		const dataToSend = {
			responsavelAtualizacao: user?.nomeCompleto,
			dataCriacao: data.dataCriacao!,
			dataAtualizacao: currentDatePayload(updateDate),
			faseEnum: String(data.faseEnum.value),
			faseAdcional: data.faseAdcional || '',
			descricao: data.descricao || '',
			clausulaJustificativa: data.clausulaJustificativa,
			justificativa: data.justificativa,
			penalidade: {
				id: Number(data.penalidade.id),
				aplicavel: data.penalidade.aplicavel === 'SIM' ? true : false,
				penalizadoEnum: data.penalidade.aplicavel === 'SIM' ? String(data.penalidade.penalizadoEnum?.value) : null,
				pontosPerdidos: data.penalidade.aplicavel === 'SIM' ? String(data.penalidade.pontosPerdidos?.value) : null,
				notificacao: data.penalidade.notificacao ? false : true,
				tempoNotificacoes:
					(!data.penalidade.notificacao &&
						data?.penalidade?.tempoNotificacoes?.map(item => {
							return String(item.value);
						})) ||
					null,
			},
			prazo: {
				id: Number(data.listaPrazos[0].id),
				dataPrazo: formatDateToApi(String(data?.listaPrazos[0].dataPrazo)),
			},
			etapa: String(data.etapa),
			idUnidade: data?.unidade?.data?.id,
			siglaUnidade: data?.unidade?.data?.unidade,
		} as IRequestUpdateDateControl;

		if (dataToSend.etapa === DATA_CONTROL_STEPS['etapa-1']) {
			delete dataToSend.idUnidade;
			delete dataToSend.siglaUnidade;
		}

		updateDateControlMutate(dataToSend);
	};

	return (
		<>
			<Flex sx={styles.container}>
				<PageWrapper title="Editar Definir Datas" goBackRoute={ROUTES.dateControl} />

				{isLoadingDates ? (
					<Box sx={styles?.spinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Box>
				) : (
					<Box sx={styles.content}>
						<DataControlForm
							onSubmitForm={onSubmitDateControl}
							isEditing
							isLoading={isLoading}
							defaultValues={defaultValues}
						/>
					</Box>
				)}
			</Flex>
		</>
	);
};

export default DataControlEdit;
