/**
 *
 * PenaltyFilter
 *
 */

import Select from '../Select';
import { useMemo, useEffect, useState } from 'react';
import { Box, Button, Flex, SystemStyleObject } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { IPenaltyParams } from 'services/http/penalty';
import { getPeriodsConcat } from 'services/http/periods';
import { getSector } from 'services/http/sectors';
import { IPeriodsConcat } from 'types/period';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import {
	API_DEFAULT_ERROR,
	BIMESTERS_OPTIONS,
	DATA_CONTROL_STEPS_OPTIONS,
	DATA_PHASES,
	SECTOR_TYPE,
	STEPS_OPTIONS,
	BIMESTER_TOOLTIP_INFO,
	DATA_PHASES_ETAPA_UM,
	RESULT_EVALUATION_PHASES,
} from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

export interface PenaltyFilterForm {
	periodo?: IOption<IPeriodsConcat>;
	etapa?: IOption;
	bimestre?: IOption;
	fase?: IOption;
	idPactuadoExterno?: IOption;
}

export type paramsKeys = keyof IPenaltyParams;

interface PenaltyFilterProps {
	isListing?: boolean;
}

const PenaltyFilter = ({ isListing = true }: PenaltyFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			backgroundColor: '#F9FAFC',
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			p: '2rem',
			flexDir: 'column',
		},
		selectsContainer: {
			mb: '2rem',
			gap: '2rem',
			flexDir: {
				base: 'column',
				'2xl': 'row',
			},
		},
		selectsInterContainer: {
			gap: '1.5rem',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
		},
		button: {
			w: {
				base: '100%',
				xl: '15rem',
			},
		},
		periodoContent: {
			maxW: {
				base: '100%',
				'2xl': '9.063rem',
			},
			w: '100%',
		},
		etapaContent: {
			maxW: {
				base: '100%',
				'2xl': '8.875rem',
			},
			w: '100%',
		},
		bimestreContent: {
			maxW: {
				base: '100%',
				'2xl': '14rem',
			},
			w: '100%',
		},
		faseContent: {
			maxW: {
				base: '100%',
				'2xl': '15.5rem',
			},
			w: '100%',
		},
		pactuadoContent: {
			width: { base: '100%', md: '100%', '2xl': '30rem' },
			maxWidth: { base: '100%', md: '100%', '2xl': '30rem' },
		},
	};

	const {
		session: { user },
	} = useSession();
	const { addToast } = useCustomToast();
	const [searchParams, setParams] = useSearchParams();

	const schema = yup.object().shape({
		periodo: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string(),
			})
			.nullable(),
		etapa: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string(),
			})
			.nullable(),
		bimestre: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string(),
			})
			.nullable(),
		fase: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string(),
			})
			.nullable(),
		idPactuadoExterno: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string(),
			})
			.nullable(),
	});

	const [counter, setCounter] = useState(0);

	const { defaultValues, params } = useMemo(() => {
		const paramsKeys: paramsKeys[] = ['etapa', 'bimestre', 'fase', 'idPactuadoExterno', 'anoInicio', 'anoFim'];
		const params = parseUrlParams<paramsKeys>(paramsKeys, searchParams);

		const etapa = DATA_CONTROL_STEPS_OPTIONS.find(item => item.value === params.etapa);

		const bimestre = BIMESTERS_OPTIONS.find(item => item.value === params.bimestre);

		const DATA_PHASES_OPTIONS = params.etapa
			? DATA_PHASES[params.etapa as keyof typeof DATA_PHASES]
			: DATA_PHASES_ETAPA_UM;

		const fase = DATA_PHASES_OPTIONS.find(item => item.value === params.fase);

		const defaultValues: PenaltyFilterForm = {
			etapa,
			bimestre,
			fase,
		};

		return { defaultValues, params };
	}, [searchParams]);

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		resetField,
		getValues,
	} = useForm<PenaltyFilterForm>({
		resolver: isListing ? undefined : yupResolver(schema),
		defaultValues,
	});

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const etapa = watch('etapa');
	const fase = watch('fase');

	const { data: periodoConcatPenalty } = useQuery<IPeriodsConcat[], AxiosError<ResponseErrors>>(
		['periods-penalties'],
		() => getPeriodsConcat(),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const parsedPeriods = useMemo(() => {
		return parsedOptionArray<IPeriodsConcat>(
			periodoConcatPenalty,
			'periodoConcatenado',
			'periodoConcatenado',
		) as IOption<IPeriodsConcat>[];
	}, [periodoConcatPenalty]);

	const { data: agreeds, isLoading: isLoadingAgreeds } = useQuery<ISector[], AxiosError<ResponseErrors>>(
		['agreeds', etapa?.value],
		() => getSector(selectedStep[etapa?.value as keyof typeof selectedStep], stringifyCurrentUserUnits(user)),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!etapa?.value,
		},
	);

	const penaltyAgreeds = useMemo(() => {
		return parsedOptionArray(agreeds, 'nome', 'idSetor');
	}, [agreeds]);

	const DATA_PHASES_OPTIONS = etapa ? DATA_PHASES[etapa.value as keyof typeof DATA_PHASES] : DATA_PHASES_ETAPA_UM;

	const handleChangeStep = (newValue: IOption) => {
		if (fase?.value === RESULT_EVALUATION_PHASES.DECISAO_CAAP) {
			const value = DATA_PHASES[newValue.value as keyof typeof DATA_PHASES].find(
				item => item.value === RESULT_EVALUATION_PHASES.DECISAO_CAAP,
			);

			resetField('fase', { defaultValue: value });
		}
	};

	useEffect(() => {
		if (counter < 4) {
			const idPactuadoExterno = penaltyAgreeds?.find(item => String(item.value) === params.idPactuadoExterno);
			const periodo = parsedPeriods?.find(
				item => String(item.data?.anoInicio) === params.anoInicio && String(item.data?.anoFim) === params.anoFim,
			);

			setValue('idPactuadoExterno', idPactuadoExterno);
			setValue('periodo', periodo);
			setCounter(counter + 1);
		}
	}, [counter, params.anoFim, params.anoInicio, params.idPactuadoExterno, parsedPeriods, penaltyAgreeds, setValue]);

	const clearFields = (field: keyof PenaltyFilterForm, defaultValue: string | IOption[] = '') => {
		resetField(field, { defaultValue });
	};

	const onHandleSubmit = (values: PenaltyFilterForm) => {
		const parseData = cleanDeep({
			etapa: String(values.etapa?.value || ''),
			anoInicio: String(values.periodo?.data?.anoInicio || ''),
			anoFim: String(values.periodo?.data?.anoFim || ''),
			bimestre: String(values.bimestre?.value || ''),
			fase: String(values.fase?.value || ''),
			idPactuadoExterno: String(values.idPactuadoExterno?.value || ''),
		});

		setParams(parseData);
	};

	return (
		<Flex as="form" onSubmit={handleSubmit(onHandleSubmit)} sx={styles.container}>
			<Flex sx={styles.selectsContainer}>
				<Flex sx={styles.selectsInterContainer}>
					<Box sx={styles.etapaContent}>
						<Controller
							name="etapa"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Select
									label="Etapa"
									placeholder="Selecione a etapa"
									options={STEPS_OPTIONS}
									onChange={e => {
										onChange(e);
										clearFields('idPactuadoExterno');
										handleChangeStep(e as unknown as IOption);
									}}
									value={value}
									dataTestId="select--etapa"
									errorMessage={errors?.etapa?.message}
								/>
							)}
						/>
					</Box>
					<Box sx={styles.periodoContent}>
						<Controller
							name="periodo"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Select
									label="Período"
									options={parsedOptionArray(periodoConcatPenalty, 'periodoConcatenado', 'periodoConcatenado')}
									placeholder="Selecione o período"
									onChange={onChange}
									value={value}
									dataTestId="select--periodo"
									errorMessage={errors?.periodo?.message}
								/>
							)}
						/>
					</Box>
					<Box sx={styles.bimestreContent}>
						<Controller
							name="bimestre"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Select
									label="Bimestre"
									placeholder="Selecione o bimestre"
									tooltipInfo={BIMESTER_TOOLTIP_INFO}
									options={BIMESTERS_OPTIONS}
									onChange={onChange}
									value={value}
									dataTestId="select--bimestre"
									errorMessage={errors?.bimestre?.message}
								/>
							)}
						/>
					</Box>
				</Flex>

				<Flex sx={styles.selectsInterContainer}>
					<Box sx={styles.faseContent}>
						<Controller
							name="fase"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Select
									label="Fase"
									placeholder="Selecione a fase"
									options={DATA_PHASES_OPTIONS}
									onChange={onChange}
									value={value}
									dataTestId="select--fase"
									errorMessage={errors?.fase?.message}
								/>
							)}
						/>
					</Box>
					<Box sx={styles.pactuadoContent}>
						<Controller
							name="idPactuadoExterno"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Select
									label="Pactuado"
									placeholder="Selecione o pactuado"
									options={penaltyAgreeds}
									onChange={onChange}
									value={value}
									dataTestId="select--pactuado"
									errorMessage={errors?.idPactuadoExterno?.message}
									isLoading={isLoadingAgreeds && !!getValues('etapa.value')}
									isDisabled={!etapa?.value}
								/>
							)}
						/>
					</Box>
				</Flex>
			</Flex>
			<Button sx={styles.button} type="submit" data-testid="button--submit">
				Pesquisar
			</Button>
		</Flex>
	);
};

export default PenaltyFilter;
