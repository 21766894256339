import { IVotingRecord } from 'app/components/ResultEvaluationVotingForm';

interface IVotingReduce {
	[key: string]: number;
}

export const formatVotingData = (data: IVotingRecord[]) => {
	return data.reduce((acc: IVotingReduce, currValue) => {
		const voto = currValue.voto;
		if (voto) {
			if (voto in acc) {
				acc[voto]++;
			} else {
				acc[voto] = 1;
			}
		}

		return acc;
	}, {});
};
