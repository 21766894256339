/**
 *
 * ReportMonitoringModal
 *
 */

import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	SystemStyleObject,
	Box,
	Flex,
	Tag,
	Heading,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { DownloadPdfIconModal, SighMainLogoIconModal } from 'assets/icons';
import { AxiosError } from 'axios';
import { useCustomToast } from 'hooks/useToast';
import {
	downloadCompleteReportMonitoringPdf,
	downloadCompleteReportMonitoringXlxs,
} from 'services/http/monitoringReport';
import { IReportMonitoringResponse, ReportSectorTotalization } from 'types/reportMonitoring';
import { API_DEFAULT_ERROR, BIMESTERS_ENUM, STEPS_OPTIONS_FILTER } from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { formatDecimalNumber } from 'utils/formatPerformanceNumber';
import { ResponseErrors } from 'utils/parseErrors';

interface ReportMonitoringModalProps {
	data?: IReportMonitoringResponse;
	onClose: () => void;
	isOpen: boolean;
}

const ReportMonitoringModal = ({ data, onClose, isOpen }: ReportMonitoringModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		principal: {
			maxWidth: '68.75rem',
			w: '100%',
			alignItems: 'center',
			padding: '0.5rem 3rem',
			margin: '2.5rem',
		},
		header: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			flexDirection: { base: 'column', lg: 'row' },
			alignItems: { base: 'baseline', lg: 'center' },
		},
		title: { fontSize: '1.5rem', fontWeight: 600, textAlign: 'center' },
		boxB: { m: { base: '1.5rem 0', lg: '0 9rem' } },
		head: {
			flexDirection: { base: 'column', lg: 'row' },
			m: { base: '0', lg: '1rem 0' },
			gap: '0.625rem',
			alignItems: { base: 'baseline', lg: 'center' },
		},
		headText: { fontSize: '1.5rem', fontWeight: 800 },
		headTag: {
			fontSize: '0.75rem',
			borderRadius: '0.75rem',
			h: '0.938rem',
			backgroundColor: 'blue.400',
			minW: '3.531rem',
		},
		flexContainerTablePeriod: {
			height: { base: 'auto', md: '80px', lg: '102px' },
			w: '100%',
			display: 'flex',
			flexDirection: { base: 'row', md: 'column' },
			flexWrap: 'wrap',
			pb: { lg: '21px' },
			gap: '4px',
		},
		titleThirdBox: {
			display: 'flex',
			w: '100%',
			color: 'white',
			borderTopLeftRadius: '0.25rem',
			borderTopRightRadius: '0.25rem',
			p: '1rem',
			h: '2.75rem',
			bgColor: '#004A73',
			minHeight: '2.188rem',
			fontSize: '1rem',
			fontWeight: 700,
			alignItems: 'center',
			justifyContent: 'flex-start',
			textAlign: 'left',
		},
		thirdBox: {
			display: 'flex',
			flexDirection: { base: 'column', lg: 'row' },
			maxW: '100%',
			w: '100%',
			borderRadius: '0.25rem',
			p: '0 1rem',
			pb: { base: '0.5rem', lg: 0 },
			bgColor: '#ddeffd',
			h: { base: 'auto', lg: '8.5rem' },
			rowGap: { base: '0.5rem', lg: '0.1rem' },
			gap: { base: '1rem', lg: '3rem' },
		},
		textBoxGrid4: {
			fontWeight: 700,
			width: '40%',
		},
		textBoxGridPact: {
			fontSize: '1rem',
			width: '45%',
		},
		footerContent: {
			maxW: { base: '100%' },
			w: '100%',
			justifyContent: 'center',
			alignSelf: 'center',
			gap: { base: '1.5rem', sm: '1.875rem' },
			flexDirection: { base: 'column', lg: 'row' },
			flexWrap: 'wrap',
			p: '3rem 24px 16px 24px',
		},
		button: { fontSize: '1rem', h: '2.75rem', w: { base: '100%', lg: '298px' } },
		modalCloseBtn: {
			margin: '0.5rem 1rem',
			padding: '0.625rem',
		},
		modalTextSubtitle: {
			fontSize: { base: '14px', lg: '24px' },
			fontWeight: 'bold',
			color: 'black',
		},
		modalTextSubtitleResponse: {
			fontSize: '49px',
			fontWeight: 'bold',
			color: '#004A73',
			lineHeight: '41px',
			paddingTop: '8px',
		},
		backButton: {
			bg: 'none',
			color: '#000',
			w: '100%',
			_hover: {},
		},
		buttonBox: {
			gap: { base: '2rem', lg: '3rem' },
			flexDirection: { base: 'column', lg: 'row' },
			width: '100%',
			justifyContent: 'center',
		},
		contentPrincipalContainer: {
			margin: '1rem 1.5rem',
		},
		flexPrincipalContainer: {
			flexDirection: 'column',
			gap: '2.75rem',
		},
	};

	const { addToast } = useCustomToast();

	const { mutate: downloadMutatePdf, isLoading: isLoadingDownloadPdf } = useMutation<Blob, AxiosError<ResponseErrors>>(
		() => downloadCompleteReportMonitoringPdf(data!),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'relatorio_completo.pdf', true);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				});
			},
		},
	);

	const handleExportPdf = () => {
		downloadMutatePdf();
	};

	const { mutate: downloadMutateXlxs, isLoading: isLoadingDownloadXlxs } = useMutation<
		Blob,
		AxiosError<ResponseErrors>
	>(() => downloadCompleteReportMonitoringXlxs(data!), {
		onSuccess: data => {
			const href = URL.createObjectURL(data);

			downloadFile(href, 'relatorio_completo.xlsx', false);
		},
		onError: ({ response }) => {
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			});
		},
	});

	const handleExportXlxs = () => {
		downloadMutateXlxs();
	};

	const groupTotalizacoesPerYear = (
		totalizacoes: ReportSectorTotalization[],
	): Record<string, ReportSectorTotalization[]> => {
		return totalizacoes.reduce((acc, totalizacao) => {
			const ano = totalizacao.ano.toString();
			acc[ano] = acc[ano] || [];
			acc[ano].push(totalizacao);
			return acc;
		}, {} as Record<string, ReportSectorTotalization[]>);
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent sx={styles.principal}>
					<ModalHeader sx={styles.header}>
						<SighMainLogoIconModal />

						<Box sx={styles.boxB}>
							<Text sx={styles.title}>Resultados Consolidados</Text>
						</Box>
						<Box w="155px">
							<ModalCloseButton size="2rem" sx={styles.modalCloseBtn} />
						</Box>
					</ModalHeader>

					<ModalBody w="100%" p="0">
						<Flex sx={styles.flexPrincipalContainer}>
							{data?.relatorios?.map((item, index) => (
								<Box key={index} sx={styles.contentPrincipalContainer}>
									<Flex sx={styles.head}>
										<Tag variant="solid" sx={styles.headTag} data-testid={`download--report-step-${index}`}>
											{STEPS_OPTIONS_FILTER[String(item.etapa) as keyof typeof STEPS_OPTIONS_FILTER]}
										</Tag>
										<Heading
											sx={styles.headText}
											data-testid="download--report-name"
										>{`Desempenho do pactuado: ${item.unidade}`}</Heading>
									</Flex>
									<Flex gap="3.5rem" mt="0.5rem">
										<Box>
											<Text sx={styles.modalTextSubtitle}>Setores</Text>
											<Text sx={styles.modalTextSubtitleResponse} data-testid={`download--report-sectors-${index}`}>
												{item.numeroSetores}
											</Text>
										</Box>
										<Box>
											<Text sx={styles.modalTextSubtitle}>Média de desempenho</Text>
											<Text sx={styles.modalTextSubtitleResponse} data-testid={`download--report-media-${index}`}>
												{item.mediaDesempenho < 0 ? 'N/A' : formatDecimalNumber(item?.mediaDesempenho)}
											</Text>
										</Box>
									</Flex>
									{item.setores.map((setor, index) => (
										<Flex flexDirection="column" mt="40px" key={setor?.nomeSetor + index}>
											<Text sx={styles.titleThirdBox} data-testid={`download--report-name-${setor?.nomeSetor}`}>
												{setor.nomeSetor.trim()[0].toUpperCase() + setor.nomeSetor.trim().substring(1).toLowerCase()}
											</Text>
											<Box sx={styles.thirdBox}>
												{Object.keys(groupTotalizacoesPerYear(setor.totalizacao)).map(ano => (
													<Flex flexDirection={'column'} pt="12px" pb={{ base: 0, md: 0 }} gap="4px" key={ano}>
														<Text sx={styles.textBoxGrid4} fontSize="20px">
															{ano}
														</Text>
														<Flex sx={styles.flexContainerTablePeriod}>
															{groupTotalizacoesPerYear(setor.totalizacao)[ano].map((totalizacao, idx) => (
																<Flex
																	w="17rem"
																	key={idx + totalizacao?.bimestre}
																	data-testid={`download--repor-metrics-${totalizacao?.totalPeso}`}
																	alignItems="center"
																>
																	<Text sx={styles.textBoxGrid4} fontSize="1rem">
																		{BIMESTERS_ENUM[totalizacao.bimestre as keyof typeof BIMESTERS_ENUM]}
																	</Text>
																	<Text sx={styles.textBoxGridPact}>
																		Total geral:{' '}
																		{totalizacao.desempenho < 0 ? 'N/A' : formatDecimalNumber(totalizacao?.desempenho)}
																	</Text>
																</Flex>
															))}
														</Flex>
													</Flex>
												))}
											</Box>
										</Flex>
									))}
								</Box>
							))}
						</Flex>
						<ModalFooter sx={styles.footerContent}>
							<Flex sx={styles.buttonBox}>
								<Button
									leftIcon={<DownloadPdfIconModal />}
									sx={styles.button}
									onClick={handleExportPdf}
									data-testid="download--bt-report"
									isLoading={isLoadingDownloadPdf}
								>
									Baixar em PDF
								</Button>
								<Button
									leftIcon={<DownloadPdfIconModal />}
									sx={styles.button}
									onClick={handleExportXlxs}
									data-testid="download--bt-report"
									isLoading={isLoadingDownloadXlxs}
								>
									Baixar em XLSX
								</Button>
							</Flex>
							<Button sx={styles.backButton} onClick={onClose} data-testid="cancel--bt-report">
								Voltar
							</Button>
						</ModalFooter>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ReportMonitoringModal;
