import { getAxios } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import {
	DataControlData,
	IRequestDateControl,
	IRequestDateControlOverlap,
	IRequestUpdateDateControl,
	IResponseDateControlById,
	IResponseDateControlOverlap,
	IResponseExportDate,
} from 'types/dateControl';

export interface DateControlParams {
	nomeFase?: string;
	ano?: string;
	bimestre?: string;
	etapa?: string;
}

export const createDateControl = async (data: IRequestDateControl): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.dateControlCreate, data);
	return response.data;
};

export const getDateControlById = async (dateControlId: string): Promise<IResponseDateControlById> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.dateControlGetById(dateControlId));
	return response.data;
};

export const getPeriodDataPact = async (): Promise<string[]> => {
	const api = getAxios();
	const response = await api.get<string[]>(ENDPOINTS.dateControlPeriods);
	return response.data;
};

export const getDateControl = async (params?: DateControlParams) => {
	const api = getAxios();
	const response = await api.get<DataControlData[]>(ENDPOINTS.dateControlList, {
		params: {
			...params,
		},
	});
	return response.data;
};

export const updateDateControl = async (data: IRequestUpdateDateControl, dateId: string): Promise<void> => {
	const api = getAxios();
	await api.put(ENDPOINTS.dateControlUpdate(dateId), data);
	return;
};

export const deleteDateControl = async (dateControlId: string): Promise<any> => {
	const api = getAxios();
	await api.delete(ENDPOINTS.dateControlDelete(dateControlId));
};

export interface ExportDataParams {
	periodo: string;
	etapa?: string;
}

export const getDataControlTableExport = async (params: ExportDataParams): Promise<IResponseExportDate> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.dateControlExportTableData, { params });
	return response.data;
};

export const getDataControlTableExportPdf = async (params: ExportDataParams) => {
	const api = getAxios();
	const response = await api.get<void>(ENDPOINTS.dateControlExportPdfData, {
		params,
		responseType: 'blob',
	});
	return response;
};

export const getDateControlSteps = async (etapa: string) => {
	const api = getAxios();
	const response = await api.get<DataControlData[]>(ENDPOINTS.dateControlListSteps, {
		params: {
			etapa,
		},
	});
	return response.data;
};

export const getDateControlOverlap = async ({ params }: IRequestDateControlOverlap) => {
	const api = getAxios();
	const response = await api.get<IResponseDateControlOverlap[]>(ENDPOINTS.dateControlCheckOverlap, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};
