/**
 *
 * StrategicObjectivesForm
 *
 */

import CustomModal from '../CustomModal';
import InputMask from '../InputMask';
import InputTextarea from '../InputTextarea';
import Prompt from '../Prompt';
import Select from '../Select';
import { useMemo, useState } from 'react';
import { Box, Button, HStack, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getPeriod, getPerspective } from 'services/http/strategicObjetive';
import { IOption } from 'types/select';
import { Perspective } from 'types/strategicPlanning';
import { API_DEFAULT_ERROR, CODE_MASK } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import * as yup from 'yup';

export interface IStrategicObjectiveForm {
	objetivo: string;
	periodo: IOption;
	descricao: string;
	codigo?: string;
	perspectiva: IOption;
}

interface StrategicObjectivesFormProps {
	defaultValues?: IStrategicObjectiveForm;
	isEditing?: boolean;
	isSubmitingForm?: boolean;
	onSubmitForm: (values: IStrategicObjectiveForm) => void;
}

const StrategicObjectivesForm = ({
	onSubmitForm,
	defaultValues,
	isEditing = false,
	isSubmitingForm = false,
}: StrategicObjectivesFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		backgroundBox: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '0 2rem 2rem 2rem',
		},
		content: {
			width: '100%',
		},
		row: {
			marginTop: '2.5rem',
			width: '100%',
			alignItems: 'baseline',
			gap: {
				base: '2rem',
				md: '2rem',
				lg: '2.5rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
		},
		actions: {
			gap: '3rem',
			mt: '8.1rem',
			flexWrap: 'wrap',
		},
		textarea: {
			resize: 'none',
		},
		inputObjectiveName: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '39.5rem',
			},
		},
		inputPeriod: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '18rem',
			},
		},
		inputObjectiveDescription: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '39.5rem',
			},
		},
		inputObjectiveCode: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '18rem',
			},
		},
		inputObjectivePerspective: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '60.5rem',
			},
		},
		formSave: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '13.5rem',
			},
		},
		formCancel: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '13.5rem',
			},
		},
		saveButton: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '13.5rem',
			},
		},
		cancelButton: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '13.5rem',
			},
		},
	};

	const schema = yup.object().shape({
		objetivo: yup.string().required('Este campo é obrigatório'),
		periodo: yup
			.object()
			.shape({
				label: yup.string().required('Este campo é obrigatório'),
				value: yup.string().required('Este campo é obrigatório'),
			})
			.required('Este campo é obrigatório')
			.default(undefined)
			.nullable(),
		perspectiva: yup
			.object()
			.shape({
				label: yup.string().required('Este campo é obrigatório'),
				value: yup.string().required('Este campo é obrigatório'),
			})
			.required('Este campo é obrigatório')
			.default(undefined)
			.nullable(),
		descricao: yup.string().required('Este campo é obrigatório'),
	});

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitted },
		register,
		getValues,
		resetField,
		watch,
	} = useForm<IStrategicObjectiveForm>({
		resolver: yupResolver(schema),
		defaultValues: { ...defaultValues },
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const { data: periods } = useQuery<string[], AxiosError<ResponseErrors>, string[]>(
		['objective-periods'],
		() => getPeriod(),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const selectedPeriod = useMemo(() => {
		return getValues('periodo');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getValues, watch('periodo')]);

	const { data: perspectives, isLoading: isLoadingPerspectives } = useQuery<
		Perspective[],
		AxiosError<ResponseErrors>,
		Perspective[]
	>(['objective-perspectives', selectedPeriod], () => getPerspective(String(selectedPeriod.value)), {
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data?.message || API_DEFAULT_ERROR }),
		enabled: !!selectedPeriod?.value,
	});

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.strategicObjectives);
		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	const onSubmit = (values: IStrategicObjectiveForm) => onSubmitForm?.(values);

	const handleClearPerspectiveField = () => resetField('perspectiva', { defaultValue: '' });

	return (
		<>
			<Box sx={styles.backgroundBox}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
					<HStack sx={styles?.row}>
						<Box sx={styles?.inputObjectiveName}>
							<InputTextarea
								sx={styles.textarea}
								isEditing={!!dirtyFields.objetivo && isEditing}
								label="Objetivo estratégico"
								register={register}
								name="objetivo"
								placeholder="Digite um objetivo estratégico"
								required
								errorMessage={errors?.objetivo?.message}
								data-testid="input--objectiveName"
								maxLength={255}
							/>
						</Box>

						<Box sx={styles?.inputPeriod}>
							<Controller
								name="periodo"
								control={control}
								render={({ field }) => (
									<Select
										isEditing={!!dirtyFields.periodo && isEditing}
										label="Período"
										required
										placeholder="Selecione o período"
										value={field.value}
										onChange={event => {
											handleClearPerspectiveField();
											field?.onChange(event);
										}}
										options={parsedOptionArray(periods)}
										errorMessage={errors?.periodo?.message}
										dataTestId="input--period"
									/>
								)}
							/>
						</Box>
					</HStack>

					<HStack sx={styles?.row}>
						<Box sx={styles?.inputObjectiveDescription}>
							<InputTextarea
								sx={styles.textarea}
								isEditing={!!dirtyFields.descricao && isEditing}
								label="Descrição"
								register={register}
								name="descricao"
								required
								placeholder="Digite uma descrição"
								data-testid="input--objectiveDescription"
								errorMessage={errors?.descricao?.message}
								maxLength={500}
							/>
						</Box>

						<Box sx={styles?.inputObjectiveCode}>
							<Controller
								name="codigo"
								control={control}
								render={({ field }) => (
									<InputMask
										isEditing={!!dirtyFields.codigo && isEditing}
										label="Código"
										tooltipInfo="Deve-se conter 2 letras e 2 números."
										mask={CODE_MASK}
										value={field?.value || ''}
										onChange={event => field?.onChange(event?.target?.value?.toUpperCase())}
										name="codigo"
										placeholder="Digite um código"
										errorMessage={errors?.codigo?.message}
										data-testid="input--objectiveCode"
									/>
								)}
							/>
						</Box>
					</HStack>

					<HStack sx={styles?.row}>
						<Box sx={styles?.inputObjectivePerspective}>
							<Controller
								name="perspectiva"
								control={control}
								render={({ field }) => (
									<>
										<Select
											isEditing={!!dirtyFields.perspectiva && isEditing}
											isLoading={isLoadingPerspectives}
											label="Perspectiva"
											required
											placeholder="Selecione a perspectiva"
											value={field.value}
											onChange={field?.onChange}
											options={parsedOptionArray(perspectives, 'perspectiva', 'id')}
											errorMessage={errors?.perspectiva?.message}
											dataTestId="input--objectivePerspective"
										/>
									</>
								)}
							/>
						</Box>
					</HStack>

					<HStack sx={styles?.actions}>
						<Box sx={styles?.formSave}>
							<Button type="submit" sx={styles?.saveButton} isLoading={isSubmitingForm} data-testid="button--submit">
								Salvar
							</Button>
						</Box>

						<Box sx={styles?.formCancel}>
							<Button
								sx={styles?.cancelButton}
								variant={'secondary'}
								onClick={handleOpenModal}
								data-testid="button--cancel"
							>
								Cancelar
							</Button>

							<CustomModal
								icons={[{ type: 'error' }]}
								title="Você realmente quer cancelar?"
								body="Ao cancelar os campos anteriormente preenchidos serão perdidos."
								isOpen={isOpen}
								onClose={onClose}
								actions={[
									{
										label: 'Quero Cancelar',
										type: 'cancel',
										onClick: handleGoBack,
										datatestid: 'button--cancel',
									},
									{
										label: 'Voltar',
										type: 'secondary',
										onClick: handleCloseModal,
										datatestid: 'button--confirm',
									},
								]}
							/>
						</Box>
					</HStack>
				</Box>
				<Prompt when={showPrompt} />
			</Box>
		</>
	);
};

export default StrategicObjectivesForm;
