import { PenaltyFormData } from 'app/components/PenaltyForm';
import { getAxios } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { IPenaltyDataResponse } from 'types/penalty';

export interface TemporaryPhaseRespose {
	id: string;
	fase: string;
}

export interface IPenaltyDeleteRequest {
	id: string;
	justificativaExclusao: string;
}

export interface IPenaltyParams {
	fase?: string;
	bimestre?: string;
	anoInicio?: number | string;
	anoFim?: number | string;
	idPactuadoExterno?: number | string;
	etapa?: string;
}

export interface IRequestPenaltyData {
	idControleDatas: string | number;
	bimestreEnum: string;
	pontosPerdidos: string | number;
	clausulaContratual: string;
	justificativa: string;
	idExternoPactuado: string | number;
	nomePactuado: string;
	idUnidade: string | number;
}

export const getPhase = async (): Promise<TemporaryPhaseRespose[]> => {
	return Array.from({ length: 10 }, (_, index) => ({
		id: `${index + 1}`,
		fase: `Fase ${index + 1}`,
	}));
};

export const getPenaltyDataById = async (penaltyId: string): Promise<IPenaltyDataResponse> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.penaltyGetById(penaltyId));

	return response.data;
};

export const updatePenaltyData = async (data: IRequestPenaltyData, penaltyId: string): Promise<void> => {
	const api = getAxios();
	await api.put<PenaltyFormData>(ENDPOINTS.penaltyUpdate(penaltyId), data);
	return;
};

export const createPenalty = async (data: IRequestPenaltyData): Promise<void> => {
	const api = getAxios();
	const response = await api.post(ENDPOINTS.penaltyCreate, data);
	return response.data;
};

export const getPenaltyData = async (params?: IPenaltyParams) => {
	const api = getAxios();
	const response = await api.get<IPenaltyDataResponse[]>(ENDPOINTS.penaltiesList, {
		params: {
			...params,
		},
	});
	return response.data;
};

export const getPenaltyDataId = async (penaltyId: string): Promise<IPenaltyDataResponse> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.penaltyGetById(penaltyId));
	return response.data;
};

export const getPenaltyUpdateDeleteId = async (penaltyId: string): Promise<void> => {
	const api = getAxios();
	const response = await api.get(ENDPOINTS.penaltyValidateId(penaltyId));
	return response.data;
};

export const deletePenalty = async ({ id, justificativaExclusao }: IPenaltyDeleteRequest): Promise<void> => {
	const api = getAxios();
	const response = await api.delete(ENDPOINTS.penaltyDelete, { data: { id, justificativaExclusao } });

	return response.data;
};
