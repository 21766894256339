/**
 *
 * AttributionOfAgreedForm
 *
 */

import GoalWeightGroup from './GoalWeightGroup';
import CustomModal from '../CustomModal';
import Prompt from '../Prompt';
import TagSelector from '../TagSelector';
import { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	Divider,
	Flex,
	Spinner,
	Stack,
	SystemStyleObject,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { IndicatorPactDelete, WrapperArrowIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { IResponseLastActivePact, getLastActivePact, getLastActivePactCreate } from 'services/http/managementPact';
import { getSector } from 'services/http/sectors';
import { IPeriod, IPeriodYear } from 'types/agreeds';
import { IndicatorFormSteps } from 'types/indicators';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { IStepProps } from 'types/stepper';
import { API_DEFAULT_ERROR, DATA_CONTROL_STEPS, SECTOR_TYPE, TEXT_TO_BIMESTERS_ENUM } from 'utils/constants';
import { formatFromStringToNumber, getNextSixBimesters } from 'utils/formatBimesters';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import * as yup from 'yup';

export interface AttributionAgreed {
	id?: number;
	idPactuado?: number;
	idUnidade?: number;
	pactuado?: string;
	periodos?: IPeriodYear[];
	request?: 'POST' | 'PUT' | 'DELETE';
	canBeRemoved?: boolean;
}

export interface IAttributionAgreedForm {
	tags?: IOption<ISector>[];
	copyValues?: string[];
	pactuados?: AttributionAgreed[];
}

interface AttributionAgreedFormProps extends IStepProps<IAttributionAgreedForm, IndicatorFormSteps> {
	isReadOnly?: boolean;
	isSubmitingForm?: boolean;
	isEditing?: boolean;
}

type Params = {
	etapa: keyof typeof DATA_CONTROL_STEPS;
};

const AttributionAgreedForm = ({
	defaultValues,
	onSubmit,
	isReadOnly = false,
	isSubmitingForm = false,
	prevStep,
	watching,
	isEditing = false,
}: AttributionAgreedFormProps) => {
	const formSchema = yup.object().shape({
		pactuados: yup.array().of(
			yup.object().shape({
				periodos: yup.array().of(
					yup.object().shape({
						periodos: yup.array().of(
							yup.object().shape({
								meta: yup
									.mixed()
									.required('Campo meta é obrigatório')
									.test('noValue', 'Campo meta é obrigatório.', (value = '') => {
										const maxValue = '';
										const isInvalid = maxValue === value;
										return !isInvalid;
									})
									.test('belowMinValue', 'Campo meta inválido.', value => {
										const minValue = 0.1;
										const isInvalid = formatFromStringToNumber(value) < minValue;
										return !isInvalid;
									}),
								peso: yup
									.mixed()
									.required('Campo peso é obrigatório')
									.test('noValuePeso', 'Campo peso é obrigatório.', (value = '') => {
										const maxValue = '';
										const isInvalid = maxValue === value;
										return !isInvalid;
									})
									.test('maxValue', 'Limite máximo para peso é 9.9.', value => {
										const maxValue = 9.9;
										const isInvalid = maxValue < formatFromStringToNumber(value);
										return !isInvalid;
									}),
							}),
						),
					}),
				),
			}),
		),
	});

	const { etapa } = useParams<Params>();
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const hasValidatedResults = (agreed: AttributionAgreed) => {
		if (!agreed.periodos) return false;
		return agreed?.periodos?.some(periodo => periodo?.periodos?.some(item => !item.isEditavel));
	};

	const isValidBimester = (isEditavel: boolean, isRetroativo: boolean, peso: number) => {
		if (isRetroativo) return false;

		if (isEditavel === false && peso === -1) return true;

		if (!isEditavel) return false;

		return true;
	};

	const formattedValues = useMemo(() => {
		return defaultValues?.pactuados?.length
			? defaultValues.pactuados.map(
					pactuado =>
						({
							...pactuado,
							request: 'PUT',
							canBeRemoved: !hasValidatedResults(pactuado),
							periodos: pactuado?.periodos?.map(periodo => {
								periodo.periodos?.map(
									bimester =>
										(bimester.isEditavel = isValidBimester(
											bimester.isEditavel!,
											bimester.isRetroativo!,
											bimester.pesoCalculado,
										)),
								);
								return periodo;
							}),
						} as AttributionAgreed),
			  )
			: [];
	}, [defaultValues]);

	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		watch,
		reset,
		formState: { errors, isDirty, isSubmitting, isSubmitted },
	} = useForm<IAttributionAgreedForm>({
		resolver: yupResolver(formSchema),
		defaultValues: { ...defaultValues, pactuados: formattedValues },
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const { fields, append, replace, update } = useFieldArray({
		name: 'pactuados',
		keyName: 'code',
		control,
	});

	const stepsEnum = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const styles: Record<string, SystemStyleObject> = {
		titleWrapper: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			gap: '1rem',
			mb: '3.7rem',
		},
		titleText: {
			fontSize: '1.3rem',
			fontWeight: '800',
			color: 'black',
		},
		container: {
			bgColor: 'gray.80',
			border: '0.063rem solid',
			borderColor: 'gray.95',
			borderRadius: '0.25rem',
			mb: '1rem',
			padding: '2rem',

			_first: {
				borderBottom: fields.length ? 'none' : '0.063rem solid',
				borderColor: fields.length ? 'none' : 'gray.95',
				borderBottomStartRadius: fields.length ? '0' : '0.25rem',
				borderBottomEndRadius: fields.length ? '0' : '0.25rem',
				paddingBottom: '0',
				mb: '0',
			},

			':nth-of-type(2)': {
				borderTop: 'none',
				borderTopStartRadius: '0',
				borderTopEndRadius: '0',
				paddingTop: '0',

				borderBottom: '0.063rem solid',
				borderColor: 'gray.95',
				borderBottomStartRadius: '0.25rem',
				borderBottomEndRadius: '0.25rem',
				paddingBottom: '2rem',
				mb: '1rem',
			},
		},
		stack: {
			paddingTop: '1.5rem',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
		titleContainer: {
			flexDirection: { base: 'column', lg: 'row' },
			alignItems: { base: 'start', lg: 'center' },
			justifyContent: 'space-between',
			paddingRight: '120px',
			w: '100%',
		},
		title: {
			color: 'black.800',
			fontSize: '1.25rem',
			fontWeight: '600',
			marginRight: '1rem',
		},
		yearTitle: {
			color: 'black.800',
			fontSize: '1.25rem',
			fontWeight: 'bold',
			paddingBottom: '1.5rem',
			paddingTop: '1.5rem',
		},
		checkboxInput: {
			fontSize: '1rem',
			fontFamily: 'Lato',
			fontWeight: '600',
			color: 'black.800',
			"span[class*='checkbox__control']:not([data-disabled])": {
				borderColor: 'gray.95',
				_checked: {
					bg: 'blue.400',
					borderColor: 'blue.400',
					color: 'white',
				},
			},
			"span[class*='checkbox__control']:is([data-disabled])": {
				borderColor: 'gray.95',
				_disabled: {
					bg: 'blue.100',
					borderColor: 'blue.100',
					color: 'white',
				},
			},
			"span[class*='chakra-checkbox__label']:is([data-disabled])": {
				opacity: '1',
			},
			"span[class*='checkbox__control']:is([data-disabled]):[aria-disabled=true]": {
				borderColor: 'gray.95',
				_disabled: {
					bg: 'blue.100',
					borderColor: 'blue.100',
					color: 'white',
				},
			},
		},
		buttonApply: {
			borderRadius: '1rem',
			height: '1.688rem',
			width: '4.25rem',
			fontFamily: 'Lato',
			fontSize: '0.75rem',
		},
		gridFields: {
			w: { base: '100%', lg: '60rem' },
			gap: '3.75rem',
			rowGap: '2rem',
			gridTemplateColumns: {
				md: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
			},
			maxWidth: { base: '18.75rem', md: '40.625rem', lg: '56.25rem' },
		},
		inputContainer: {
			h: '2.75rem',
			w: '24.5rem',
			backgroundColor: 'white',
			border: '1px solid #E1E2E5',
			borderRadius: '1.375rem',
			p: '0.25rem 1rem',
			display: 'flex',
			alignItems: 'center',
		},
		periodText: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		inputLabel: {
			fontSize: '1rem',
			fontWeight: 'medium',
		},
		goalContainer: {
			borderRight: '1px solid #E1E2E5',
			borderLeft: '1px solid #E1E2E5',
			alignItems: 'center',
			pl: '1rem',
		},
		goalInput: {
			border: 'none',
			w: '6rem',
			h: '2.25rem',
			fontSize: '1rem',
			p: '0',
			pl: '0.25rem',
		},
		weightContainer: {
			alignItems: 'center',
			pl: '1rem',
		},
		weightInput: {
			border: 'none',
			w: '2.25rem',
			h: '2.25rem',
			fontSize: '1rem',
			p: '0',
			pl: '0.25rem',
		},
		actions: {
			mt: '3.125rem',
			textAlign: 'right',
			fontSize: '1rem',
		},
		actionsButtons: {
			mt: { base: '0', lg: '1rem' },
			display: 'flex',
			justifyContent: 'start',
			gap: {
				base: '1rem',
				sm: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'row',
			},
		},
		button: {
			w: { base: '100%', lg: '14.625rem' },
			fontSize: { base: '1rem' },
			h: { base: '2.75rem' },
		},
		spinner: {
			justifyContent: 'center',
			alignItems: 'center',
			h: '50vh',
		},
		deleteButton: {
			backgroundColor: 'transparent',
			textColor: 'red',
			display: 'flex',
			gap: '6px',
			_hover: {},
		},
		deleteText: {
			textColor: 'red',
		},
	};

	const {
		session: { user },
	} = useSession();

	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();

	const { data: pactuados, isLoading: isPactuadosLoading } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['agreeds-tags'],
		() =>
			getSector(
				!isEditing
					? stepsEnum[DATA_CONTROL_STEPS[etapa!] as keyof typeof stepsEnum]
					: stepsEnum[defaultValues?.etapaEnum! as keyof typeof stepsEnum],
			),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const tags = watch('tags');
	const lastTagAdded = tags?.length && tags.at(-1)?.label;

	const onSuccessLastActivePacti = (values: IResponseLastActivePact[]) => {
		const data = {
			ano: values?.at(-1)?.anoInicio!,
			bimestre: values?.at(-1)?.bimestreInicio!,
		};

		if (fields.length < tags?.length!) {
			const pactuado = pactuados?.find(pactuado => pactuado.idSetor === Number(tags?.[tags?.length - 1]?.value));
			const defaultPactuado = defaultValues?.pactuados
				?.find(pactuado => pactuado.pactuado === lastTagAdded)
				?.periodos?.at(0);

			const customData = {
				ano: defaultPactuado?.ano as number,
				bimestre:
					TEXT_TO_BIMESTERS_ENUM[
						defaultPactuado?.periodos.at(0)?.periodoAtribuicaoPactuado as keyof typeof TEXT_TO_BIMESTERS_ENUM
					],
			};

			const parsedData = getNextSixBimesters(data);

			const parsedPeriods = parsedData.at(-1)?.periodos.length ? parsedData : [parsedData.at(0)];

			const periodos = defaultValues?.tags?.map(tags => tags.label).includes(lastTagAdded as string)
				? (getNextSixBimesters(customData) as IPeriodYear[])
				: (parsedPeriods as IPeriodYear[]);

			append({
				id: pactuado?.idSetor,
				canBeRemoved: true,
				idPactuado: pactuado?.idSetor,
				pactuado: pactuado?.nome,
				idUnidade: pactuado?.idUnidade,
				periodos: periodos,
				request: 'POST',
			});
		}
	};

	useQuery<IResponseLastActivePact[], AxiosError<ResponseErrors>, IResponseLastActivePact[]>(
		['last-active-pact', lastTagAdded],
		() => getLastActivePact({ pactuado: String(lastTagAdded), ano: String(defaultValues?.ano) }),
		{
			onSuccess: values => {
				onSuccessLastActivePacti(values);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Ocorreu um erro!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});

				if (response?.data?.message === 'Pacto de Gestão não encontrado') {
					const filteredTags = tags?.filter(tags => tags.label !== lastTagAdded);

					setValue('tags', filteredTags);
				}
			},
			enabled: !!lastTagAdded && isEditing,
		},
	);

	useQuery<IResponseLastActivePact[], AxiosError<ResponseErrors>, IResponseLastActivePact[]>(
		['last-active-pact-create', lastTagAdded],
		() => getLastActivePactCreate({ pactuado: String(lastTagAdded), ano: String(defaultValues?.ano) }),
		{
			onSuccess: values => {
				onSuccessLastActivePacti(values);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Ocorreu um erro!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});

				if (response?.data?.message === 'Pacto de Gestão não encontrado') {
					const filteredTags = tags?.filter(tags => tags.label !== lastTagAdded);

					setValue('tags', filteredTags);
				}
			},
			enabled: !!lastTagAdded && !isEditing,
		},
	);

	useEffect(() => {
		//Verifica quais tags (pactuados) ainda existem no array e
		//filtra os campos para retirar aqueles que não existem mais.
		if (fields?.length > tags?.length!) {
			const values = fields.filter(item => tags?.some(tag => tag.label === item.pactuado));

			replace(values);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tags]);

	const onApllyAll = () => {
		const copyGoals = getValues('copyValues') && getValues('copyValues')?.find(item => item === 'copyGoals');
		const copyWeight = getValues('copyValues') && getValues('copyValues')?.find(item => item === 'copyWeight');
		const valuesToCopy = getValues('pactuados') && getValues('pactuados')?.[0]?.periodos;

		if (copyGoals && !copyWeight) {
			//Copiar meta para todos
			const values = fields.map(item => ({
				...item,
				periodos: item?.periodos?.map((ano, indexAno) => ({
					...ano,
					periodos: ano.periodos.map((periodo, indexPeriodo) => ({
						...periodo,
						meta: periodo.isEditavel
							? valuesToCopy?.[indexAno]?.periodos?.[indexPeriodo]?.meta || periodo.meta
							: periodo.meta,
					})),
				})),
			}));

			replace(values);
		}

		if (copyWeight && !copyGoals) {
			//Copiar peso para todos
			const values = fields.map(item => ({
				...item,
				periodos: item?.periodos?.map((ano, indexAno) => ({
					...ano,
					periodos: ano.periodos.map((periodo, indexPeriodo) => ({
						...periodo,
						peso: periodo.isEditavel
							? valuesToCopy?.[indexAno]?.periodos?.[indexPeriodo]?.peso || periodo.peso
							: periodo.peso,
					})),
				})),
			}));

			replace(values);
		}

		if (copyGoals && copyWeight) {
			//Copiar todos
			const values = fields.map(item => ({
				...item,
				periodos: item?.periodos?.map((ano, indexAno) => ({
					...ano,
					periodos: ano.periodos.map((periodo, indexPeriodo) => ({
						...periodo,
						meta: periodo.isEditavel
							? valuesToCopy?.[indexAno]?.periodos?.[indexPeriodo]?.meta || periodo.meta
							: periodo.meta,
						peso: periodo.isEditavel
							? valuesToCopy?.[indexAno]?.periodos?.[indexPeriodo]?.peso || periodo.peso
							: periodo.peso,
					})),
				})),
			}));

			replace(values);
		}
	};

	const handleReplicateBimester = (value: AttributionAgreed, index: number) => {
		const newGoal = value?.periodos?.[0].periodos[0].meta;
		const newWeight = value?.periodos?.[0].periodos[0].peso;

		const dataToReplace = fields[index];

		const newValues = {
			...dataToReplace,
			periodos: dataToReplace?.periodos?.map(item => ({
				...item,
				periodos: item?.periodos?.map(periodo => {
					const _newGoal = newGoal ?? periodo.meta;
					const _newWeight = newWeight ?? periodo.peso;
					return {
						...periodo,
						meta: periodo.isEditavel ? _newGoal : periodo.meta,
						peso: periodo.isEditavel ? _newWeight : periodo.peso,
					};
				}),
			})),
		};

		update(index, newValues);
	};

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.indicators);
		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	const onHandleSubmit = ({ pactuados }: IAttributionAgreedForm) => {
		let data = pactuados;

		const filteredPutAgreeds = data?.filter(pactuado => pactuado.request === 'PUT');
		const filteredPostAgreeds = data?.filter(pactuado => pactuado.request === 'POST');
		const fieldsToDelete = defaultValues?.pactuados?.filter(defaultAgreeds =>
			filteredPutAgreeds?.every(pactuado => pactuado.pactuado !== defaultAgreeds.pactuado),
		);

		const agreedsUpdated = formattedValues
			?.map(defaultAgreed => ({
				...defaultAgreed,
				periodos: defaultAgreed.periodos
					?.map(defaultPeriodoAno => {
						let updatedPeriods: IPeriod[] = [];

						defaultPeriodoAno.periodos?.forEach(defaultPeriodo => {
							if (defaultPeriodoAno.ano) {
								filteredPutAgreeds?.forEach(pactuado => {
									pactuado.periodos?.forEach(periodoAno => {
										const period = periodoAno.periodos.find(
											periodo =>
												defaultPeriodo.id === periodo.id &&
												(periodo.meta !== defaultPeriodo.meta || periodo.peso !== defaultPeriodo.peso),
										);

										if (period) {
											updatedPeriods.push(period);
										}
									});
								});
							}
						});

						return {
							...defaultPeriodoAno,
							periodos: updatedPeriods,
						};
					})
					.filter(item => item.periodos.length),
			}))
			.filter(pactuado => pactuado.periodos?.length);

		let agreedsToDelete: AttributionAgreed[] = [];

		fieldsToDelete?.forEach(item => {
			agreedsToDelete.push({
				...item,
				request: 'DELETE',
			});
		});

		if (isEditing) {
			data = [...filteredPostAgreeds!, ...agreedsUpdated!, ...agreedsToDelete];
		}
		onSubmit({ pactuados: data });
	};

	const onHandlePrevStep = () => {
		watching?.({ pactuados: getValues('pactuados'), tags: getValues('tags') });
		prevStep?.();
	};

	const handleDelete = (pactuado?: string) => {
		const newTags = getValues().tags?.filter(tags => tags.label !== pactuado);
		setValue('tags', newTags);
	};

	useEffect(() => reset({ ...defaultValues, pactuados: formattedValues }), [defaultValues, formattedValues, reset]);

	const tagsThatCanBeRemoved = fields
		?.filter(field => field.canBeRemoved === true)
		.map(fields => fields.pactuado) as string[];

	return (
		<>
			<Box sx={styles.titleWrapper}>
				<Button onClick={onHandlePrevStep} variant="noStyle">
					<WrapperArrowIcon />
				</Button>
				<Text sx={styles.titleText}>{defaultValues?.nome || ''}</Text>
			</Box>

			<Box as="form" onSubmit={handleSubmit(onHandleSubmit)}>
				{isPactuadosLoading ? (
					<Flex sx={styles.spinner}>
						<Spinner />
					</Flex>
				) : (
					<>
						<Box sx={styles.container}>
							{!isPactuadosLoading && (
								<Controller
									name="tags"
									control={control}
									render={() => (
										<TagSelector
											fieldName="tags"
											setValue={setValue}
											getValues={getValues}
											options={parsedOptionArray(pactuados, 'nome', 'idSetor')}
											defaultTags={defaultValues?.tags}
											tagsThatCanBeRemoved={tagsThatCanBeRemoved}
										/>
									)}
								/>
							)}
							{fields?.[0] && <Divider color="gray.95" />}
						</Box>
						{fields.map((item, index) => (
							<Box key={item.code} sx={styles.container}>
								<VStack sx={styles.stack}>
									<Flex sx={styles.titleContainer}>
										<Text sx={styles.title}>{item.pactuado}</Text>
										{item.canBeRemoved && (
											<Button
												onClick={() => handleDelete(item.pactuado)}
												sx={styles.deleteButton}
												data-testid="button--delete"
											>
												<IndicatorPactDelete color="red" />
												<Text sx={styles.deleteText}>Remover Pactuado</Text>
											</Button>
										)}
									</Flex>
									{index === 0 && (
										<Controller
											name="copyValues"
											control={control}
											defaultValue={['copyWeight']}
											// eslint-disable-next-line unused-imports/no-unused-vars
											render={({ field: { ref, ...rest } }) => (
												<CheckboxGroup {...rest}>
													<Stack spacing={[1, 5]} direction={['column', 'row']} mt="0.15rem">
														<Text sx={styles.inputLabel}>Replicar para todos pactuados:</Text>
														<Checkbox sx={styles.checkboxInput} value="copyGoals" data-testid="checkbox--copyGoals">
															Metas
														</Checkbox>
														<Checkbox sx={styles.checkboxInput} value="copyWeight" data-testid="checkbox--copyWeight">
															Pesos
														</Checkbox>
														<Button
															variant="primary"
															sx={styles.buttonApply}
															onClick={onApllyAll}
															data-testid="button--apply"
														>
															Aplicar
														</Button>
													</Stack>
												</CheckboxGroup>
											)}
										/>
									)}
								</VStack>
								<GoalWeightGroup
									control={control}
									nestedIndex={index}
									fieldErrors={errors}
									isReadOnly={isReadOnly}
									onHandleReplicateBimester={() => handleReplicateBimester(item, index)}
								/>
							</Box>
						))}
						<Box sx={styles.actions}>
							<Box sx={styles.actionsButtons}>
								<Button
									type="submit"
									sx={styles.button}
									data-testid="button--submit"
									variant="primary"
									isLoading={isSubmitingForm}
								>
									Finalizar
								</Button>
								<Button sx={styles.button} variant="secondary" data-testid="button--cancel" onClick={handleOpenModal}>
									Cancelar
								</Button>
							</Box>
						</Box>
					</>
				)}

				<CustomModal
					icons={[{ type: 'error' }]}
					title="Você realmente quer cancelar?"
					body="Ao cancelar os campos anteriormente preenchidos serão perdidos."
					isOpen={isOpen}
					onClose={onClose}
					actions={[
						{
							label: 'Quero Cancelar',
							type: 'cancel',
							onClick: handleGoBack,
							datatestid: 'button--confirm',
						},
						{
							label: 'Voltar',
							type: 'secondary',
							onClick: handleCloseModal,
							datatestid: 'button--cancel',
						},
					]}
				/>
			</Box>
			<Prompt when={showPrompt} />
		</>
	);
};

export default AttributionAgreedForm;
