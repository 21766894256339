/**
 *
 * ResultsEvaluationIndicatorChart
 *
 */
import LineChart from '../LineChart';
import ResulterTooltip from '../ResulterIndicatorCard/resulterTooltip';
import { Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import { RESULTER_STATUS } from 'utils/constants';
import { maskThousands } from 'utils/Numbers';

interface ResultsEvaluationIndicatorChartProps {
	resultados: number[];
	metas: number[];
	desempenho: number;
	resulDataAnterior: number;
	meta: number;
	topIndicator: number;
	indicatorStatus: string;
	categories?: { axiX: string; tooltip: string }[];
}

const ResultsEvaluationIndicatorChart = ({ ...data }: ResultsEvaluationIndicatorChartProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			background: 'white',
			width: '100%',
			justifyContent: 'center',
		},
		card: {
			border: '0.063rem solid #E1E2E5',
			width: '100%',
			padding: '1rem 1.625rem 0.625rem 1rem',
			justifyContent: 'space-between',
			borderRadius: '0.25rem',
			gap: '2rem',
		},
		navCard: {
			minWidth: '7.4rem',
			flexDirection: 'column',
			gap: '0.8rem',
		},
		navItem: {
			flexDirection: 'column',
			gap: '.25rem',
		},
		naviItemTitle: {
			fontSize: '0.875rem',
		},
		navItemDescription: {
			fontSize: '1.25rem',
			opacity: 0.5,
			fontWeight: 600,
		},
		textWithIcon: {
			alignItems: 'center',
		},
	};

	const desempenho = data.desempenho < 0 ? 'N/A' : `${String(data?.desempenho).slice(0, 5).replace('.', ',')}%`;
	const resultado = data.resulDataAnterior < 0 ? 'N/A' : maskThousands(data?.resulDataAnterior);

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.card}>
				<Flex sx={styles.navCard}>
					<Flex sx={styles.navItem}>
						<Text sx={styles.naviItemTitle}>Desempenho</Text>
						<Flex sx={styles.textWithIcon}>
							<Text sx={styles.navItemDescription}>{desempenho}</Text>
							<ResulterTooltip
								label={RESULTER_STATUS[data?.indicatorStatus as keyof typeof RESULTER_STATUS]?.label}
								icon={RESULTER_STATUS[data?.indicatorStatus as keyof typeof RESULTER_STATUS]?.icon}
							/>
						</Flex>
					</Flex>
					<Flex sx={styles.navItem}>
						<Text sx={styles.naviItemTitle}>Resultado anterior</Text>
						<Text sx={styles.navItemDescription}>{resultado}</Text>
					</Flex>
				</Flex>
				<LineChart goals={data.metas} results={data.resultados} labelsIndicatosAxisX={data.categories} />
			</Flex>
		</Flex>
	);
};

export default ResultsEvaluationIndicatorChart;
