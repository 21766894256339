/**
 *
 * CaapMembersList
 *
 */

import { useState } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Button, Flex, Spinner, Stack, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AccordionCaapMember from 'app/components/AccordionCaapMember';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import CustomModal from 'app/components/CustomModal';
import PageWrapper from 'app/components/PageWrapper';
import Pagination from 'app/components/Pagination';
import { RegisterIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { deleteCaapMembers, exportCaapMembers, getCaapMembers } from 'services/http/caapMembers';
import { CaapMembers } from 'types/caapMembers';
import { IPagination } from 'types/pagination';
import { API_DEFAULT_ERROR, USER_ROLES } from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { ResponseErrors } from 'utils/parseErrors';

const CaapMembersList = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		button: {
			px: '1rem',
		},
		buttonContainer: {
			gap: '1rem',
			justifyContent: 'flex-end',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			width: '100%',
		},
		spinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const PAGE_SIZE = 15;
	const [currentPage, setCurrentPage] = useState(0);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		data: caapMembersListData,
		isLoading: isLoadingCaapMembersList,
		refetch: caapMembersListRefetch,
	} = useQuery<IPagination<CaapMembers[]>, AxiosError<ResponseErrors>>(
		['caap-list', currentPage],
		() => getCaapMembers({ page: currentPage, size: PAGE_SIZE }),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				});
			},
		},
	);

	const { mutate: caapMembersDeleteMutate } = useMutation<void, AxiosError<ResponseErrors>, string>(
		id => deleteCaapMembers(id),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'Um membro foi excluído.',
				});
				caapMembersListRefetch();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				});
			},
		},
	);

	const { mutate: caapMembersExportMutate } = useMutation<Blob, AxiosError<ResponseErrors>>(
		() => exportCaapMembers({ page: currentPage, size: PAGE_SIZE }),
		{
			onSuccess: data => {
				const href = URL.createObjectURL(data);

				downloadFile(href, 'composicao-caap.xlsx');

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				});
			},
		},
	);

	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();
	const hasPresidentPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const canExportMember = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.PACTUADO,
		USER_ROLES.REPRESENTANTE,
	]);
	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const handleDataPaginate = (page: number) => setCurrentPage(page - 1);

	const handleEdit = (id: string) => navigate(ROUTES.caapMembersEdit(id));

	const handleDelete = (id: string) => caapMembersDeleteMutate(id);

	const handleExportCaapMembers = () => {
		caapMembersExportMutate();
		onClose();
	};

	return (
		<Flex gap="2rem" flexDir="column">
			<PageWrapper title="Membros CAAP" goBackRoute={ROUTES.home} />

			<Flex sx={styles.buttonContainer}>
				{canViewAuditHistory && (
					<Button
						variant="unstyled"
						leftIcon={<RepeatClockIcon />}
						sx={styles.buttonShowModalAudit}
						onClick={onOpenAuditModal}
					>
						Histórico de Alterações
					</Button>
				)}
				{hasPresidentPermission && (
					<Button
						leftIcon={<RegisterIcon color="white" />}
						sx={styles.button}
						onClick={() => navigate(ROUTES.caapMembersCreate)}
						data-testid="button--createCaapMembers"
					>
						Cadastrar Membros CAAP
					</Button>
				)}

				{canExportMember && (
					<Button
						variant="secondary"
						leftIcon={<RegisterIcon color="#3EA2A2" />}
						sx={styles.button}
						color="green.550"
						onClick={onOpen}
						data-testid="button-exportCaapMembers"
					>
						Exportar Composição
					</Button>
				)}
			</Flex>
			<Stack sx={styles.content}>
				{isLoadingCaapMembersList ? (
					<Flex sx={styles.spinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Flex>
				) : (
					caapMembersListData?.content.map(item => (
						<AccordionCaapMember key={item.id} data={item} onDeleteMember={handleDelete} onEditMember={handleEdit} />
					))
				)}
			</Stack>
			<Flex alignItems={'center'} justifyContent={'center'}>
				<Pagination
					currentPage={currentPage}
					totalItems={caapMembersListData?.totalElements}
					onPaginate={handleDataPaginate}
					pageSize={PAGE_SIZE}
				/>
			</Flex>

			<CustomModal
				icons={[{ type: 'info' }]}
				title="Você irá exportar esta portaria."
				body="Todos os itens contidos nesta portaria foram comprimidos em '.xlsx' e serão baixados agora. Deseja continuar?"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Exportar Dados',
						type: 'primary',
						onClick: handleExportCaapMembers,
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: onClose,
						datatestid: 'button--cancel',
					},
				]}
			/>

			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</Flex>
	);
};

export default CaapMembersList;
