/**
 *
 * AuditRegister
 *
 */
import AuditRecordsModalDetails from '../AuditRecordsModalDetails';
import CustomCalendar from '../CustomCalendar';
import InputMask from '../InputMask';
import Multiselect from '../Multiselect';
import Pagination from '../Pagination';
import Select from '../Select';
import Table from '../Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	SystemStyleObject,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { ViewMatrixIcon } from 'assets/icons';
import cleanDeep from 'clean-deep';
import { addYears } from 'date-fns';
import { useCustomToast } from 'hooks/useToast';
import { isEmpty } from 'lodash';
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form';
import { getAuditRecordComponents, getAuditRecordModules, getAuditRecords } from 'services/http/audit';
import { AuditModule, AuditRecord, Modules } from 'types/audit';
import { IPaginationParams } from 'types/pagination';
import { IOption } from 'types/select';
import { IColumn } from 'types/table';
import { API_DEFAULT_ERROR, AUDIT_COMPONENTS_ENUM, AUDIT_MODULES_ENUM, CPF_MASK } from 'utils/constants';
import { formatCpf } from 'utils/formatCpf';
import { formatDate, formatDateToApi } from 'utils/formatDate';
import { useLocationParam } from 'utils/locationParam';
import { unmaskValue } from 'utils/mask';
import { parsedOptionArray } from 'utils/parseOptionArray';
import * as yup from 'yup';

export interface AuditRecordForm {
	cpf?: string;
	modulo?: IOption<AuditModule>;
	componente?: IOption<string>[];
	dataAlteracao?: string;
}
export interface AuditRecordParams
	extends Omit<AuditRecordForm, 'modulo' | 'componente'>,
		IPaginationParams<AuditRecord> {
	modulo?: Modules;
	componente?: string[];
}

type GhostFormValidator = FieldErrorsImpl<{
	ghostFormValidator: boolean;
}>;

interface AuditRecordsProps {
	onClose: () => void;
	isOpen: boolean;
	hasDefaultModulo?: boolean;
}

const AuditRecordsModal = ({ isOpen, onClose, hasDefaultModulo = true }: AuditRecordsProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxWidth: '83.125rem',
			borderRadius: '0.25rem',
		},
		box: {
			maxW: {
				base: '100%',
				lg: '16.5rem',
			},
			w: '100%',
		},
		selectDataBox: {
			maxW: {
				base: '100%',
				lg: '12.875rem',
			},
			w: '100%',
			mt: '0.975rem',
		},
		flexHeader: {
			gap: {
				base: '2rem',
				lg: '3.5rem',
			},
			justifyContent: 'start',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			mb: '1.5rem',
		},
		modalHeader: {
			margin: 0,
			padding: 0,
			bgColor: '#F9FAFC',
			borderRadius: '0.25rem',
		},
		modalHeaderContainer: {
			flexDir: 'column',
			justifyContent: 'start',
			px: '2rem',
			pt: '2.5rem',
			pb: '2rem',
		},
		modalBody: {
			margin: 0,
			padding: 0,
			bgColor: 'white',
		},
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
		paginationContainer: {
			pt: '1.8rem',
			pb: '1rem',
			justifyContent: 'center',
			alignItems: 'center',
			w: 'full',
		},
		closeButton: {
			w: '13.75rem',
			justifySelf: 'end',
			fontSize: '1rem',
		},
		searchButton: {
			maxW: {
				base: '100%',
				lg: '240px',
			},
			fontSize: '1rem',
			mt: '0.5rem',
		},
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		errorText: {
			fontSize: '0.9rem',
			color: 'red.400',
		},
		InputMask: {
			mb: '1rem',
		},
		footer: {
			bgColor: 'white',
		},
	};

	const defaultParams: AuditRecordParams = {
		page: 1,
		size: 10,
		sort: 'dataAlteracao',
	};

	const columns: IColumn[] = [
		{
			label: 'Responsável pela ação',
			key: 'cpf',
			columnCustomStyle: {
				w: '18.25rem',
				fontWeight: 'medium',
				pl: '1rem',
			},
		},
		{
			label: 'Onde',
			key: 'modulo',
			columnCustomStyle: {
				w: '14.875rem',
				fontWeight: 'medium',
				pl: '1rem',
				fontSize: '1rem',
			},
		},
		{
			label: 'Componente alterado',
			key: 'campoAlterado',
			columnCustomStyle: {
				w: '28.375rem',
				fontWeight: 'medium',
				pl: '1rem',
				whiteSpace: 'pre',
			},
		},
		{
			label: 'Ato',
			key: 'acaoEnum',
			columnCustomStyle: {
				w: '12.375rem',
				fontWeight: 'medium',
				pl: '1rem',
			},
		},
		{
			label: 'Data de alteração',
			key: 'dataAlteracao',
			columnCustomStyle: {
				w: '12.375rem',
				fontWeight: 'medium',
				pl: '1rem',
			},
		},
		{
			label: 'Ações',
			key: 'acoes',
			columnCustomStyle: {
				w: '9.25rem',
				fontWeight: 'medium',
				pl: '1rem',
			},
		},
	];

	const {
		isOpen: isRecordsDetailsModalOpen,
		onClose: onRecordsDetailsModalClose,
		onOpen: onRecordsDetailsModalOpen,
	} = useDisclosure();
	const { addToast } = useCustomToast();
	const [recordDetails, setRecordDetails] = useState<AuditRecord>();
	const [params, setParams] = useState<AuditRecordParams>({});

	const locationParam = useLocationParam();

	const schema = yup.object().shape({
		cpf: yup.string(),
		modulo: yup.object().nullable(),
		componente: yup.array(),
		dataAlteracao: yup.mixed().default(undefined).nullable(),
		ghostFormValidator: yup.bool().when(['cpf', 'modulo', 'componente', 'dataAlteracao'], {
			is: (...rest: AuditRecordForm[]) => isEmpty(cleanDeep(rest)),
			then: yup.bool().required('É necessário escolher um filtro para continuar.'),
			otherwise: yup.bool(),
		}),
	});

	const defaultValueModule = useMemo(() => {
		if (!hasDefaultModulo) {
			return undefined;
		}

		return {
			label: AUDIT_MODULES_ENUM[locationParam! as keyof typeof AUDIT_MODULES_ENUM],
			value: locationParam!,
		};
	}, [hasDefaultModulo, locationParam]);

	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
		register,
	} = useForm<AuditRecordForm>({
		resolver: yupResolver(schema),
		defaultValues: { modulo: defaultValueModule },
	});

	const hasParams = useMemo(() => !isEmpty(cleanDeep(params)), [params]);

	const moduleParam = watch('modulo');

	const { data: auditComponents, isLoading: isLoadingAuditComponents } = useQuery(
		['audit-components', moduleParam, isOpen],
		() => getAuditRecordComponents(String(moduleParam?.value || '')),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
			enabled: isOpen,
		},
	);

	const { data: auditModules, isLoading: isLoadingAuditModules } = useQuery(
		['audit-modules', isOpen],
		() => getAuditRecordModules(),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
			enabled: isOpen,
		},
	);

	const { data: auditRecordsData, isLoading } = useQuery(
		['auditRecords', params, isOpen, moduleParam],
		() => getAuditRecords({ ...defaultParams, ...params, page: params.page! - 1 }),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
			enabled: hasParams && isOpen,
		},
	);

	const auditComponentsOptions = useMemo(() => parsedOptionArray<string>(auditComponents), [auditComponents]);

	const auditModulesOptions = useMemo(
		() => parsedOptionArray<AuditModule>(auditModules, 'modulo', 'moduloEnum'),
		[auditModules],
	);

	const onHandleRegisterDatailsModalOpen = useCallback(
		(record: AuditRecord) => {
			onRecordsDetailsModalOpen();
			setRecordDetails(record);
		},
		[onRecordsDetailsModalOpen],
	);

	const { data, totalItems } = useMemo(() => {
		const data = auditRecordsData?.content.map(item => ({
			...item!,
			cpf: formatCpf(item.cpf),
			modulo: <Text sx={{ fontSize: '1rem' }} dangerouslySetInnerHTML={{ __html: AUDIT_MODULES_ENUM[item.modulo] }} />,
			dataAlteracao: formatDate(item.dataAlteracao),
			acaoEnum: AUDIT_COMPONENTS_ENUM[item.acaoEnum],
			acoes: (
				<Tooltip label="Detalhes da alteração" placement="top-start" gutter={-5} sx={styles.tooltip}>
					<IconButton
						aria-label={'Detalhes da alteração'}
						variant="unstyled"
						icon={<ViewMatrixIcon />}
						onClick={() => onHandleRegisterDatailsModalOpen(item)}
					/>
				</Tooltip>
			),
		}));
		return { data, totalItems: auditRecordsData?.totalElements };
	}, [auditRecordsData, onHandleRegisterDatailsModalOpen, styles.tooltip]);

	const onHandleSubmit = (values: AuditRecordForm) => {
		const parsedData: AuditRecordParams = cleanDeep({
			cpf: unmaskValue(values.cpf),
			modulo: values.modulo?.value as Modules,
			dataAlteracao: values.dataAlteracao && formatDateToApi(values.dataAlteracao),
			componente: values?.componente?.map(item => String(item.value)),
		});
		setParams({ ...parsedData, page: defaultParams.page });
	};

	useEffect(() => {
		if (hasDefaultModulo) {
			setParams({ modulo: defaultValueModule?.value as Modules, page: defaultParams.page });
		}
	}, [defaultParams.page, defaultValueModule, hasDefaultModulo, reset, setValue]);

	const handleOnCloseModal = () => {
		if (hasDefaultModulo) {
			setParams({ modulo: defaultValueModule?.value as Modules, page: defaultParams.page });
		} else {
			setParams({});
		}
		reset();
		onClose();
	};

	const handleChangePage = (page: number) => setParams(prevState => ({ ...prevState, page }));
	return (
		<>
			<Modal isOpen={isOpen} onClose={handleOnCloseModal}>
				<ModalOverlay />
				<ModalContent sx={styles.container} data-testid="modal">
					<ModalHeader sx={styles.modalHeader}>
						<Flex sx={styles.modalHeaderContainer} as="form" onSubmit={handleSubmit(onHandleSubmit)}>
							<Flex sx={styles.flexHeader}>
								<Box sx={styles.box}>
									<InputMask
										sx={styles.InputMask}
										label="CPF"
										mask={CPF_MASK}
										name="cpf"
										labelVariant="bold"
										data-testid="input--cpf"
										placeholder="Número do CPF"
										errorMessage={errors?.cpf?.message}
										register={register}
									/>
								</Box>
								<Box sx={styles.box}>
									<Controller
										name="modulo"
										control={control}
										render={({ field }) => (
											<Select
												name="modulo"
												label="Onde"
												labelVariant="bold"
												placeholder="Onde foi alterado"
												value={field?.value}
												onChange={field?.onChange}
												options={auditModulesOptions}
												isLoading={isLoadingAuditModules}
												errorMessage={errors?.modulo?.message}
												isDisabled={hasDefaultModulo}
											/>
										)}
									/>
								</Box>
								<Box sx={styles.box}>
									<Controller
										name="componente"
										control={control}
										render={({ field }) => (
											<Multiselect
												name="componente"
												label="Componente alterado"
												labelVariant="bold"
												placeholder="Qual componente alterado"
												value={field?.value}
												onChange={field?.onChange}
												options={auditComponentsOptions}
												isLoading={isLoadingAuditComponents}
												isClearable={false}
												errorMessage={errors?.componente?.message}
											/>
										)}
									/>
								</Box>
								<Box sx={styles.selectDataBox}>
									<CustomCalendar
										maxDate={addYears(new Date(), 5)}
										minDate={new Date()}
										view="days"
										showDays
										fieldName="dataAlteracao"
										dataTestId="input--year"
										label="Data de alteração"
										labelVariant="bold"
										placeholder="Selecione uma data"
										setValue={setValue}
										control={control}
										errorMessage={errors.dataAlteracao?.message}
									/>
								</Box>
							</Flex>
							<Text sx={styles.errorText} data-testid="text--error">
								{(errors as GhostFormValidator)?.ghostFormValidator?.message}
							</Text>

							<Button sx={styles.searchButton} type="submit" data-testid="button--submit">
								Pesquisar
							</Button>
						</Flex>
					</ModalHeader>

					<ModalCloseButton size="lg" />

					<ModalBody sx={styles.modalBody} data-testid="modal-body">
						{hasParams && (
							<>
								{isLoading ? (
									<Box sx={styles?.loadingSpinner}>
										<Spinner size="xl" color="#3EA2A2" />
									</Box>
								) : (
									<>
										<Table columns={columns} data={data!} />
										<Flex sx={styles.paginationContainer}>
											<Pagination
												currentPage={params?.page || defaultParams?.page!}
												onPaginate={handleChangePage}
												totalItems={totalItems}
												pageSize={defaultParams?.size}
											/>
										</Flex>
									</>
								)}
							</>
						)}
					</ModalBody>

					<ModalFooter sx={styles.footer}>
						<Button sx={styles.closeButton} onClick={handleOnCloseModal}>
							Fechar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{recordDetails && (
				<AuditRecordsModalDetails
					isOpen={isRecordsDetailsModalOpen}
					onClose={onRecordsDetailsModalClose}
					recordDetails={recordDetails}
				/>
			)}
		</>
	);
};

export default AuditRecordsModal;
