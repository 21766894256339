import React from 'react';
import { Box, Table as ChakraTable, SystemStyleObject, Tbody, Td, Th, Thead, Tr, Flex } from '@chakra-ui/react';
import { IColumn, IRow, IStyle } from 'types/table';

interface TableExportProps {
	columns: IColumn[];
	data: IRow[];
	headerCustomStyle?: SystemStyleObject;
	cellCustomStyle?: SystemStyleObject;
}

const TableExportModal = ({ columns, data, cellCustomStyle, headerCustomStyle }: TableExportProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			maxW: '1612px',
			overflowX: 'auto',
			fontWeight: 'normal',

			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
			},
		},
		content: {
			minW: 'auto',
			'th:first-of-type': {
				paddingLeft: ' 2rem',
				paddingRight: '10.375rem',
			},
			'td:first-of-type': {
				paddingLeft: '2rem',
				width: '30rem',
			},
			'th:last-of-type': {
				paddingRight: 0,
			},
			'td:nth-child(2)': {
				textAlign: 'left',
			},

			th: {
				textAlign: 'center',
			},
			height: '50vh',
		},
		header: {
			backgroundColor: 'blue.700',
			alignItems: 'center',
			color: 'white',
			gap: '0.75rem',
			width: '6rem',
			...headerCustomStyle,
		},
		cell: {
			background: 'white',
			...cellCustomStyle,
		},
		left: {
			position: 'sticky',
			left: 0,
		},
		right: {
			position: 'sticky',
			right: 0,
		},
	};

	const getStyle = ({ column, isHeader = false, row }: IStyle) => {
		let style = { ...styles?.cell, ...column?.columnCustomStyle };

		if (column?.sticky) style = { ...style, ...styles[column?.sticky] };

		if (isHeader) style = { ...style, ...styles?.header };

		if (row) style = { ...style, ...row };

		return style;
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<ChakraTable variant="exportDate">
					<Thead>
						<Tr>
							{columns?.map(column => (
								<Th
									key={column?.key}
									sx={getStyle({
										column,
										isHeader: true,
									})}
								>
									<Flex sx={styles.header}>{column?.label}</Flex>
								</Th>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{data?.map((data, index) => (
							<Tr key={index}>
								{columns?.map(column => (
									<Td
										sx={getStyle({
											column,
											index,
											row: data?.row as SystemStyleObject,
										})}
										key={`${index}-${column?.key}`}
									>
										{data[column?.key] as string}
									</Td>
								))}
							</Tr>
						))}
					</Tbody>
				</ChakraTable>
			</Box>
		</Box>
	);
};

export default TableExportModal;
