/**
 *
 * CaapMembersForm
 *
 */

import CustomModal from '../CustomModal';
import InputText from '../InputText';
import Prompt from '../Prompt';
import Select from '../Select';
import { useState } from 'react';
import {
	Box,
	Button,
	Divider,
	Flex,
	IconButton,
	Spinner,
	SystemStyleObject,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AddCaapMemberIcon, DeleteIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getCaapFunctions, getCaapMemberFilter } from 'services/http/caapMember';
import { getSector } from 'services/http/sectors';
import { CaapMemberFunction, IResponseCaapMemberFilter } from 'types/caapMembers';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR, SECTOR_TYPE } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

interface ISelectedSector {
	index: number;
	setor: IOption<ISector>;
	type: 'TITULAR' | 'SUPLENTE';
}

export interface ICaapMembers {
	id?: string;
	representacao: string;
	unidadeSetorTitular?: IOption<Partial<ISector>>;
	nomeTitular?: IOption | null;
	maspTitular?: IOption | null;
	vinculoTitular: string;
	cargoTitular: string;
	funcaoTitular?: IOption | null;
	unidadeSetorSuplente?: IOption<Partial<ISector>>;
	nomeSuplente?: IOption | null;
	maspSuplente?: IOption | null;
	vinculoSuplente: string;
	cargoSuplente: string;
	funcaoSuplente?: IOption | null;
	dataAtualizacao?: string;
	nomesOption?: IOption[];
	nomesSuplenteOption?: IOption[];
	maspOption?: IOption[];
	maspSuplenteOption?: IOption[];
}

export interface ICaapMembersForm {
	membrosDaCaap: ICaapMembers[];
}

interface CaapMembersFormProps {
	defaultValues?: ICaapMembersForm;
	onSubmit: (values: ICaapMembersForm) => void;
	isEditing?: boolean;
	isSubmitLoading?: boolean;
}

const CaapMembersForm = ({
	onSubmit,
	defaultValues,
	isEditing = false,
	isSubmitLoading = false,
}: CaapMembersFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			p: '1.5rem 2rem 1rem',
			flexDir: 'column',
			gap: '1rem',
		},
		fieldContainer: {
			flexDir: 'column',
			gap: '1rem',
		},
		fieldContent: {
			gap: {
				base: '2rem',
				xl: '3.875rem',
			},
			pos: 'relative',
			alignItems: {
				base: 'center',
				xl: 'flex-end',
			},
			flexDir: {
				base: 'column',
				xl: 'row',
			},
		},
		title: {
			fontSize: '1.125rem',
			fontWeight: 'black',
		},
		input: {
			maxW: {
				base: '100%',
				xl: '32rem',
			},
			w: '100%',
			fontSize: '1rem',
		},
		selectsContainer: {
			gap: '2rem',
			flexDir: {
				base: 'column',
				'2xl': 'row',
			},
		},
		selectLarge: {
			maxW: {
				base: '100%',
				xl: '32rem',
				'2xl': '32rem',
			},
			w: '100%',
		},
		selectSmall: {
			maxW: {
				base: '100%',
				'2xl': '22rem',
			},
			w: '100%',
		},
		footerText: {
			fontSize: '0.875rem',
			alignSelf: 'flex-end',
		},
		updateButton: {
			borderRadius: '20px',
			px: '1.5rem',
			h: '2.5rem',
			mb: '0.25rem',
		},
		tooltipContainer: {
			pos: 'absolute',
			right: '0',
			top: '-3',
		},
		buttonContainer: {
			mt: '0.625rem',
			gap: '2rem',
			flexDir: 'column',
			alignItems: 'center',
		},
		buttonContent: {
			gap: {
				base: '2rem',
				xl: '2.5rem',
			},
			alignSelf: 'self-start',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
			w: '100%',
		},
		button: {
			px: '5.375rem',
			w: {
				base: '100%',
				xl: 'fit-content',
			},
		},
		content: {
			flexDir: 'column',
			gap: '2rem',
		},
		spinner: {
			justifyContent: 'center',
			alignItems: 'center',
			h: '50vh',
		},
		selectsFirstContainer: {
			gap: '1.5rem',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
			width: { base: '100%', '2xl': '32rem' },
		},
		selectsSecondContainer: {
			gap: '1.5rem',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
			width: { base: '100%', '2xl': '42rem' },
		},
		maspContent: {
			maxW: {
				base: '100%',
				'2xl': '10rem',
			},
			w: '100%',
		},
		roleContent: {
			width: { base: '100%', md: '100%', '2xl': '30rem' },
			maxWidth: { base: '100%', md: '100%', '2xl': '30rem' },
		},
	};

	const {
		session: { user },
	} = useSession();

	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);
	const [selectedSector, setSelectedSector] = useState<ISelectedSector>({} as ISelectedSector);

	const caapMemberObject = {
		representacao: '',
		unidadeSetorTitular: undefined,
		nomeTitular: undefined,
		maspTitular: undefined,
		vinculoTitular: '',
		cargoTitular: '',
		funcaoTitular: undefined,
		unidadeSetorSuplente: undefined,
		nomeSuplente: undefined,
		maspSuplente: undefined,
		vinculoSuplente: '',
		cargoSuplente: '',
		funcaoSuplente: undefined,
		nomesOption: [],
		nomesSuplenteOption: [],
		maspOption: [],
		maspSuplenteOption: [],
	};

	const optionSchema = yup
		.object()
		.shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		})
		.required('Este campo é obrigatório.')
		.default(undefined)
		.nullable();

	const schema = yup.object().shape({
		membrosDaCaap: yup.array().of(
			yup.object().shape({
				representacao: yup.string().required('Este campo é obrigatório.').max(100, 'Máximo de 100 caracteres.'),
				unidadeSetorTitular: optionSchema,
				nomeTitular: optionSchema,
				maspTitular: optionSchema,
				vinculoTitular: yup.string(),
				cargoTitular: yup.string(),
				funcaoTitular: optionSchema,
				unidadeSetorSuplente: optionSchema,
				nomeSuplente: optionSchema,
				maspSuplente: optionSchema,
				vinculoSuplente: yup.string(),
				cargoSuplente: yup.string(),
				funcaoSuplente: optionSchema,
			}),
		),
	});

	const {
		handleSubmit,
		register,
		control,
		getValues,
		setValue,
		watch,
		clearErrors,
		formState: { errors, isDirty, isSubmitting, isSubmitted },
	} = useForm<ICaapMembersForm>({
		resolver: yupResolver(schema),
		defaultValues: {
			membrosDaCaap: [caapMemberObject],
			...defaultValues,
		},
	});

	const { fields, remove, append, update } = useFieldArray({
		name: 'membrosDaCaap',
		keyName: 'code',
		control,
	});

	const userUnits = stringifyCurrentUserUnits(user);

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const { data: unitSector, isLoading: isUnitSectorLoading } = useQuery<
		ISector[],
		AxiosError<ResponseErrors>,
		ISector[]
	>(['unidade-setor'], () => getSector(SECTOR_TYPE.BOARD_TYPE, userUnits), {
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const { data: funcoes, isLoading: isFuncoesLoading } = useQuery<CaapMemberFunction[], AxiosError<ResponseErrors>>(
		['funcoes'],
		() => getCaapFunctions(),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const clearFields = (index: number, unit: 'Titular' | 'Suplente') => {
		const selectedField = watch(`membrosDaCaap.${index}.unidadeSetor${unit}`);

		const isEqual = defaultValues?.membrosDaCaap[index][`unidadeSetor${unit}`]?.label === selectedField?.label;

		if (isEqual) {
			setValue(`membrosDaCaap.${index}.nome${unit}`, defaultValues?.membrosDaCaap[index][`nome${unit}`]);
			setValue(`membrosDaCaap.${index}.masp${unit}`, defaultValues?.membrosDaCaap[index][`masp${unit}`]);
			setValue(`membrosDaCaap.${index}.vinculo${unit}`, defaultValues?.membrosDaCaap[index][`vinculo${unit}`]!);
			setValue(`membrosDaCaap.${index}.cargo${unit}`, defaultValues?.membrosDaCaap[index][`cargo${unit}`]!);
			setValue(`membrosDaCaap.${index}.funcao${unit}`, defaultValues?.membrosDaCaap[index][`funcao${unit}`]);
		} else {
			setValue(`membrosDaCaap.${index}.nome${unit}`, null);
			setValue(`membrosDaCaap.${index}.masp${unit}`, null);
			setValue(`membrosDaCaap.${index}.vinculo${unit}`, '');
			setValue(`membrosDaCaap.${index}.cargo${unit}`, '');
			setValue(`membrosDaCaap.${index}.funcao${unit}`, null);
		}
	};

	const { data: members } = useQuery<
		IResponseCaapMemberFilter[],
		AxiosError<ResponseErrors>,
		IResponseCaapMemberFilter[]
	>(
		['unidadeFiltratada'],
		() =>
			getCaapMemberFilter({
				unidadeId: String(selectedSector?.setor?.data?.idUnidade),
			}),
		{
			onSuccess: data => {
				const currentValue = watch();
				if (currentValue) {
					const fields =
						selectedSector.type === 'TITULAR'
							? {
									nomesOption: parsedOptionArray(data, 'nome', 'nome'),
									maspOption: parsedOptionArray(data, 'masp', 'masp'),
							  }
							: {
									nomesSuplenteOption: parsedOptionArray(data, 'nome', 'nome'),
									maspSuplenteOption: parsedOptionArray(data, 'masp', 'masp'),
							  };

					update(selectedSector?.index, {
						...currentValue?.membrosDaCaap[selectedSector?.index],
						...fields,
					});
				}

				setSelectedSector({} as ISelectedSector);
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!selectedSector?.setor,
		},
	);

	const handleSetValues = (
		index: number,
		field: keyof Pick<ICaapMembers, 'nomeTitular' | 'maspTitular' | 'nomeSuplente' | 'maspSuplente'>,
		type: 'nome' | 'masp',
	) => {
		const selectedField = getValues(`membrosDaCaap.${index}.${field}`);

		const membro = members?.find(membro => membro[type] === selectedField?.value);

		if (membro) {
			if (field === 'nomeTitular' || field === 'maspTitular') {
				setValue(`membrosDaCaap.${index}.vinculoTitular`, membro.vinculo);
				setValue(`membrosDaCaap.${index}.cargoTitular`, membro.cargo);
				setValue(`membrosDaCaap.${index}.nomeTitular`, { label: membro.nome, value: membro.nome });
				setValue(`membrosDaCaap.${index}.maspTitular`, { label: membro.masp, value: membro.masp });
				clearErrors(`membrosDaCaap.${index}.nomeTitular`);
				clearErrors(`membrosDaCaap.${index}.maspTitular`);
			}

			if (field === 'nomeSuplente' || field === 'maspSuplente') {
				setValue(`membrosDaCaap.${index}.vinculoSuplente`, membro.vinculo);
				setValue(`membrosDaCaap.${index}.cargoSuplente`, membro.cargo);
				setValue(`membrosDaCaap.${index}.nomeSuplente`, { label: membro.nome, value: membro.nome });
				setValue(`membrosDaCaap.${index}.maspSuplente`, { label: membro.masp, value: membro.masp });
				clearErrors(`membrosDaCaap.${index}.nomeSuplente`);
				clearErrors(`membrosDaCaap.${index}.maspSuplente`);
			}
		}
	};

	const handleAddCaapMember = () => {
		append(caapMemberObject);
	};

	const handleDeleteCaapMember = (index: number) => {
		remove(index);
	};

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.caapMembers);
		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	const onHandleSubmit = (values: ICaapMembersForm) => {
		onSubmit(values);
	};

	return (
		<Flex as="form" onSubmit={handleSubmit(onHandleSubmit)} sx={styles.fieldContainer}>
			{isUnitSectorLoading || isFuncoesLoading ? (
				<Flex sx={styles.spinner}>
					<Spinner />
				</Flex>
			) : (
				<>
					<Flex sx={styles.content}>
						{fields.map((item, index) => (
							<Flex sx={styles.container} key={item.code}>
								<Flex sx={styles.fieldContainer}>
									<Flex sx={styles.fieldContent}>
										<Box sx={styles.input}>
											<InputText
												name={`membrosDaCaap.${index}.representacao`}
												label="Representação"
												placeholder="Representação"
												labelVariant="bold"
												register={register}
												data-testid={`input--membrosDaCaap.${index}.representacao`}
												errorMessage={errors?.membrosDaCaap?.[index]?.representacao?.message}
												fontSize="1rem"
												maxLength={100}
												required
											/>
										</Box>

										{fields.length > 1 && (
											<Box sx={styles.tooltipContainer}>
												<Tooltip
													label="Excluir permanentemente."
													color="#3B3333"
													bg="white"
													hasArrow
													arrowSize={15}
													placement="top-start"
													fontSize="sm"
													fontWeight="normal"
													arrowPadding={3}
												>
													<IconButton
														aria-label={'Excluir membro CAAP'}
														variant="unstyled"
														icon={<DeleteIcon />}
														data-testid={`button--removeRow-${index}`}
														onClick={() => handleDeleteCaapMember(index)}
													/>
												</Tooltip>
											</Box>
										)}
									</Flex>

									<Divider />
								</Flex>
								<Flex sx={styles.fieldContainer}>
									<Text sx={styles.title}>Titular</Text>
									<Box sx={styles.selectLarge}>
										<Controller
											name={`membrosDaCaap.${index}.unidadeSetorTitular`}
											control={control}
											render={({ field: { onChange, value } }) => (
												<Select
													label="Unidade de exercício"
													placeholder="Escolha a unidade"
													labelVariant="bold"
													onChange={e => {
														onChange(e);
														clearFields(index, 'Titular');
														setSelectedSector({ index, setor: e as unknown as IOption<ISector>, type: 'TITULAR' });
													}}
													value={value}
													options={parsedOptionArray(unitSector, 'nome', 'idSetor')}
													dataTestId={`select--membrosDaCaap.${index}.unidadeSetorTitular`}
													errorMessage={errors?.membrosDaCaap?.[index]?.unidadeSetorTitular?.message}
													required
												/>
											)}
										/>
									</Box>
									<Flex sx={styles.selectsContainer}>
										<Flex sx={styles.selectsFirstContainer}>
											<Box sx={styles.selectLarge}>
												<Controller
													name={`membrosDaCaap.${index}.nomeTitular`}
													control={control}
													render={({ field: { onChange, value } }) => (
														<Select
															label="Nome"
															placeholder="Escolha o titular"
															labelVariant="bold"
															options={item?.nomesOption}
															onChange={e => {
																onChange(e);
																handleSetValues(index, 'nomeTitular', 'nome');
															}}
															value={value}
															dataTestId={`select--membrosDaCaap.${index}.nomeTitular`}
															errorMessage={errors?.membrosDaCaap?.[index]?.nomeTitular?.message}
															isDisabled={!watch(`membrosDaCaap.${index}.unidadeSetorTitular`)}
															required
														/>
													)}
												/>
											</Box>
											<Box sx={styles.maspContent}>
												<Controller
													name={`membrosDaCaap.${index}.maspTitular`}
													control={control}
													render={({ field: { onChange, value } }) => (
														<Select
															label="MASP"
															placeholder="Buscar"
															labelVariant="bold"
															options={item?.maspOption}
															onChange={e => {
																onChange(e);
																handleSetValues(index, 'maspTitular', 'masp');
															}}
															value={value}
															dataTestId={`select--membrosDaCaap.${index}.maspTitular`}
															errorMessage={errors?.membrosDaCaap?.[index]?.maspTitular?.message}
															isDisabled={!watch(`membrosDaCaap.${index}.unidadeSetorTitular`)}
															required
														/>
													)}
												/>
											</Box>
										</Flex>
										<Flex sx={styles.selectsSecondContainer}>
											<Box sx={styles.maspContent}>
												<InputText
													name={`membrosDaCaap.${index}.vinculoTitular`}
													label="Vínculo"
													placeholder="Vínculo"
													labelVariant="bold"
													register={register}
													data-testid={`input--membrosDaCaap.${index}.vinculoTitular`}
													errorMessage={errors?.membrosDaCaap?.[index]?.vinculoTitular?.message}
													isReadOnly
													fontSize="1rem"
												/>
											</Box>
											<Box sx={styles.maspContent}>
												<InputText
													name={`membrosDaCaap.${index}.cargoTitular`}
													label="Cargo"
													placeholder="Definir cargo"
													labelVariant="bold"
													register={register}
													data-testid={`input--membrosDaCaap.${index}.cargoTitular`}
													errorMessage={errors?.membrosDaCaap?.[index]?.cargoTitular?.message}
													isReadOnly
												/>
											</Box>
											<Box sx={styles.roleContent}>
												<Controller
													name={`membrosDaCaap.${index}.funcaoTitular`}
													control={control}
													render={({ field: { onChange, value } }) => (
														<Select
															label="Função"
															placeholder="Definir função"
															labelVariant="bold"
															options={parsedOptionArray(funcoes, 'funcaoDescricao', 'id')}
															onChange={onChange}
															value={value}
															dataTestId={`select--membrosDaCaap.${index}.funcaoTitular`}
															errorMessage={errors?.membrosDaCaap?.[index]?.funcaoTitular?.message}
															required
														/>
													)}
												/>
											</Box>
										</Flex>
									</Flex>

									<Divider />
								</Flex>

								<Flex sx={styles.fieldContainer}>
									<Text sx={styles.title}>Suplente</Text>
									<Box sx={styles.selectLarge}>
										<Controller
											name={`membrosDaCaap.${index}.unidadeSetorSuplente`}
											control={control}
											render={({ field: { onChange, value } }) => (
												<Select
													label="Unidade de exercício"
													placeholder="Escolha a unidade"
													labelVariant="bold"
													options={parsedOptionArray(unitSector, 'nome', 'idSetor')}
													onChange={e => {
														onChange(e);
														clearFields(index, 'Suplente');
														setSelectedSector({ index, setor: e as unknown as IOption<ISector>, type: 'SUPLENTE' });
													}}
													value={value}
													dataTestId={`select--membrosDaCaap.${index}.unidadeSetorSuplente`}
													errorMessage={errors?.membrosDaCaap?.[index]?.unidadeSetorSuplente?.message}
													required
												/>
											)}
										/>
									</Box>
									<Flex sx={styles.selectsContainer}>
										<Flex sx={styles.selectsFirstContainer}>
											<Box sx={styles.selectLarge}>
												<Controller
													name={`membrosDaCaap.${index}.nomeSuplente`}
													control={control}
													render={({ field: { onChange, value } }) => (
														<Select
															label="Nome"
															placeholder="Escolha o suplente"
															labelVariant="bold"
															options={item?.nomesSuplenteOption}
															onChange={e => {
																onChange(e);
																handleSetValues(index, 'nomeSuplente', 'nome');
															}}
															value={value}
															dataTestId={`select--membrosDaCaap.${index}.nomeSuplente`}
															errorMessage={errors?.membrosDaCaap?.[index]?.nomeSuplente?.message}
															isDisabled={!watch(`membrosDaCaap.${index}.unidadeSetorSuplente`)}
															required
														/>
													)}
												/>
											</Box>
											<Box sx={styles.maspContent}>
												<Controller
													name={`membrosDaCaap.${index}.maspSuplente`}
													control={control}
													render={({ field: { onChange, value } }) => (
														<Select
															label="MASP"
															placeholder="Buscar"
															labelVariant="bold"
															options={item?.maspSuplenteOption}
															onChange={e => {
																onChange(e);
																handleSetValues(index, 'maspSuplente', 'masp');
															}}
															value={value}
															dataTestId={`select--membrosDaCaap.${index}.maspSuplente`}
															errorMessage={errors?.membrosDaCaap?.[index]?.maspSuplente?.message}
															isDisabled={!watch(`membrosDaCaap.${index}.unidadeSetorSuplente`)}
															required
														/>
													)}
												/>
											</Box>
										</Flex>
										<Flex sx={styles.selectsSecondContainer}>
											<Box sx={styles.maspContent}>
												<InputText
													name={`membrosDaCaap.${index}.vinculoSuplente`}
													label="Vínculo"
													placeholder="Vínculo"
													labelVariant="bold"
													register={register}
													data-testid={`input--membrosDaCaap.${index}.vinculoSuplente`}
													errorMessage={errors?.membrosDaCaap?.[index]?.vinculoSuplente?.message}
													fontSize="1rem"
													isReadOnly
												/>
											</Box>
											<Box sx={styles.maspContent}>
												<InputText
													name={`membrosDaCaap.${index}.cargoSuplente`}
													label="Cargo"
													placeholder="Definir cargo"
													labelVariant="bold"
													register={register}
													data-testid={`input--membrosDaCaap.${index}.cargoSuplente`}
													errorMessage={errors?.membrosDaCaap?.[index]?.cargoSuplente?.message}
													isReadOnly
												/>
											</Box>
											<Box sx={styles.roleContent}>
												<Controller
													name={`membrosDaCaap.${index}.funcaoSuplente`}
													control={control}
													render={({ field: { onChange, value } }) => (
														<Select
															label="Função"
															placeholder="Definir função"
															labelVariant="bold"
															options={parsedOptionArray(funcoes, 'funcaoDescricao', 'id')}
															onChange={onChange}
															value={value}
															dataTestId={`select--membrosDaCaap.${index}.funcaoSuplente`}
															errorMessage={errors?.membrosDaCaap?.[index]?.funcaoSuplente?.message}
															required
														/>
													)}
												/>
											</Box>
										</Flex>
									</Flex>
								</Flex>

								{isEditing && (
									<Text sx={styles.footerText}>
										Data da última atualização: {formatDate(defaultValues?.membrosDaCaap[0].dataAtualizacao)}
									</Text>
								)}
							</Flex>
						))}
					</Flex>

					<Flex sx={styles.buttonContainer}>
						{!isEditing && (
							<Button
								sx={styles.button}
								leftIcon={<AddCaapMemberIcon color="#FFF" />}
								onClick={handleAddCaapMember}
								data-testid="button--addNewRow"
							>
								Adicionar Representação
							</Button>
						)}

						<Flex sx={styles.buttonContent}>
							<Button type="submit" sx={styles.button} data-testid="button--submit" isLoading={isSubmitLoading}>
								Salvar
							</Button>
							<Button sx={styles.button} variant="secondary" data-testid="button--cancel" onClick={handleOpenModal}>
								Cancelar
							</Button>
						</Flex>
					</Flex>

					<CustomModal
						icons={[{ type: 'error' }]}
						title="Você realmente quer cancelar?"
						body="Ao cancelar os campos anteriormente preenchidos serão perdidos."
						isOpen={isOpen}
						onClose={onClose}
						actions={[
							{
								label: 'Quero Cancelar',
								type: 'cancel',
								onClick: handleGoBack,
								datatestid: 'button--confirm',
							},
							{
								label: 'Voltar',
								type: 'secondary',
								onClick: handleCloseModal,
								datatestid: 'button--cancel',
							},
						]}
					/>
				</>
			)}

			<Prompt when={showPrompt} />
		</Flex>
	);
};

export default CaapMembersForm;
