import { AuditRecordParams } from 'app/components/AuditRecordsModal';
import { getAxios } from 'hooks/useAxios';
import qs from 'qs';
import { ENDPOINTS } from 'services/endpoints';
import { AuditModule, AuditRecord } from 'types/audit';
import { IPagination } from 'types/pagination';

export const getAuditRecords = async (params: AuditRecordParams): Promise<IPagination<AuditRecord[]>> => {
	const api = getAxios();
	const response = await api.get<IPagination<AuditRecord[]>>(ENDPOINTS.getAuditRecords, {
		params,
		paramsSerializer: params => {
			return qs.stringify(params, { arrayFormat: 'repeat' });
		},
	});
	return response.data;
};

export const getAuditRecordModules = async (): Promise<AuditModule[]> => {
	const api = getAxios();
	const response = await api.get<AuditModule[]>(ENDPOINTS.getAuditModules);
	return response.data;
};

export const getAuditRecordComponents = async (modulo?: string): Promise<string[]> => {
	const api = getAxios();
	const response = await api.get<string[]>(ENDPOINTS.getAuditComponents, { params: { modulo: modulo } });
	return response.data;
};
