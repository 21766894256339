import { getAxiosForSAU } from 'hooks/useAxios';
import { ENDPOINTS } from 'services/endpoints';
import { UserMail } from 'types/user';

export interface UserMailParams {
	cpf?: string;
}

export const getUserMail = async (params?: UserMailParams): Promise<UserMail> => {
	const api = getAxiosForSAU();
	const response = await api.get(ENDPOINTS.userMail, { params });
	return response.data;
};
