/**
 *
 * InputMask
 *
 */
import { InfoIcon } from '@chakra-ui/icons';
import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputProps,
	SystemStyleObject,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { TableEditIconEdited } from 'assets/icons';
import { UseFormRegister } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

interface InputMaskProps extends InputProps {
	mask: string;
	name: string;
	register?: UseFormRegister<any>;
	label?: string;
	labelVariant?: string;
	errorMessage?: string;
	errorMessageVariant?: string;
	tooltipInfo?: string;
	required?: boolean;
	disabled?: boolean;
	isEditing?: boolean;
}

const InputMask = ({
	mask,
	name,
	register,
	label,
	labelVariant,
	errorMessage,
	errorMessageVariant,
	tooltipInfo,
	required,
	disabled,
	isEditing,
	...rest
}: InputMaskProps) => {
	const styles: Record<string, SystemStyleObject> = {
		tooltip: {
			pb: '1rem',
			background: 'white',
			bg: 'white',
			color: 'black',
			borderWidth: '1px',
			borderColor: 'gray.300',
			borderRadius: '4px',
			fontSize: '0.8rem',
		},
		tooltipIcon: {
			boxSize: '0.8rem',
			ml: '0.5rem',
			color: 'gray.300',
			svg: {
				path: {
					fill: 'blue.700',
				},
				rect: {
					fill: 'blue.700',
				},
			},
		},
		isEditingIcon: {
			ml: '0.5rem',
		},
	};

	return (
		<>
			<FormControl isInvalid={!!errorMessage}>
				{label && (
					<FormLabel variant={labelVariant}>
						{label} {required && <Text as="span">*</Text>}{' '}
						{tooltipInfo && (
							<Tooltip bg="white" placement="top-start" hasArrow sx={styles?.tooltip} label={tooltipInfo}>
								<InfoIcon sx={styles?.tooltipIcon} />
							</Tooltip>
						)}
						{isEditing && (
							<Box sx={styles?.isEditingIcon}>
								<TableEditIconEdited />
							</Box>
						)}
					</FormLabel>
				)}

				<Input
					as={ReactInputMask}
					{...register?.(name)}
					disabled={disabled}
					{...rest}
					mask={mask}
					sx={styles?.inputPlaceholder}
				/>
				{errorMessage && (
					<FormErrorMessage data-testid="text--error" variant={errorMessageVariant}>
						{errorMessage}
					</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default InputMask;
