/**
 *
 * InputNumeric
 *
 */

/**
 *
 * InputNumeric
 *
 */

import { InfoIcon } from '@chakra-ui/icons';
import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputProps,
	SystemStyleObject,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { TableEditIconEdited } from 'assets/icons';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

interface InputNumericProps extends InputProps {
	formLabelVariant?: string;
	inputVariant?: string;
	label?: string;
	errorMessage?: string;
	errorMessageVariant?: string;
	required?: boolean;
	disabled?: boolean;
	suffix?: string;
	decimalScale?: number;
	fixedDecimalScale?: boolean;
	isEditing?: boolean;
	isAllowed?: (values: NumberFormatValues) => boolean;
	tooltipInfo?: string;
}

const InputNumeric = ({
	formLabelVariant,
	inputVariant,
	label,
	errorMessage,
	required,
	disabled,
	suffix,
	errorMessageVariant,
	decimalScale = 2,
	fixedDecimalScale = false,
	isEditing,
	tooltipInfo,
	...rest
}: InputNumericProps) => {
	const styles: Record<string, SystemStyleObject> = {
		isEditingIcon: {
			ml: '0.5rem',
		},
		tooltip: {
			pb: '1rem',
			background: 'white',
			bg: 'white',
			color: 'black',
			borderWidth: '1px',
			borderColor: 'gray.300',
			borderRadius: '4px',
			fontSize: '0.8rem',
		},
		tooltipIcon: {
			boxSize: '0.8rem',
			ml: '0.5rem',
			color: 'gray.300',
			svg: {
				path: {
					fill: 'blue.700',
				},
				rect: {
					fill: 'blue.700',
				},
			},
		},
	};

	const handleMinusKey = (event: React.KeyboardEvent<HTMLInputElement>) => event.key === '-' && event.preventDefault();

	return (
		<>
			<FormControl isInvalid={!!errorMessage}>
				{label && (
					<FormLabel variant={formLabelVariant} mr="0">
						{label}{' '}
						{required && (
							<Text variant="required" as="span">
								*
							</Text>
						)}
						{isEditing && (
							<Box sx={styles?.isEditingIcon}>
								<TableEditIconEdited />
							</Box>
						)}
						{tooltipInfo && (
							<Tooltip bg="white" placement="top-start" hasArrow sx={styles?.tooltip} label={tooltipInfo}>
								<InfoIcon sx={styles?.tooltipIcon} />
							</Tooltip>
						)}
					</FormLabel>
				)}
				<Input
					variant={inputVariant}
					disabled={disabled}
					suffix={suffix}
					thousandSeparator="."
					decimalSeparator=","
					as={NumericFormat}
					decimalScale={decimalScale}
					fixedDecimalScale={fixedDecimalScale}
					onKeyDown={event => handleMinusKey(event)}
					{...rest}
				/>
				{errorMessage && (
					<FormErrorMessage data-testid="text--error" variant={errorMessageVariant}>
						{errorMessage}
					</FormErrorMessage>
				)}
			</FormControl>
		</>
	);
};

export default InputNumeric;
