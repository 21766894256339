/**
 *
 * CreateManagementPact
 *
 */

import { SystemStyleObject, Flex, Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import PactForm, { IPactForm } from 'app/components/PactForm';
import PageWrapper from 'app/components/PageWrapper';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { createManagementPact } from 'services/http/managementPact';
import { IRequestManagementPact } from 'types/managementPact';
import { API_DEFAULT_ERROR, DATA_CONTROL_STEPS } from 'utils/constants';
import { formatPeriod } from 'utils/formatBimesters';
import { ResponseErrors } from 'utils/parseErrors';

type Params = {
	etapa: keyof typeof DATA_CONTROL_STEPS;
};

const CreateManagementPact = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.75rem',
			mb: '5.5rem',
			width: { base: '100%', lg: '65rem', '2xl': '100%' },
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();
	const { etapa } = useParams<Params>();
	const { mutate: createManagementPactMutate, isLoading: isCreateManagementPactLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestManagementPact
	>(data => createManagementPact(data), {
		onSuccess: () => {
			navigate(ROUTES.managementPact);
			addToast({ type: 'success', description: 'O pacto foi criado com sucesso!', title: 'Sucesso!' });
		},
		onError: ({ response }) =>
			addToast({ type: 'error', title: 'Tente novamente!', description: response?.data.message || API_DEFAULT_ERROR }),
	});

	const handleSubmit = (data: IPactForm) => {
		const payload: IRequestManagementPact = {
			idExternoPactuado: Number(data.pactuado?.value),
			pactuado: String(data.pactuado?.label),
			idUnidade: Number(data.pactuado?.data?.idUnidade),
			indicadores:
				data?.indicadores?.map(item => ({
					id: Number(item.indicador?.value),
					metricas: formatPeriod(item?.periodos || []),
				})) || [],
			etapaEnum: DATA_CONTROL_STEPS[etapa!],
		};

		createManagementPactMutate(payload);
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title={`Cadastrar Pacto de Gestão (${etapa === 'etapa-1' ? '1' : '2'}ª Etapa)`} />

			<Box sx={styles.content}>
				<PactForm onSubmitForm={handleSubmit} isSubmitLoading={isCreateManagementPactLoading} />
			</Box>
		</Flex>
	);
};

export default CreateManagementPact;
