/**
 *
 * PageWrapper
 *
 */

import { Box, Button, SystemStyleObject, Text } from '@chakra-ui/react';
import { WrapperArrowIcon } from 'assets/icons';
import { To, useNavigate } from 'react-router-dom';

interface PageWrapperProps {
	title: string;
	goBackRoute?: To;
}

const PageWrapper = ({ title, goBackRoute }: PageWrapperProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			gap: '1rem',
		},
		Text: {
			fontSize: '1.3rem',
			fontWeight: '800',
			color: 'black',
		},
	};

	const navigate = useNavigate();

	const handleNavigate = () => (goBackRoute ? navigate(goBackRoute) : navigate(-1));

	return (
		<Box sx={styles.container}>
			<Button onClick={handleNavigate} variant="noStyle" data-testid="button--go-back">
				<WrapperArrowIcon />
			</Button>
			<Text sx={styles.Text}>{title}</Text>
		</Box>
	);
};

export default PageWrapper;
