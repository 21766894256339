/**
 *
 * CustomCalendar
 *
 */

import DaysView from './DaysView';
import MonthsView from './MonthsView';
import YearsView from './YearsView';
import Select from '../Select';
import { useEffect, useRef, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Icon, SystemStyleObject, Text, useOutsideClick } from '@chakra-ui/react';
import { DayIcon } from 'assets/icons';
import { addMonths, eachMonthOfInterval, eachYearOfInterval, subMonths } from 'date-fns';
import { Controller, UseFormSetValue } from 'react-hook-form';
import { createCalendar, formatDateForDefaultValues, showCalendarFormattedDate } from 'utils/formatDate';

export interface ISelectedDate {
	day: number;
	month: number;
	year: number;
	isCurrentSelected?: boolean;
}

interface CustomCalendarProps {
	fieldName: string;
	label?: string | any;
	placeholder?: string;
	dataTestId?: string;
	errorMessage?: string;
	maxDate: Date;
	minDate: Date;
	setValue: UseFormSetValue<any>;
	view?: 'days' | 'months' | 'years';
	showDays?: boolean;
	showMonths?: boolean;
	control: any;
	isEditing?: boolean;
	isDisabled?: boolean;
	required?: boolean;
	labelVariant?: string;
	defaultValue?: any;
	yearsToDisable?: number[];
}

const CustomCalendar = ({
	fieldName,
	label,
	placeholder,
	dataTestId,
	errorMessage,
	maxDate,
	minDate,
	setValue,
	view = 'years',
	showDays = false,
	showMonths = false,
	control,
	isEditing = false,
	isDisabled = false,
	labelVariant,
	required,
	defaultValue,
	yearsToDisable,
}: CustomCalendarProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
			w: '100%',
			position: 'relative',
		},
		calendarContainer: {
			maxW: '24.5rem',
			minW: {
				base: '100%',
				lg: '24.5rem',
			},
			border: '1px solid',
			borderColor: 'gray.95',
			borderRadius: '4px',
			p: '2rem 3rem',
			backgroundColor: 'white',
			flexDir: 'column',
			position: 'absolute',
			top: errorMessage ? '3.3rem' : '4.1rem',
			zIndex: '1',
		},
		actionContainer: {
			w: '100%',
			justifyContent: 'space-between',
			px: '0.25rem',
		},
		actionButton: {
			backgroundColor: 'white',
			border: '1px solid',
			borderColor: 'gray.95',
			h: {
				base: '2rem',
				lg: '1.5rem',
			},
			w: {
				base: '2rem',
				lg: '1.5rem',
			},
		},
		dateText: {
			fontSize: {
				base: '1.125rem',
				lg: '0.938rem',
			},
		},
		buttonContainer: {
			mt: '1.188rem',
			justifyContent: 'center',
			gap: {
				base: '1rem',
				lg: '0.625rem',
			},
			w: '100%',
		},
		button: {
			borderRadius: {
				base: '1.25rem',
				lg: '0.813rem',
			},
			h: {
				base: '2rem',
				lg: '1.563rem',
			},
			w: '100%',
			fontSize: {
				base: '1rem',
				lg: '0.75rem',
			},
			fontWeight: 'medium',
			py: '0.625rem',
			alignSelf: 'center',
			lineHeight: {
				base: 0,
				lg: '1.2',
			},
			svg: {
				h: '14px',
			},
		},
		dividerContainer: {
			position: 'relative',
			my: '1rem',
		},
		divider: {
			_before: {
				content: '""',
				position: 'absolute',
				borderBottom: '2px dashed #3EA2A2',
				left: '0',
				right: '0',
			},
		},
		disabledDivider: {
			_before: {
				content: '""',
				position: 'absolute',
				borderBottom: '1px solid #3EA2A2',
				left: '0',
				right: '0',
			},
		},
		select: {
			height: '2.75rem',
			color: 'black.800',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
	};

	const currentDate =
		view === 'days'
			? { day: minDate.getDate(), month: minDate.getMonth(), year: minDate.getFullYear(), isCurrentSelected: false }
			: { day: 1, month: 0, year: minDate.getFullYear(), isCurrentSelected: false };

	const [selectedDate, setSelectedDate] = useState<ISelectedDate>(currentDate);
	const [toggleView, setToggleView] = useState<'days' | 'months' | 'years'>(view);
	const [toggleButton, setToggleButton] = useState(false);
	const ref = useRef(null);

	useOutsideClick({
		ref: ref,
		handler: () => setToggleButton(false),
	});

	const calendar = createCalendar(selectedDate.month, selectedDate.year);

	const prevMonth = (value: ISelectedDate) => {
		const date = new Date(value.year, value.month, value.day);
		const formattedDate = subMonths(date, 1);

		onChange({ day: formattedDate.getDate(), month: formattedDate.getMonth(), year: formattedDate.getFullYear() });
	};

	const nextMonth = (value: ISelectedDate) => {
		const date = new Date(value.year, value.month, value.day);
		const formattedDate = addMonths(date, 1);

		onChange({ day: formattedDate.getDate(), month: formattedDate.getMonth(), year: formattedDate.getFullYear() });
	};

	const prevYear = () => {
		if (selectedDate?.year > minDate.getFullYear()) setSelectedDate(prev => ({ ...prev, year: prev?.year - 1 }));
	};

	const nextYear = () => {
		if (selectedDate?.year < maxDate.getFullYear()) setSelectedDate(prev => ({ ...prev, year: prev?.year + 1 }));
	};

	const toggleViewButton = () => {
		if (toggleView === 'years') setToggleView('months');

		if (toggleView === 'months') setToggleView('years');

		if (toggleView === 'days') setToggleView('months');
	};

	const yearsRange = eachYearOfInterval({
		start: minDate,
		end: maxDate,
	});

	const monthsRange = eachMonthOfInterval({
		start: new Date(minDate.getFullYear(), 0, 1),
		end: new Date(minDate.getFullYear(), 11, 1),
	});

	const onChange = (value: ISelectedDate) => {
		const { day, month, year } = value;
		const { day: selectedDateDay, month: selectedDateMouth, year: selectedDateYear, isCurrentSelected } = selectedDate;
		let fieldNameValue: string | number | undefined = '';

		if (
			view === 'days' &&
			isCurrentSelected &&
			day === selectedDateDay &&
			month === selectedDateMouth &&
			year === selectedDateYear
		)
			setSelectedDate({ ...value, isCurrentSelected: false });
		else if (isCurrentSelected && view === 'months' && month === selectedDateMouth && year === selectedDateYear)
			setSelectedDate({ ...value, isCurrentSelected: false });
		else if (isCurrentSelected && view === 'years' && year === selectedDateYear)
			setSelectedDate({ ...value, isCurrentSelected: false });
		else {
			fieldNameValue = showCalendarFormattedDate(new Date(year, month, day), toggleView);
			setSelectedDate({ ...value, isCurrentSelected: true });
		}

		setValue(fieldName, fieldNameValue);
	};

	useEffect(() => {
		if (defaultValue) {
			const formatedDefaultValue = formatDateForDefaultValues(defaultValue, view);
			setSelectedDate({ ...formatedDefaultValue!, isCurrentSelected: true });
		}
	}, [defaultValue, view]);

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.select} onClick={() => setToggleButton(!toggleButton)}>
				<Controller
					name={fieldName}
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<Select
								name={fieldName}
								label={label}
								placeholder={placeholder}
								dataTestId={dataTestId}
								errorMessage={errorMessage}
								onChange={onChange}
								value={value}
								isCalendar
								isSearchable={false}
								isEditing={isEditing}
								isDisabled={isDisabled}
								required={required}
								labelVariant={labelVariant}
							/>
						);
					}}
				/>
			</Flex>
			{toggleButton && !isDisabled && (
				<Flex sx={styles.calendarContainer} ref={ref}>
					<Flex sx={styles.actionContainer}>
						<Button
							sx={styles.actionButton}
							onClick={toggleView === 'days' ? () => prevMonth(selectedDate) : () => prevYear()}
						>
							<Icon as={ChevronLeftIcon} color="blue.700" _hover={{ color: 'white' }} />
						</Button>
						<Text sx={styles.dateText}>
							{showCalendarFormattedDate(new Date(selectedDate.year, selectedDate.month, selectedDate.day), toggleView)}
						</Text>
						<Button
							sx={styles.actionButton}
							onClick={toggleView === 'days' ? () => nextMonth(selectedDate) : () => nextYear()}
						>
							<Icon as={ChevronRightIcon} color="blue.700" _hover={{ color: 'white' }} />
						</Button>
					</Flex>
					{showMonths && (
						<Flex sx={styles.buttonContainer}>
							<Button sx={styles.button} maxW={{ base: '15rem', lg: '13.125rem' }} onClick={() => toggleViewButton()}>
								{toggleView === 'years' && 'Quero selecionar o mês'}
								{toggleView === 'months' && 'Quero selecionar o ano'}
								{toggleView === 'days' && 'Quero selecionar o mês'}
							</Button>
							{toggleView === 'months' && showDays && (
								<Button
									sx={styles.button}
									maxW={{ base: '5.5rem', lg: '4.375rem' }}
									onClick={() => setToggleView('days')}
									leftIcon={<DayIcon color="white" />}
								>
									Dia
								</Button>
							)}
						</Flex>
					)}
					<Flex sx={styles.dividerContainer}>
						<Box sx={showMonths ? styles.divider : styles.disabledDivider} />
					</Flex>

					{toggleView === 'years' ? (
						<YearsView
							selectedDate={selectedDate}
							onChange={onChange}
							yearsRange={yearsRange}
							yearsToDisable={yearsToDisable}
						/>
					) : toggleView === 'months' ? (
						<MonthsView selectedDate={selectedDate} onChange={onChange} monthsRange={monthsRange} />
					) : (
						showDays && <DaysView selectedDate={selectedDate} onChange={onChange} calendar={calendar} />
					)}
				</Flex>
			)}
		</Flex>
	);
};

export default CustomCalendar;
