/**
 *
 * PactFilter
 *
 */

import CustomCalendar from '../CustomCalendar';
import Select from '../Select';
import { useMemo } from 'react';
import { Box, Button, Flex, SystemStyleObject } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getSector } from 'services/http/sectors';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR, DATA_CONTROL_STEPS_OPTIONS, SECTOR_TYPE } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import { getInitialYear } from 'utils/yearsArray';
import * as yup from 'yup';

export interface IPactFilterForm {
	pactuado?: IOption;
	ano?: string;
	etapa?: IOption;
}

interface paramsKeys extends IPactFilterForm {
	idPactuado: string;
}

export type PactFilterParamsKeys = keyof paramsKeys;

interface PactFilterProps {
	isListing?: boolean;
}

const PactFilter = ({ isListing = true }: PactFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
		},
		content: {
			width: '100%',
		},
		fields: {
			display: 'flex',
			width: '100%',
			alignItems: 'baseline',
			columnGap: {
				base: '3rem',
				md: '1rem',
				lg: '1rem',
				xl: '3rem',
			},
			rowGap: '3rem',
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
		},

		pact: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '23rem',
			},
			minWidth: {
				base: '100%',
				md: '18rem',
			},
		},
		years: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '12rem',
			},
		},
		etapa: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '12rem',
			},
		},
		buttonSubmit: {
			width: '100%',
		},
		button: {
			mt: {
				base: '4rem',
				lg: '2rem',
			},
			width: { base: '100%', md: '100%', lg: '15rem' },
		},
	};

	const schema = yup.object().shape({
		pactuado: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
		ano: yup
			.string()
			.required('Este campo é obrigatório.')
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		etapa: yup
			.object()
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			}),
	});

	const {
		session: { user },
	} = useSession();
	const { addToast } = useCustomToast();
	const yearMinDate = getInitialYear();
	const yearMaxDate = yearMinDate + 5;
	const [searchParams, setParams] = useSearchParams();

	const {
		control,
		handleSubmit,
		setValue,
		resetField,
		watch,
		getValues,
		formState: { errors },
	} = useForm<IPactFilterForm>({
		resolver: !isListing ? undefined : yupResolver(schema),
	});

	const stepFieldData = getValues('etapa.value');
	const ano = watch('ano');

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const { data: pactuados, isLoading: isPactuadosLoading } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['agreeds', stepFieldData],
		() => getSector(selectedStep[stepFieldData as keyof typeof selectedStep], stringifyCurrentUserUnits(user)),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!stepFieldData,
		},
	);

	useMemo(() => {
		const params = parseUrlParams<PactFilterParamsKeys>(['etapa', 'idPactuado', 'ano', 'pactuado'], searchParams);
		const isValid = isEmpty(cleanDeep(params));

		if (!isValid) {
			const etapa = DATA_CONTROL_STEPS_OPTIONS.find(item => item.value === params.etapa);
			const pactuado = pactuados?.find(item => Number(item.idSetor) === Number(params.idPactuado))!;
			const parsedPactuado = { label: pactuado?.nome, value: pactuado?.idSetor };
			const pact = parsedPactuado?.value ? parsedPactuado : undefined;
			setValue('etapa', etapa || getValues('etapa'));
			setValue('pactuado', pact || getValues('pactuado') || undefined);
			setValue('ano', params?.ano || getValues('ano') || undefined);
		}
	}, [getValues, pactuados, searchParams, setValue]);

	const onSubmit = (values: IPactFilterForm) => {
		const parseData = {
			etapa: String(values.etapa?.value || ''),
			idPactuado: String(values.pactuado?.value || ''),
			ano: String(values.ano || ''),
			pactuado: String(values.pactuado?.label || ''),
		};

		setParams(parseData);
	};

	const onChangeStep = (option: IOption) => {
		resetField('pactuado');
		setParams({
			etapa: String(option?.value || ''),
		});
	};

	return (
		<>
			<Flex sx={styles.container}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
					<Box sx={styles.fields}>
						<Box sx={styles?.etapa}>
							<Controller
								name="etapa"
								control={control}
								render={({ field }) => (
									<>
										<Select
											label="Etapa"
											dataTestId="input--step"
											placeholder="Selecione a etapa"
											value={field?.value || ''}
											onChange={e => {
												onChangeStep(e as IOption);
												field.onChange(e);
											}}
											options={DATA_CONTROL_STEPS_OPTIONS}
											errorMessage={errors?.etapa?.message}
											required
										/>
									</>
								)}
							/>
						</Box>

						<Box sx={styles.pact}>
							<Controller
								name="pactuado"
								control={control}
								render={({ field }) => (
									<>
										<Select
											label="Pactuado"
											dataTestId="input--agreed-name"
											placeholder="Selecione um pactuado"
											value={field?.value || ''}
											onChange={field?.onChange}
											options={parsedOptionArray(pactuados, 'nome', 'idSetor')}
											placeholderSelectedItems={`${pactuados?.values.name}`}
											errorMessage={errors?.pactuado?.message}
											required
											isLoading={isPactuadosLoading && !!stepFieldData}
											isDisabled={!stepFieldData}
										/>
									</>
								)}
							/>
						</Box>

						<Box sx={styles.years}>
							<CustomCalendar
								label="Ano"
								placeholder="Selecione um ano"
								fieldName="ano"
								dataTestId="input--year"
								errorMessage={!ano ? errors.ano?.message : ''}
								setValue={setValue}
								minDate={new Date(yearMinDate, 0, 1)}
								maxDate={new Date(yearMaxDate, 0, 1)}
								control={control}
								required
							/>
						</Box>
					</Box>
					<Box sx={styles.buttonSubmit}>
						<Button type="submit" sx={styles.button} data-testid="button--submit">
							Pesquisar
						</Button>
					</Box>
				</Box>
			</Flex>
		</>
	);
};

export default PactFilter;
