import { mockMonitoringReportService } from 'services/mocks/monitoringReport';
import { mockNotificationsService } from 'services/mocks/notifications';
import { mockRepresentativeService } from 'services/mocks/representative';
import { mockStrategicPlanningService } from 'services/mocks/strategicPlanning';

export const useMockAxios = () => {
	if (process.env.REACT_APP_MOCK_API) {
		mockStrategicPlanningService();
		mockMonitoringReportService();
		mockNotificationsService();
		mockRepresentativeService();
		return;
	}
};

export const getMockAxios = () => {
	mockStrategicPlanningService();
	mockMonitoringReportService();
	mockNotificationsService();
	mockRepresentativeService();
};
