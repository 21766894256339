import AxiosMockAdapter from 'axios-mock-adapter';
import { ENDPOINTS } from 'services/endpoints';
import { api } from 'services/http';

export const mockNotificationsService = (delayResponse?: number) => {
	const mock = new AxiosMockAdapter(api, { delayResponse });

	mock
		.onGet(ENDPOINTS.getAllNotifications)
		.reply(200, {
			content: [
				{
					id: 0,
					lida: true,
					situacao: 'Penalização!',
					data: '2024-01-01',
					descricao:
						'Foi aplicada uma penalização de "-3" pontos para o setor "Lorem Ipsum Dolor", pelo descumprimento do prazo da fase "Validação do Recurso". Clique no botão para conferir as alterações realizadas.',
					moduloEnum: {
						titulo: 'Penalidades',
						name: 'PENALIDADES',
						path: '',
					},
				},
				{
					id: 1,
					lida: true,
					situacao: 'Alteração de indicador',
					data: '2024-01-01',
					descricao:
						'O peso do indicador "Quantidade de Leitos Livres /Diário" foi alterado no sistema. Clique no botão para conferir as alterações realizadas.',
					moduloEnum: {
						titulo: 'Indicadores',
						name: 'INDICADORES',
						path: '',
					},
				},
				{
					id: 2,
					lida: true,
					situacao: 'Atenção!',
					data: '2024-01-01',
					descricao:
						'A fase "Validação do Recurso" da "2ª" etapa do "3º" bimestre do Pacto de Gestão Participativa OB-08 se encerra em "5" dias.',
					moduloEnum: {
						titulo: 'Controle de Datas',
						name: 'CONTROLE_DE_DATAS',
						path: '',
					},
				},
				{
					id: 3,
					lida: false,
					situacao: 'Cadastro de Pacto de Gestão',
					data: '2024-01-01',
					descricao:
						'O Pacto de Gestão cadastrado para a competência TR-11 foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
					moduloEnum: {
						titulo: 'Pacto de Gestão',
						name: 'PACTO_DE_GESTAO',
						path: '',
					},
				},
				{
					id: 4,
					lida: false,
					situacao: 'Pacto  validado',
					data: '2024-01-01',
					descricao:
						'O pacto para o setor “Diretoria Assistencial” foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
					moduloEnum: {
						titulo: 'Pacto de Gestão',
						name: 'PACTO_DE_GESTAO',
						path: '',
					},
				},

				{
					id: 8,
					lida: false,
					situacao: 'Cadastro de Pacto de Gestão',
					data: '2024-01-01',
					descricao:
						'O Pacto de Gestão cadastrado para a competência TR-11 foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
					moduloEnum: {
						titulo: 'Pacto de Gestão',
						name: 'PACTO_DE_GESTAO',
						path: '',
					},
				},
				{
					id: 9,
					lida: false,
					situacao: 'Pacto  validado',
					data: '2024-01-01',
					descricao:
						'O pacto para o setor “Diretoria Assistencial” foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
					moduloEnum: {
						titulo: 'Pacto de Gestão',
						name: 'PACTO_DE_GESTAO',
						path: '',
					},
				},
			],
			page: 1,
			totalPages: 2,
			totalElements: 10,
			last: true,
			size: 5,
			number: 0,
		})

		.onPut(ENDPOINTS.updateAllNotifications)
		.reply(200)
		.onPut(ENDPOINTS.updateMarkedNotifications)
		.reply(200)
		.onDelete(ENDPOINTS.deleteNotifications);
	mock
		.onGet(ENDPOINTS.getRecentsNotifications)
		.reply(200, mockDataNotifications)
		.onPut(ENDPOINTS.updateAllNotifications)
		.reply(200)
		.onAny()
		.passThrough();
};

export const mockDataNotifications = [
	{
		id: 0,
		descricao: `Notificação 0`,
		data: '2024-03-11T17:00:00',
		lida: true,
		moduloEnum: {
			titulo: 'Penalidades',
			name: 'PENALIDADES',
			path: '',
		},
	},
	{
		id: 1,
		descricao: `Notificação 1`,
		data: '2024-03-11T17:00:00',
		lida: false,
		moduloEnum: {
			titulo: 'Indicadores',
			name: 'INDICADORES',
			path: '',
		},
	},
	{
		id: 2,
		descricao: `Notificação 2`,
		data: '2024-03-11T17:00:00',
		lida: true,
		moduloEnum: {
			titulo: 'Controle de Datas',
			name: 'CONTROLE_DE_DATAS',
			path: '',
		},
	},
	{
		id: 3,
		descricao: `Notificação 3`,
		data: '2024-03-11T17:00:00',
		lida: false,
		moduloEnum: {
			titulo: 'Pacto de Gestão',
			name: 'PACTO_DE_GESTAO',
			path: '',
		},
	},
	{
		id: 4,
		descricao: `Notificação 4`,
		data: '2024-03-11T17:00:00',
		lida: true,
		moduloEnum: {
			titulo: 'Controle de Datas',
			name: 'CONTROLE_DE_DATAS',
			path: '',
		},
	},
	{
		id: 5,
		descricao: `Notificação 5`,
		data: '2024-03-11T17:00:00',
		lida: false,
		moduloEnum: {
			titulo: 'Pacto de Gestão',
			name: 'PACTO_DE_GESTAO',
			path: '',
		},
	},
];
