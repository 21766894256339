/**
 *
 * CreateStrategicObjective
 *
 */

import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import StrategicObjectivesForm, { IStrategicObjectiveForm } from 'app/components/StrategicObjectivesForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { IRequestStrategicObjective, createStrategicObjective } from 'services/http/strategicObjetive';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';

const CreateStrategicObjective = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.875rem',
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { mutate: createStrategicObjectveMutate, isLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestStrategicObjective
	>(data => createStrategicObjective(data), {
		onSuccess: () => {
			addToast({
				type: 'success',
				title: 'Sucesso!',
				description: 'Objetivo estratégico foi cadastrado!',
			});
			navigate(ROUTES.strategicObjectives);
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
	});

	const handleSubmit = (data: IStrategicObjectiveForm) => {
		createStrategicObjectveMutate({
			objetivo: data.objetivo,
			descricao: data.descricao,
			periodo: String(data.periodo.value),
			codigo: data.codigo || null,
			idPerspectiva: Number(data.perspectiva.value),
		});
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Cadastro de Objetivo Estratégico" goBackRoute={ROUTES.strategicObjectives} />

			<Box sx={styles.content}>
				<StrategicObjectivesForm onSubmitForm={handleSubmit} isSubmitingForm={isLoading} />
			</Box>
		</Flex>
	);
};

export default CreateStrategicObjective;
