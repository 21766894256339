/**
 *
 * RepresentativeCreate
 *
 */

import { Flex, SystemStyleObject } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import RepresentativeForm, { IRepresentativeForm } from 'app/components/RepresentativeForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { IUpdateRepresentativeParams, createRepresentative, updateRepresentative } from 'services/http/representative';
import { IRepresentative } from 'types/representative';
import { API_DEFAULT_ERROR } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';

const RepresentativeCreate = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
			gap: '3.875rem',
		},
	};

	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { mutate: createRepresentativeMutate, isLoading: isCreating } = useMutation<
		IRepresentative,
		AxiosError<ResponseErrors>,
		IRepresentative
	>(data => createRepresentative(data), {
		onSuccess: () => {
			addToast({
				type: 'success',
				title: 'Sucesso!',
				description: 'O vínculo de representante(s) foi efetuado com sucesso!',
			});
			navigate(ROUTES.representatives);
		},
		onError: error => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: error?.response?.data?.message || API_DEFAULT_ERROR,
			});
		},
	});

	const { mutate: updateRepresentativeMutate, isLoading: isUpdateLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IUpdateRepresentativeParams
	>(data => updateRepresentative(data), {
		onSuccess: () => {
			addToast({
				type: 'success',
				description: 'O vínculo de representantes foi editado com sucesso!',
				title: 'Sucesso!',
			});
			navigate(ROUTES.representatives);
		},
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
	});

	const handleSubmit = (values: IRepresentativeForm) => {
		if (values?.id) {
			updateRepresentativeMutate({
				data: values.representantes.map(item => ({
					id: item?.id || '',
					nome: item.nome?.label || '',
					email: item.email || '',
					masp: item.masp || '',
				})),
				representativeId: values.id,
			});
		} else {
			createRepresentativeMutate({
				nomePactuado: values?.setor?.data?.nome || '',
				pactuadoId: String(values?.setor?.data?.idSetor),
				unidadeId: Number(values?.setor?.data?.idUnidade),
				etapaEnum: String(values?.etapa?.value) || '',
				dadosRepresentantes: values?.representantes?.map(({ email, masp, nome }) => ({
					email: String(email) || '',
					masp: String(masp) || '',
					nome: nome?.label || '',
				})),
			});
		}
	};

	return (
		<Flex sx={styles.container}>
			<PageWrapper title="Vincular Representante" />

			<RepresentativeForm onSubmit={handleSubmit} isSubmitLoading={isCreating || isUpdateLoading} />
		</Flex>
	);
};

export default RepresentativeCreate;
