import Multiselect from '../Multiselect';
import Select from '../Select';
import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, SystemStyleObject, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useCustomToast } from 'hooks/useToast';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getIndicatorsName } from 'services/http/indicators';
import { getActivePacts } from 'services/http/managementPact';
import { getPeriodBimester, getPeriodsConcat } from 'services/http/periods';
import { IResultsParamsKeys } from 'services/http/resultEvaluation';
import { IResponseIndicatorName } from 'types/indicators';
import { IResponseActivePact } from 'types/managementPact';
import { IPeriodsConcat, IResponsePeriodBimester } from 'types/period';
import { IOption } from 'types/select';
import {
	API_DEFAULT_ERROR,
	BIMESTER_ENUM_TO_VALUE,
	BIMESTER_TOOLTIP_INFO,
	DATA_CONTROL_STEPS_OPTIONS,
	FIRST_PAGE,
	STEPS_OPTIONS,
} from 'utils/constants';
import { BIMESTERS_ENUM } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';
import { stringfyActivePactsIds } from 'utils/stringifyActivePactIds';
import * as yup from 'yup';

export interface ResulterFilterForm {
	etapa?: IOption;
	periodoPacto?: IOption<IPeriodsConcat>;
	bimestre?: IOption<IResponsePeriodBimester>;
	pactuado?: IOption<IResponseActivePact>[];
	indicador?: IOption[];
}

export type paramsKeys = keyof ResulterFilterForm;

const ResulterFilter = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			backgroundColor: '#F9FAFC',
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			p: '2rem',
			flexDir: 'column',
		},
		selectsContainer: {
			mb: '2rem',
			gap: '2.5rem',

			flexDir: {
				base: 'column',
				xl: 'row',
			},
			flexWrap: {
				xl: 'wrap',
			},
		},
		etapaContent: {
			maxW: {
				base: '100%',
				xl: '19rem',
			},
			w: '100%',
		},
		periodoContent: {
			maxW: {
				base: '100%',
				xl: '19rem',
			},
			w: '100%',
		},
		bimestreContent: {
			maxW: {
				base: '100%',
				xl: '19rem',
			},
			w: '100%',
		},
		pactuadoContent: {
			maxW: {
				base: '100%',
				xl: '19rem',
			},
			w: '100%',
		},
		indicadorContent: {
			maxW: {
				base: '100%',
				xl: '19rem',
			},
			w: '100%',
		},
		errorText: {
			fontSize: '0.9rem',
			color: 'red.400',
			mb: '0.5rem',
		},
		button: {
			w: {
				base: '100%',
				xl: '15rem',
			},
		},
	};

	const { addToast } = useCustomToast();
	const [isInvalid, setIsInvalid] = useState(false);
	const [searchParams, setParams] = useSearchParams();

	const optionSchema = yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.default(undefined)
		.nullable();

	const schema = yup.object().shape({
		etapa: optionSchema,
		periodo: optionSchema,
		bimestre: optionSchema,
		pactuado: yup.array().of(optionSchema),
		indicador: yup.array().of(optionSchema),
	});

	const { defaultValues, params } = useMemo(() => {
		const params = parseUrlParams<IResultsParamsKeys>(
			['etapa', 'bimestre', 'ano', 'anoInicio', 'anoFim', 'idIndicadores', 'idPacutados'],
			searchParams,
		);

		const etapa = DATA_CONTROL_STEPS_OPTIONS.find(item => item.value === params.etapa);

		const defaultValues = {
			etapa,
		};

		return { defaultValues, params };
	}, [searchParams]);

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		watch,
		resetField,
	} = useForm<ResulterFilterForm>({
		resolver: yupResolver(schema),
		defaultValues,
	});
	const { data: periodoPacto, isLoading: isLoadingPactPeriods } = useQuery<
		IPeriodsConcat[],
		AxiosError<ResponseErrors>
	>(['pact-periods'], () => getPeriodsConcat(), {
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
			});
		},
	});

	const parsedPeriods = useMemo(() => {
		return parsedOptionArray(periodoPacto, 'periodoConcatenado', 'periodoConcatenado') as IOption<IPeriodsConcat>[];
	}, [periodoPacto]);

	const pactPeriod = watch('periodoPacto');
	const stepFieldData = watch('etapa');

	const { data: bimestres, isLoading: isBimesterLoading } = useQuery<
		IResponsePeriodBimester[],
		AxiosError<ResponseErrors>
	>(
		['periods-bimesters', pactPeriod],
		() =>
			getPeriodBimester({ anoFim: String(pactPeriod?.data?.anoFim), anoInicio: String(pactPeriod?.data?.anoInicio) }),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!pactPeriod?.data?.anoInicio,
		},
	);

	const parsedBimesters = useMemo(() => {
		const sortedBimesters = (bimestres || [])
			.sort((a, b) => {
				const aSortKey = a.ano + BIMESTER_ENUM_TO_VALUE[a?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE];
				const bSortKey = b.ano + BIMESTER_ENUM_TO_VALUE[b?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE];
				return aSortKey.localeCompare(bSortKey);
			})
			.map(item => ({
				label: `${BIMESTERS_ENUM[item.bimestre as keyof typeof BIMESTERS_ENUM]} - ${item.ano}`,
				value: `${item.bimestre}_${item.ano}`,
				data: item,
			})) as IOption<IResponsePeriodBimester>[];

		return sortedBimesters;
	}, [bimestres]);

	const { data: activePacts, isLoading: isLoadingActivePacts } = useQuery<
		IResponseActivePact[],
		AxiosError<ResponseErrors>
	>(['active-pacts', stepFieldData], () => getActivePacts(String(stepFieldData?.value)), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data.message || API_DEFAULT_ERROR,
			}),
		enabled: !!stepFieldData?.value,
	});

	const parsedActivePacts = useMemo(() => {
		return activePacts?.map(item => ({
			label: item.pactuado,
			value: item.idAtribuicaoPactuado,
			idExternoPactuado: item.idExternoPactuado,
		})) as unknown as IOption[];
	}, [activePacts]);

	const selectedActivePacts = watch('pactuado');

	const selectedActivePactsIds = stringfyActivePactsIds(selectedActivePacts);

	const { data: indicadores, isLoading: isLoadindIndicators } = useQuery<
		IResponseIndicatorName[],
		AxiosError<ResponseErrors>
	>(['indicators', selectedActivePacts], () => getIndicatorsName({ idPacutados: selectedActivePactsIds || [] }), {
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
			});
		},
	});

	const resetFormField = (field: keyof ResulterFilterForm, defaultValue: [] | string = []) =>
		resetField(field, { defaultValue });

	useEffect(() => {
		const pactuadoList = params.idPacutados?.split(',');
		const indicadorList = params.idIndicadores?.split(',');

		const periodoPacto = parsedPeriods?.find(
			item => String(item.data?.anoInicio) === params.anoInicio && String(item.data?.anoFim) === params.anoFim,
		);
		const bimestre = parsedBimesters?.find(item => item.data?.bimestre === params.bimestre);
		const pactuado = parsedActivePacts?.filter(item =>
			pactuadoList?.includes(String(item.value)),
		) as IOption<IResponseActivePact>[];
		const indicador = parsedOptionArray(indicadores, 'nome', 'id')?.filter(item =>
			indicadorList?.includes(String(item.value)),
		) as unknown as IOption[];

		if (!watch('periodoPacto')) setValue('periodoPacto', periodoPacto);
		if (!watch('bimestre')) setValue('bimestre', bimestre);
		if (!watch('pactuado')) setValue('pactuado', pactuado);
		setValue('indicador', indicador);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, bimestres, parsedActivePacts, indicadores]);

	const onHandleSubmit = (values: ResulterFilterForm) => {
		const isInvalid = isEmpty(cleanDeep(values));

		setIsInvalid(isInvalid);

		if (!isInvalid) {
			setParams(
				cleanDeep({
					etapa: String(values.etapa?.value || ''),
					bimestre: String(values.bimestre?.data?.bimestre || ''),
					ano: String(values.bimestre?.data?.ano || ''),
					anoInicio: String(values.periodoPacto?.data?.anoInicio || ''),
					anoFim: String(values.periodoPacto?.data?.anoFim || ''),
					idPacutados: String(values.pactuado?.map(values => values.value) || ''),
					idIndicadores: String(values.indicador?.map(values => values.value) || ''),
					page: String(FIRST_PAGE),
				}),
			);
		}
	};

	return (
		<Flex as="form" onSubmit={handleSubmit(onHandleSubmit)} sx={styles.container}>
			<Flex sx={styles.selectsContainer}>
				<Box sx={styles.etapaContent}>
					<Controller
						name="etapa"
						control={control}
						render={({ field }) => (
							<Select
								label="Etapa"
								placeholder="Selecione a etapa"
								options={STEPS_OPTIONS}
								value={field?.value}
								onChange={e => {
									field?.onChange(e);
									resetFormField('pactuado');
								}}
								dataTestId="select--etapa"
								errorMessage={errors?.etapa?.message}
								isLoading={isLoadingPactPeriods}
							/>
						)}
					/>
				</Box>

				<Box sx={styles.periodoContent}>
					<Controller
						name="periodoPacto"
						control={control}
						render={({ field }) => (
							<Select
								label="Período do pacto"
								placeholder="Selecione o periodo"
								options={parsedOptionArray(periodoPacto, 'periodoConcatenado', 'periodoConcatenado')}
								onChange={e => {
									field?.onChange(e);
									resetFormField('bimestre', '');
								}}
								value={field?.value}
								dataTestId="select--periodo"
								errorMessage={errors?.periodoPacto?.message}
							/>
						)}
					/>
				</Box>

				<Box sx={styles.bimestreContent}>
					<Controller
						name="bimestre"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								tooltipInfo={BIMESTER_TOOLTIP_INFO}
								label="Bimestre"
								options={parsedBimesters}
								placeholder="Selecione o bimestre"
								onChange={onChange}
								value={value}
								dataTestId="select--bimestre"
								errorMessage={errors?.bimestre?.message}
								isDisabled={isBimesterLoading || !pactPeriod?.data?.anoFim || !pactPeriod?.data?.anoInicio}
								isLoading={isBimesterLoading && !!pactPeriod?.value}
							/>
						)}
					/>
				</Box>

				<Box sx={styles.pactuadoContent}>
					<Controller
						name="pactuado"
						control={control}
						render={({ field }) => (
							<Multiselect
								label="Pactuado"
								placeholder="Selecione um pactuado"
								options={parsedActivePacts}
								onChange={e => {
									field?.onChange(e);
									resetFormField('indicador');
								}}
								value={field?.value}
								dataTestId="select--pactuado"
								errorMessage={errors?.pactuado?.message}
								isDisabled={!stepFieldData}
								isLoading={isLoadingActivePacts && !!stepFieldData?.value}
							/>
						)}
					/>
				</Box>

				<Box sx={styles.indicadorContent}>
					<Controller
						name="indicador"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Multiselect
								label="Indicador"
								placeholder="Selecione um indicador"
								options={parsedOptionArray(indicadores, 'nome', 'id')}
								onChange={onChange}
								value={value}
								dataTestId="select--indicador"
								errorMessage={errors?.indicador?.message}
								isLoading={isLoadindIndicators}
								isClearable={false}
							/>
						)}
					/>
				</Box>
			</Flex>
			{isInvalid && (
				<Text sx={styles.errorText} data-testid="text--error">
					É necessário escolher um filtro para continuar.
				</Text>
			)}
			<Button type="submit" sx={styles.button} data-testid="button--submit">
				Pesquisar
			</Button>
		</Flex>
	);
};

export default ResulterFilter;
